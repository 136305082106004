import { Component } from '@angular/core';

import { Platform, AlertController  } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
//import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { register } from 'swiper/element/bundle';


register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    //private oneSignal: OneSignal,
    private alertCtrl: AlertController,
     public router:Router,
     private storage: Storage
  ) {
    //this.storageInit();
    defineCustomElements(window);
    this.initializeApp();

  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
        
    });
  }

  

  
 
  async showAlert(title, msg, url) {
    const alert = await this.alertCtrl.create({
      header: title,
      subHeader: msg,
      buttons: [
        {
          text: `Action: ${url}`,
          handler: () => {
            // E.g: Navigate to a specific screen
            if(url)
            {

              this.router.navigate([url]);
            }

          }
        }
      ]
    })
    alert.present();
  }
}
