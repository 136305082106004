import { Product, CartService } from '../../services/cart.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { Router } from "@angular/router";
import { ModalController, AlertController,NavController,ActionSheetController , NavParams, IonContent} from '@ionic/angular';
import {LocationService} from '../../services/location.service';
import {PaymentMethodsComponent} from '../payment-methods/payment-methods.component';
import {PayComponent} from '../pay/pay.component';
import {PaymentPreviewComponent} from '../payment-preview/payment-preview.component';
import {ProductsService} from '../../services/products';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { LoginService} from '../../services/login';
import { environment } from '../../../environments/environment';
import { CommunityService} from '../../services/community';
import { PhotoService } from '../../services/photo.service';
import {ChaEventsService} from '../../services/chaevents';




@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
})
export class ImagePreviewComponent implements OnInit {
   @ViewChild(IonContent) content: IonContent;

  current_user:any;
  community:any;
  communitymessage:any;
  attachments:any = [];
  posts:any=[];
  chaphoto:any;
  photo:any=[];
  photos:any=[]
  chaloading = 0;
  id:any;
  jersey:any = 0;
 
  constructor(
    public params: NavParams,
    private cartService: CartService, 
    private modalCtrl: ModalController, 
    private viewCtrl: ModalController,
    private alertCtrl: AlertController,
    public navCtrl: NavController,
    private locationService:LocationService,
    public actionSheetController: ActionSheetController,
    private productsService:ProductsService,
    private iab: InAppBrowser, 
    private loginService: LoginService,
    private communityService: CommunityService,
    public photoService: PhotoService,
    public event:ChaEventsService,
    private scroller: ViewportScroller, 
    private router: Router) {

    this.current_user = this.params.get('current_user');
    this.photos = this.params.get('photos');
    this.id = 'cha' + this.params.get('pic_index');
    this.jersey = Number(this.params.get('jersey'));
    
    //console.log('pic_index:',this.id);
    //this.scroller.scrollToAnchor(this.id);
    //this.router.navigate([], { fragment: this.id });
     }
  
  ngOnInit() {
    this.scrollTo(this.id);

  }

  ngAfterViewInit(){

    

  }
 

 contactsPic(data){
    return this.baseUrl(data) + data;
  }

  imageCall(data){
    return environment.server + data;
  }

  baseUrl(data){
    //console.log('image:',data);
      if(data){


      let ext = data.split('.');

      if(ext.length > 1)
      {
        return 'assets/img/avatar/';

      }
      else{

        return environment.server + 'uploads/images/';
      }
    }
    

  }


 

  async presentAlert(title,subtitle,message)
  {
    const alert = await this.alertCtrl.create({
        header: title,
        subHeader: subtitle,
        message: message,
        buttons: ['OK']
      });

      await alert.present();
  }

close(photos){
  //this.event.photoPublish(this.photos);
  this.viewCtrl.dismiss(photos);

}

base(url){
    return url;

  }

   scrollTo(_id: string) {
      let y = document.getElementById(_id);
      if(y != null)
      {
        this.content.scrollToPoint(0, y.offsetTop);

      }
      
  }

  removePhoto(index){
    this.photos.splice(index, 1);
    if(this.photos.length == 0){
      //this.event.photoPublish(this.photos);
      this.close(this.photos);
    }
  }



}