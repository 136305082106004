// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-thumbnail {
  width: 30%;
  margin: 3%;
}
ion-thumbnail img {
  max-width: 100%;
  min-width: 100%;
}

ion-content {
  --background: none;
  background: url("/assets/img/hm_theme_bg.png") !important;
  background-repeat: no-repeat !important;
  background-position: center bottom !important;
  background-size: cover !important;
}`, "",{"version":3,"sources":["webpack://./src/app/vendor-list/vendor-list.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,UAAA;AACJ;AAAI;EACI,eAAA;EACA,eAAA;AAER;;AAGA;EACI,kBAAA;EACA,yDAAA;EACA,uCAAA;EACA,6CAAA;EACA,iCAAA;AAAJ","sourcesContent":["ion-thumbnail {\n    width: 30%;\n    margin: 3%;\n    img {\n        max-width: 100%;    \n        min-width: 100%;\n    }\n\n}\n\nion-content {\n    --background: none;\n    background: url('/assets/img/hm_theme_bg.png') !important;\n    background-repeat: no-repeat !important;\n    background-position: center bottom !important;\n    background-size: cover !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
