// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-checkbox {
  --size: 24px;
}

ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid #023e48;
}

.search-input {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2 2m0 0l2-2m-2 2V6a2 2 0 012-2h2a2 2 0 012 2v10a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path></svg>');
  background-repeat: no-repeat;
  background-position: 95% center;
  padding-right: 30px; /* Adjust this value to accommodate the width of the icon */
}`, "",{"version":3,"sources":["webpack://./src/app/common/add-group/add-group.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAGE;EACE,kBAAA;EACA,yBAAA;AAAJ;;AAIE;EACE,2UAAA;EACA,4BAAA;EACA,+BAAA;EACA,mBAAA,EAAA,2DAAA;AADJ","sourcesContent":["ion-checkbox {\n    --size: 24px;\n    // --checkbox-background-checked: #6815ec;\n  }\n  \n  ion-checkbox::part(container) {\n    border-radius: 6px;\n    border: 2px solid #023e48;\n  }\n\n  \n  .search-input {\n    background-image: url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" fill=\"none\" viewBox=\"0 0 24 24\" stroke=\"currentColor\" class=\"w-6 h-6\"><path stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"2\" d=\"M10 14l2 2m0 0l2-2m-2 2V6a2 2 0 012-2h2a2 2 0 012 2v10a2 2 0 01-2 2h-2a2 2 0 01-2-2z\"></path></svg>');\n    background-repeat: no-repeat;\n    background-position: 95% center;\n    padding-right: 30px; /* Adjust this value to accommodate the width of the icon */\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
