// handle socket io connections

import { Injectable, NgModule } from '@angular/core';
import { Config } from '../config';
import * as io from 'socket.io-client';
import { Socket } from 'ngx-socket-io';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

 
@Injectable()
export class SocketService{
	public socket = io(environment.socketbase);
	public socket_communities = io(environment.socketbase+'/community');
	public chasocket = null;

	constructor(private chasocket1: Socket) {

		 //super({ url: environment.socketbase, options: {} });
		//this.socket = chasocket.
		// connect to our server
		// change this url here
		//this.socket = io.connect(environment.socketbase,{secure: true});
		//this.socket = io.connect(environment.socketbase);

		//this.socket = 

		this.chasocket = this.socket;
		//this.chasocket.connect();

		//console.log('socket.io CHECK11:',this.socket);
		//console.log('socket.io CHECK22:',this.socket.connected);
		if (this.socket.connected)
		{
			//console.log('socket.io is connected.');

		}
		else{

			//console.log('socket.io is NOT connected.');

		}

  

	}

	// generate a unique custom request id
	private makeId(len) {
		var text = '';
		var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$';
		for (var i = 0; i < (len || 10); i++) {
			text += possible.charAt(Math.floor(Math.random() * possible.length));
		}
		return text;
	}

	// send an event and get a response back
	public promise(eventName, request) {
		return new Promise((resolve, reject) => {


;
			var success = response => {
				//console.debug(eventName + '|' + request.responseName + ': complete!');
				this.chasocket.removeListener(request.responseName, success);
				resolve(response);
			};

			request.responseName = '$response$' + this.makeId(10) + '$';
			//console.debug(eventName + '|' + request.responseName + ': Sending socket promise...');
			this.chasocket.on(request.responseName, success);
			this.chasocket.emit(eventName, request);
		});
	}

	public chapromise(eventName,request){
		//console.log('entry into new socket:::::::::::::::',eventName);
		return new Promise((resolve, reject) => {
			this.chasocket
             .fromEvent(eventName)
             .pipe(map((data) => {
             	//console.log('new socket:::::::::::::::',data);
             	resolve(data);
             }));

		});


	}

	public emit(...args: any[]) {
		//this.socket.emit(...args)
		//this.chasocket.emit(args);
		// @BUG
		//console.log('see arguments:',args);
		if(args.length == 1)
		{
			this.chasocket.emit(args[0]);

		}
		else{
			this.chasocket.emit(args[0], args[1]);

		}
		
	}
	public emitb(chaname, chavalue) {
		this.chasocket.emit(chaname, chavalue);
		//this.chasocket.emit(args);
		// @BUG
		//this.socket.emit(args[0], args[1]);
	}

	public on(name, data) {
		this.chasocket.on(name, data);
		//this.chasocket.on(name, data);
	}

	public removeListener(name, data) {
		this.chasocket.removeListener(name, data);
		//this.chasocket.removeListener(name, data);
	}

	public getInquiry = () => {
        return Observable.create((observer) => {
            this.chasocket.on('monitorinquiries', (inquiry) => {
                observer.next(inquiry);
            });
        });
    }


    public getOrders = () => {
        return Observable.create((observer) => {
            this.chasocket.on('monitororders', (orders) => {
                observer.next(orders);
            });
        });
    }

    public getDealerInquiryUpdate = () => {
        return Observable.create((observer) => {
            this.chasocket.on('dealerstatus', (inquiry) => {
                observer.next(inquiry);
            });
        });
    }
    public onNameSpace(name, data,namespace) {
		namespace.on(name, data);
		//this.chasocket.on(name, data);
	}
    public channelInitialization(communities){
    	if (this.socket_communities.connected){
	    	for(let community of communities){
	    		
	    		this.socket_communities.emit('join',{room:'community-'+community._id})
	    	}
    	}

    	

    }
}