import { Component, OnInit } from '@angular/core';
import { NavController,ModalController, NavParams } from '@ionic/angular';
import {Config} from '../../config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MoneyService} from '../../services/money';
import { AlertController } from '@ionic/angular';
import {HelperService} from '../../services/helper';
import { ChaEncryptionService} from '../../services/chaencryption';
import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';
@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss'],
})
export class PayComponent implements OnInit {
	data:any;
	pin:any;
  placeholder:any ='Enter Amount';
	submitForm: FormGroup;
  type:any;
  mode:any;

 
  constructor(
  	public navParams: NavParams,
  	public viewCtrl: ModalController,
  	public formBuilder: FormBuilder,
  	private moneyService:MoneyService,
  	public alertController: AlertController,
    public helper:HelperService,
    public encryptionService: ChaEncryptionService,
    private spinnerDialog: SpinnerDialog) {
  	this.data = this.navParams.get('data');
    this.type = this.data.type;
    this.mode = this.data.mode;

    if(this.type == 'scanpay')
    {
      this.submitForm = formBuilder.group({
        pin: ['', Validators.compose([Validators.required])],
        amount: ['', Validators.compose([Validators.required])]

        
      });

    }
    else
    {
    	this.submitForm = formBuilder.group({
          pin: ['', Validators.compose([Validators.required])]

          
      	});
    }

   }

  ngOnInit() {}

  context(){
  	let label = '';
  	switch (this.data.type) {
  		case "airtime":
  			label = 'Buy Airtime Amount: ' +Config.chaCurrency+ this.data.amount;
        //this.placeholder = 'Enter Ufulupay Secure Pin';
  			break;
      case "scanpay":
        label = 'Please Enter Amount to Pay';
        //this.placeholder = 
        break;
      case "sellmusic":
        label = 'Payment Amount: ' +Config.chaCurrency+ this.data.amount;
        //this.placeholder = 'Enter Ufulupay Secure Pin';
        break;
      case "cart":
        label = 'Payment Amount: ' +Config.chaCurrency+ this.data.amount;
        //this.placeholder = 'Enter Ufulupay Secure Pin';
        break;
  		
  		default:
  			// code...
  			break;
  	}

  	return label;
  }

  verify(){
    this.spinnerDialog.show();
  	let data = this.submitForm.value;
  	let newdata = {};


    console.log('CART****:',this.data.cart);
   

  	switch (this.data.type) {
  		case "airtime":
  			newdata = {
		   		user:this.data.user,
		   		amount: this.data.amount,
		   		phone: this.data.phone,
		   		pin: data.pin,
		   		transaction_type:'topup',
          remarks: 'airtime'
		   		};
  			break;
      case "scanpay":
        newdata = {
           user:this.data.user,
           amount: data.amount,
           to: this.data.to,
           pin: data.pin,
           transaction_type:'scanpay',
          remarks: 'scanpay'
           };
        break;
      case "sells":
        newdata = {
           user:this.data.user,
           amount: this.data.amount,
           mra:this.data.mra,
           inquiry_reference: this.data.inquiry_id,
           pin: this.encryptionService.encrypt(data.pin),
           transaction_type:'spareparts',
           remarks: 'Spare Parts Purchase',
           shipping_type: this.data.shipping_type

           };
        break;
      case "cart":
        newdata = {
           user:this.data.user,
           amount: this.data.amount,
           pin: this.encryptionService.encrypt(data.pin),
           transaction_type:'cart',
           remarks: 'Cart Purchase',
           shipping_type: this.data.shipping_type,
           cart:JSON.stringify(this.data.cart)

           };
        break;
      case "sellmusic":
        newdata = {
           user:this.data.user,
           amount: this.data.amount,
           
           pin: data.pin,
           transaction_type:'music',
           remarks: 'Music',
           

           };
        break;
  		
  		default:
  			// code...
  			break;
  	}

    console.log('NEWWWWWWW paid:=======',newdata);

    
  	 
   	this.moneyService.Pay(newdata).then((results)=>{
   		
   		if(results['msg'] == 'insufficient money')
   		{
         this.spinnerDialog.hide();
   			this.presentAlert('Notification','Not Successful','Insufficient Money');
   			//this.dismiss('');
         
         this.submitForm.reset();

   		}
      else if(results['msg'] == 'Please provide pay parameters') 
      {
        this.spinnerDialog.hide();
         //this.presentAlert('Notification','Successful',results['msg']);
         this.dismiss('invalid type');

      }
   		else
   		{
         this.spinnerDialog.hide();
   			//this.presentAlert('Notification','Successful',results['msg']);
          if(this.mode == 'community')
          {
            this.dismiss({state:'success',amount:newdata['amount']});
          }
          else
          {
   			    this.dismiss('success');
           }

   		}


   	},(error)=>{
   		console.log('check pay error ', error);
       this.spinnerDialog.hide();
   		this.presentAlert('Error','',error.msg);
   		this.dismiss('');

   	});


  }

  dismiss(msg){
  	this.viewCtrl.dismiss(msg);
  }


  async presentAlert(title,subtitle,message) {

    if (subtitle == 'Not Successful')
    {
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: title,
        message: message,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: (blah) => {
              console.log('Confirm Cancel: blah');
              this.dismiss('');
            }
          }, {
            text: 'TopUp',
            handler: () => {
              console.log('Confirm Okay');
              this.dismiss('topup');
            }
          }
        ]
      });

      await alert.present();

    }
    else
    {

      const alert = await this.alertController.create({
        header: title,
        subHeader: subtitle,
        message: message,
        buttons: ['OK']
      });

      await alert.present();
    }
  }

}
