import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController,ModalController,NavParams,ActionSheetController,AlertController ,LoadingController } from '@ionic/angular';
import { LoginService} from '../../services/login';
import { ChatService} from '../../services/chat';
import { Storage } from '@ionic/storage';
import {TopUpComponent} from '../../top-up/top-up.component';
import {AddServiceComponent} from '../add-service/add-service.component';
import {MoneyService} from '../../services/money';
import {HelperService} from '../../services/helper';
import {VerificationComponent} from '../../verification/verification.component';


@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
})
export class PaymentMethodsComponent implements OnInit {

  scrollPosition: number = 0;
 chat = {
    id: null,
    users: [],
    name: '',
    messages: []
  }
  balance:number;
  accounts:any;
  verificationId:any;
  ErrorMsg:any;
  message:any = 'processing...';
  data:any;
  back:any = 0;
  incomingData:any;

  constructor(
    public navCtrl: NavController, 
    public navParams: NavParams,
    public modalCtrl: ModalController,
    private loginService: LoginService,
    private chatService: ChatService,
    public storage: Storage,
    private moneyService:MoneyService,
    public actionSheetController: ActionSheetController,
    public alertController: AlertController,
    private helperService:HelperService,
    public loadingCtrl: LoadingController,
    public viewCtrl: ModalController,
    public router:Router) {
    this.balance = 100;
    
    this.incomingData = navParams.get('data');
    //this.chat = this.chatService.getChatById(navParams.get('chatId'));

    this.loginService.checkIfloggedIn();

    // if we refreshed on this page, then go back to chats
    console.log('check user');
    console.log(this.loginService.user)
    /*if (!this.loginService.user) {
      this.loginService.go();
      return;
    }
    console.log('this user details are new:');
    console.log(this.loginService.user);*/
  }

  ngOnInit() {
    this.getAccounts();
  }


  


async addservice() {

  let modal = await this.modalCtrl.create(
      { component: AddServiceComponent,
        componentProps:{user:this.loginService.user}
      });
    modal.present();
     let { data } = await modal.onDidDismiss();

     if(data == 'Verified')
        {
          //this.login(userdata); 
          //this.createpin(userdata);

        }
         else if(data == 'success')
        {
          this.back = 1;

          this.getAccounts();

        }

      return await modal.present();

    


    
}

getAccounts(){
  this.moneyService.getMoneyAccounts().then((result)=>{
    console.log('Check Accounts',result);
    
    
      this.accounts = result['msg'];
    
    

  },(error)=>{
    //console.log('check get account error', error);
    if(error.error['msg'] == 'No record found')
    {
      this.accounts = [];
    }
  });
}


checkService(service){
  if(service == '5c991f34a02a2cc2a225bbd9')
  {
    return 'Airtel';
  }
  else if(service == '5c9d1e69effdefeedbee6c70'){
    return 'Tnm';

  }

}


recommended() {
//this.navCtrl.push('Recommended');
}

accountDelete(id){
  let data = {account_id: id};
  this.moneyService.deleteAccount(data).then((result)=>{

    console.log('deleted',result);
    this.getAccounts();

  },(error)=>{
    console.log('check delete error', error);
  });


}



setDefaultAccount(id,userid){
  let data = {account_id: id,
              userid:userid};
   console.log('check whats in', data);
  this.moneyService.setDefaultAccount(data).then((result)=>{

    console.log('set default success',result);
    this.getAccounts();

  });


}


pay(data){
  console.log('PAYMENGT::',data);
  this.viewCtrl.dismiss(data);
}


async presentActionSheet(id,userid) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Actions',
      buttons: [{
        text: 'Delete',
        role: 'destructive',
        icon: 'trash',
        handler: () => {
          console.log('Delete clicked',id);
          this.accountDelete(id);
        }
      }, {
        text: 'Set as Default',
        icon: 'checkmark-circle',
        handler: () => {
          console.log('Share clicked');
          this.setDefaultAccount(id,userid)

        }
      }, {
        text: 'Edit',
        icon: 'create',
        handler: () => {
          console.log('Play clicked');
        }
      }, {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();
  }

async getVerificaiton(accountResult){
  let modal = await this.modalCtrl.create(
      { component: VerificationComponent,
        componentProps:{phoneNumber: '+'+this.data.account_name,verificationId:accountResult['verificationId']}
      });
    modal.present();
     let { data } = await modal.onDidDismiss();

     if(data == 'Verified')
        {
            let updateData = {
              id: accountResult['id']
            }
            console.log('check ID',accountResult);
            this.updateAccount(updateData);
        }
         

      await modal.present();
}


async loading(data){


  let loading = await this.helperService.loading(this.loadingCtrl,this.message);
    await loading.present().then(()=>{

  this.moneyService.generateVerificationId(data).then((result)=>{
    loading.dismiss();

      let accountResult = result['msg'];

      this.getVerificaiton(accountResult);





      

  },(err)=>{

    //console.log('check Error for servic', err);
    let message = JSON.parse(err._body);
    this.presentAlert('Error Notification',message.msg);
    loading.dismiss();
    


  });


  });



  }

  verify(accountdata){


  
  let data = {
    userid :this.loginService.user._id,
    account_id:  accountdata._id,
    account_name: accountdata.account_name,
   

  }

  this.data = data;

  this.loading(data);
  



}





updateAccount(data){
 this.moneyService.updateAccount(data).then((result)=>{
   this.viewCtrl.dismiss();

 },(err)=>{

 });

}



async presentAlert(header,message) {
    const alert = await this.alertController.create({
      header: 'Notice',
      subHeader: header,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

 chaBack(){
   this.router.navigate(['/dashboard/tab3'])
 }


 dismiss() {
    this.viewCtrl.dismiss();
  }

}
