import { Injectable,ViewChild} from '@angular/core';
import { Platform, NavController,LoadingController} from '@ionic/angular';
import {AudioService} from './audio';
import {SocketService} from './socket';
import {LoginService} from './login';
import {ChatService} from './chat';
import { Storage } from '@ionic/storage';
import { Config } from '../config';
import * as marked from "marked";
import { RestProvider } from './rest/rest';
import {Transaction} from '../models/transaction';
import {ChaEventsService} from '../services/chaevents';
//import { ChatPage } from '../pages';


@Injectable()
export class UserService {

	user = null
	playLoginSound = true
	complete = null
	fulldata:any;
	messages = []
	data:any;
	transId:any;
	status = 0;
	loading:any;
	transactionResults:Transaction;





	constructor(
	 public audio:AudioService,
	 public socket:SocketService,  
	 public storage:Storage,
	 private chatService:ChatService,
	 private loginService:LoginService,
	 public restProvider: RestProvider,
	 private events: ChaEventsService,
	 public loadingCtrl: LoadingController) {
	 	
		
	}
	

	public getUserById(id){

		return new Promise((resolve,reject) => {
			this.storage.get('token').then((token) => {

			this.restProvider.authorisedGet(token.token,'api/pay_user/?id='+id).then((result)=>{
					console.log(result);
					resolve(result);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);

			});


		});

	}


	public searchUser(data){
    
    //console.log('CHECK STATUS STATUS:::::',data);
    return new Promise((resolve,reject) => {
      this.restProvider.authSendToApi(data,'api/getuser').then((result)=>{
        
        resolve(result['msg']);
        },(err) => {
          reject(err);
          console.log(err);
        }
      );

    });
  }


  public addContact(data){
    
    //console.log('CHECK STATUS STATUS:::::',data);
    return new Promise((resolve,reject) => {
      this.restProvider.authSendToApi(data,'api/savecontacts').then((result)=>{
        
        resolve(result['msg']);
        },(err) => {
          reject(err);
          console.log(err);
        }
      );

    });
  }

  public resetPassword(data){
    
    //console.log('CHECK STATUS STATUS:::::',data);
    return new Promise((resolve,reject) => {
      this.restProvider.authSendToApi(data,'api/resetpassword').then((result)=>{
        
        resolve(result['msg']);
        },(err) => {
          reject(err);
          console.log(err);
        }
      );

    });
  }



	



}
