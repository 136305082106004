import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-chat-message-action',
  templateUrl: './chat-message-action.component.html',
  styleUrls: ['./chat-message-action.component.scss'],
})
export class ChatMessageActionComponent  implements OnInit {

  constructor(
    public popoverController: PopoverController,
    public params : NavParams
  ) { }

  ngOnInit() {}

  dismiss(role){
    console.log('role::',role);
    this.popoverController.dismiss(role);
 
  }

}
