import { Injectable } from '@angular/core';

import {Transaction} from '../../models/transaction';

import { Storage } from '@ionic/storage';
import { environment } from '../../../environments/environment';


@Injectable()
export class RestProviderLocal {

	apiUrl = environment.server;
	transactionApi = environment.transactionApi;
	apiUrlNode =environment.server;


  constructor(
  	public storage: Storage, ) {
    //console.log('Hello RestProviderLocal Provider');
  }

  getFromStorage(key){
  	return new Promise((resolve, reject) => {

  		this.storage.get(key).then((keydata)=>{

        if(key == null || key == '' || key == undefined)
        {
          reject('');

        }
        else
        {
          resolve(JSON.parse(keydata));

        }



  		});



  	});


  }

   setToStorage(key,data){
     return new Promise((resolve, reject) => {

     //console.log('local store data:', data);
     //console.log('local store data222:', key);

      if(data != null && data.length !=0 && data[0] != null && ("value" in data[0]) == true ){

        //console.log('storage data has entered::::::', data);

        if((data[0]["value"])!= null && ("msg" in data[0]["value"]) == true )
        {

          if(data[0]["value"]["msg"] == 'No record found')
           {
            let newdata =  data;
            newdata[0]["value"]["msg"] = [];
            resolve(newdata);
            }
            else{

              this.storage.set(key,JSON.stringify(data)).then((results)=>{

                     //console.log('ARE YOU REALLY REALLY SAVING1?::::::', results);
                      resolve(results);
                    
             

           });

            }

        }
        else{



           this.storage.set(key,JSON.stringify(data)).then((results)=>{

                     //console.log('ARE YOU REALLY REALLY SAVING2?::::::', results);
                      resolve(results);
                    
             

           });
       }

     }
     else{
       resolve([]);

     }

   });


   }
	

}
