import {ListenerService} from '../services/listeners';
import {ProductsService} from '../services/products';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export function UfuluSchools(listenerService: any,productService: any) { 

	let data =[ {
		type:'root',
		page_title:'Ufulu Schools',
		page_name:'ufulu_schools',
		layout: { name:'search-location',
					parameters:{
						sections:{
							toolbar:{
								buttons:[
								{
									icon:'add-circle-outline',
									name:'Create School 2',
									action:{
										method:'navigate',
										type:'modal',
										page:'create_school'
									}
								},
								{
									icon:'school-outline',
									name:'Manage School',
									action:{
										method:'navigate',
										type:'root',
										page:'manage_school'
									}
								},
								{
									icon:'bus-outline',
									name:'Add School Bus',
									action:{
										method:'navigate',
										type:'root',
										page:'add_school_bus'
									}
								}

								]
							},
							search: {
		                        type: 'location_filter',
		                        data: {
		                            getItems: 'getProducts',
		                            items: 'products',
		                            items_reserve: 'products_reserve',
		                            searchItems: 'searchProducts',
		                            groupid: 'current_community',
		                            dataService: 'productService',
		                            chalimit: 10,
		                            locationmenu: 'locationmenu',
		                            placeholder: 'Search for Schools'
		                        }
		                    },
							footer:{}
						}
					}
				}


	},

	{
		type:'modal',
		page_title:'Create Schools',
		page_name:'create_school',
		layout: { 	name:'cha-form',
					parameters:{
						sections:{
							page_info:{
								title:'Enter your school details',
								description:'You shall be required to join the community where the school exist. If there is no community in your Area please create one'
							},
							toolbar:{
							},
							form_builder:[
							  
							  {
							    name: 'school_name',
							    title:'School Name',
							    validators: ['required'],
							    type: 'input'
							  },
							  {
							    name: 'school_category',
							    title:'School Category',
							   validators: ['required'],
							    type: 'dropdown',
							    action:[
							    {
							    	method:'dropdown',
							    	backend_url:'school_category'
							    }]
							  },
							  {
							    name: 'school email',
							    title:'School Email',
							    validators: ['email'],
							    type: 'input'
							  }
							],
							form_buttons:[
							{
								button_name:'add_location',
								button_title:'Add School Location',
								button_type: 'form_button',
								action:[
							    {
							    	method:'logic_class',
							    	function:'add_location',
							    	icon:''
							    }]

							},
							{
								button_name:'create_school',
								button_title:'Create School',
								button_type: 'save_button',
								action:[
							    {
							    	method:'endpoint',
							    	backend_url:'save_school'
							    	
							    }]

							}

							]
						}
					}
				}
	}


	];

	return data;

	
	
}