/**
 * Ionic 4 Spotify app starter - Ion Spotify (https://store.enappd.com/product/ionic-4-spotify-app-starter)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { Component, OnInit, Input } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Router } from '@angular/router';
import { SpotifyService } from '../../../services/spotify.service';
import { AddsongslistmodalComponent } from '../addsongslistmodal/addsongslistmodal.component';

@Component({
  selector: 'app-addsongsmodal',
  templateUrl: './addsongsmodal.component.html',
  styleUrls: ['./addsongsmodal.component.scss'],
})
export class AddsongsmodalComponent implements OnInit {
  @Input() value: any;
  addSongActions: any;
  modalData: any;

  constructor(
    public modalCtrl: ModalController,
    public route: Router,
    public serviceProvider: SpotifyService,
    public navParam: NavParams
  ) {
    this.modalData = this.navParam.get('value');
    console.log(this.modalData);
    this.addSongActions = this.serviceProvider.addSongActions;
  }

  ngOnInit() { }
  closeModal() {
    this.modalCtrl.dismiss();
  }
  async goForActions(list) {
    console.log(list);
    switch (list.type) {
      case 'Add':
        this.closeModal();
        const modal = await this.serviceProvider.loadModal(AddsongslistmodalComponent);
        modal.present();

        console.log('modal dissmissed');
        break;
      case 'collaborative':
        const toast = await this.serviceProvider.showToast('Marked playlist as Non-collaborative');
        await toast.present();
        this.closeModal();
        break;
      case 'Rename':
        this.closeModal();
        break;
      case 'Delete':
        this.closeModal();
        break;
      case 'Find':
        this.closeModal();
        break;
      case 'Short':
        this.closeModal();
        break;
      case 'Share':
        this.closeModal();
        break;
      case 'Public':
        this.closeModal();
        break;
    }
  }
}
