import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HelperService} from '../services/helper';

@Injectable({
    providedIn: 'root'
})
export class ChaEventsService {

    private eventSubject = new Subject<any[]>();
    private userloginSubject = new Subject<any>();
    private userlogoutSubject = new Subject<any>();
    private authSubject = new Subject<any>();
    private chatMessageSubject = new Subject<any>();
    private chatPaymentSubject = new Subject<any>();
    private callSubject = new Subject<any>();
    private allUsersSubject = new Subject<any>();
    private handleOfferSubject = new Subject<any>();
    private handleCandidateSubject = new Subject<any>();
    private handleAnswerSubject = new Subject<any>();
    private photoSubject = new Subject<any>();
    private photoSubjectB = new Subject<any>();
    private sparesSubject = new Subject<any>();
    private cartSubject = new Subject<any>();
    private imageSubject = new Subject<any>();
    private communitySubject = new Subject<any>();
    private communityStatusSubject = new Subject<any>();
    private communityListSubject = new Subject<any>();
    private productListSubject = new Subject<any>();
    private communityMessageSubject = new Subject<any>();
    private communityChatStatus = new Subject<any>();
    private justLoggedInSubject = new Subject<any>();
    private communityuserchangedSubject = new Subject<any>();
    private afterlogoutchangedSubject = new Subject<any>();
    private coordinatesSubject = new Subject<any>();
    
    private dynamicDataSubject = new Subject<{ [key: string]: [value: any[]]}[]>();

     constructor(public helperService:HelperService){


     }

    justLoggedInPublish( data: any){

        this.justLoggedInSubject.next(data);

    }

    coordinatesPublish( data: any){

        this.coordinatesSubject.next(data);

    }
    
    afterlogoutchangedPublish( data: any){

        this.afterlogoutchangedSubject.next(data);

    }
    communityuserchangedPublish(data: any){

        this.communityuserchangedSubject.next(data);

    }
    dynamicDataPublish(data: {[key:string]:[value: any[]]}[]) {
        this.dynamicDataSubject.next(data);
    }

    publish(chaindex:any, data: any) {
        this.eventSubject[chaindex].next(data);
    }
 
    userLoginPublish( data: any) {
        this.userloginSubject.next(data);
    }
    callPublish( data: any) {
        this.callSubject.next(data);
    }
    userLogoutPublish( data: any) {
        this.userlogoutSubject.next(data);
    }
    authPublish( data: any) {
        this.authSubject.next(data);
    }
    chaMessagePublish( data: any){
        this.chatMessageSubject.next(data);
    }
    chaPaymentPublish( data: any){
        this.chatPaymentSubject.next(data);
    }
    allUsersPublish( data: any) {
        this.allUsersSubject.next(data);
    }

    handleOfferPublish(data:any){
        this.handleOfferSubject.next(data);
    }

    handleCandidatePublish(data:any){
        this.handleCandidateSubject.next(data);
    }

    handleAnswerPublish(data:any){
        this.handleAnswerSubject.next(data);
    }
    photoPublish(data:any){
        this.photoSubject.next(data);
    }
    photoPublishB(data:any){
        this.photoSubjectB.next(data);
    }

    sparesPublish(data:any){
        this.sparesSubject.next(data);
    }

     cartPublish(data:any){
        this.cartSubject.next(data);
    }

    imagePublish(data:any){
        this.imageSubject.next(data);
    }
    communityPublish(data:any){
        this.communitySubject.next(data);
    }
    communityStatusPublish(data:any){
        this.communityStatusSubject.next(data);
    }
    communityListPublish(data:any){
        this.communityListSubject.next(data);
    }

    communityMessagePublish(data:any){
        this.communityMessageSubject.next(data);
    }
    productListPublish(data:any){
        this.productListSubject.next(data);
    }
    /*
    communityMessagePublish(data:any){
        this.communityMessageSubject.next(data);
    }*/

    



    dynamicDataRetrieve(chaindex:any): Subject<any> {

        return this.dynamicDataSubject;  
        
    }

    retrieve(chaindex:any): Subject<any> {
        return this.eventSubject[chaindex];
    }

    userLogin(): Subject<any> {

        return this.userloginSubject;
    }
    userLogout(): Subject<any> {
        return this.userlogoutSubject;
    }
    auth(): Subject<any> {
        return this.authSubject;
    }

    chaMessage(): Subject<any> {
        return this.chatMessageSubject;

    }
    chaPayment(): Subject<any> {
        return this.chatPaymentSubject;

    }
    call(): Subject<any> {
        return this.callSubject;

    }
    allUsers(): Subject<any> {
        return this.allUsersSubject;

    }

    handleOffer(): Subject<any> {
        return this.handleOfferSubject;

    }

    handleCandidate(): Subject<any> {
        return this.handleCandidateSubject;

    }

    handleAnswer(): Subject<any> {
        return this.handleAnswerSubject;

    }

    photo(): Subject<any> {
        return this.photoSubject;

    }
     photoB(): Subject<any> {
        return this.photoSubjectB;

    }

    spares(): Subject<any> {
        return this.sparesSubject;

    }

    cart(): Subject<any> {
        return this.cartSubject;

    }

    chaImage(): Subject<any> {
        return this.imageSubject;

    }
    community(): Subject<any> {
        return this.communitySubject;

    }
    communityStatus(): Subject<any> {
        return this.communityStatusSubject;

    }
     communityList(): Subject<any> {
        return this.communityListSubject;

    }
    communityMessage(): Subject<any> {
        return this.communityMessageSubject;

    }

    justLoggedIn(): Subject<any> {
        return this.justLoggedInSubject;

    }

    communityuserchanged(): Subject<any> {

       return this.communityuserchangedSubject;

    }

    afterlogoutchanged(): Subject<any> {

       return this.afterlogoutchangedSubject;

    }

    coordinates(): Subject<any> {

       return this.coordinatesSubject;

    }
    productList(): Subject<any> {
        return this.productListSubject;

    }

}