import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HelperService} from './helper';
import {ChaEventsService} from './chaevents';
import {CommunityService} from './community';
import { SocketService } from './socket';
import {SubscriberService} from './subscribers';
import {Item} from '../models/item';
import { Geolocation,PositionError,Geoposition } from '@awesome-cordova-plugins/geolocation/ngx';
import { Storage } from '@ionic/storage';
import { LoginService} from '../services/login';
import {User} from '../models/user';
 
@Injectable({
    providedIn: 'root'
})
export class ListenerService extends SubscriberService {

    public chasearch:any = 0;
    public products_reserve:any = [];
    public current_community:any = [];
    public communityStatus:any = []
    public global_community_id:any = '';
    public myshop:any =[];
    public shoplist:any = []
    public community_shop_products_categories:any = [];
    public current_user:any = {};


    constructor(
        public events:ChaEventsService,
        public socket:SocketService,
        public helper:HelperService,
        public communityService:CommunityService,
        public geolocation: Geolocation,
        public storage:Storage,
        public loginService: LoginService,

        ){
        super(events);
        this.initSocketListeners();
        this.communityParameters();
        this.checkLogIn();
        //this.communityList(this.current_user);
    }
    public communityList(current_user){
        this.communityService.retrieveCommunityList(current_user).then((data)=>{
            this.communityService.communitylist = data['communitylist'];
            this.communityService.setSessionStorage('communitylist',data['communitylist']);
            this.events.communityStatusPublish(data);
            this.events.communityListPublish(data['communitylist']);
        });
    }


    public checkCurrentCommunity(){
        return new Promise((resolve,reject)=>{
            this.geolocation.getCurrentPosition().then((resp) => {
                let data = {lat:resp.coords.latitude,lng:resp.coords.longitude};

                this.communityService.checkCommunityByCoordinates(data).then((results)=>{
                    if(results =='No record found'){
                       // this.check = true; // no community available
                       reject('No record found');
                    }
                    else{
                      if(results != undefined){
                          let community = results[0];
                          resolve(community);

                      }
                      else{
                          reject('No record found')
                      }
                      
                      
                      //this.community_data = community;
                    }
                });
            });

        });
        


    }

    checkLogIn(){
        
        this.loginService.checkIfloggedIn().then((user)=>{
          if(user != undefined && user != '' && user !== null)
          {
              this.current_user = user as User;
          }
          else{
              //console.log('user data is blank',user);
          }
        },(error)=>{
                //console.log('check user failed in here', error);
            }
        );
    }

    initSocketListeners(){
         let chathis = this;
         this.socket.onNameSpace('communityshops', function(data) {
             //console.log('see if socket is working', data);
             let community_id =  data.room.split('-')[1]
             chathis.getProducts(community_id,this.communityService,null,1)

             //this.events.productListPublish()

        }, chathis.socket.socket_communities);
    }

    public communityParameters(){
        //console.log('CHECK IF MAP COMMUNITY IS INITALIZED """""""""""""""""""""')
        this.initCommunityOffline();
        this.initCommunity();
    }

    public returnCommunityId(){
        return this.global_community_id;
    }




    public getCommunityShop(community_id,userid){

      let data = {
        community_id:community_id,
        userid:userid
      }
      this.communityService.getCommunityShop_by_id(data).then((results)=>{
        let data = results as any[];
        if(data != null && data.length != 0)
        {
          this.myshop = data;
          this.shoplist = this.helper.formartShop(this.myshop);
          
          
          //console.log('Show the shoplist::::____________',this.shoplist);
        }
        


      })
    }

    public async getCommunityShopProductCategory(category_id){

      let data = {
        category_id:category_id
      }
      this.communityService.getCommunityShopProductCategory(data).then((results)=>{
        let data = results as any[];
        if(data != null && data.length != 0)
        {
          this.community_shop_products_categories = data;
          
        }
        


      })
    }

    public getProducts(groupid,dataService,event=null,activesocket=0){
        let data = {community_id:groupid};
        //console.log('Data for all products 1A',data);
        return new Promise((resolve,reject) => {

            dataService.getCommunityAllProduct(data).then((results)=>{
                if(results != 'No record found')
                {
                    this.chasearch = 1;
                    if(activesocket == 0 )
                    {
                        this.products = this.helper.mapAttributes(results, 'community_products') as Item[]
                    }
                    // _reserve will keep initial data before search
                    this.products_reserve = this.helper.mapAttributes(results, 'community_products')
                    if(event != null){
                       event.target.complete();
                    }
                    //console.log('PRODUCTSS LISTS!!!!!!!!!!!!!!!',this.products);
                    resolve(this.products);

                }

            },((error)=>{
                this.chasearch = 1;
                reject(error);
                //this.error = 1;
            }))
        });
    }

    //community initializer
    initbCommunity(current_user){
        this.geolocation.watchPosition().subscribe(position => {
            if ((position as Geoposition).coords != undefined) {
                let geoposition = (position as Geoposition);
                //check if its in the same geo fence
                let coordinates = {lat:geoposition.coords.latitude,lng:geoposition.coords.longitude};
                //console.log('gps:',coordinates);
                this.communityService.latitude = geoposition.coords.latitude;
                this.communityService.longitude = geoposition.coords.longitude;
                this.communityService.checkIfGeoFenceChanged(coordinates,current_user).then(data=>{
                    //console.log('Community from coordinates real &&&&&',data);
                    if(data['state']== 'differentcommunity')
                    {
                        this.communityService.communityStatus = data;
                        this.communityStatus = data
                        this.communityService.setSessionStorage('communityStatus',data);
                        this.communityService.retrieveMycommunity(coordinates, current_user).then((data2)=>{
                            this.events.communityStatusPublish(data2);
                            this.communityService.communityStatus = data2;
                            this.communityStatus = data2;
                            this.communityService.setSessionStorage('communityStatus',data2);
                            this.communityService.retrieveCommunityList(current_user).then((data)=>{
                                this.communityService.communitylist = data['communitylist'];
                                this.communityService.setSessionStorage('communitylist',data['communitylist']);
                                this.events.communityStatusPublish(data);
                                this.events.communityListPublish(data['communitylist']);
                            });
                        });
                    }
                    else
                    { 
                        this.events.communityStatusPublish(data);
                        this.communityService.communityStatus = data;



                        this.communityService.setSessionStorage('communityStatus',data);
                    }
                });
            } else { 
                let positionError = (position as PositionError);
                this.communityService.retrieveCommunityList(current_user).then((data)=>{
                    this.events.communityStatusPublish(data);
                    this.communityService.communityStatus = data;
                    this.communityService.setSessionStorage('communityStatus',data);
                    this.events.communityListPublish(data['communitylist']);
                    this.communityService.setSessionStorage('communitylist',data['communitylist']);
                    if(typeof(data['communitylist']) != 'string')
                    {
                        this.communityService.communitylist = data['communitylist'];
                    }
                });
            }
        });
    }



    async initCommunityOffline(){
        this.storage.get('communitylist_'+this.current_user._id).then((communitylist) => {
            if(typeof(communitylist) != 'string' && communitylist != null)
            {
                this.communityService.communitylist = communitylist;
                this.events.communityListPublish(communitylist);
            }
            else
            {
                this.communityService.communitylist = [];
            }
            this.events.communityuserchangedPublish(this.current_user);
        },(err)=>{
            //console.log('--------------------------storage data community list',err);
        });

        this.storage.get('communitydata_'+this.current_user._id).then((communitydata) => {
            if(communitydata != null)
            {
                this.communityService.communitydata = communitydata;
                this.current_community = communitydata;
                this.communityService.setSessionStorage('communitydata',communitydata);
                this.events.communityuserchangedPublish(this.current_user);
            }
        });
    }

    async initCommunity(){
        if(this.current_user == null || this.current_user == '' || this.current_user == undefined || this.helper.isEmpty(this.current_user) == true  )
        {
            this.loginService.refreshData().then((user)=>{
                this.current_user = user;
                this.initbCommunity(this.current_user);
            },(error)=>{
                //console.log('))))))))))))))))))))))))))))))))userlongin error......',error);
            });
        }
        else
        {
            this.initbCommunity(this.current_user);
        }
    }

    initbCommunityc(current_user,coordinates){
       //console.log('monitor coordinates:::',coordinates);
        //let coordinates = {lat:this.communityService.latitude,lng:this.communityService.longitude};
        this.communityService.retrieveMycommunity(coordinates, current_user).then((data2)=>{
            this.events.communityStatusPublish(data2);
            this.communityService.communityStatus = data2;
            this.communityService.setSessionStorage('communityStatus',data2);
            this.communityService.retrieveCommunityList(current_user).then((data)=>{
                this.communityService.communitylist = data['communitylist'];
                this.communityService.setSessionStorage('communitylist',data['communitylist']);
                this.events.communityuserchangedPublish(this.current_user);
            });
            
        });
    }

    checkCommunity(data){
        return new Promise((resolve,reject) => {
            this.communityService.checkCommunityByCoordinates(data).then((results)=>{
                if(results =='No record found'){
                    resolve({state:'no community',community:null});
                }
                else{
                    let community = results[0];
                    //comminity available (true am a member and false not a member)
                    let chajoin = this.communityService.checkIfMemberExist(community.members,this.current_user);
                    if(chajoin == true){
                        this.storage.set('communitydata_'+this.current_user._id,JSON.stringify(community));
                        resolve({state:'autologin',community:community});
                    }
                    else
                    {
                        resolve({state:'promptjoin',community:community});
                    }
                }
            });
        });
    }



//function for search to be modified

public searchProducts(searchword,total, data,dataService,groupid='', start_value=0,chalimit=20){
      
      start_value = start_value + chalimit;
      
      //let data = {};
      if(start_value > Number(total) )
      {
        start_value = 0;
        
      }
      //console.log('monitor:',this.start_value);
      data = {community_id:groupid, searchword:searchword,start_value:start_value};
      //console.log('Data for all products',data);
      return new Promise((resolve,reject) => {
        dataService.searchCommunityProducts(data).then((results)=>{
            let charesults = results as any[]
            if(results != 'No record found')
            {
              this.chasearch = 1;
              if(charesults.length >0){
                this.products = results as Item[];
                resolve(results);

              }
              
              //this.products_reserve = results
              //console.log('Search results LISTS!!!!!!!!!!!!!!!',results);

            }



          },((error)=>{
            this.chasearch = 1;
            reject(error);
            //this.error = 1;

          }))
      });


  }





  //dynamic pages functions

  public async getDropDown(url,dataService){
      return new Promise((resolve,reject) => {
          dataService.getDropDown(url).then((results)=>{
            let charesults = results as any[]
            if(results != 'No record found')
            {
              if(charesults.length >0){
                resolve(results);
              }
            }
            else{
                resolve([]);
            }

          },((error)=>{
            reject(error);

          }));




      });


  }

   

}