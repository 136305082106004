import { Injectable,ViewChild} from '@angular/core';
import { Platform, NavController,LoadingController} from '@ionic/angular';
import {AudioService} from './audio';
import {SocketService} from './socket';
import {LoginService} from './login';
import {ChatService} from './chat';
import { Storage } from '@ionic/storage';
import { Config } from '../config';
import {marked} from 'marked';
import { RestProvider } from './rest/rest';
import {Transaction} from '../models/transaction';
import {ChaEventsService} from '../services/chaevents';
import {environment} from '../../environments/environment';
//import { ChatPage } from '../pages';

 
@Injectable()
export class ProductsService {

	user = null
	playLoginSound = true
	complete = null
	fulldata:any;
	messages = []
	data:any;
	transId:any;
	status = 0;
	loading:any;
	transactionResults:Transaction;


	constructor(
	 public audio:AudioService,
	 public socket:SocketService,  
	 public storage:Storage,
	 private chatService:ChatService,
	 private loginService:LoginService,
	 public restProvider: RestProvider,
	 private events: ChaEventsService,
	 public loadingCtrl: LoadingController) {
	 	marked.setOptions({
			sanitize: true,
			gfm: true
		});
		
		
	} 



	public getProductsByType(type){
		let data ={product_type:type}
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/getproduct').then((result)=>{
				console.log('Products'+type,result);
				if(result == null)
				{
					resolve([]);

				}
				else
				{
					resolve(result['msg']);

				}
				
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public getModel(makeid){
		let data ={makeid:makeid}
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/carmodel').then((result)=>{
				console.log('spareparts',result);
				resolve(result['msg']['Results']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}


	public getVin(data){
		
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/carvin').then((result)=>{
				console.log('spareparts',result);
				resolve(result['msg']['Results']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public saveInquiry(data){
		
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/carpartinquiry').then((result)=>{
				console.log('saved data',result);
				this.socket.emit('monitorinquiries',result);

				resolve(result);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}


	public airtimePay(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/airtimepay').then((result)=>{
				console.log(result);
				resolve(result);
				},(err) => {
					console.log(err);
				}
			);

		});
	}


	public Pay(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/pay').then((result)=>{
				console.log(result);
				resolve(result);
				},(err) => {
					console.log(err);
					reject(err.error);
				}
			);

		});
	}
	// send a message
	public transferMoney(transaction) {
		
		this.data = transaction;
		//this.sending = true;
		//this.blurInput(null);
		//set transaction data
		let data = this.getTransactionId(transaction)

		return data;
		
		

		/*setTimeout(() => {
			this.content.scrollToBottom(300);
			this.sending = false;
		}, 100)*/
		//return 'sucessful';
	}
	private addMessage(message) {
		this.messages.push(message);
		if (Config.markdown) {
			message.marked = message.message ? marked.parse(message.message) : null;
		}
		var prev = null;
		for (let message of this.messages) {
			if (message.from != prev) {
				prev = message.from;
				message.first = true;
			}
		}
	}
	private getTransactionId(transaction) {
		let data = {
			toId:transaction.toId,
			fromId:transaction.fromId,
			amount:transaction.amount,
			remarks:transaction.remarks,
			transaction_type:'moneyTransfer'
			};
		return data; 

	}
	public getStatus(){
		return this.status;
	}
	public updateStatus(status){
		this.status =  status;
	}

	public getMoneyAccounts(){

		return new Promise((resolve,reject) => {
			this.storage.get('token').then((token) => {

			this.restProvider.authGetToApi('api/accounts?secret_token='+token.token).then((result)=>{
					console.log(result);
					resolve(result);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);

			});


		});

	}


	public getDefaultMoneyAccount(){

		return new Promise((resolve,reject) => {
			this.storage.get('token').then((token) => {

			this.restProvider.authorisedGet(token.token,'api/default_account').then((result)=>{
					console.log(result);
					resolve(result);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);

			});


		});

	}

	public getAccounts(){

		return new Promise((resolve,reject) => {
			this.storage.get('token').then((token) => {

			this.restProvider.authorisedGet(token.token,'api/money_accounts').then((result)=>{
					console.log(result);
					resolve(result);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);

			});


		});

	}

	public getMoneyService(){
		return new Promise((resolve,reject) => {
			this.storage.get('token').then((token) => {

			this.restProvider.authorisedGet(token.token,'api/service').then((result)=>{
					//console.log(result);
					resolve(result);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);

			});


		});

	}


	public getTransactions(){
		return new Promise((resolve,reject) => {
			this.storage.get('token').then((token) => {

			this.restProvider.authorisedGet(token.token,'api/get_transactions').then((result)=>{
					//console.log(result);
					resolve(result);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);

			});


		});

	}


	public createPin(data,token){
		console.log('CHECK Pin DATA::',data)
		return new Promise((resolve,reject) => {


			this.restProvider.authSendToApiPin(data,'api/moneyregister',token).then((result)=>{
				console.log(result);
				resolve(result);
				},(err) => {
					console.log(err);
					reject(err);
				}
			);

		});

	}


	public generateVerificationId(data){
		return new Promise((resolve,reject) => {
			//console.log('charlie',JSON.stringify(data));
			this.restProvider.authSendToApi(data,'api/generate_verificaiton').then((result)=>{
				//console.log(result);
				resolve(result);
				},(err) => {
					//console.log(err);
					reject(err);
				}
			);

		});

	}


	public createAccount(data){
		return new Promise((resolve,reject) => {
			//console.log('charlie',JSON.stringify(data));
			this.restProvider.authSendToApi(data,'api/moneyaccount').then((result)=>{
				//console.log(result);
				resolve(result);
				},(err) => {
					//console.log(err);
					reject(err);
				}
			);

		});

	}


	public deleteAccount(data){
		return new Promise((resolve,reject) => {
			//console.log('charlie',JSON.stringify(data));
			this.restProvider.authSendToApi(data,'api/delete_account').then((result)=>{
				//console.log(result);
				resolve(result);
				},(err) => {
					//console.log(err);
					reject(err);
				}
			);

		});

	}

	public updateAccount(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/updatemoneyaccount').then((result)=>{
				resolve(result);
				},(err) => {
					reject(err);
				}
			);

		});

	}

	public setDefaultAccount(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/setdefault_account').then((result)=>{
				resolve(result);
				},(err) => {
					reject(err);
				}
			);

		});

	}

	public getInquiries(fromId){
		let data ={fromId:fromId}
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/inquiries').then((result)=>{
				console.log('spareparts',result);
				if(result != null)
					{
						resolve(result['msg']);
					}
					else{
						reject('');
					}
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public getOrders(fromId){
		let data ={fromId:fromId}
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/orders').then((result)=>{
				console.log('spareparts Orders',result);
					if(result != null)
					{
						resolve(result['msg']);
					}
					else{
						reject('');
					}
				},(err) => {
					
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public getDealerOrders(id){
		let data ={dealerId:id}
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/dealerorders').then((result)=>{
				console.log('spareparts Orders',result);
					if(result != null)
					{
						resolve(result['msg']);
					}
					else{
						reject('');
					}
				},(err) => {
					
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public getShipperOrders(id){
		let data ={shipperId:id}
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/shipperorders').then((result)=>{
				//console.log('spareparts Orders',result);
					if(result != null)
					{
						resolve(result['msg']);
					}
					else{
						reject('');
					}
				},(err) => {
					
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public getAgentOrders(id){
		let data ={clearingAgentId:id}
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/agentorders').then((result)=>{
				//console.log('spareparts Orders',result);
					if(result != null)
					{
						resolve(result['msg']);
					}
					else{
						reject('');
					}
				},(err) => {
					
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public getInquiriesDealer(fromId){
		let data ={fromId:fromId}
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/inquiriesdealer').then((result)=>{
				console.log('sparepartsDEALER',result);
				if(result != null)
					{
						resolve(result['msg']);
					}
					else{
						reject('');
					}
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public getInquiriesShipper(fromId){
		let data ={fromId:fromId}
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/inquiriesshipper').then((result)=>{
				console.log('sparepartsSHIPPER',result);
				if(result != null)
					{
						resolve(result['msg']);
					}
					else{
						reject('');
					}
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public getInquiriesbyId(id){
		let data ={id:id}
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/inquiries_by_id').then((result)=>{
				//console.log('spareparts',result);
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public getOrderbyId(id){
		let data ={id:id}
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/order_by_id').then((result)=>{
				//console.log('spareparts',result);
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public updateInquiry(id,status){
		let data ={id:id,status:status};
		console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/inquiry_update_status').then((result)=>{
				console.log('Inquiry update from service:::',result);
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public updateOrder(id,status){
		let data ={id:id,status:status};
		console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/order_update_status').then((result)=>{
				console.log('Order update from service:::',result);
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public updateOrderDealerInfo(id,status){
		let data ={id:id,status:status};
		console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/order_update_dealer_status').then((result)=>{
				console.log('Order update from service:::',result);
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public updateOrderShipperInfo(id,status){
		let data ={id:id,status:status};
		console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/order_update_shipper_status').then((result)=>{
				console.log('Order update from service:::',result);
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public updateOrderAgentInfo(id,status){
		let data ={id:id,status:status};
		console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/order_update_agent_status').then((result)=>{
				console.log('Order update from service:::',result);
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public updateInquiryDealerInfo(data){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/inquiry_update_dealer').then((result)=>{
				console.log('Inquiry update from service:::',result);
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public updateInquiryShipperInfo(data){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/inquiry_update_shipper').then((result)=>{
				console.log('Inquiry update from service:::',result);
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}


	public updateInquiryDealer(id,status){
		let data ={id:id,status:status};
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/inquiry_update_dealer_status').then((result)=>{
				//console.log('Inquiry update from service:::',result);
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}


	public updateInquiryShipper(id,status){
		let data ={id:id,status:status};
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/inquiry_update_shipper_status').then((result)=>{
				//console.log('Inquiry update from service:::',result);
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public getForex(currency){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		let data = {currency:currency};
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/getForex').then((result)=>{
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public updateOrderTracking(data){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/update_order_tracking').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public taobaoSearch(data){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/taobao').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public taobaoItem(data){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/taobao_single').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}


	public translate(data){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/translate').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}



	public  getProductType(){
		
		let data = {};
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/getproducttype').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public  getCommunityProductType(data){
		
		//let data = {};
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,environment.api_urls.get_community_shop_product_category).then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}


	public  getProductTypeCommunity(v){
		
		let data = {__v:v};
		//console.log('CHECK STATUS STATUS:::::',data);

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/getproducttype').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}


	public  saveProduct(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/addproduct').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}
	public  saveEditProduct(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/editproduct').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}


	public  saveCommunityProduct(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/addcommunityproduct').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public  getCommunityProduct(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/getcommunityproduct').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	

	public  getCommunityProductById(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/getcommunityproduct_by_id').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public  getCommunityAllProduct(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/getcommunity_all_products').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public searchCommunityProducts(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/search_community_products').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});

	}

	public  getProductsMeasure(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/getmeasure').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}


	public  generateThumbnail(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/multiple-gethumbnail').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	





}
