import { Component, OnInit,ViewChild, Input } from '@angular/core';
import {ITimer} from "../models/itimer";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  NavController,NavParams,  ModalController } from '@ionic/angular';
import { LoginService } from '../services/login';
import { TimerComponent } from '../common/timer/timer.component';


export interface CountdownTimer {
  seconds: number;
  secondsRemaining: number;
  runTimer: boolean;
  hasStarted: boolean;
  hasFinished: boolean;
  displayTime: string;
}

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit {
	//@ViewChild(TimerComponent, { static: true }) timer: TimerComponent;
	submitForm: FormGroup;
	//phonenumber:any;
	//verificationId:any;
	ErrorMsg:any;
	data:any = {msg:null}
	maxtime: any=30
  timer: CountdownTimer;
  timeInSeconds:any=60;
  to_number:any;
  verification:any

  @Input() phonenumber: string;
  @Input() verificationId: string;
  

  constructor(
  	public navCtrl: NavController, 
	public navParams: NavParams,
	public viewCtrl: ModalController,
	public formBuilder: FormBuilder,
	private loginService: LoginService) {
  		//form validator
  		//this.phonenumber = this.navParams.get('phoneNumber');
  		//this.verificationId = this.navParams.get('verificationId');

  		this.ErrorMsg = '';
      this.to_number = this.phonenumber;
      this.verification = this.verificationId;
  		//console.log("here is phone: " +  this.phonenumber);
  		//console.log("here is verificatinId: " +  this.verificationId);


  	    this.submitForm = formBuilder.group({
        code: ['', Validators.compose([Validators.required,Validators.maxLength(4),Validators.pattern('^\\d+$')])]
    	});

	 }

  ngOnInit() {

  	//this.startTimer();
     this.initTimer();
     this.startTimer();
  }



  codeCheck(){
  		let data = this.submitForm.value;
  		let codeData = {code:data.code, verificationId:this.verificationId}
  		//console.log(codeData);
	  	this.loginService.verifyCode(codeData).then((data) => {
	  		this.data = data;
	  		if(this.data.msg == 'Verified')
	  		{
          console.log('data verified::', this.data);
	  			this.viewCtrl.dismiss(this.data.msg);

	  		}
	  		else{
	  			this.ErrorMsg = JSON.stringify(data);
	  		}

	    });

  		//console.log(data);
    }

    resendCode()
    {
    	let data = {verificationId:this.verification,username:this.to_number}
      //!this.hasFinished()
      this.initTimer();
      this.startTimer();
    	this.loginService.resendCode(data).then(() => {

      		console.log('resent',data);
    	}, error => {
    		//console.log(data);
    	});

    	

    }

    dismiss() {
	   this.viewCtrl.dismiss();
	}

	home() {
	//this.navCtrl.push('Home');
	}

  /*
	startTimer(){
		this.timer.initTimer();
    this.timer.startTimer();
		setTimeout(() => {
      		this.timer.startTimer();
    	}, 1000)
	}
  */




  // New timer countdown


  initTimer() {
    if (!this.timeInSeconds) { this.timeInSeconds = 0; }

    this.timer = <CountdownTimer>{
      seconds: this.timeInSeconds,
      runTimer: false,
      hasStarted: false,
      hasFinished: false,
      secondsRemaining: this.timeInSeconds
    };

    this.timer.displayTime = this.getSecondsAsDigitalClock(this.timer.secondsRemaining);
  }

  startTimer() {
    this.timer.hasStarted = true;
    this.timer.runTimer = true;
    this.timerTick();
  }

  pauseTimer() {
    this.timer.runTimer = false;
  }

  resumeTimer() {
    this.startTimer();
  }

  timerTick() {
    setTimeout(() => {
      if (!this.timer.runTimer) { return; }
      this.timer.secondsRemaining--;
      this.timer.displayTime = this.getSecondsAsDigitalClock(this.timer.secondsRemaining);
      if (this.timer.secondsRemaining > 0) {
        this.timerTick();
      } else {
        this.timer.hasFinished = true;
      }
    }, 1000);
  }

  getSecondsAsDigitalClock(inputSeconds: number) {
    const secNum = parseInt(inputSeconds.toString(), 10); // don't forget the second param
    const hours = Math.floor(secNum / 3600);
    const minutes = Math.floor((secNum - (hours * 3600)) / 60);
    const seconds = secNum - (hours * 3600) - (minutes * 60);
    let hoursString = '';
    let minutesString = '';
    let secondsString = '';
    hoursString = (hours < 10) ? '0' + hours : hours.toString();
    minutesString = (minutes < 10) ? '0' + minutes : minutes.toString();
    secondsString = (seconds < 10) ? '0' + seconds : seconds.toString();
    return hoursString + ':' + minutesString + ':' + secondsString;
  }

  hasFinished() {
    return this.timer.hasFinished;
  }


}
