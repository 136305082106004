// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.thumbnail {
  position: relative;
  display: inline-block;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(255, 0, 0, 0.7);
  color: white;
  padding: 2px 5px;
  cursor: pointer;
  font-size: 12px;
  border-radius: 50%;
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/app/common/photo-prev/photo-prev.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,MAAA;EACA,QAAA;EACA,gCAAA;EACA,YAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;AACJ","sourcesContent":[".thumbnail {\n    position: relative;\n    display: inline-block;\n}\n\n.delete-icon {\n    position: absolute;\n    top: 0;\n    right: 0;\n    background: rgba(255, 0, 0, 0.7);\n    color: white;\n    padding: 2px 5px;\n    cursor: pointer;\n    font-size: 12px;\n    border-radius: 50%;\n    z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
