// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-payment-methods ion-content {
  --background: transparent;
}
app-payment-methods .item-md ion-avatar[item-start], app-payment-methods .item-md ion-thumbnail[item-start] {
  margin: 8px 4px 8px 0;
}
app-payment-methods .item-ios ion-avatar[item-start], app-payment-methods .item-ios ion-thumbnail[item-start] {
  margin: 8px 4px 8px 0;
}
app-payment-methods .item-md ion-avatar {
  min-width: 60px;
  min-height: 40px;
}
app-payment-methods .item-ios ion-avatar {
  min-width: 60px;
  min-height: 40px;
}
app-payment-methods .cha-verify ion-button {
  background-color: #2b404a;
  color: #fff;
  font-size: 1.4rem;
  line-height: normal;
  font-weight: 300;
  padding: 6px;
  border-radius: 8px;
}
app-payment-methods .cha_below_icons ion-avatar {
  padding-top: 14px;
  padding-left: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/common/payment-methods/payment-methods.component.scss"],"names":[],"mappings":"AAEI;EACI,yBAAA;AADR;AAII;EACI,qBAAA;AAFR;AAKI;EACI,qBAAA;AAHR;AAMI;EACI,eAAA;EACA,gBAAA;AAJR;AAOI;EACI,eAAA;EACA,gBAAA;AALR;AAQI;EACI,yBAAA;EACA,WAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;AANR;AASI;EACI,iBAAA;EACA,kBAAA;AAPR","sourcesContent":["app-payment-methods {\n\n    ion-content{\n        --background: transparent;\n    }\n\n    .item-md ion-avatar[item-start], .item-md ion-thumbnail[item-start] {\n        margin: 8px 4px 8px 0;\n    }\n\n    .item-ios ion-avatar[item-start], .item-ios ion-thumbnail[item-start] {\n        margin: 8px 4px 8px 0;\n    }\n\n    .item-md ion-avatar {\n        min-width: 60px;\n        min-height: 40px;\n    }\n\n    .item-ios ion-avatar {\n        min-width: 60px;\n        min-height: 40px;\n    }\n\n    .cha-verify ion-button{\n        background-color: #2b404a;\n        color: #fff;\n        font-size: 1.4rem;\n        line-height: normal;\n        font-weight: 300;\n        padding: 6px;\n        border-radius: 8px;\n        \n    }\n    .cha_below_icons ion-avatar {\n        padding-top: 14px;\n        padding-left: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
