import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavController,ModalController, NavParams } from '@ionic/angular';

import { MoneyService} from '../services/money';
import { HelperService } from '../services/helper';

import { AlertService } from '../services/alerts';
import {Config} from '../../app/config';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss'],
})
export class PinComponent implements OnInit {
	title = Config.appTitle;
	submitForm: FormGroup;
  	submitAttempt: boolean = false;
  	emailPattern = Config.emailPattern;
    passwordPattern = Config.passwordPattern;
    results:any = {msg:null};
    chaprefix = Config.phoneDefaultPrefix;
    verificationResult:any = {msg:{verificationId:null}};
    user:any;
    username:any;
    token:any;

  constructor(
  	public navCtrl: NavController,
   public navParams: NavParams,
   public modalCtrl: ModalController,
   public formBuilder: FormBuilder,
   public viewCtrl: ModalController,
   private moneyService: MoneyService,
   public alertCtrl: AlertService,
   private helperService: HelperService) { 

  	this.username = this.navParams.get('username');
    this.token = this.navParams.get('token');

   	//form validator
  	    this.submitForm = formBuilder.group({
        pin: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(6), Validators.pattern('^\\d+$')])],
        confirmPin: ['', Validators.compose([Validators.required])],
        
        
    	}, {validator: this.matchingPasswords('pin', 'confirmPin')});
  }

  ngOnInit() {}

  submitData(){
    let userdata = this.submitForm.value;
    let pindata = {
      username:this.username,
      password: userdata.pin
    }
    console.log(userdata); 
    
    this.moneyService.createPin(pindata,this.token).then((data) => {

        if(!this.helperService.isEmptyObj(data))
        {
          this.viewCtrl.dismiss('Verified');
        }
        else
        {

          console.log('empty',JSON.stringify(data))
          this.alertCtrl.showPrompt(Config.prompts.signup.alreadyExists.title,Config.prompts.signup.alreadyExists.message);
        }

        
        
     

    },(error)=>{
      console.log(JSON.stringify(error));
      if(!this.helperService.isEmptyObj(error['msg']['username']))
      {
        this.alertCtrl.showPrompt(Config.prompts.pin.createpin.title,error['msg']['username'][0]);

      }
      else
      {
        this.alertCtrl.showPrompt(Config.prompts.pin.createpin.title,Config.prompts.pin.createpin.error);
      }

    })
    

  }

 

goBack(){
   this.navCtrl.pop();
 }
matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
      return (group: FormGroup): {[key: string]: any} => {
        let password = group.controls[passwordKey];
        let confirmPassword = group.controls[confirmPasswordKey];

        if (password.value !== confirmPassword.value) {
          return {
            mismatchedPasswords: true
          };
        }
      }
    }

}
