import { Component, OnInit, Input } from '@angular/core';
import { ContactService } from '../../services/contact';
import {Config} from '../../config';

@Component({
  selector: 'user-image',
  templateUrl: './user-image.component.html',
  styleUrls: ['./user-image.component.scss'],
})
export class UserImage implements OnInit {

  	@Input() contact;
	@Input() hideSelf = false;
	single = null;
	contacts = [];

	constructor(private contactService: ContactService) {
	}

	ngOnInit() {
		if (!this.contact) {
			return;
		}

		if (!(this.contact instanceof Array)) {
			this.contact = [this.contact];
		}

		for (let contact of this.contact) {
			let getContact = null;
			if (typeof contact == 'string') {
				getContact = this.contactService.get(contact);
				if (!getContact) {
					continue;
				}
			} else {
				getContact = contact;
			}

			if (this.hideSelf && this.contactService.user.id == getContact.id) {
				continue;
			}
 
			if (getContact.image) {
				getContact.imagePath = this.baseUrl(getContact.image) + getContact.image;
			}

			this.contacts.push(getContact);
		}

		this.single = this.contacts.length > 1 ? 'multi' : 'single';
	}

	baseUrl(data){
		  let ext = data.split('.');

		  if(ext.length > 1)
		  {
		    return 'assets/img/avatar/';

		  }
		  else{
		    return Config.server + 'uploads/images/';
		  }
	}

}
