// handles incoming and outgoing text messages

import {  Injectable, ApplicationRef, NgZone } from '@angular/core';

import { SocketService } from './socket';
import {ContactService} from './contact';
import { Config } from '../config';
//import { Events } from '@ionic/angular';
import {marked} from 'marked';
import {LoginService} from '../services/login';
import {HelperService} from './helper';
import {ChaEventsService} from '../services/chaevents';
import { RestProvider } from './rest/rest';
import {Chat} from '../models/chats';
import {User} from '../models/user';
 import { Storage } from '@ionic/storage';
 import { environment } from '../../environments/environment';



@Injectable()
export class ChatService {
	user = null
	chauser:any;
	chats = []
	messageCount = 0;
	unread_messageCount = 0;
	groupListing : any;



	// manages chats
	constructor(
		private zone:NgZone, 
		private ref: ApplicationRef, 
		private socket: SocketService, 
		private events: ChaEventsService, 
		private contactService: ContactService, 
		public loginService: LoginService,
		private helper:HelperService,
		public restProvider: RestProvider,
		public storage:Storage,) {

		this.init();
		
	}


	public init(){
		let evts = this.events;


		/*this.loginService.checkIfloggedIn().then((user)=>{
	      //console.log('check user details in here', user);
	       this.chauser = user;


	    },(error)=>{
	        console.log('check user failed in here2121', error);
	    });*/

		marked.setOptions({
			sanitize: true,
			gfm: true
		});

		// triggered after a successfull login
		this.events.userLogin().subscribe( data => {
			//console.log('CHECK SUB:::::',data);
			this.user = data.user;
		});

		//console.log('check sports........444')

		/*this.loginService.checkIfloggedIn().then((user)=>{
			console.log('CHECK SUB@:::::',user);
    	 this.chauser = user ;


    	});*/



		let self = this;
		//offline
		this.storage.get('chats').then((data) => {
				//console.log('chats offline:',data);
				if(data)
				{
					this.chats = data;
				}


			});

		// broadcast an event when there is a chat mesasage recieved
		// another way to do this would be to forward the message directly
		this.socket.on('chat-message', function(name, message) {
			//console.log('SOCKET chat-message',message);
			let have = false;

				for (let chat of self.chats) {

					if (message.chat == chat.id) {
						//console.log('CHAT LOGIC messagechat:',message.chat)
						//console.log('CHAT LOGIC chatid:',chat.id)
						have = true;
						break;
					}
				}

			// get a new list of chats if we are missing something
			if (!have) {
				//console.debug('getting new chats...');
				self.socket.emit('chats');
			}

			self.updateLastMessage(message.chat, message);
			evts.chaMessagePublish(message);
			self.updateRecieved(message._id);


		});

		// triggered where there is an update to the chat data. like adding a user to the chat
		this.socket.on('chat', chat => {
			//zone.run(() => {
				//console.debug('got a new chat update:', chat)

				for (let c of this.chats) {
					if (c.id == chat.id) {
						c.users = chat.users;
						c.lastMessage = chat.lastMessage;
						c.lastDate = chat.lastDate;
						if(chat["groupname"] !== undefined)
						{
						  c.groupname = chat.groupname;

						}
						
						if (Config.markdown) {
							c.lastMarked = self.parseMessage(chat.lastMessage);
						}
						this.processChats();
						return;
					}
				}

				this.chats.push(chat);
				this.storage.set('chats',this.chats);
				this.countMessages(chat.id);
				this.processChats();
			//});
		});

		this.socket.on('chats', chats => {
			this.zone.run(() => {
				//console.debug('Chats: ', chats);

				// merge new chats to the chat array but dont replace the array itself
				for (let chat of chats) {
					let i = true;
					for (let c of this.chats) {
						if (c.id == chat.id) {
							i = false;
							break;
						}
					}
					if (i) {
						this.chats.push(chat);
					}
				}

				if (Config.markdown) {
					for (let chat of this.chats) {
						chat.lastMarked = this.parseMessage(chat.lastMessage);
					}
				}

				this.processChats();
			});
		});


		
	}



	public updateRecieved(messageid){

		let data = {messageid:messageid};

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/updaterecieved').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					//console.log(err);
				}
			);

			resolve('');

		});


	}



	public updateSeen(messageid){

		let data = {messageid:messageid};

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/updateseen').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					//console.log(err);
				}
			);

			resolve('');

		});


	}

	public unreadFilter(messages,userid){

		


	}



	private checkAttachment(message){
  	if(message.attachment){
  		if(message.attachment.type){
  			if(message.attachment.type === 'moneyTransfer'){
  				return true;
  			}
  			else{
  				return false;
  			}
  		}
  		else{
  				return false;
  			}
  	}
  	else{
  				return false;
  			}
  }

	private parseMessage(message) {
		if (!message) {
			return null;
		}
		if(this.checkAttachment(message))
		{
			return '[Attachement]';
		}
		//console.debug('parsing ', message)
		let m = '';
		if (Config.markdown) {
			m = marked.parse(message);
		}
		//if(message)
		m = m ? m.replace(/\<img.+\">/i, 'Image') : null;
		m = m.replace(/(<([^>]+)>)/ig,'');
		return m;
	}

	private processChats() {
		if(this.chauser == null)
		{

	        this.loginService.refreshData().then((user)=>{
	          if (user != null) {
	          	this.chauser = user;
	          	for (let chat of this.chats) {
					chat.name = this.chatName(chat);
				}
				this.chats.sort(this.sortChats);
				//this.helper.changeChats(this.chats);
				this.ref.tick();
	            
			}


			});

    	}

    	else{

    		for (let chat of this.chats) {
			chat.name = this.chatName(chat);
			}
			this.chats.sort(this.sortChats);
			//this.helper.changeChats(this.chats);
			this.ref.tick();
    		
    	}
		
	}

	// update the last message send for the chats view
	public updateLastMessage(id, message, date = new Date) {
		for (let c in this.chats) {
			let chat = this.chats[c];
			if (chat.id == id) {
				//console.log('MESSAGE!!',message);
				if(this.helper.checkIfKeyExists(message,'message'))
				{
					message = 'Attachment';
				}
				//chat.lastMessage = message ? message.message : 'Attachment';
				chat.lastMessage = message;
				this.helper.changeLastmessage(chat.lastMessage);
				chat.lastDate = date;
				if (Config.markdown) {
					chat.lastMarked = this.parseMessage(chat.lastMessage);

				}
				break;
			}
		}
		this.chats.sort(this.sortChats);
	}

	public getChatById(id) {
		//console.log('am here: ' + id);
		//console.log(this.chats);
		for (let chat of this.chats) {
			//console.log('id list: ' + chat.id + ' :myid: ' + id );
			if (chat.id == id) {
				//console.log('did i enter?');
				return chat;
			}
		}
		return null;
	}


	public getChatByIdB(id) {
		//console.log('am here: ' + id);
		return new Promise((resolve, reject) => {
			//console.log('DO WE HAVE CHATS?',this.chats);
			if(this.chats.length == 0)
			{
				this.storage.get('chats').then((data) => {
					//console.log('offline Chats',data);
					if(!data){
						resolve([]);
					}
					else
					{

						this.chats = data;
						for (let chat of this.chats) {
							//console.log('id list: ' + chat.id + ' :myid: ' + id );
							if (chat.id == id) {
								//console.log('did i enter?');
								resolve(chat);
							}
						}
						resolve([]);
					}



				});

			}
			else
			{

				this.storage.set('chats',this.chats);
				for (let chat of this.chats) {
					//console.log('id list: ' + chat.id + ' :myid: ' + id );
					if (chat.id == id) {
						//console.log('did i enter?');
						resolve(chat);
					}
				}
				resolve([]);
			}
		});


	}

	public getChatByContact(id) {
		return new Promise((resolve, reject) => {
			for (let chat of this.chats) {
				if (chat.users.length == 2 && chat.users.indexOf(this.user.id) > -1 && chat.users.indexOf(id) > -1) {
					resolve(chat);
					return;
				}
			}

			this.socket.promise('get-contact-chat', {id: id}).then(chat => {
				this.chats.push(chat);
				this.chats.sort(this.sortChats);
				resolve(chat);
			});
		});
	}

	// sort cchats by last message
	private sortChats(a, b) {
		if (!a) {
			return -1;
		}
		if (!b) {
			return 1;
		}
		if (new Date(a.lastDate) > new Date(b.lastDate)) {
			return -1;
		}
		if (new Date(a.lastDate) < new Date(b.lastDate)) {
			return 1;
		}
		return 0;
	};

	get(chatId,mode) {
		let self = this;
		return new Promise((resolve, reject) => {
			console.log('check socket MODE',mode);
			if(mode == 'offline')
			{

				this.storage.get('message-'+ String(chatId)).then((data) => {
					//console.log('MESSAGEIS:',data);
					if(data){
						resolve(data);
					}
					else{
						resolve([]);
					}
				});

			}
			else
			{
				this.socket.promise('chat', {
					chat: chatId
				}).then((data: any) => {
					//console.log('am in MESSAGE',data);
					this.storage.set('message-'+ String(chatId),data);
					resolve(data);
				},(err)=>{
					//console.log('Here MESSAGE',err);
					


				});
			}


		});
	}

	addContact(chat, contact) {
		return new Promise((resolve, reject) => {
			this.socket.promise('add-to-chat', {chat: chat.id, contact: contact.id}).then(chat => {
				resolve(chat);
			});
		});
	}

	chatName(chat) {
		let name = '';
		//console.log('IN CHAT WHATS USER::::',this.chauser);

		for (let c of chat.users) {

			if (c == this.chauser._id) {
				continue;
			}
			name += (name ? ', ' : '') + (this.contactService.get(c).name || this.contactService.get(c).username)
		}
		return name;
	}

	// send a new message to another contact
	send(id,data) {
		let content = {
			location : data.location,
			caption : data.caption,
			message : data.message,
			attachment : data.attachment,
			name : data.name,
			type : data.type,
			time: data.time,
			chatId : id
		}
		console.log('ChatPHOTOooooooooooos::', content);
		this.socket.emit('message', content);
		 this.refreshChats().then((data)=>{
		 	//console.log('final resolution:',data);

		 	});
	}
	

	// @todo: typing
	typing() {

	}

	countMessages(chat_id){
		let messages = this.getChatById(chat_id);
		//console.log('messages per given chat', messages);
		this.messageCount = this.messageCount + messages.length;

	}
 
	public refreshChats(){
		// triggered where there is an update to the chat data. like adding a user to the chat
		//console.log('............check refrechchats step2............');
		return new Promise((resolve, reject) => {
			//console.log('inner promise');
			this.socket.promise('chats',{}).then((data: any) => {
				//console.log('check chats',data)
				resolve(data);
			},(err)=>{
				//console.log('check chats ERRR',err);
				reject(err);
			});

		});



		

	}

	public refreshChatsb(){
		// triggered where there is an update to the chat data. like adding a user to the chat
		//console.log('............check refrechchats step2............');
		return new Promise((resolve, reject) => {
			//console.log('inner promise');
			
				this.restProvider.authGetToApi('api/getchats').then((result)=>{
					//console.log(result);
					let chats = result['msg'] as Chat[];
					this.saveChatsInStorage(chats);
					resolve(chats);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);
			});

		
	}

	public getChatCollegue(users:User[],loginuserid){
		
		if(users.length == 2)
		{
			let colleague:User;
			for(let user of users){
				if(user._id != loginuserid)
				{
					colleague = user;

				}

			}

			return colleague;

		}
		else if(users.length == 1)
		{
			let colleague = users[0] as User;
			

			return colleague ;

		}
		else
		{

			let colleagueb:User[] = [];
			for(let user of users){
				if(user._id != loginuserid)
				{
					colleagueb.push(user);

				}

			}

			return colleagueb;


		}
		
	}

	saveImageToStorage(image,name){
		this.storage.set('profpic-'+ String(name), {image: String(image)});
	}
	public async saveChatsInStorage(chats:Chat[]){

		// Save Chats
		this.storage.set('chats-'+String(this.loginService.user._id), JSON.stringify(chats));
		// save profile pic
		for(let chat of chats){
			let colleague = this.getChatCollegue(chat.users,this.loginService.user._id) as User; 
			if(colleague.image != environment.default_img)
			{
				let senddata = {chaimagename:colleague.image}
				this.restProvider.authSendToApi(senddata,'api/gethumbnail').then((result)=>{

							//console.log('Thumbnail:',result);
								if(result != null){
									this.saveImageToStorage(result['msg'],colleague._id.toString());
								}
							
							},(err) => {
								//console.log(err);
								
							}
						);
			}
			else
			{
				//save default to storage
				this.saveImageToStorage(environment.default_img,colleague._id.toString());
			}




		}
	}


	public groups_list(id = '') {
		let data = {
			id: id
		};
		return new Promise((resolve, reject) => {
			console.log("Attempting to get the groups data by the user id",data);
			this.restProvider.authSendToApi(data, environment.api_urls.get_groups)
				.then((result) => {
					if (result != null && result['msg'] != 'No record found') {
						if( result['msg'] == 'ID is null'){
							this.groupListing = [];
							resolve([]);
						}
						resolve(result['msg']);
					} else {
						resolve([]);
						
					}
					this.groupListing = result ;

					console.log('groups list_____here', this.groupListing);
				})
				.catch((err) => {
					console.error('Error fetching groups:', err);
					reject(err);
				});
		});
		
	}
	
	public users_list(id = '') {
		let data = {
			id: id
		};
		return new Promise((resolve, reject) => {
			this.restProvider.authSendToApi(data, environment.api_urls.get_users)
				.then((result: any) => { // Explicitly specify the type as 'any'
					if (result != null && result['msg'] != 'No record found') {
						const names = result.data.map((user: { name: string }) => user.name);
						// const id = result.data.map((user: { _id: string }) => user._id);
						const users = result.data.map((user) => ({ _id: user._id, name: user.name, image: user.image })); 

                		resolve({ users });
						// resolve(names);
					} else {
						resolve([]);
					}

					console.log('users____',result)
				})
				.catch((err) => {
					console.error('Error fetching users:', err);
					reject(err);
				});
		});
	}

	public saveGroup(groupData){
		
		console.log('CHECK STATUS STATUS:::::',groupData);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(groupData,environment.api_urls.savegroup).then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
				}
			);
		});
	}
	public getAttachment(data){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data, environment.api_urls.get_chat_attachments, data.chatId).then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					//console.log(err);
				}
			);

			//resolve('');

		});
	}

}