import { Camera, CameraResultType } from '@capacitor/camera';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { FilePicker, PickFilesResult} from '@capawesome/capacitor-file-picker';
import { Chat, SingleChat} from '../../models/chats';
import { ChatService } from 'src/app/services/chat';

@Component({
  selector: 'app-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss'],
})
export class MediaPreviewComponent implements OnInit {
	option: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
  videos: boolean = false;
  photos: boolean = false;
  docs: boolean = false;
  photo:any;
  video:any;
  document: any;
  docName : any;
  pdfSrc: string;
  data = {
    message: null,
    attachment: null,
    time: null
    }
  chat:SingleChat = {
    id: null,
    users: [],
    name: '',
    messages: [],
    // photo:[]
    }
  
  @Input() mediaType: string;
  @Input() userName: string;
  @Input() chatId: string;

  constructor(
    private chatService: ChatService,
  	public navParams: NavParams,
    public viewCtrl: ModalController
  ) {
    this.photo = this.navParams.get('photo');
    this.video = this.navParams.get('video');
    this.document = this.navParams.get('doc');
    this.docName = this.navParams.get('name'); 
  }

  ngOnInit() {
    this.updateMediaFlags();
    if (this.mediaType === 'doc' && this.document.endsWith('.pdf')) {
      this.pdfSrc = this.document; // Assign PDF URL to display
    }
  }

  updateMediaFlags() {
    if (this.mediaType === 'pic') {
      this.photos = true;
      this.videos = false;
      this.docs = false;
    } else if (this.mediaType === 'vid') {
      this.photos = false;
      this.videos = true;
      this.docs = false;
    } else if (this.mediaType === 'doc') {
      this.photos = false;
      this.videos = false;
      this.docs = true;
    }
  }

  send(){
   
    this.data.attachment = this.document;
    this.data.attachment = this.photo;
    this.data.attachment = this.video;
    this.chatId;
    console.log(this.data.attachment);
    const timestamp = new Date().toLocaleTimeString([], this.option);
    let data ={
      message: this.data.message,
      attachment: this.data.attachment,
      type : 'video',
      time: timestamp
    }
    this.chatService.send(this.chatId, data);
    this.data.attachment = '';
    
    this.dismiss();
  }

 async pickImages() {
    try {
        const result = await Camera.pickImages({
            quality: 90, 
            limit: 10,     
        });
        // console.log('Images picked:', result.photos);
        const base64Images = await Promise.all(result.photos.map(async photo => {
            const base64 = await this.convertToBase64(photo);
            return base64;
        }));
        let photo = base64Images;
        // console.log('Base64 images:', photo);
    } catch (error) {
        console.error('Error picking images:', error);
    }
  
}

// Function to convert image to base64
convertToBase64(imageUri) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function() {
            const reader = new FileReader();
            reader.onloadend = function() {
                resolve(reader.result);
            };
            reader.onerror = function(error) {
                reject(error);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = function(error) {
            reject(error);
        };
        xhr.open('GET', imageUri);
        xhr.responseType = 'blob';
        xhr.send();
    });
}


  dismiss() {
    this.viewCtrl.dismiss();
    this.data.attachment = '';
  }
  getVideo = async () => {
    try {
        const result = await FilePicker.pickFiles({
            types: [
                'video/mp4', 'video/mpeg', 'video/quicktime',
                'video/x-msvideo', 'video/x-flv', 'video/x-matroska',
                'video/webm', 'video/x-ms-wmv', 'video/3gpp',
                'video/3gpp2', 'video/avi', 'video/x-m4v',
                'video/ogg', 'video/x-ms-asf', 'video/x-mng', 'video/x-msvideo'
            ],
            //multiple: true,
            readData: true
        });

        let concatenatedBase64 = '';

        const readFileAsBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = function (event) {
                    const result = event.target.result as string;
                    const base64String = result.split(',')[1];
                    const fullBase64String = `data:video/mp4;base64,${base64String}`;
                    resolve(fullBase64String);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file.blob);
            });
        };

        for (const file of result.files) {
            const fullBase64String = await readFileAsBase64(file);
            concatenatedBase64 += fullBase64String;
        }

       let video = concatenatedBase64;
        console.log('Attachment:', video);
    } catch (e) {
        alert(JSON.stringify(e));
    }
};

previewPdf() {
    if (this.pdfSrc) {
      // Display PDF using ngx-extended-pdf-viewer
    }
  }
}
