// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chacapitalize {
  text-transform: capitalize;
  margin-top: -7%;
}

app-top-up .timer-button {
  font-size: 5rem !important;
  height: 1em !important;
}
app-top-up .no-bottom-border.item .item-inner {
  border-bottom: none !important;
}
app-top-up .hm_sign_resend {
  width: 100%;
  margin-bottom: 20px;
}
app-top-up .hm_signin_inner_wrapper {
  width: 95%;
  margin-top: 0% !important;
  margin: 0 auto;
}
app-top-up .hm_mobile_number_div {
  width: 100%;
  height: 60px;
  border: 1px solid #faae3f;
  margin-bottom: 10px;
  font-size: x-large;
}
app-top-up .topup_page .hm_mobile_code {
  padding-top: 12px;
}
app-top-up .card-title-ios {
  display: block;
  font-size: 1.8rem;
  line-height: 1.2;
  color: #222;
  margin-left: 30%;
  padding: 8px 0;
}
app-top-up .card-title-md {
  display: block;
  font-size: 1.8rem;
  line-height: 1.2;
  color: #222;
  margin-left: 30%;
  padding: 8px 0;
}

.test-modal .modal-wrapper {
  transform: translateY(40%) !important;
}

.cha-backdrop {
  /*visibility: visible;*/
  background-color: #000 !important;
  visibility: visible !important;
  opacity: 0.4;
}`, "",{"version":3,"sources":["webpack://./src/app/top-up/top-up.component.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,eAAA;AACJ;;AAGC;EACG,0BAAA;EACA,sBAAA;AAAJ;AAOE;EACI,8BAAA;AALN;AAQE;EACE,WAAA;EACA,mBAAA;AANJ;AASA;EACI,UAAA;EACA,yBAAA;EACA,cAAA;AAPJ;AAUA;EACI,WAAA;EACA,YAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;AARJ;AAWC;EACG,iBAAA;AATJ;AAaA;EACI,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,cAAA;AAXJ;AAeA;EACI,cAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,cAAA;AAbJ;;AAsBC;EACG,qCAAA;AAnBJ;;AAuBA;EAEI,uBAAA;EACA,iCAAA;EACA,8BAAA;EACA,YAAA;AArBJ","sourcesContent":[".chacapitalize { \n    text-transform: capitalize;\n    margin-top: -7%; \n  }\n\napp-top-up {\n\t.timer-button {\n    font-size: 5rem !important;\n    height: 1em !important;\n  }\n\n\n\n  \n  \n  .no-bottom-border.item .item-inner {\n      border-bottom: none !important;\n  }\n\n  .hm_sign_resend {\n    width: 100%;\n    margin-bottom: 20px;\n}\n\n.hm_signin_inner_wrapper {\n    width: 95%;\n    margin-top: 0% !important;\n    margin: 0 auto;\n}\n\n.hm_mobile_number_div {\n    width: 100%;\n    height: 60px;\n    border: 1px solid #faae3f;\n    margin-bottom: 10px;\n    font-size: x-large;\n    }\n\n .topup_page .hm_mobile_code {\n    padding-top: 12px;\n}\n\n\n.card-title-ios {\n    display: block;\n    font-size: 1.8rem;\n    line-height: 1.2;\n    color: #222;\n    margin-left: 30%;\n    padding: 8px 0;\n}\n\n\n.card-title-md {\n    display: block;\n    font-size: 1.8rem;\n    line-height: 1.2;\n    color: #222;\n    margin-left: 30%;\n    padding: 8px 0;\n}\n\n\n\n\n}\n\n.test-modal {\n\t.modal-wrapper{\n    transform: translateY(40%) !important;\n\t}\n}\n\n.cha-backdrop{\n\n    /*visibility: visible;*/\n    background-color: #000 !important;\n    visibility: visible !important;\n    opacity: 0.4;\n\n\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
