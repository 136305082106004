import { Component, OnInit, Input,ViewChild,ElementRef } from '@angular/core';
import { Router, Params, ActivatedRoute,NavigationExtras} from '@angular/router';
import {IonSearchbar,NavController,} from '@ionic/angular';
import {HelperService} from '../../services/helper';
import { environment } from '../../../environments/environment';
import {Item} from '../../models/item';
 
@Component({
  selector: 'cha-search',
  templateUrl: './cha-search.component.html',
  styleUrls: ['./cha-search.component.scss'],
}) 
export class ChaSearchComponent {
  @Input() getItems: (groupid,dataService,event) => any;
  @Input() searchItems: (searchword,total,data,dataService,groupid) => any;
  @Input() items:Item[];
  @Input() items_reserve:Item[];
  @Input() chasearch:any;
  @Input() groupid:any;
  @Input() dataService:any;
  @Input() chalimit:any;
  @Input() locationmenu:any;
  @Input() placeholder:any;
  @ViewChild('searchbar1') searchbar1: IonSearchbar;
  localCurrency:any= environment.chaCurrency;
  error:any=0;
  start_value:any=0;
  //chalimit:any=20;
  myInput:any;
  chaloading:any=0;

  constructor(
    public route: Router,
    public helper:HelperService,
    public navCtrl: NavController, ) { 
    console.log('items in:',this.items)
    if(this.items == undefined || this.items.length == 0 ){
      this.items = [];  
    }
    

    
	    
  }


  doRefresh(event) {

     this.error = 0;
     this.chasearch = 0;
     this.getItems(this.groupid,this.dataService,event).then((results)=>{
       this.items = results as Item[];

     });



   }

   onInput(chaevent){
    
    this.searchbar1.getInputElement().then( data => {
     let chasearch = data.value
     //console.log('peep:',this.items_reserve);


     this.items = this.helper.dynamicSearchInObjectb(this.items_reserve,chasearch,'name','desc')
     if(this.items.length != 0 )
     {
       this.searchItems(chasearch,this.items[0]['totalCount'],this.items_reserve,this.dataService,this.groupid).then((results)=>{
           this.items = results as Item[];
       });
     }
       

    });

    
     //console.log('here2',this.checkMinimumNumber(this.myInput));



  }

  dimensionsRatio(a,b){
    let c = ((a-b)/a)*100
    return c
  }

  imageOrientation(image:any){

    if(image != null){

      if((Number(image[0].dimensions.width) > Number(image[0].dimensions.height)) && (this.dimensionsRatio(Number(image[0].dimensions.width),Number(image[0].dimensions.height)) > 20) ){
        return 'landscape';
      }
      else if(Number(image[0].dimensions.width) == Number(image[0].dimensions.height) ){
        return 'square';
      }
      else if((Number(image[0].dimensions.width) < Number(image[0].dimensions.height)) && (this.dimensionsRatio(Number(image[0].dimensions.height),Number(image[0].dimensions.width )) > 20) )
      {
        return 'portrait';
      }
      else{

        return 'square';

      }
    }

  }

  getShopName(data){
    let shop = this.helper.staticArrayHandler(data, 0,'product');
    //console.log('shop::::;####',shop);

    
      return shop.shop_name;
   
    
  }

  getShopId(data){
    let shop = this.helper.staticArrayHandler(data, 0,'product');
    
      return shop._id;
    
  }

  cha(chaitem){
    //console.log('test@@@@@@@@@@@@@@@@@@@@@@@@@@@@@',chaitem);
  }

  singleProduct(data){

     let navigationExtras: NavigationExtras = {
    queryParams: {
        title: data['name'],
        id:data['_id'] 
    }
};
    this.navCtrl.navigateForward(['/community-shops-product'], navigationExtras);

  }
   

  

 

}
