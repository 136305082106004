// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-transfer-modal .contact-card img {
  width: 180px;
  height: 180px;
  border-radius: 100%;
  overflow: hidden;
}
app-transfer-modal user-image {
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
}
app-transfer-modal button.action {
  height: 50px;
  width: 50px;
  margin: 10px;
}
app-transfer-modal button.close {
  float: right;
  position: absolute;
  margin-top: -20px;
  margin-left: 28vw;
}
app-transfer-modal h2 {
  margin-bottom: 30px;
}

.chacancel {
  float: left;
}

.charecords {
  float: right;
  margin-right: 20px;
}

.item-md .item-button {
  padding: 0 0.6em;
  height: 50px;
  font-size: 1.4rem;
  width: 100%;
}

ion-card img {
  display: block;
  width: 25%;
  margin-left: 30%;
  margin-right: 30%;
  border-radius: 50%;
}

.card-title-md {
  text-align: center;
}

.card-md p {
  text-align: center;
}

.chaprofile {
  background-color: #f8f8f8;
}

.cha {
  color: #fff;
}

.cha-input {
  background-color: #f0f8fc;
}

.cha-loading {
  color: green;
}`, "",{"version":3,"sources":["webpack://./src/app/common/transfer-modal/transfer-modal.component.scss"],"names":[],"mappings":"AAEC;EACC,YAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AADF;AAIC;EACC,cAAA;EACA,cAAA;EACA,mBAAA;AAFF;AAKC;EACC,YAAA;EACA,WAAA;EACA,YAAA;AAHF;AAMC;EACC,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;AAJF;AAOC;EACC,mBAAA;AALF;;AASA;EACC,WAAA;AAND;;AAQA;EACI,YAAA;EACA,kBAAA;AALJ;;AAQA;EACI,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,WAAA;AALJ;;AAQA;EACI,cAAA;EACA,UAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AALJ;;AAOA;EACC,kBAAA;AAJD;;AAOA;EACC,kBAAA;AAJD;;AAMA;EACC,yBAAA;AAHD;;AAOA;EACC,WAAA;AAJD;;AAOA;EACC,yBAAA;AAJD;;AAMA;EACE,YAAA;AAHF","sourcesContent":["app-transfer-modal {\n\n\t.contact-card img {\n\t\twidth: 180px;\n\t\theight: 180px;\n\t\tborder-radius: 100%;\n\t\toverflow: hidden;\n\t}\n\n\tuser-image {\n\t\tmargin: 0 auto;\n\t\tdisplay: block;\n\t\tmargin-bottom: 20px;\n\t}\n\n\tbutton.action {\n\t\theight: 50px;\n\t\twidth: 50px;\n\t\tmargin: 10px;\n\t}\n\n\tbutton.close {\n\t\tfloat: right;\n\t\tposition: absolute;\n\t\tmargin-top: -20px;\n\t\tmargin-left: 28vw;\n\t}\n\n\th2 {\n\t\tmargin-bottom: 30px;\n\t}\n}\n\n.chacancel {\n\tfloat: left;\n}\n.charecords {\n    float: right;\n    margin-right: 20px;\n}\n\n.item-md .item-button {\n    padding: 0 0.6em;\n    height: 50px;\n    font-size: 1.4rem;\n    width: 100%;\n}\n\nion-card img {\n    display: block;\n    width: 25%;\n    margin-left: 30%;\n    margin-right: 30%;\n    border-radius: 50%;\n}\n.card-title-md {\n\ttext-align: center;\n\n}\n.card-md p {\n\ttext-align: center;\n\t}\n.chaprofile {\n\tbackground-color: #f8f8f8\n}\n\n\n.cha{\n\tcolor:#fff;\n}\n\n.cha-input{\n\tbackground-color: #f0f8fc;\n}\n.cha-loading {\n  color: green;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
