/**
 * Ionic 4 Spotify app starter - Ion Spotify (https://store.enappd.com/product/ionic-4-spotify-app-starter);
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { Injectable } from '@angular/core';
import { LoadingController, ModalController, ToastController, ActionSheetController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';
import { MediaObject, Media } from '@awesome-cordova-plugins/media/ngx';

@Injectable({
  providedIn: 'root'
})
export class SpotifyService {
  selectedMusics: any = [];
  slidSelectedMusics: any = [];
  arrayData: Array<any> = [];
  fromSongs = '';
  file: MediaObject;
  togMinus: boolean;
  togHeart: boolean;
  buttonToggle = false;
  mayImage: '';
  artistData = [
    { image: '../../assets/chat1.jpg', name: 'Hindi', show: false, id: 0 },
    { image: '../../assets/chat2.jpg', name: 'English', show: false, id: 1 },
    { image: '../../assets/chat4.jpg', name: 'Punjabi', show: false, id: 2 },
    { image: '../../assets/chat5.jpg', name: 'Tamil', show: false, id: 3 },
    { image: '../../assets/chat6.jpg', name: 'Telgu', show: false, id: 4 },
    { image: '../../assets/chat7.jpg', name: 'Malyalam', show: false, id: 5 },
    { image: '../../assets/chat8.jpg', name: 'Marathi', show: false, id: 6 },
    { image: '../../assets/chat3.jpg', name: 'Gujrati', show: false, id: 7 },
    { image: '../../assets/chat5.jpg', name: 'Bengali', show: false, id: 8 },
    { image: '../../assets/chat6.jpg', name: 'Kannada', show: false, id: 9 },
    { image: '../../assets/chat1.jpg', name: 'Hindi', show: false, id: 0 },
    { image: '../../assets/chat2.jpg', name: 'English', show: false, id: 1 },
    { image: '../../assets/chat4.jpg', name: 'Punjabi', show: false, id: 2 },
    { image: '../../assets/chat5.jpg', name: 'Tamil', show: false, id: 3 },
    { image: '../../assets/chat6.jpg', name: 'Telgu', show: false, id: 4 },
    { image: '../../assets/chat7.jpg', name: 'Malyalam', show: false, id: 5 },
    { image: '../../assets/chat8.jpg', name: 'Marathi', show: false, id: 6 },
    { image: '../../assets/chat3.jpg', name: 'Gujrati', show: false, id: 7 },
    { image: '../../assets/chat5.jpg', name: 'Bengali', show: false, id: 8 },
    { image: '../../assets/chat6.jpg', name: 'Kannada', show: false, id: 9 },
  ];
  slideArtistData = [
    { image: '../../assets/chat1.jpg', name: 'Aman Hayer', show: false, id: 0 },
    { image: '../../assets/chat2.jpg', name: 'Nikhil sachdeva', show: false, id: 1 },
    { image: '../../assets/chat4.jpg', name: 'Arijit', show: false, id: 2 },
    { image: '../../assets/chat5.jpg', name: 'Sonu Nigam', show: false, id: 3 },
    { image: '../../assets/chat6.jpg', name: 'Neha Kakkar', show: false, id: 4 },
    { image: '../../assets/chat7.jpg', name: 'JoJO', show: false, id: 5 },
    { image: '../../assets/chat8.jpg', name: 'Arman Malick', show: false, id: 6 },
    { image: '../../assets/chat3.jpg', name: 'Amaal Malick', show: false, id: 7 },
    { image: '../../assets/chat5.jpg', name: 'Silva d\'souza', show: false, id: 8 },
    { image: '../../assets/chat6.jpg', name: 'Amit Trivedi', show: false, id: 9 },
    { image: '../../assets/chat1.jpg', name: 'Pit bull', show: false, id: 0 },
    { image: '../../assets/chat2.jpg', name: 'Lady Gaaga', show: false, id: 1 },
    { image: '../../assets/chat4.jpg', name: 'Rihan', show: false, id: 2 },
    { image: '../../assets/chat5.jpg', name: 'Taylor Swift', show: false, id: 3 },
    { image: '../../assets/chat6.jpg', name: 'Selena Gomez', show: false, id: 4 },
    { image: '../../assets/chat7.jpg', name: 'Justin Bieber', show: false, id: 5 },
    { image: '../../assets/chat8.jpg', name: 'Astha Gill', show: false, id: 6 },
    { image: '../../assets/chat3.jpg', name: 'Jaspreet', show: false, id: 7 },
    { image: '../../assets/chat5.jpg', name: 'Guru Randhwa', show: false, id: 8 },
    { image: '../../assets/chat6.jpg', name: 'Badshah', show: false, id: 9 },
  ];
  slideselectArtists = [
    { image: '../../assets/chat1.jpg', name: 'Aman Hayer', show: false, id: 0 },
    { image: '../../assets/chat2.jpg', name: 'Nikhil sachdeva', show: false, id: 1 },
    { image: '../../assets/chat4.jpg', name: 'Arijit', show: false, id: 2 },
    { image: '../../assets/chat5.jpg', name: 'Sonu Nigam', show: false, id: 3 },
    { image: '../../assets/chat6.jpg', name: 'Neha Kakkar', show: false, id: 4 },
    { image: '../../assets/chat7.jpg', name: 'JoJO', show: false, id: 5 },
    { image: '../../assets/chat8.jpg', name: 'Arman Malick', show: false, id: 6 },
    { image: '../../assets/chat3.jpg', name: 'Amaal Malick', show: false, id: 7 },
    { image: '../../assets/chat5.jpg', name: 'Silva d\'souza', show: false, id: 8 },
    { image: '../../assets/chat6.jpg', name: 'Amit Trivedi', show: false, id: 9 },
    { image: '../../assets/chat1.jpg', name: 'Pit bull', show: false, id: 0 },
    { image: '../../assets/chat2.jpg', name: 'Lady Gaaga', show: false, id: 1 },
    { image: '../../assets/chat4.jpg', name: 'Rihan', show: false, id: 2 },
    { image: '../../assets/chat5.jpg', name: 'Taylor Swift', show: false, id: 3 },
    { image: '../../assets/chat6.jpg', name: 'Selena Gomez', show: false, id: 4 },
    { image: '../../assets/chat7.jpg', name: 'Justin Bieber', show: false, id: 5 },
    { image: '../../assets/chat8.jpg', name: 'Astha Gill', show: false, id: 6 },
    { image: '../../assets/chat3.jpg', name: 'Jaspreet', show: false, id: 7 },
    { image: '../../assets/chat5.jpg', name: 'Guru Randhwa', show: false, id: 8 },
    { image: '../../assets/chat6.jpg', name: 'Badshah', show: false, id: 9 },
  ];
  homeData = [
    {
      heading: 'Desperate',
      image: '../../assets/chat6.jpg',
      artists: 'Faith Mussa'
    },

    {
      image: '../../assets/chat4.jpg',
      artists: 'Ernest Ikwanga'
    },
    {
      heading: 'Popular and trending',
      popularList: [
        { popularListimage: '../../assets/chat6.jpg', musicType: 'Desperate', artists: 'Faith Mussa' },
        { popularListimage: '../../assets/chat8.jpg', musicType: 'Kalilima', artists: 'Faith Mussa' },
        { popularListimage: '../../assets/chat5.jpg', musicType: 'Mdidi', artists: 'Faith Mussa' },
        { popularListimage: '../../assets/chat9.jpg', musicType: 'Amaonakuchedwa', artists: 'Lawi' },
        { popularListimage: '../../assets/chat1.jpg', musicType: 'Sunset in the Sky', artists: 'Lawi' },
        { popularListimage: '../../assets/chat8.jpg', musicType: 'Sound of Bengaluru', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat4.jpg', musicType: 'Sound of Chennai', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat3.jpg', musicType: 'Sound of Bengaluru', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat12.jpg', musicType: 'Sound of Bihar', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat11.jpg', musicType: 'Sound of Assam', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' }
      ]
    },
    {
      heading: 'Recommanded for you',
      popularList: [
        { popularListimage: '../../assets/chat1.jpg', musicType: 'Sunset in the Sky', artists: 'Lawi' },
        { popularListimage: '../../assets/chat9.jpg', musicType: 'Amaonakuchedwa', artists: 'Lawi' },
        { popularListimage: '../../assets/chat3.jpg', musicType: 'Thirties', artists: 'Lawi' },
        { popularListimage: '../../assets/chat4.jpg', musicType: 'sound of Mumbai', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat5.jpg', musicType: 'Sound of Delhi', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat6.jpg', musicType: 'Sound of Bengaluru', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat7.jpg', musicType: 'Sound of Chennai', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat8.jpg', musicType: 'Sound of Bengaluru', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat9.jpg', musicType: 'Sound of Bihar', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat10.jpg', musicType: 'Sound of Assam', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' }
      ]
    },
    {
      heading: 'Editor\'s picks ',
      popularList: [
        { popularListimage: '../../assets/chat4.jpg', musicType: 'Yenda', artists: 'Ernest Ikwanga' },
        { popularListimage: '../../assets/chat7.jpg', musicType: 'Chisekese', artists: 'Ernest Ikwanga' },
        { popularListimage: '../../assets/chat2.jpg', musicType: 'Woloka', artists: 'Mafilika' },
        { popularListimage: '../../assets/chat9.jpg', musicType: 'sound of Mumbai', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat10.jpg', musicType: 'Sound of Delhi', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat11.jpg', musicType: 'Sound of Bengaluru', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat12.jpg', musicType: 'Sound of Chennai', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat5.jpg', musicType: 'Sound of Bengaluru', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat4.jpg', musicType: 'Sound of Bihar', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat3.jpg', musicType: 'Sound of Assam', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' }
      ]
    },
    {
      heading: 'Punjabi new releases',
      popularList: [
        { popularListimage: '../../assets/chat12.jpg', musicType: 'New Music Punjabi', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat11.jpg', musicType: 'Latest Telgu', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat10.jpg', musicType: 'Gibidi', artists: 'KBG' },
        { popularListimage: '../../assets/chat9.jpg', musicType: 'sound of Mumbai', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat8.jpg', musicType: 'Sound of Delhi', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat7.jpg', musicType: 'Sound of Bengaluru', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat6.jpg', musicType: 'Sound of Chennai', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat5.jpg', musicType: 'Sound of Bengaluru', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat4.jpg', musicType: 'Sound of Bihar', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat1.jpg', musicType: 'Sound of Assam', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' }
      ]
    },
    {
      heading: 'Tamil new releases',
      popularList: [
        { popularListimage: '../../assets/chat1.jpg', musicType: 'New Music Punjabi', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat4.jpg', musicType: 'Latest Telgu', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat8.jpg', musicType: 'Latest Tamil', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat9.jpg', musicType: 'sound of Mumbai', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat3.jpg', musicType: 'Sound of Delhi', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat6.jpg', musicType: 'Sound of Bengaluru', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat4.jpg', musicType: 'Sound of Chennai', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat11.jpg', musicType: 'Sound of Bengaluru', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat12.jpg', musicType: 'Sound of Bihar', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat10.jpg', musicType: 'Sound of Assam', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' }
      ]
    },
    {
      heading: 'Telgu new releases',
      popularList: [
        { popularListimage: '../../assets/chat12.jpg', musicType: 'New Music Punjabi', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat7.jpg', musicType: 'Latest Telgu', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat9.jpg', musicType: 'Latest Tamil', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat2.jpg', musicType: 'sound of Mumbai', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat9.jpg', musicType: 'Sound of Delhi', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat4.jpg', musicType: 'Sound of Bengaluru', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat8.jpg', musicType: 'Sound of Chennai', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat9.jpg', musicType: 'Sound of Bengaluru', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat12.jpg', musicType: 'Sound of Bihar', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' },
        { popularListimage: '../../assets/chat3.jpg', musicType: 'Sound of Assam', artists: 'Diljeet Dosanjh, Hardy Sandhu,Gary Sandhu,Mika Singh' }
      ]
    },
    {
      because: 'BECAUSE YOU LIKE',
      name: 'Arijit Singh',
      image: '../../assets/arijit.jfif',
      artists: 'This is Dr Zeus.The essential tracks,all in one playlist.'
    },
    {
      because: 'BECAUSE YOU LIKE',
      name: 'Badshah',
      image: '../../assets/sonu.jfif',
      artists: 'This is Dr Zeus.The essential tracks,all in one playlist.'
    },
    {
      because: 'BECAUSE YOU LIKE',
      name: 'Arman Malik',
      image: '../../assets/download.jfif',
      artists: 'This is Dr Zeus.The essential tracks,all in one playlist.'
    },
    {
      because: 'BECAUSE YOU LIKE',
      name: 'Amit Trivedi',
      image: '../../assets/Amit.jfif',
      artists: 'This is Dr Zeus.The essential tracks,all in one playlist.'
    },
    {
      because: 'BECAUSE YOU LIKE',
      name: 'Ankit Tiwari',
      image: '../../assets/Ankit.jfif',
      artists: 'This is Dr Zeus.The essential tracks,all in one playlist.'
    },
    {
      because: 'BECAUSE YOU LIKE',
      name: 'Sunidhi Chauhaan',
      image: '../../assets/Sunidhi.jfif',
      artists: 'This is Dr Zeus.The essential tracks,all in one playlist.'
    },

  ];
  songsList = [
    {
      title: 'Filmi for you',
      songs: [
        { title: 'Bloom', image: '../../assets/1.jfif', artist: '5:15' },
        { title: 'Morning Mr Magpie', image: '../../assets/2.jfif', artist: '4:41' },
        { title: 'Little by Little', image: '../../assets/3.jfif', artist: '4:27' },
        { title: 'Feral', image: '../../assets/4.jfif', artist: '3:13' },
        { title: 'Lotus Flower', image: '../../assets/5.jfif', artist: '5:01' },
        { title: 'Codex', image: '../../assets/6.jfif', artist: '4:47' },
        { title: 'Give Up the Ghost', image: '../../assets/7.jfif', artist: '4:50' },
        { title: 'Separator', image: '../../assets/8.jfif', artist: '5:20' }
      ]
    },
    {
      title: 'Desi pop for you',
      songs: [
        { title: 'Airbag', image: '../../assets/9.jfif', artist: '4:44' },
        { title: 'Paranoid Android', image: '../../assets/10.jfif', artist: '6:23' },
        { title: 'Subterranean Homesick Alien', image: '../../assets/11.jfif', artist: '4:27' },
        { title: 'Exit Music (For a Film)', image: '../../assets/1.jfif', artist: '4:24' },
        { title: 'Let Down', image: '../../assets/2.jfif', artist: '4:59' },
        { title: 'Karma Police', image: '../../assets/3.jfif', artist: '4:21' },
        { title: 'Fitter Happier', image: '../../assets/4.jfif', artist: '1:57' },
        { title: 'Electioneering', image: '../../assets/5.jfif', artist: '3:50' },
        { title: 'Climbing Up the Walls', image: '../../assets/6.jfif', artist: '4:45' },
        { title: 'No Surprises', image: '../../assets/7.jfif', artist: '3:48' },
        { title: 'Lucky', image: '../../assets/8.jfif', artist: '4:19' },
        { title: 'The Tourist', image: '../../assets/9.jfif', artist: '5:24' }
      ],
      description: '\n\tOK Computer is the third studio album by the English alternative rock band Radiohead, released on 16 June 1997 on Parlophone in the United Kingdom and 1 July 1997 by Capitol Records in the United States. It marks a deliberate attempt by the band to move away from the introspective guitar-oriented sound of their previous album The Bends. Its layered sound and wide range of influences set it apart from many of the Britpop and alternative rock bands popular at the time and laid the groundwork for Radiohead\'s later, more experimental work.\n'
    },
    {
      title: 'Recently played',
      songs: [
        { title: 'Mysterons', image: '../../assets/1.jfif', artist: '5:02' },
        { title: 'Sour Times', image: '../../assets/3.jfif', artist: '4:11' },
        { title: 'Strangers', image: '../../assets/10.jfif', artist: '3:55' },
        { title: 'It Could Be Sweet', image: '../../assets/10.jfif', artist: '4:16' },
        { title: 'Wandering Star', image: '../../assets/11.jfif', artist: '4:51' },
        { title: 'It\'s a Fire', image: '../../assets/1.jfif', artist: '3:49' },
        { title: 'Numb', image: '../../assets/2.jfif', artist: '3:54' },
        { title: 'Roads', image: '../../assets/5.jfif', artist: '5:02' },
        { title: 'Pedestal', image: '../../assets/7.jfif', artist: '3:39' },
        { title: 'Biscuit', image: '../../assets/1.jfif', artist: '5:01' },
        { title: 'Glory Box', image: '../../assets/9.jfif', artist: '5:06' }
      ]
    },
    {
      title: 'Quick Look',
      songs: [
        { title: 'Silence', image: '../../assets/1.jfif', artist: '4:58' },
        { title: 'Hunter', image: '../../assets/4.jfif', artist: 'CNN' },
        { title: 'Nylon Smile', image: '../../assets/6.jfif', artist: 'ZEE' },
        { title: 'The Rip', image: '../../assets/1.jfif', artist: '4:29' },
        { title: 'Plastic', image: '../../assets/4.jfif', artist: '3:27' },
        { title: 'We Carry On', image: '../../assets/10.jfif', artist: '6:27' },
        { title: 'Deep Water', image: '../../assets/11.jfif', artist: '1:31' },
        { title: 'Machine Gun', image: '../../assets/2.jfif', artist: '4:43' },
        { title: 'Small', image: '../../assets/7.jfif', artist: '6:45' },
        { title: 'Magic Doors', image: '../../assets/1.jfif', artist: '3:32' },
        { title: 'Threads', image: '../../assets/3.jfif', artist: '5:45' }
      ],
      description: '\n\tThird is the third studio album by English musical group Portishead, released on 27 April 2008, on Island Records in the United Kingdom, two days after on Mercury Records in the United States, and on 30 April 2008 on Universal Music Japan in Japan. It is their first release in 10 years, and their first studio album in eleven years. Third entered the UK Album Chart at #2, and became the band\'s first-ever American Top 10 album on the Billboard 200, reaching #7 in its entry week.\n'
    },
    {
      title: 'Latest Mix',
      songs: [
        { title: 'Silence', image: '../../assets/1.jfif', artist: '4:58' },
        { title: 'Hunter', image: '../../assets/7.jfif', artist: 'CNN' },
        { title: 'Nylon Smile', image: '../../assets/8.jfif', artist: '3:16' },
        { title: 'The Rip', image: '../../assets/10.jfif', artist: '4:29' },
        { title: 'Plastic', image: '../../assets/11.jfif', artist: '3:27' },
        { title: 'We Carry On', image: '../../assets/3.jfif', artist: '6:27' },
        { title: 'Deep Water', image: '../../assets/4.jfif', artist: '1:31' },
        { title: 'Machine Gun', image: '../../assets/5.jfif', artist: '4:43' },
        { title: 'Small', image: '../../assets/8.jfif', artist: '6:45' },
        { title: 'Magic Doors', image: '../../assets/4.jfif', artist: '3:32' },
        { title: 'Threads', image: '../../assets/1.jfif', artist: '5:45' }
      ],
      description: '\n\tThird is the third studio album by English musical group Portishead, released on 27 April 2008, on Island Records in the United Kingdom, two days after on Mercury Records in the United States, and on 30 April 2008 on Universal Music Japan in Japan. It is their first release in 10 years, and their first studio album in eleven years. Third entered the UK Album Chart at #2, and became the band\'s first-ever American Top 10 album on the Billboard 200, reaching #7 in its entry week.\n'
    }
  ];
  podcastSongs = [
    { title: 'Silence', image: '../../assets/1.jfif', name: 'The Dave Ramsey Show', artist: 'Jay Shetty' },
    { title: 'Hunter', image: '../../assets/7.jfif', name: 'The Dave Ramsey Show', artist: 'Md shadman Alam' },
    { title: 'Nylon Smile', image: '../../assets/8.jfif', name: 'Md Shadman', artist: 'Umang Rathore' },
    { title: 'The Rip', image: '../../assets/10.jfif', name: 'The Tim Ferris Show', artist: 'shivam Goyal' },
    { title: 'Plastic', image: '../../assets/11.jfif', name: 'The Tim Ferris Show', artist: 'Sanchit Gupta' },
    { title: 'We Carry On', image: '../../assets/3.jfif', name: 'The Tim Ferris Show', artist: 'Abhijeet Rathore' },
    { title: 'Deep Water', image: '../../assets/4.jfif', name: 'The Tim Ferris Show', artist: 'Deepshikha Chaudhary' },
    { title: 'Machine Gun', image: '../../assets/5.jfif', name: 'Great Women of Business', artist: 'Abhishek' },
    { title: 'Small', image: '../../assets/8.jfif', name: 'Great Women of Business', artist: 'Ankit' },
    { title: 'Magic Doors', image: '../../assets/4.jfif', name: 'Great Women of Business', artist: 'Ted' },
    { title: 'Threads', image: '../../assets/chat2.jpg', name: 'Great Women of Business', artist: 'Epdl' },
    { title: 'Silence', image: '../../assets/chat2.jpg', name: 'Great Women of Business', artist: 'Hbo' },
    { title: 'Hunter', image: '../../assets/4.jfif', name: 'Great Women of Business', artist: 'CNN' },
    { title: 'Nylon Smile', image: '../../assets/6.jfif', name: 'Business Wars', artist: 'ZEE' },
    { title: 'The Rip', image: '../../assets/1.jfif', name: 'Business Wars', artist: 'Star One' },
    { title: 'Plastic', image: '../../assets/4.jfif', name: 'Business Wars', artist: 'Enappd' },
    { title: 'We Carry On', image: '../../assets/10.jfif', name: 'Business Wars', artist: '6:27' },
    { title: 'Deep Water', image: '../../assets/11.jfif', name: 'Business Wars', artist: '1:31' },
    { title: 'Machine Gun', image: '../../assets/2.jfif', name: 'Business Wars', artist: 'Enappd' },
    { title: 'Small', image: '../../assets/7.jfif', name: 'Bollywood Hungama', artist: 'Enappd' },
    { title: 'Magic Doors', image: '../../assets/chat11.jpg', name: 'Bollywood Hungama', artist: 'Youstart' },
    { title: 'Threads', image: '../../assets/3.jfif', name: 'Bollywood Hungama', artist: '5:45' },
    { title: 'Airbag', image: '../../assets/9.jfif', name: 'Bollywood Hungama', artist: '4:44' },
    { title: 'Paranoid Android', image: '../../assets/10.jfif', name: 'Bollywood Hungama', artist: '6:23' },
    { title: 'Subterranean Homesick Alien', image: '../../assets/chat12.jpg', name: 'Md Shadman', artist: '4:27' },
    { title: 'Exit Music (For a Film)', image: '../../assets/chat10.jpg', name: 'Md Shadman', artist: '4:24' },
    { title: 'Let Down', image: '../../assets/2.jfif', name: 'Md Shadman', artist: '4:59' },
    { title: 'Karma Police', image: '../../assets/3.jfif', name: 'Md Shadman', artist: 'Enappd' },
    { title: 'Fitter Happier', image: '../../assets/4.jfif', name: 'Md Shadman', artist: '1:57' },
    { title: 'Electioneering', image: '../../assets/5.jfif', name: 'Md Shadman', artist: '3:50' },
    { title: 'Climbing Up the Walls', image: '../../assets/6.jfif', name: 'Md Shadman', artist: '4:45' },
    { title: 'No Surprises', image: '../../assets/7.jfif', name: 'Md Shadman', artist: 'Enappd' },
    { title: 'Lucky', image: '../../assets/8.jfif', name: 'Md Shadman', artist: '4:19' },
    { title: 'The Tourist', image: '../../assets/9.jfif', name: 'Md Shadman', artist: 'Enappd' },
    { title: 'Bloom', image: '../../assets/chat12.jpg', name: 'Md Shadman', artist: 'Enappd' },
    { title: 'Morning Mr Magpie', image: '../../assets/2.jfif', name: 'Md Shadman', artist: 'Enappd' },
    { title: 'Little by Little', image: '../../assets/3.jfif', name: 'Md Shadman', artist: 'Enappd' },
    { title: 'Feral', image: '../../assets/4.jfif', name: 'Md Shadman', artist: '3:13' },
    { title: 'Lotus Flower', image: '../../assets/5.jfif', name: 'Md Shadman', artist: 'Enappd' },
    { title: 'Codex', image: '../../assets/6.jfif', name: 'Md Shadman', artist: 'Enappd' },
    { title: 'Give Up the Ghost', image: '../../assets/7.jfif', name: 'Md Shadman', artist: 'Enappd' },
    { title: 'Separator', image: '../../assets/8.jfif', name: 'Md Shadman', artist: 'Enappd' }
  ];
  // songAndArtist = [
  //   {
  //     title: 'Bloom',
  //     image: '', artist: 'Daft Punk',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Morning Mr Magpie', image: '', artist: 'Daft Punk',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Little by Little', image: '', artist: 'Dr Zeus',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Feral', image: '', artist: 'Honey Singh',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Lotus Flower', image: '', artist: 'Diljeet Dosanjh',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Codex', image: '', artist: 'Amrendra Gill',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Give Up the Ghost', image: '', artist: 'Soni Mohapatra',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Separator', image: '', artist: 'Aditya Sharma',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Silence', image: '', artist: 'Shivam Goyal',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Hunter', image: '', artist: 'Shadman Alam',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Nylon Smile', image: '', artist: 'Mika Singh',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'The Rip', image: '', artist: 'Akriti Jain',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Plastic', image: '', artist: 'Priyanka Chopra',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'We Carry On', image: '', artist: 'Arman Mallik',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Deep Water', image: '', artist: 'Saksham Sharma',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Machine Gun', image: '', artist: 'Deepshikha Chaudhary',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Small', image: '', artist: 'Enappd',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Magic Doors', image: '', artist: 'Youstart',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   },
  //   {
  //     title: 'Threads', image: '', artist: 'Soni Sehgal',
  //     url: 'https://www.dropbox.com/s/vut222qevgityco/crowd-cheering.mp3?dl=0',
  //     clickedHeart: this.valueHeart ? this.valueHeart : false,
  //     clickedMinus: false
  //   }
  // ];
  songActions = [
    { icon: 'musical-notes', textTitle: 'Add to playlist', type: 'playlist' },
    { icon: 'add-circle-outline', textTitle: 'Add to Queue', type: 'add' },
    { icon: 'person', textTitle: 'View Artist', type: 'Artist' },
    { icon: 'share', textTitle: 'Share', type: 'Share' },
    { icon: 'flag', textTitle: 'Report explicit Content', type: 'Report' },
    { icon: 'contacts', textTitle: 'Show Credits', type: 'Credits' }
  ];
  addSongActions = [
    { icon: 'add-circle-outline', textTitle: 'Add songs', type: 'Add', mode: 'ios' },
    { icon: 'musical-notes', textTitle: 'Make Non-collaborative', type: 'collaborative', mode: 'md' },
    { icon: 'create', textTitle: 'Rename playlist', type: 'Rename', mode: 'md' },
    { icon: 'close', textTitle: 'Delete playlist', type: 'Delete', mode: 'md' },
    { icon: 'search', textTitle: 'Find in playlist', type: 'Find', mode: 'ios' },
    { icon: 'menu', textTitle: 'Short playlist', type: 'Short', mode: 'md' },
    { icon: 'share', textTitle: 'Share', type: 'Share', mode: 'md' },
    { icon: 'lock', textTitle: 'Make Public', type: 'Public', mode: 'ios' }
  ];
  slidesMusic = [
    { title: 'Playlist' },
    { title: 'Artists' },
    { title: 'Albums' },
  ];
  slidesPodcasts = [
    { title: 'Episodes' },
    { title: 'Downloads' },
    { title: 'Shows' },
  ];
  mightLike = [
    {
      head: 'You might also like',
      likeList: [
        {
          region: 'Tamil',
          type: 'Party Type',
          image: '../../assets/tamil.jpg'
        },
        {
          region: 'Telgu',
          type: 'Romance',
          image: '../../assets/tamil1.jpg'
        },
        {
          region: 'Hindi',
          type: 'Mix',
          image: '../../assets/tamil2.jpg'
        },
        {
          region: 'English',
          type: 'Party Type',
          image: '../../assets/tamil3.jpg'
        },
        {
          region: 'Punjabi',
          type: 'Bhangra',
          image: '../../assets/tamil4.jpg'
        },
        {
          region: 'Cannadda',
          type: 'House Mix',
          image: '../../assets/tamil5.jpg'
        },
        {
          region: 'Bhojpuri',
          type: 'Big Bass',
          image: '../../assets/tamil6.jpg'
        },
        {
          region: 'Kashmiri',
          type: 'Folk Songs',
          image: '../../assets/tamil7.jpg'
        },
        {
          region: 'Kashmiri',
          type: 'Folk Songs',
          image: '../../assets/tamil8.jpg'
        },
      ]
    }
  ];
  settingsData = [
    {
      head: 'Data Saver',
      content: [{ action: 'On', description: 'Sets your music quality to low and disables Canvas.', toggle: true }]
    },
    {
      head: 'Playback',
      content:
        [{ action: 'Crossfade', description: 'Allows you to crossfade between songs', toggle: false, range: true },
        { action: 'Gapless', description: 'Allows gapless playback', toggle: true },
        { action: 'Allow Explicit Content', description: 'Turn onto play explicit content.explicit contentis labeled with E tag', toggle: true },
        { action: 'Show unplayable songs', description: 'show songs that are unplayable', toggle: true },
        { action: 'Normalize volume', description: 'Set the same volume level for all tracks', toggle: true },
        { action: 'Device broadcsat Status', description: 'Allow other app on your device to see what you are listening to.', toggle: true },
        { action: 'Autoplay', description: 'Keepos on listening to similar tracks when music ends.', toggle: true },
        { action: 'Canvas', description: 'Play canvases for the current track. They are optimized to use very little data and battery/.', toggle: true }
        ]
    },
    {
      head: 'Languages',
      content: [{ action: 'Languages for music', type: 'Languages', description: 'Choose your preferred language for music', toggle: false }]
    },
    {
      head: 'Devices',
      content: [{ action: 'Connect to adevice', description: 'Listen to and control Spotify on your device', toggle: false },
      { action: 'Show local devices only', description: 'Only show devices on your local wifi in your device menu', toggle: true }]
    },
    {
      head: 'Car View',
      content: [{ action: 'Turn on automatically', description: 'Spotify will switch to car view whenever Bluetooth is detected in your car', toggle: true }]
    },
    {
      head: 'Social',
      content: [{ action: 'Private session', description: 'Start aprivate session to listen anonymously', toggle: true },
      { action: 'Listening Activity', description: 'Share what i listen to with my followers on Spotify.', toggle: true },
      { action: 'Connect to Facebook', type: 'Facebook', description: '', toggle: false }]
    },

    {
      head: '',
      content: [{ action: 'Waze navigation', description: 'enable the waze integration', toggle: true },
      { action: 'Download using cellular', description: 'Recommended setting: Off', toggle: true },
      { action: 'Delete Cache', description: 'You can free up storage by deketing your cache. Your download\'s wont\'t be deleted.', toggle: false }]
    },
    {
      head: 'Notifications',
      content: [{ action: 'Notifications', type: 'Notifications', description: 'choose which notifications to receive', toggle: false }]
    },
    {
      head: 'Advertisements',
      content: [{ action: 'Spotify Ad Partner Preferences', description: 'Control how ads are targeted to me based on information gathered from advertising partners.', toggle: true }]
    },
    {
      head: 'About',
      content: [{ action: 'Version', description: '1.0.0.1', toggle: false },
      { action: 'Third-party software', description: 'Sweet software that helped us', toggle: false },
      { action: 'Terms and Conditions', description: 'All the stuff you need to know', toggle: false },
      { action: 'Privacy Policy', description: 'Important for both of us', toggle: false },
      { action: 'Support', description: 'Get help from us and the communoty', toggle: false },
      { action: 'Log out', description: 'You are logged in', toggle: false },
      ]
    },
    {
      head: 'Other',
      content: [
        { action: 'Storage', description: 'Choose where to store your music data', toggle: false },
        { action: 'Log out', description: 'You are logged in', toggle: false },
      ]
    },
  ];
  genreLike = [
    {
      likeList: [
        {
          region: 'Toda\'s Top Hits',
          type: '23,422,955 FOLLOWERS',
          image: '../../assets/tamil.jpg'
        },
        {
          region: 'Pop Rising',
          type: '23,422,955 FOLLOWERS',
          image: '../../assets/tamil1.jpg'
        },
        {
          region: 'Pop Sauce',
          type: '23,422,955 FOLLOWERS',
          image: '../../assets/tamil2.jpg'
        },
        {
          region: 'Global X',
          type: '23,422,955 FOLLOWERS',
          image: '../../assets/tamil3.jpg'
        },
        {
          region: 'Rocky X',
          type: '23,422,955 FOLLOWERS',
          image: '../../assets/tamil4.jpg'
        },
        {
          region: 'Cannadda',
          type: '23,767,955 FOLLOWERS',
          image: '../../assets/tamil5.jpg'
        },
        {
          region: 'Bhojpuri',
          type: '28,422,955 FOLLOWERS',
          image: '../../assets/tamil6.jpg'
        },
        {
          region: 'Kashmiri',
          type: '13,422,955 FOLLOWERS',
          image: '../../assets/tamil7.jpg'
        },
        {
          region: 'Kashmiri',
          type: '3,422,955 FOLLOWERS',
          image: '../../assets/tamil8.jpg'
        },
      ]
    }
  ];
  public userName = new BehaviorSubject<any>('');
  nameOfUser = this.userName.asObservable();

  public trackUrl = new BehaviorSubject<any>('');
  urlTrack = this.trackUrl.asObservable();

  public selectedMusic = new BehaviorSubject<any>('');
  musicSelected = this.selectedMusic.asObservable();

  public heartValue = new BehaviorSubject<any>('');
  valueHeart = this.heartValue.asObservable();

  public minusValue = new BehaviorSubject<any>('');
  valueMinus = this.minusValue.asObservable();

  public songsDetails = new BehaviorSubject<any>('');
  detailOfSong = this.songsDetails.asObservable();

  // tslint:disable-next-line: variable-name
  public Username(user_name: string) {
    this.userName.next(user_name);
  }

  // tslint:disable-next-line: variable-name
  public soundUrl(track_url: any) {
    this.trackUrl.next(track_url);
  }

  // tslint:disable-next-line: variable-name
  public toggleHeart(heart_value: boolean) {
    this.heartValue.next(heart_value);
  }
  // tslint:disable-next-line: variable-name
  public toggleMinus(minus_value: boolean) {
    this.minusValue.next(minus_value);
  }
  // tslint:disable-next-line: variable-name
  public mightLikesongs(might_Like: any) {
    this.songsDetails.next(might_Like);
  }


  constructor(
    public loadCtrl: LoadingController,
    public modalCtrl: ModalController,
    public musicControls: MusicControls,
    public media: Media,
    public toastCtrl: ToastController,
    public actionCtrl: ActionSheetController

  ) {
    this.togMinus = false;
    this.togHeart = false;
  }

  settingMusicControl() {
    this.musicControls.destroy(); // it's the same with or without the destroy
    this.musicControls.create({
      track: 'Test track',
      artist: 'test artist',
      cover: '',
      isPlaying: true,
      dismissable: true,

      // hide previous/next/close buttons:
      hasPrev: true,      // show previous button, optional, default: true
      hasNext: true,      // show next button, optional, default: true
      hasClose: true,       // show close button, optional, default: false
      hasSkipForward: true,  // show skip forward button, optional, default: false
      hasSkipBackward: true, // show skip backward button, optional, default: false
      skipForwardInterval: 15, // display number for skip forward, optional, default: 0
      skipBackwardInterval: 15, // display number for skip backward, optional, default: 0
      // iOS only, optional
      album: 'test album',     // optional, default: ''
      duration: 0, // optional, default: 0
      elapsed: 0, // optional, default: 0

      // Android only, optional
      // text displayed in the status bar when the notific\ation (and the ticker) are updated
      ticker: 'Now playing test'
    });
    this.musicControls.subscribe().subscribe((action) => {
      console.log('action', action);
      const message = JSON.parse(action).message;
      console.log('message', message);
      switch (message) {
        case 'music-controls-next':
          // Do something
          break;
        case 'music-controls-previous':
          // Do something
          break;
        case 'music-controls-pause':
          // Do something
          console.log('music pause');
          this.file.pause();
          this.musicControls.listen();
          this.musicControls.updateIsPlaying(false);
          break;
        case 'music-controls-play':
          // Do something
          console.log('music play');
          this.file.play();
          this.musicControls.listen();
          this.musicControls.updateIsPlaying(true);
          break;
        case 'music-controls-destroy':
          // Do something
          break;
        // External controls (iOS only)
        case 'music-controls-toggle-play-pause':
          // Do something
          break;
        case 'music-controls-seek-to':
          // Do something
          break;
        case 'music-controls-skip-forward':
          // Do something
          break;
        case 'music-controls-skip-backward':
          // Do something
          break;

        // Headset events (Android only)
        // All media button events are listed below
        case 'music-controls-media-button':
          // Do something
          break;
        case 'music-controls-headset-unplugged':
          // Do something
          break;
        case 'music-controls-headset-plugged':
          // Do something
          break;
        default:
          break;
      }
    });
    this.musicControls.listen();
    this.musicControls.updateIsPlaying(true);
  }
  play(trckUrl: string) {
    this.file = this.media.create(trckUrl);
    this.file.play();
    this.settingMusicControl();
    const duration = this.file.getDuration();
    console.log('duration', duration);
  }

  pause(value: boolean) {
    this.file.pause();
    this.musicControls.listen();
    this.musicControls.updateIsPlaying(value);
  }

  async loader(spinner?: any, duration?: number, message?: string, cssClass?: string) {
    const loader = await this.loadCtrl.create({
      spinner,
      duration,
      message,
      translucent: true,
      cssClass
    });
    loader.present();
  }


  async loadModal(component?: any, props?: any, index?: undefined) {
    const modal = await this.modalCtrl.create({
      component,
      componentProps: { value: props, index },
      backdropDismiss: true,
      showBackdrop: true,
      cssClass: 'customCss'
    });
    return modal;
  }


  async showToast(message: string) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      position: 'bottom',
      cssClass: 'toastCss',
      color: 'light'
    });
    return toast;
  }
  async presentActionSheet(header: string, ) {
    const actionSheet = await this.actionCtrl.create({
      header,
      cssClass: 'actionCss',
      buttons: [{
        text: 'Relevence',
        role: 'destructive',
        handler: () => {
          console.log('Delete clicked');
        }
      }, {
        text: 'Custom order',
        handler: () => {
          console.log('Share clicked');
        }
      }, {
        text: 'Name',
        handler: () => {
          console.log('Play clicked');
        }
      }, {
        text: 'Recently played',
        handler: () => {
          console.log('Favorite clicked');
        }
      }, {
        text: 'Recently added',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    return actionSheet;
  }
}
