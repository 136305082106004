// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appTitle :'UfuluWathu App',
  production: false,
  chaAllowedJWT_Domain : ["webportal.ufulupay.com"],
  chaDisAllowedJWT_Domain : [
                  "https://webportal.ufulupay.com/api/checkuser",
                  "https://webportal.ufulupay.com/api/signup",
                  "https://webportal.ufulupay.com/api/verifycode",
                  "https://webportal.ufulupay.com/api/verifypin",
                  "https://webportal.ufulupay.com/api/resendcode",
                  "https://webportal.ufulupay.com/api/regeneratecode"],
    server : 'https://webportal.ufulupay.com/',
    
    socketbase:'https://webportal.ufulupay.com',
    sparepart_dealer:'60a8dd1fa90eefa85b476920',
    currency:'yuan',
    chaCurrency:'MWK',
    freight_cost: 500,
    mra: 0.3,
    transactionApi:'transactions/',
    audio : true,
    phoneDefaultPrefix : '265',
    servicename: 'DPO',
    version_major: 0,
    version_minor: 0,
    version_patch: 3,
    upgrade_url:'http://pureplaytech.net/ufuluapp',
    default_img:'1-81-02.svg',
    default_image: '1-81-03.svg',
    item_placeholder:'item_placeholder.jpg',
    file_upload_path:'uploads/images/',
    api_urls:{
      get_communitylist: 'api/get_community_list',
      get_communitylist_by_id: 'api/get_community_by_id',
      get_community_message: 'api/get_community_message',
      savecommunity:'api/savecommunity',
      savelocation: 'api/savelocation',
      get_near_residence: 'api/get_near_residence',
      get_community_residence_by_id: 'api/get_community_residence_by_user',
      downloadmusic: 'api/musicdownload',

      get_community_shop_by_userid:'api/get_community_shop_by_userid',
      get_community_shop_product_category:'api/get_community_shop_product_category',
      get_jersey_orders:'api/get_jersey_orders',

      get_chat_attachments: 'api/chat_attachments',
      get_groups: 'api/get_groups',
      get_users: 'api/list_of_users',
      savechatImage: 'api/savechatImage',
      get_chat_photo: 'api/get_chat_photo',
      savegroup:'api/savegroup'

    }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
