import { Component, OnInit,Input } from '@angular/core';
import { NavController,NavParams,ModalController,LoadingController } from '@ionic/angular';
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss'],
})
export class ServiceComponent implements OnInit {

   url: string;
   adURL: SafeResourceUrl;
   loading:any=null;
   chaloading:any=1;

  constructor(
  	public navParams: NavParams,
  	public sanitizer:DomSanitizer,
  	public viewCtrl: ModalController,
    public loadingController:LoadingController
  	) { 
  		

  	console.log('url', this.adURL);


  }

  ngOnInit() {

  	this.url = this.navParams.get('adURL');
    //this.presentLoading()
  	this.adURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);    

  }

  dismiss() {
	   this.viewCtrl.dismiss();
	}

  async presentLoading() {
    this.loading = await this.loadingController.create({
    spinner: 'bubbles',
    message:'Ufulu Micro Service Loading ...'
    //duration: 2000
    });
return await this.loading.present();
}


loadingDismiss(){
  setTimeout(() => this.chaloading = 0, 5000);

  

  
}

}
