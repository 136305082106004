// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  padding: 0;
}

button {
  width: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/common/community-message-reaction/community-message-reaction.page.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;;AAEE;EACE,WAAA;AACJ","sourcesContent":["ion-content {\n    padding: 0;\n  }\n\n  button {\n    width: 40px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
