import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, Params, ActivatedRoute,NavigationExtras } from '@angular/router';
import { ModalController, Platform, NavParams, LoadingController,  ActionSheetController,AlertController  } from '@ionic/angular';
import { Dialogs } from '@awesome-cordova-plugins/dialogs/ngx';
import { RestProvider } from '../../services/rest/rest';
import { PhotoService } from '../../services/photo.service';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import {ProductsService } from '../../services/products';
import {SocketService} from '../../services/socket';

import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';
import {HelperService} from '../../services/helper';
import {ChaEventsService} from '../../services/chaevents';

import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { environment } from '../../../environments/environment';
import { LoginService} from '../../services/login';
import {User} from '../../models/user';


declare interface TableData {
    headerRow: string[];
    dataRows: string[][];
}

@Component({
  selector: 'app-add-product-modal',
  templateUrl: './add-product-modal.component.html',
  styleUrls: ['./add-product-modal.component.scss'],
})
export class AddProductModalComponent implements OnInit {
  public tableData1: TableData;

  imageError: string;
    isImageSaved: boolean;
    cardImageBase64: string;
    imageErrorb: string;
    isImageSavedb: boolean;
    success:boolean=false;
    cardImageBase64b: string;
    public isCollapsed = false;
    chaactive:any=0;

    product_type:any=null;
    product_name:any=null;
    product_desc:any=null;
    product_img:any=[];
    product:any=[];

    sub_product_unit:any=null;
    sub_product_name:any=null;
    sub_product_price:any=null;
    sub_product_url:any=null;
    slides_items:any=[];
    chaActivated:any=0;

    formError:any=null;
    formErrorb:any=null;

    measures:any = [];

    product_types:any=[];
    sub_product_measure:any;
    current_user:any = {};
    currency:any = environment['chaCurrency'];
    


    public itemsList: Object[] = [
    {
      title: 'Collapsible Group Item #1',
      description: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven\'t heard of them accusamus labore sustainable VHS.'
    },
    {
      title: 'Collapsible Group Item #2',
      description: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven\'t heard of them accusamus labore sustainable VHS.'
    },
    {
      title: 'Collapsible Group Item #3',
      description: 'Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven\'t heard of them accusamus labore sustainable VHS.'
    }
  ];
  step:any= 1;
  max_step:any = 1;
  first_step:any = 1;
  step2:boolean=false;
  //currency:any = environment.chaCurrency;
  photo:any=[];
  display_warning:any = 0;
  step1Form: FormGroup;
  //step2Form: FormGroup;
  @Input() shop_id:any
  @Input() community_id:any;
  @Input() user_id:any;
  @Input() product_data:any = []

  @Input() mode:string;
  @Input() category:any;
  @Input() _id:any;
  //@Input() category:any;
  sub_product_img:any=[];
  editsubproduct_state:boolean = false;
  edit_index:any;


  constructor(
    private activatedRoute: ActivatedRoute,
  	public platform: Platform,
    public params: NavParams,
    public viewCtrl: ModalController,
    public formBuilder: FormBuilder,
    private dialogs: Dialogs,
    private helper: HelperService,
    private alertController: AlertController,
    public event:ChaEventsService,
    private iab: InAppBrowser,
    public productsService:ProductsService,
     public events: ChaEventsService, 
     private loginService: LoginService,


  	) { 

    this.step1Form = formBuilder.group({
          product_type: ['', Validators.compose([ Validators.required])],
          product_name: ['', Validators.compose([ Validators.required])],
          product_desc: ['', Validators.compose([ Validators.required])],
          product_price: ['', Validators.compose([ Validators.required])],
          product_unit: ['', Validators.compose([ Validators.required])],
          available_quantity: ['', Validators.compose([ Validators.required])]
         
          
        });
    

    this.loginService.checkIfloggedIn().then((user)=>{
      //console.log('check user details in here!!!!!!!!!!!!!!!!', user);
      if(user != undefined && user != '' && user !== null)
      {
       this.current_user = user as User;
       
       this.product_img = [];
       /*this.product_img.push({data:this.current_user.image,type:'profile_pic'});
       console.log('user is here1:',this.current_user);*/

      }
      else{
        console.log('why is it blank',user);
      }

    },(error)=>{
        console.log('check user failed in here', error);
    });
	    
  }




  ngOnInit() {

    console.log('category:',this.category);
    this.sub_product_img = [];
    this.product_img = [];

    this.tableData1 = {
          headerRow: [ 'ID', 'Name', 'Price', 'Units', 'Thumbnail'],
          dataRows: [
              ['1', 'Dakota Rice', 'Niger', 'Oud-Turnhout', '$36,738'],
              ['2', 'Minerva Hooper', 'Curaçao', 'Sinaai-Waas', '$23,789'],
              ['3', 'Sage Rodriguez', 'Netherlands', 'Baileux', '$56,142'],
              ['4', 'Philip Chaney', 'Korea, South', 'Overland Park', '$38,735'],
              ['5', 'Doris Greene', 'Malawi', 'Feldkirchen in Kärnten', '$63,542'],
              ['6', 'Mason Porter', 'Chile', 'Gloucester', '$78,615']
          ]
      };
      this.getProductType(this.category);
/*
      this.events.photo().subscribe((photo)=>{
      this.photo = [];
      this.photo.push({data:photo,type:'picture'});
      this.display_warning = 0;

    });

      */

      this.events.photo().subscribe((photo)=>{
        console.log('AFTER EDIT of PHOTO:::',photo);
        console.log('state:',this.editsubproduct_state);
        console.log('length', photo.length);
      if(photo == null )
      {
        this.sub_product_img = []
        this.cardImageBase64b = null;

      }
      else{


        if(photo.length != 0 && photo.length != undefined ){
          //console.log('in here:^^^^',photo);
          if(photo[0].identifier == 'sub_product_img' && this.editsubproduct_state == true){
              this.sub_product_img = photo ;
              console.log('+++++++++SUB PRODUCT IMAGEB:',this.sub_product_img.length);
              console.log('+++++++++SUB PRODUCT IMAGESEEEEEEEEE*******B:',this.sub_product_img);
              this.cardImageBase64b = this.sub_product_img

          }
           else{
            this.sub_product_img = []
            this.cardImageBase64b = null;

          }

        }
        else{


          if(photo.identifier == 'product_img' ){
            //this.product_img = [];
            this.product_img.push({data:photo.data,type:'picture'});
            
            

          }
          else if(photo.identifier == 'sub_product_img' ){

            //this.sub_product_img = [];  
            this.sub_product_img.push({data:photo.data,type:'picture'});
            this.cardImageBase64b = this.sub_product_img

          }
          else{
            this.sub_product_img = []
            this.cardImageBase64b = null;

          }
          
        }
      }
      this.display_warning = 0;
      

    });



    if(this.mode == 'edit'){

          this.productsService.getCommunityProductById({id:this._id}).then((results)=>{
            console.log('PRODUCT by ID:::',results[0]);
            this.product_data =results[0] ;
            let data  = {chaimagename:this.product_data.product_image};
            this.productsService.generateThumbnail(data).then((images)=>{
              this.step1Form.patchValue({
                  'product_type': this.product_data.product_type[0]._id,
                  'product_name': this.product_data.product_name,
                  'product_desc':this.product_data.product_desc,
                  'product_price': this.product_data.product_price,
                  'available_quantity': this.product_data.available_quantity,

              });


              //console.log('%%%%%%%%%%%%%%%%%%%%%%IMAGES',this.product_data.product_image)

              this.product_img = images;
              this.slides_items = this.product_data.slides_items;
              for(let i = 0; i<this.product_data.slides_items.length; i++ ){

                this.getThumbnail(this.product_data.slides_items[i].sub_product_url).then((results)=>{
                  this.slides_items[i].sub_product_url = results; 
                  
                

                  
                });

              }



            });

          });

          



        }
        else{
          console.log('***not edit',this.mode);

        }

  }


  async getThumbnail(url){
    return new Promise((resolve,reject)=>{
      let data  = {chaimagename:url};
          this.productsService.generateThumbnail(data).then((images)=>{
            resolve(images);

          }, (err)=>{
            reject(err);
          });

    });
     

  }


   getProductType(category){

     let data = {
       category_id:category._id
     }

     this.productsService.getCommunityProductType(data).then((results)=>{
       console.log('product type:::::',results);
       this.product_types = results;


     });

   }

   checkToggle(id){
      for(let i=0; i<this.tableData1.dataRows.length; i++){
        if(i == id){
          this.isCollapsed = !this.isCollapsed;
          this.chaactive = id;
        }
      }

      

    }

    collapse(chaindex){
      if(this.isCollapsed == true && chaindex ===this.chaactive ){
        return this.isCollapsed
      }
      else
      {
        return false;
      }
    }
    getProductTypeName(id){
      for(let type of this.product_types)
      {
        if(type._id == id){

          return type.product_type_name;
        }
      }
      return '';
    }
    /*
    generalDetails(product_data){
      this.formError = null;
      if(product_data.product_type == null || this.product_img.length == 0 || product_data.product_name == null || product_data.product_desc == null)
      {
        this.formError = 'Please make sure that all field are filled';
      }
      else
      {
        if(this.product.length == 0)
        {

          this.product.push({
            product_type: product_data.product_type,
            product_image: this.product_img,
            product_name: product_data.product_name,
            product_desc: product_data.product_desc,
            product_price: product_data.product_price,
            available_quantity: product_data.available_quantity,
            user_id:this.user_id,
            community_id:this.community_id,
            shop_id:this.shop_id




          });
          this.chaActivated = 1;
        }
        else
        {

          this.product[0] = {
            product_type: product_data.product_type,
            product_image: this.product_img,
            product_name: product_data.product_name,
            product_desc: product_data.product_desc,
            product_price: product_data.product_price,
            available_quantity: product_data.available_quantity,
            user_id:this.user_id,
            community_id:this.community_id,
            shop_id:this.shop_id



          };



        }  

        console.log('Check product community.......................********...***..',this.product)                                                                                                

        this.saveProduct();
        //let product_type_name = this.getProductTypeName(product_data.product_type);
        //this.getProductsMeasure(product_type_name);

      }


    }

    addSubProduct(data){

      this.formErrorb = null;
      if(data.sub_product_unit == null || data.sub_product_name == null || data.sub_product_price == null || this.sub_product_img.length == 0)
      {
        this.formErrorb = 'Please make sure that all field are filled';
      }
      else
      {

        this.slides_items.push(
        {
          sub_product_unit:data.sub_product_unit,
          sub_product_name:data.sub_product_name,
          sub_product_price:data.sub_product_price,
          //sub_product_measure:this.sub_product_measure,
          sub_product_url:this.sub_product_img

        });


        this. clearSubProduct();


      }

    }

    clearSubProduct()
    {
      this.sub_product_unit = null;
      this.sub_product_name = null;
      this.sub_product_price = null;
      this.sub_product_measure = null;
      this.cardImageBase64b = null;
      this.isImageSavedb = false;

    }

    clearProduct()
    {
      this.product_type = null;
      this.product_img = [];
      this.product_desc = null;
      this.cardImageBase64 = null;
      this.product_name = null
      this.isImageSaved = false;
      this.product = [];
      this.chaActivated = 0;
      this.slides_items = []

    }

    validate(){

      
      if(this.product_type == null || this.product_img.length == 0 || this.product_name == null || this.product_desc == null)
      {
        return true;
      }
      else{
        return false;
      }

    }

    validateb(){

       if(this.sub_product_unit == null || this.sub_product_name.length == 0 || this.sub_product_price == null || this.cardImageBase64b == null)
      {

        return true;
      }
      else{
        return false;
      }

    }

    validatec(){


      if(this.product.length == 0 || this.slides_items.length == 0 )
      {

        return true;
      }
      else{
        return false;
      }


    }

    saveProduct(){

      this.productsService.saveCommunityProduct(this.product[0]).then((results)=>{
        //console.log('Check Result:',results);
        this.step1Form.reset();
        this.product_img = [];

        //this.clearSubProduct();
        //this.clearProduct();

        this.success = true;
        this.step = 'complete';

      });


    }*/

    getProductsMeasure(product_type_name){
      console.log('product_type_name:',product_type_name);
      if(this.product.length != 0){

        let data = {category:product_type_name};

        this.productsService.getProductsMeasure(data).then((results)=>{
        console.log('Check Result*******************+++++++++++++++++++++++++++++++:',results);

        if(results  != 'No record found')
        {
          this.measures = results;
        }
        

      });

      }
    }


    dismiss(){
      if(this.editsubproduct_state == true){
        this.step = 3;
        this.editsubproduct_state = false;

      }
      else{
        this.viewCtrl.dismiss('');

      }
      

    }

  stepProgress(){

     return Math.round((this.step / this.max_step) * 100) +'%'
   }

   chaValidate(step,step1Form){
   let outcome = true;


   switch (step) {
        case 1:
          

          if(step1Form.controls.product_type.valid == true && step1Form.controls.product_name.valid == true && this.product_img.length != 0 && step1Form.controls.product_desc.valid == true)
          {

            outcome = false;
          }



          if(this.product_img.length == 0){
            this.display_warning = 1;
          }


          break;
        case 2:

          if(step1Form.controls.sub_product_unit.valid == true && step1Form.controls.sub_product_name.valid == true && step1Form.controls.sub_product_price.valid == true && step1Form.controls.sub_product_measure.valid == true && this.cardImageBase64b != null  )
          {  
            outcome = false;
            if(this.sub_product_img.length == 0){
              this.display_warning = 1;
            }
          }
          else{
            this.display_warning = 1;

          }

          

          /*outcome = true;
          if(this.step2 == true){
             outcome = false;
          }*/
          break;
        case 3:
          outcome = false;
          break;
        default:
            outcome = true;
           break;

    }

    return outcome


 }


processDataEdit(step){

console.log('see if edit comes here:',this.edit_index);
  
  if(this.editsubproduct_state == true && this.edit_index != null ){
    let data = this.step1Form.value;
    this.slides_items[this.edit_index]['sub_product_unit'] = data['sub_product_unit'];
    this.slides_items[this.edit_index]['sub_product_name'] = data['sub_product_name'];
    this.slides_items[this.edit_index]['sub_product_price'] = data['sub_product_price'];
    this.slides_items[this.edit_index]['sub_product_measure'] = data['sub_product_measure'];
    this.slides_items[this.edit_index]['sub_available_quantity'] = data['sub_available_quantity'];
    
    this.slides_items[this.edit_index]['sub_product_url'] = this.cardImageBase64b;

    


  }
  else if(this.editsubproduct_state == true && this.edit_index !=null ){
     this.step1Form.patchValue({
                'sub_product_unit': this.product_data.slides_items[this.edit_index].sub_product_unit,
                'sub_product_name': this.product_data.slides_items[this.edit_index].sub_product_name,
                'sub_product_price':this.product_data.slides_items[this.edit_index].sub_product_price,
                'sub_product_measure': this.product_data.slides_items[this.edit_index].sub_product_measure,
                'sub_available_quantity': this.product_data.slides_items[this.edit_index].sub_available_quantity

                });

  }


  this.editsubproduct_state = false; 
  console.log('see if edit comes here2:',this.product_data)




}

 processData(step){
  switch(step){
    case 1:
      let data = this.step1Form.value;
      this.generalDetails(data);
      this.saveProduct();
      
      break;
    case 2:
      let datab = this.step1Form.value;
      this.addSubProduct(datab);

      
      //this.shop['location'] = this.chalng+','+this.chalat;
     
      //this.step2 = true;

      break;
    case 3:
      console.log('here');
      this.saveProduct();

      break;

   default:
         break;

  }

}

changeStep(){

  this.step1Form.reset();
  this.step = 2;
  this.editsubproduct_state = false;
  this.cardImageBase64b = null;
  this.sub_product_img = [];



}

stepAction(step,action,mode='add'){

   switch (action) {
        case 'next':
          
          if(step < this.max_step && this.mode !='edit')
          {
            this.step = step + 1;
          }
          else if(step < this.max_step && this.mode =='edit')
          {

            this.step = this.max_step;

          }

          if(this.editsubproduct_state==true ){

            this.processDataEdit(step);
          }
          else{
            this.processData(step);
          }
         // this.processData(step);
          
          break;
        case 'previous':
          if(step != 1 && step != 'complete')
          {
            this.step = step - 1;
          }
          else if(step == 'complete'){
            this.step = this.max_step;
          }
          break;
        case 'complete':
          //this.step = 'complete';
          if(this.mode=='edit'){
            //this.processDataEdit(step);
            this.saveEditProduct();
          }
          else{
            this.processData(step);
          }
          
          //this.saveData();
          break;
        default:
           break;

    }

 }

 manageShop(){
   this.viewCtrl.dismiss({message:'product saved',data:this.product});

 }




 // additional code


  removeImage() {
        this.cardImageBase64 = null;
        this.isImageSaved = false;
    }

    removeImageb() {
        this.cardImageBase64b = null;
        this.isImageSavedb = false;
    }
/*
    checkToggle(id){
      for(let i=0; i<this.tableData1.dataRows.length; i++){
        if(i == id){
          this.isCollapsed = !this.isCollapsed;
          this.chaactive = id;
        }
      }

      

    }

    collapse(chaindex){
      if(this.isCollapsed == true && chaindex ===this.chaactive ){
        return this.isCollapsed
      }
      else
      {
        return false;
      }
    }
    getProductTypeName(id){
      for(let type of this.product_types)
      {
        if(type._id == id){

          return type.product_type_name;
        }
      }
      return '';
    }*/
    generalDetails(data = []){
      this.formError = null;
      
      this.product_type = data['product_type'];
      this.product_name = data['product_name'];
      this.product_desc = data['product_desc'];
      if(this.product_type == null || this.product_img.length == 0 || this.product_name == null || this.product_desc == null)
      {
        this.formError = 'Please make sure that all field are filled';
      }
      else
      {
        if(this.product.length == 0)
        {

          this.product.push({
            product_type: this.product_type,
            product_image: this.product_img,
            product_name: this.product_name,
            product_desc: this.product_desc,
            user_id:this.user_id,
            community_id:this.community_id,
            shop_id:this.shop_id,
            product_price:data['product_price'],
            product_unit:data['product_unit'],
            available_quantity:data['available_quantity']


            //sub_product_measure:this.sub_product_measure,

            //slides_items:[]



          });
          this.chaActivated = 1;
        }
        else
        {

          this.product[0] = {
            product_type: this.product_type,
            product_image: this.product_img,
            product_name: this.product_name,
            product_desc: this.product_desc,
            product_price:data['product_price'],
            product_unit:data['product_unit'],
            available_quantity:data['available_quantity']

            //slides_items:[]



          };



        }


        let product_type_name = this.getProductTypeName(this.product_type);
        //console.log('product_type_name:::',product_type_name);
        //this.getProductsMeasure(product_type_name);

      }


    }

    addSubProduct(data = []){
      this.sub_product_img = [];
      this.sub_product_unit = data ['sub_product_unit'];
      this.sub_product_name = data ['sub_product_name'];
      this.sub_product_price = data ['sub_product_price'];
      this.sub_product_measure = data ['sub_product_measure'];
      this.formErrorb = null;
      if(this.sub_product_unit == null || this.sub_product_name == null || this.sub_product_price == null || this.cardImageBase64b == null)
      {
        this.formErrorb = 'Please make sure that all field are filled';
      }
      else
      {

        this.slides_items.push(
        {
          sub_product_unit:this.sub_product_unit,
          sub_product_name:this.sub_product_name,
          sub_product_price:this.sub_product_price,
          sub_product_measure:this.sub_product_measure,
          sub_product_url:this.cardImageBase64b,
          sub_available_quantity:data['sub_available_quantity']

        });
        console.log('Adding into ONE_________',this.slides_items);data

        this. clearSubProduct();


      }

    }

    clearSubProduct()
    {
      this.sub_product_unit = null;
      this.sub_product_name = null;
      this.sub_product_price = null;
      this.sub_product_measure = null;
      this.cardImageBase64b = null;
      this.isImageSavedb = false;
      this.sub_product_img = [];
      this.editsubproduct_state = false;

    }

    clearProduct()
    {
      this.product_type = null;
      this.product_img = [];
      this.product_desc = null;
      this.cardImageBase64 = null;
      this.product_name = null
      this.isImageSaved = false;
      this.product = [];
      this.chaActivated = 0;
      this.slides_items = []

    }

    validate(){

      
      if(this.product_type == null || this.product_img.length == 0 || this.product_name == null || this.product_desc == null)
      {
        return true;
      }
      else{
        return false;
      }

    }

    validateb(){

       if(this.sub_product_unit == null || this.sub_product_name.length == 0 || this.sub_product_price == null || this.cardImageBase64b == null)
      {

        return true;
      }
      else{
        return false;
      }

    }

    validatec(){


      if(this.product.length == 0 || this.slides_items.length == 0 )
      {

        return true;
      }
      else{
        return false;
      }


    }

    saveProduct(){

      //this.product[0].slides_items = this.slides_items;
      console.log('stringfy',JSON.stringify(this.product) );
      
      this.productsService.saveProduct(this.product[0]).then((results)=>{
        if(results != 'No record found')
        {
          this.step = 'complete';
        }

      })
     
      /*this.productService.sendPostAuthRequest('/addproduct',this.product[0]).then((results)=>{
        console.log('Check Result:',results);
        

        this.clearSubProduct();
        this.clearProduct();

        this.success = true;

      });*/


    }
    saveEditProduct(){

      this.product_data.slides_items = this.slides_items;
      this.product_data['_id'] = this._id;
      console.log('stringfy MORE',this.product_data );
      
      this.productsService.saveEditProduct(this.product_data).then((results)=>{
        console.log('After Saved::',results);
        if(results != 'No record found')
        {
          this.step = 'complete';
        }

      })
     
      


    }
    /*
    getProductsMeasure(product_type_name){
      console.log('product_type_name:',product_type_name);
      if(this.product.length != 0){

        let data = {category:product_type_name};

        this.productService.sendPostAuthRequest('/getmeasure',data).then((results)=>{
        console.log('Check Result:',results);

        if(results['msg'] != 'No record found')
        {
          this.measures = results['msg'];
        }
        

      });

      }
    }*/


    contactsPic(data){
    return this.baseUrl(data) + data;
  }

  baseUrl(data){
      if(data){


      let ext = data.split('.');

      if(ext.length > 1)
      {
        return 'assets/img/avatar/';

      }
      else{
        return environment.server + 'uploads/images/';
      }
    }
    

  }

  editSubProduct(i){
    console.log('check INDEX:::::::::',i);
    console.log('check MODE:::::::::',this.mode);

    let data = this.slides_items[i];
    if(this.mode != 'edit'){
      this.step1Form.value.sub_product_unit = data ['sub_product_unit'];
      this.step1Form.value.sub_product_name = data ['sub_product_name'];
      this.step1Form.value.sub_product_price = data ['sub_product_price'];
      this.step1Form.value.sub_product_measure = data ['sub_product_measure'];

    }
    else{
      console.log('check DATA:::::::::',this.product_data.slides_items[i]);
      this.step1Form.patchValue({
                'sub_product_unit': this.product_data.slides_items[i].sub_product_unit,
                'sub_product_name': this.product_data.slides_items[i].sub_product_name,
                'sub_product_price':this.product_data.slides_items[i].sub_product_price,
                'sub_product_measure': this.product_data.slides_items[i].sub_product_measure,
                'sub_available_quantity': this.product_data.slides_items[i].sub_available_quantity

            });
      
    }
    
    this.step = 2;
    this.sub_product_img = data['sub_product_url'];
    this.cardImageBase64b = this.sub_product_img;
    //console.log('check INDEX IMAGES:::::::::',data['sub_product_url']);
    this.editsubproduct_state = true;
    this.edit_index = i;



  }





    

}
