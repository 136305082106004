import { Component, OnInit } from '@angular/core';
import {ITimer} from "../../models/itimer"

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  NavController,ModalController,NavParams,AlertController } from '@ionic/angular';

import { MoneyService } from '../../services/money';

import { LoginService} from '../../services/login';
import { ChatService} from '../../services/chat';
import { ChaEncryptionService} from '../../services/chaencryption';
import { HelperService} from '../../services/helper';

import {Config} from '../../config';
import {User} from '../../models/user';
//import 'babel-polyfill';
//import {brandedQRCode} from 'branded-qr-code';


@Component({
  selector: 'app-payment-preview',
  templateUrl: './payment-preview.component.html',
  styleUrls: ['./payment-preview.component.scss'],
})
export class PaymentPreviewComponent implements OnInit {

	ErrorMsg:any;
	data:any = {msg:null};
  previewdata:any;
  	public user: User;
  	qrcode:any;
  	elementType : 'url' | 'canvas' | 'img' = 'url';
  	value : any = Config.server;

  constructor(
  	public navCtrl: NavController, 
	public navParams: NavParams,
	public viewCtrl: ModalController,
	private loginService: LoginService,
  	public alertCtrl: AlertController,
  	public encryptionService: ChaEncryptionService,) {
  		this.user = new User();
    	this.user.image = '';
      this.previewdata = this.navParams.get('data');

  	 }

  ngOnInit() {

  	console.log('ionViewDidLoad QRCODE');
    this.user = this.loginService.user as User;
    
  }


  dismiss() {
	   this.viewCtrl.dismiss();
	}

	home() {
	//this.navCtrl.push('Home');
	}

  baseUrl(data){
    let ext = data.split('.');

    if(ext.length > 1)
    {
      return 'assets/img/avatar/';

    }
    else{
      return Config.server + 'uploads/images/';
    }

}

}
