// handle login and authenticaion for users

import { Injectable, ViewChild } from '@angular/core';
import {  AlertController,Platform } from '@ionic/angular';



@Injectable()
export class AlertService {

	constructor(
	 public platform: Platform,
	 public alertCtrl: AlertController, 
	) {

		
	}

	public async showPrompt(title,message) {

		let alert = await this.alertCtrl.create({
      		header: title,
      		subHeader: message,
      		buttons: ['OK']
    	});
    	return await alert.present();
    
  	}	

	
}
