import { Component, OnInit,Input,ElementRef } from '@angular/core';
import { Router, Params, ActivatedRoute,NavigationExtras } from '@angular/router';

import {  PopoverController,NavController,ModalController,NavParams,AlertController ,ActionSheetController} from '@ionic/angular';

import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@awesome-cordova-plugins/native-geocoder/ngx';

import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';
import { environment } from '../../../../../environments/environment';
import { Storage } from '@ionic/storage';
import {HelperService} from '../../../../services/helper';
import {Item} from '../../../../models/item';
import {ChaEventsService} from '../../../../services/chaevents';


@Component({
  selector: 'cha-search-location',
  templateUrl: './cha-search-location.component.html',
  styleUrls: ['./cha-search-location.component.scss'],
}) 
export class ChaSearchLocationComponent  {
 
  @Input() data:any;
  @Input() toolbar_buttons:any = [];
 
 //cha search
  @Input() getItems: (groupid,dataService,event) => any;
  @Input() searchItems: (searchword,total,data,dataService,groupid) => any;
  @Input() items:Item[];
  @Input() items_reserve:Item[];
  @Input() chasearch:any;
  @Input() groupid:any;
  @Input() dataService:any;
  @Input() chalimit:any;
  @Input() locationmenu:any;
  @Input() placeholder:any;
  constructor(
  	private activatedRoute: ActivatedRoute,
  	private router:Router,
    public navCtrl: NavController,
    public helper: HelperService,
    public modalCtrl: ModalController,
    public geolocation: Geolocation,
    private nativeGeocoder: NativeGeocoder,
    
    public alertController: AlertController,
    public storage: Storage,
    private spinnerDialog: SpinnerDialog,
    public actionSheetController: ActionSheetController,
    private popoverCtrl: PopoverController,
    private events: ChaEventsService
    

  	) {
    console.log('DATA:',this.data);
    //this.toolbar_buttons = this.data.layout.parameters.sections.toolbar.toolbar_button;
    //console.log(this.toolbar_buttons);



    
  	}

    onInput(chaevent){
      console.log('datahere:',this.data);
    }

    buttonAction(action){
      if(action.method =='navigate'){
        console.log('createschool button',action);
        let datab = [];
        let dynamicdata = {
          key:'page_name',
          value: action.page
        };
        datab.push(dynamicdata)
        this.events.dynamicDataPublish(datab);

        //let navigationExtras: NavigationExtras = { state: {page:this.data,page_name:action.page} };
        //this.navCtrl.navigateForward('/dashboard/apps/base',navigationExtras);

      }

    }


 
  

  

}
