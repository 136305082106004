
export class Config {

	// applicaiton title
	public static appTitle = 'UfuluApp';

	// url of the chat server
	// for local development it will be something like http://192.168.0.214:9000/
	//public static server = 'https://ionic-video-chat-server.herokuapp.com/';
	//public static server = 'http://3.139.237.213:5000/';
	public static server = 'http://127.0.0.1:5000/';
	//public static server = 'https://192.168.1.55:5000/';
	//public static server = 'http://10.254.254.248:5000/';
	//public static server = 'https://www.ufulupay.com/';
	//public static apiurl = 'http://104.251.212.129:8000/';
	//public static server = 'http://104.251.212.129:5000/';
	//public static apiurl = 'http://104.251.212.129:8000/api/';
	//public static apiurl = 'https://192.168.1.55:8000/';
	public static apiurl = 'http://127.0.0.1:8000/';
	//public static apiurl = 'http://www.ufulupay.com:8001/';
	public static transactionApi = 'transactions/';

	//public static loyaltyUrl = 'http://104.251.212.129:8003';
	public static loyaltyUrl = 'http://127.0.0.1:8102';
	//public static loyaltyUrl = 'http://10.254.254.248:8101';

	// enables or disables chat sounds. usefull for development
	public static audio = true;

	// STUN/TURN ice servers for connection negotiation
	public static ice = [
		{
			urls: 'stun:stun.l.google.com:19302'
		}, {
			urls: 'stun:stun.services.mozilla.com'
		}, {
			urls: 'stun:numb.viagenie.ca',
			username: 'numb@arzynik.com',
			credential: 'numb'
		}, {
			urls: 'turn:numb.viagenie.ca',
			username: 'numb@arzynik.com',
			credential: 'numb'
		}
	];
	// if we allow attachments or not.
	// be sure to configure your AWS_ACCESS_KEY_ID, AWS_SECRET_ACCESS_KEY and S3_BUCKET in the server config
	public static attachments = true;

	// whether or enable markdown parsing client side
	public static markdown = true;

	// static values declarations for the app
	// signup static values
	public static emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
	public static passwordPattern = '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,18}$';
	public static phoneDefaultPrefix = '265';
	public static chaCurrency ='MK';

	public static prompts = {
		signup:{
			alreadyExists:{
				title:'Sorry',
				message:'The Phone Number is Already being Used',
				error: ' Network Error'
			},

		},
		pin:{
			createpin:{
				title:'Notice',
				message:'',
				error: 'An Error has Occured'
			},
			
		}
	}
	
}
