import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'dashboard', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'apps', loadChildren: () => import('./apps/apps.module').then(m => m.AppsPageModule) },
  { path: 'verification', loadChildren: () => import('./common/verification/verification.module').then(m => m.VerificationPageModule) },
  { path: 'signup', loadChildren: () => import('./signup/signup.module').then(m => m.SignupPageModule) },
  { path: 'forgotpassword', loadChildren: () => import('./forgotpassword/forgotpassword.module').then(m => m.ForgotPasswordPageModule) },
  { path: 'chat/:chatId/:mode', loadChildren: () => import('./chat/chat.module').then(m => m.ChatPageModule) },
  { path: 'chat2/:chatId', loadChildren: () => import('./chat/chat.module').then(m => m.ChatPageModule) },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountPageModule) },
  { path: 'contacts', loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsPageModule) },
  { path: 'add-contacts', loadChildren: () => import('./add-contacts/add-contacts.module').then(m => m.AddContactsPageModule) },
  { path: 'mybalance', loadChildren: () => import('./mybalance/mybalance.module').then(m => m.MybalancePageModule) },
  { path: 'mobilemoney', loadChildren: () => import('./mobilemoney/mobilemoney.module').then(m => m.MobilemoneyPageModule) },
  { path: 'qrcode', loadChildren: () => import('./qrcode/qrcode.module').then(m => m.QrcodePageModule) },
  { path: 'userprofile', loadChildren: () => import('./userprofile/userprofile.module').then(m => m.UserprofilePageModule) },
  { path: 'editprofile', loadChildren: () => import('./editprofile/editprofile.module').then(m => m.EditprofilePageModule) },
  { path: 'vendor-manager', loadChildren: () => import('./vendor-manager/vendor-manager.module').then(m => m.VendorManagerPageModule) },
  { path: 'vendor-dashboard', loadChildren: () => import('./vendor-dashboard/vendor-dashboard.module').then(m => m.VendorDashboardPageModule) },
  { path: 'loyalty-users', loadChildren: () => import('./loyalty-users/loyalty-users.module').then(m => m.LoyaltyUsersPageModule) },
  { path: 'transactions', loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsPageModule) },
  { path: 'airtime', loadChildren: () => import('./airtime/airtime.module').then(m => m.AirtimePageModule) },
  { path: 'handyman', loadChildren: () => import('./handyman/handyman.module').then(m => m.HandymanPageModule) },
  { path: 'tiles', loadChildren: () => import('./microservices/chinatrade/tiles/tiles.module').then(m => m.TilesPageModule) },
  { path: 'community-shops-product', loadChildren: () => import('./microservices/community-shops-product/community-shops-product.module').then(m => m.CommunityShopsProductPageModule) },
  
  
  { path: 'spareparts', loadChildren: () => import('./spareparts/spareparts.module').then(m => m.SparePartsPageModule) },
  { path: 'sparepartsdealer', loadChildren: () => import('./sparepartsdealer/sparepartsdealer.module').then(m => m.SparePartsDealerPageModule) },
  { path: 'sparepartshipper', loadChildren: () => import('./sparepartshipper/sparepartshipper.module').then(m => m.SparePartsShipperPageModule) },
  { path: 'music', loadChildren: () => import('./music/music.module').then(m => m.MusicPageModule) },
  { path: 'songslist', loadChildren: () => import('./songslist/songslist.module').then(m => m.SongslistPageModule) },
  { path: 'communitymessagedetails', loadChildren: () => import('./microservices/community-message-details/community-message-details.module').then(m => m.CommunityMessageDetailsPageModule) },
  { path: 'create-community-shops', loadChildren: () => import('./microservices/create-community-shops/create-community-shops.module').then(m => m.CreateCommunityShopsPageModule) },
  { path: 'manage-community-shops', loadChildren: () => import('./microservices/manage-community-shops/manage-community-shops.module').then(m => m.ManageCommunityShopsPageModule) }
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
