import { Product, CartService } from '../../services/cart.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController,NavController,ActionSheetController } from '@ionic/angular';
import {LocationService} from '../../services/location.service';
import {PaymentMethodsComponent} from '../payment-methods/payment-methods.component';
import {PayComponent} from '../pay/pay.component';
import {PaymentPreviewComponent} from '../payment-preview/payment-preview.component';
import {ProductsService} from '../../services/products';
import { LoginService} from '../../services/login';
import {CommunityService} from '../../services/community';
@Component({
  selector: 'app-cart-modal',
  templateUrl: './cart-modal.component.html',
  styleUrls: ['./cart-modal.component.scss'],
})
export class CartModalComponent implements OnInit {
 
  cart: Product[] = [];
  chaaddress:any;
  addresses:any=[];
  beaddress:any;
  forex:any= {};
  total_volume:any=0;
  total_weight:any=0;
  weight:any=[];
  communityid:any;


 
  constructor(
    private cartService: CartService, 
    private modalCtrl: ModalController, 
    private viewCtrl: ModalController,
    private alertCtrl: AlertController,
    public navCtrl: NavController,
    private locationService:LocationService,
    public actionSheetController: ActionSheetController,
    private productsService:ProductsService,
    private loginService: LoginService,
    private communityService:CommunityService ) { }
  
  ngOnInit() {
    this.cart = this.cartService.getCart();
    this.addressStatus();
    console.log('Data CART:',this.cart); 

    this.productsService.getForex('yuan').then((results)=>{

      this.forex = results;
      console.log('FOREX',this.forex);
      //calculate total volume
      this.total_volume = this.getTotalVolume();
      this.total_weight = this.getTotalWeight();


    });


  }

  addressStatus(){
    this.locationService.getAddress().then((result)=>{
      console.log('gfjh',result);

      if(result == 'No record found')
      {
        this.chaaddress =1;
      }
      else
      {
        this.chaaddress = 0;
        this.addresses = result;
        this.beaddress = this.defaultAddress(result);
      }

    });

  }

  defaultAddress(data){
    for(let single of data){
      if(single.default == 1){
        return single;
      }

    }
  }

  getShipping(){

    if(this.address.length == 0)
    {
      return 0;
    }
    else
    {
      //this.address[0]
    }



  }

  address()
  {
    //this.navCtrl.navigateForward('/userprofile');
     this.modalCtrl.dismiss('address');
  }
 
  decreaseCartItem(product) {
    this.cartService.decreaseProduct(product);
  }
 
  increaseCartItem(product) {
    this.cartService.addProduct(product);
  }
 
  removeCartItem(product) {
    this.cartService.removeProduct(product);
  }
 
  getTotal() {
    return this.cart.reduce((i, j) => i + j.price * j.amount, 0);
  }
 
  close() {
    this.modalCtrl.dismiss();
  }

  closeb(value) {
    this.modalCtrl.dismiss(value);
  }
 
 getCommunityAddress(communityid){

    
  }

 getAddress(communityid='')
{
  
  this.locationService.getAddress(communityid).then((results)=>{
    if(results == 'No record found')
    {
      this.addresses = [];
    }
    else
    {
      this.addresses = results;

    }
    
    console.log('address:',this.addresses);

  },(error)=>{
    console.log('address error:',error);
  })
}
  async checkout() {
    // Perfom PayPal or Stripe checkout process
    let amount = this.getTotal(); 
    this.selectDelivery(amount);
    //console.log('CART:',this.cart);

    /*
    let alert = await this.alertCtrl.create({
      header: 'Thanks for your Order!',
      message: 'We will deliver your food as soon as possible',
      buttons: ['OK']
    });
    alert.present().then(() => {
      this.modalCtrl.dismiss();
    });
    */



  }

calculateVolume(product){
  //console.log('PRODUCT in VOLUME:::',product);
  if(product.type =='taobao')
  {
     return 0;
  }
  else
  {
    if(product.product_type[0].product_type_name != '')
    {

      //linear algebra for calculating volume
      //calculating number of boxes per given square meters
      let givenSqm = product.amount;
      let sqm_per_box = product.subproduct.sub_product_measure[0].sqm_per_package;
      let no_of_boxes = Number(givenSqm)/Number(sqm_per_box);

      //calculate the volume
      let boxes_per_pallet = product.subproduct.sub_product_measure[0].num_packages_per_grosspack;
      let cbm_per_pallet = product.subproduct.sub_product_measure[0].volume_per_grosspack;
      let volume = Number(Math.round(no_of_boxes))/Number(boxes_per_pallet) * Number(cbm_per_pallet);
      //this.volume.push({vol:volume.toFixed(2),chaindex:index})
      return volume.toFixed(2);



    }
    else
    {
      return 0;
    }
  }


}

calculateWeight(product){
  if(product.type =='taobao')
  {
    return 0;
  }
  else
  {
    if(product.product_type[0].product_type_name != '')
    {

      //linear algebra for calculating volume
      //calculating number of boxes per given square meters
      let givenSqm = product.amount;
      let sqm_per_box = product.subproduct.sub_product_measure[0].sqm_per_package;
      let no_of_boxes = Number(givenSqm)/Number(sqm_per_box);

      //calculate the weight
      let weight_per_box = product.subproduct.sub_product_measure[0].weight_per_package; 
      let weight = weight_per_box * Math.round(no_of_boxes);
      //this.weight.push({weight:weight,chaindex:index});
      return weight;



    }
    else
    {
      return 0;
    }
  }




}


getTotalVolume(){
  let data = 0;
  for(let singlecart of this.cart){

    if(singlecart['type'] != 'community'){
      data = Number(data) + Number(this.calculateVolume(singlecart)); 
    }
  }

  if(Math.round(data) == 0){

    return 0;
  }
  else 
  {
    return data.toFixed(2);

  }

  
}

getTotalWeight(){
  let data = 0;
  for(let singlecart of this.cart){
    if(singlecart['type'] != 'community'){
      data = Number(data) + Number(this.calculateWeight(singlecart)); 
      }
      else{

        data = Number(data)

      }
  }


  if(Math.round(data) == 0){

    return 0;
  }
  else 
  {
    return data.toFixed(2);

  }
}



buy(data){


    this.selectDelivery(data);
  }

  pay(shippingmode,amount)
  {



    if(shippingmode == 'seacargo')
    {
      
      let newdata = {
        type:'cart',
        amount:amount,
        remarks:'cart',
        cart:this.cart,
        shipping_type:'seacargo',
        user:this.loginService.user.username
       
       }


       console.log('SEA CART', this.cart);

      this.securityPin(newdata);


    }
    else if(shippingmode == 'aircargo')
    {
        
        let newdata = {
          type:'cart',
          amount:amount,
          remarks:'cart',
          cart:this.cart,
          shipping_type:'aircargo',
          user:this.loginService.user.username
         
         }

         this.securityPin(newdata);


    }

  }

  getTitle(){
    let title = '';
    let index = 0;
    for(let singlecart of this.cart)
    {
      if(index == 0)
      {
        title = singlecart.name;
      }
      else
      {
        title = title + ', ' + singlecart.name
      }
    }

    return title;

  }

  async securityPin(SentData){

    let modal = await this.modalCtrl.create(
      {
        component:PayComponent,
        componentProps:{data:SentData},
        cssClass: 'my-custom-modal-css-full'
      });
       await modal.present();

    await modal.onDidDismiss().then((rawdata)=>{
      console.log('CHECK Rawdata::',rawdata);
      if(rawdata.data == 'invalid type')
      {
        this.presentAlert('Notification','Not Successful','payment type not available please contact Admin');
        
      }
      else if(rawdata.data == 'success')
      {


        let data ={
          amount:SentData.amount,
          name: this.getTitle()

        };
        this.paymentPreview(data);
        //let  message =''

        //this.presentAlert('Notification','Payment Successful',message);


        //this.dismiss(rawdata);
      }
      //this.slidephoto = [];
     // this.dismiss(rawdata);


      /*let data = rawdata['data'];
      if(data && data.state == 'success')
        { 
          console.log('success',data);
          console.log('Amount::',SentData);
          if(data.type == 'Visa Card')
          {
            let chaData = {
              type:'visacard'




            }
            this.pushPayment(chaData);
          }
          //this.router.navigate(['/dashboard'])
          //this.login();

        }
        else
        {
          console.log('failed',data);
        }*/
        console.log('failed AFTER',rawdata);
    });
        
        
     
      //return await modal.present();
  }

  async paymentPreview(SentData){


    let modal = await this.modalCtrl.create(
      {
        component:PaymentPreviewComponent,
        componentProps:{data:SentData}
      });
       await modal.present();

    await modal.onDidDismiss().then((rawdata)=>{



      this.cartService.resetCart();
      
      this.closeb('orders');



    });


    

  }

  async pushPayment(chaData){

    let modal = await this.viewCtrl.create(
      {
        component:PayComponent,
        componentProps:{data:chaData},
        cssClass: 'my-custom-modal-css-full'
      });
       await modal.present();

    await modal.onDidDismiss().then((data)=>{

    });


  }

  getCommission(amount){

    return Number(this.forex.commission) * Number(amount);

  }
 
 estimateSeaCost(){



   let cost = []
   for(let singlecart of this.cart)
   {
     if(singlecart['type'] =='taobao')
     {
       let volume = 0.2;
       let estimatedcost = (Number(this.forex.seacost_small) *  Number(volume)) * Number(this.forex.shipping_rate);
       let final_estimatedcost = this.getCommission(estimatedcost) + estimatedcost;
       cost.push(Number(final_estimatedcost.toFixed(2)));


     }
     else if(singlecart['type'] =='community'){
       //needs to add courier cost
       let final_estimatedcost = 0;
       //let estimatedcost = (Number(this.forex.seacost_small) *  Number(this.calculateVolume(singlecart))) * Number(this.forex.shipping_rate);
       //let final_estimatedcost = this.getCommission(estimatedcost) + estimatedcost;
       cost.push(Number(final_estimatedcost.toFixed(2)));

     }
     else
     {

         if(this.forex.shipping_weight_threshold < this.calculateVolume(singlecart))
         {
           // large goods and charge per cbm
           let estimatedcost = (Number(this.forex.seacost_large) *  Number(this.calculateVolume(singlecart))) * Number(this.forex.shipping_rate);
           let final_estimatedcost = this.getCommission(estimatedcost) + estimatedcost;
           cost.push(Number(final_estimatedcost.toFixed(2)));

         }
         else
         {
           // small goods

           let estimatedcost = (Number(this.forex.seacost_small) *  Number(this.calculateVolume(singlecart))) * Number(this.forex.shipping_rate);
           let final_estimatedcost = this.getCommission(estimatedcost) + estimatedcost;
           cost.push(Number(final_estimatedcost.toFixed(2)));

         }
       }
    }

     return cost.reduce((a, b) => a + b, 0);

 }


 estimateAirCost(){
   let cost = []
   for(let singlecart of this.cart)
   {
     if(singlecart['type'] =='taobao')
     {
       let weight = 1.5;
       let estimatedcost = (Number(this.forex.aircost_small) *  Number(weight)) * Number(this.forex.shipping_rate);
       let final_estimatedcost = this.getCommission(estimatedcost) + estimatedcost;
       cost.push(Number(final_estimatedcost.toFixed(2)));
       




     }
     else 
     {

       let estimatedcost = (Number(this.forex.aircost_small) *  Number(this.calculateWeight(singlecart))) * Number(this.forex.shipping_rate);
       let final_estimatedcost = this.getCommission(estimatedcost) + estimatedcost;
       cost.push(Number(final_estimatedcost.toFixed(2)));

     }
   }


   return cost.reduce((a, b) => a + b, 0);



   

 }



  async selectDelivery(amount) {

    if(this.cart[0]['type'] == 'community'){
      //let address = this.getCommunityAddress(this.cart[0]['communityid'])

      //select the nearest courier

      //calculate courier fee

      let seacost = this.estimateSeaCost() + Number(amount);
      //let aircost = this.estimateAirCost() + Number(amount);
      

      const actionSheet = await this.actionSheetController.create({
        header: 'Select Shipping Methods',
        cssClass: 'my-custom-class',
        buttons: [{
          text: 'Sea Cargo (MK'+ String(seacost.toFixed(2)) + ')' ,
          icon: 'boat',
          handler: () => {
            this.pay('seacargo',seacost)
          }
        }
        /*, {
          text: 'Air Cargo (MK'+ String(aircost.toFixed(2)) + ')',
          icon: 'airplane',
          handler: () => {
            this.pay('aircargo',aircost)
          }
        }*/
        , {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }]
      });
      await actionSheet.present();

      const { role } = await actionSheet.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);



      

    }
    else{

      let seacost = this.estimateSeaCost() + Number(amount);
      let aircost = this.estimateAirCost() + Number(amount);
      

      const actionSheet = await this.actionSheetController.create({
        header: 'Select Shipping Methods',
        cssClass: 'my-custom-class',
        buttons: [{
          text: 'Sea Cargo (MK'+ String(seacost.toFixed(2)) + ')' ,
          icon: 'boat',
          handler: () => {
            this.pay('seacargo',seacost)
          }
        }, {
          text: 'Air Cargo (MK'+ String(aircost.toFixed(2)) + ')',
          icon: 'airplane',
          handler: () => {
            this.pay('aircargo',aircost)
          }
        }, {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }]
      });
      await actionSheet.present();

      const { role } = await actionSheet.onDidDismiss();
      console.log('onDidDismiss resolved with role', role);
    }

  } 

   dismiss(transactionData) {
    this.viewCtrl.dismiss(transactionData);
  }


  async presentAlert(title,subtitle,message)
  {
    const alert = await this.alertCtrl.create({
        header: title,
        subHeader: subtitle,
        message: message,
        buttons: ['OK']
      });

      await alert.present();
  }

  getCommunityCourier(community_id,lat,lng){
    return new Promise((resolve,reject) => {
      let data = {lat:lat,lng:lng,community_id:community_id};
      this.communityService.getCommunityCourier(data).then((results)=>{
        resolve(results);

      });


    });
    

  }


}