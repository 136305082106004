import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavController,ModalController, NavParams } from '@ionic/angular';

import { MoneyService} from '../../services/money';
import { UserService} from '../../services/users';
import { HelperService } from '../../services/helper';

import { AlertService } from '../../services/alerts';
import {Config} from '../../../app/config';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
	title = Config.appTitle;
	submitForm: FormGroup;
  	submitAttempt: boolean = false;
  	emailPattern = Config.emailPattern;
    passwordPattern = Config.passwordPattern;
    results:any = {msg:null};
    chaprefix = Config.phoneDefaultPrefix;
    verificationResult:any = {msg:{verificationId:null}};
    user:any;
    username:any;
    token:any;
    reset_status:boolean=false;

  constructor(
  	public navCtrl: NavController,
   public navParams: NavParams,
   public modalCtrl: ModalController,
   public formBuilder: FormBuilder,
   public viewCtrl: ModalController,
   private moneyService: MoneyService,
   public alertCtrl: AlertService,
   private helperService: HelperService,
   public userService:UserService
   ) { 

  	this.username = this.navParams.get('username');
    this.token = this.navParams.get('token');

   	//form validator
  	    this.submitForm = formBuilder.group({
        pin: ['', Validators.compose([Validators.required, Validators.minLength(6)])],
        confirmPin: ['', Validators.compose([Validators.required])],
        
        
    	}, {validator: this.matchingPasswords('pin', 'confirmPin')});
  }

  ngOnInit() {}

  submitData(){
    let userdata = this.submitForm.value;
    let pindata = {
      username:this.username,
      password: userdata.pin
    }
    console.log(pindata);

    this.userService.resetPassword(pindata).then((result)=>{
      console.log('password reset successful',result);
      this.reset_status = true;

    });

  }

 

goBack(){
   this.navCtrl.pop();
 }
matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
      return (group: FormGroup): {[key: string]: any} => {
        let password = group.controls[passwordKey];
        let confirmPassword = group.controls[confirmPasswordKey];

        if (password.value !== confirmPassword.value) {
          return {
            mismatchedPasswords: true
          };
        }
      }
    }

dismiss(data){
  this.modalCtrl.dismiss(data);
  
}

}


