import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
 
//import {Http} from '@angular/http';

import {AutoCompleteService} from 'ionic4-auto-complete';
//import { Contacts, Contact, ContactField, ContactName,ContactFindOptions,ContactFieldType } from '@ionic-native/contacts';
//import { Contacts,Contact } from '@capacitor-community/contacts';
import { Plugins } from '@capacitor/core';

const { Contacts } = Plugins;

@Injectable()
export class CompleteTestService implements AutoCompleteService {
  labelAttribute = 'name';
  formValueAttribute = 'numericCode';
  contacts: any[] = []; // Change this to the appropriate type if available
  searchQuery = '';

  constructor(
    //private contacts: Contacts
    ) {

  
  }

  async searchContacts(searchQuery) {
    try {
      const { matches } = await Contacts.getContacts({
        query: searchQuery,
        multi: true,
      });

      // Update the contacts array with the matched results
      return matches;
    } catch (error) {
      console.error('Error searching contacts', error);

    }
  }

  getResults(keyword:string) {




    //var options = new ContactFindOptions();
    //options.filter = keyword;
    //options.multiple = true;
    //let filter = ["displayName", "phoneNumbers"] as ContactFieldType[];
    return new Promise((resolve,reject)=>{

      try {
      const { matches } =  Contacts.getContacts({
        query: keyword,
        multi: true,
      });

      // Update the contacts array with the matched results
      resolve(matches);
    } catch (error) {
      console.error('Error searching contacts', error);
      reject(error);
      
    }


    

    }); 
  }


}