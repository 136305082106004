import { Component, OnInit, Input,ViewChild,ElementRef } from '@angular/core';
import { Router, Params, ActivatedRoute,NavigationExtras} from '@angular/router';
import {IonSearchbar} from '@ionic/angular';
import {HelperService} from '../../services/helper';
import { environment } from '../../../environments/environment';
import {Item} from '../../models/item'

@Component({
  selector: 'community-locator',
  templateUrl: './community-locator.component.html',
  styleUrls: ['./community-locator.component.scss'],
})
export class CommunityLocatorComponent {
  
  @Input() locationmenu:any;

  constructor() { 
     
  }


 

}
