import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import {Config} from '../../app/config';


@Pipe({ name: 'myCurrency' })
export class MyCurrencyPipe implements PipeTransform {
    transform(num: any, currencyCode: string, showSymbol: boolean, digits: string): any {
        let value = new CurrencyPipe(navigator.language).transform(num, currencyCode, showSymbol, digits);
        let firstDigit = value.match(/\d/);
        let symbol = Config.chaCurrency;
        let amount = value.slice(firstDigit.index);   
        return symbol + amount;
    }
}