import { Component, OnInit } from '@angular/core';
import {VendorService} from '../services/vendor';
import { Router,ActivatedRoute } from '@angular/router';
import {  NavController,ModalController, AlertController } from '@ionic/angular';
import { Config } from '../config';
import { LoginService} from '../services/login';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.scss'],
})
export class VendorListComponent implements OnInit {
loyalty_list:any;
token:any;
user:any;
//baseurl:any=Config.server;
  constructor(
  	public router:Router,
    private activatedRoute: ActivatedRoute,
  	private vendorService: VendorService,
  	public modalCtrl: ModalController,
    private loginService: LoginService, 
    private storage: Storage,) { 
  	

  	this.getLoyalty();
  }

  subscribe(id){

  	console.log('check user', this.user);
  	let data = {
  		vendor_serviceid: id,
  		 userid: this.loginService.user._id
  	};

  	this.vendorService.registerVendor(data).then((results)=>{
  		console.log('subscribe', results);
  		this.modalCtrl.dismiss()

  	},(error)=>{
  		console.log('subscribe error', error);
  	});

  	console.log('id:', data);


  }

  baseurl(image){
    //console.log('urlimage', Config.server + image);
    return Config.server + image;

  }


  getLoyalty(){

     this.storage.get('token').then((token) => {
  	this.vendorService.getVendor(token.token,this.loginService.user._id).then((results)=>{
      //console.log('results::', results);
      this.loyalty_list = results['msg'];


    },(error)=>{

      console.log('error::', error);

    });
  });
  }



  ngOnInit() {}

  back(){
  	console.log('dismiss');
  	this.modalCtrl.dismiss();
  }


  


}
