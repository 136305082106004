import { Injectable,ViewChild} from '@angular/core';
import { Platform, NavController,LoadingController} from '@ionic/angular';
import {AudioService} from './audio';
import {SocketService} from './socket';
import {LoginService} from './login';
import {ChatService} from './chat';
import { Storage } from '@ionic/storage';
import {marked} from 'marked';
import { RestProvider } from './rest/rest';
import {Transaction} from '../models/transaction';
import {ChaEventsService} from '../services/chaevents';
import { environment } from '../../environments/environment';


@Injectable()
export class ChaEncryptionService {



	constructor(
	 public audio:AudioService,
	 public socket:SocketService,  
	 public storage:Storage,
	 private chatService:ChatService,
	 private loginService:LoginService,
	 public restProvider: RestProvider,
	 private events: ChaEventsService,
	 public loadingCtrl: LoadingController) {
	
		
	}

	public encrypt(plaincode) {
		let plaincodecount = plaincode.length;
	    let encryptedcode = plaincodecount;
	    for(let i = 0; i <plaincodecount; i++)
	    {
	      let scramble = this.randomString();
	      encryptedcode = encryptedcode + scramble + plaincode[i];
	    }

	    return encryptedcode;
	}

	public dencrypt(encryptedcode) {
		var codeCount = encryptedcode.substring(0,1);
		var trimmed = encryptedcode.substring(1,encryptedcode.length);
		var decryptedCode = '';
		for(var i=0; i<parseInt(codeCount); i++)
		{
			trimmed = trimmed.substring(5,trimmed.length);
			decryptedCode = decryptedCode + trimmed.substring(0,1);
			trimmed = trimmed.substring(1,trimmed.length);
		}
		return decryptedCode;
		
		
	}

	randomString() {
	    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz";
	    var string_length = 5;
	    var randomstring = '';
	    for (var i=0; i<string_length; i++) {
	      var rnum = Math.floor(Math.random() * chars.length);
	      randomstring += chars.substring(rnum,rnum+1);
	    }
	    return randomstring;
  }


  public encryptData(data){
		return new Promise((resolve,reject) => {

			this.restProvider.authSendToApi(data,'api/encrypt').then((result)=>{
				console.log(result);
				resolve(result);
				},(err) => {
					console.log('encyptr error:',err);
					reject(err);
				}
			);

		});

	}



public decryptData(data){
		return new Promise((resolve,reject) => {

			this.restProvider.authSendToApi(data,'api/decrypt').then((result)=>{
				console.log(result);
				resolve(result);
				},(err) => {
					console.log(err);
					reject(err);
				}
			);

		});

	}





}
