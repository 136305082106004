import { Component, ViewChild, ElementRef,Input, OnInit } from '@angular/core';
import { ModalController, Platform, NavParams, LoadingController,  ActionSheetController,AlertController  } from '@ionic/angular';

import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@awesome-cordova-plugins/native-geocoder/ngx';
import {CommunityService} from '../../services/community';
import { LoginService} from '../../services/login';
import {ChaEventsService} from '../../services/chaevents';
import {User} from '../../models/user';
import { PhotoService } from '../../services/photo.service';
import { environment } from '../../../environments/environment';
import {HelperService} from '../../services/helper';

declare var google;


@Component({
  selector: 'app-register-community-courier',
  templateUrl: './register-community-courier.component.html',
  styleUrls: ['./register-community-courier.component.scss'],
})
export class RegisterCommunityCourierComponent implements OnInit {
  
  
  current_user:any = {};
  user_location:any;
  chalocation:any;
  photo:any;
  @Input() shop_id:any
  @Input() community_id:any;
  @Input() user_id:any;
  @Input() communityname:string;
  filtered_community:any = []

  constructor(
    
    public viewCtrl: ModalController,
    private helper: HelperService,
    public params: NavParams,
    public alertController: AlertController,
    private communityService:CommunityService,
    private loginService: LoginService,
    public events: ChaEventsService,
    public photoService: PhotoService,) {

    this.events.userLogin().subscribe(data => {
      console.log('see u user:',data);
      this.current_user = data.user as User;
    });
    this.loginService.checkIfloggedIn().then((user)=>{
      console.log('check user details in here', user);
       this.current_user = user as User;


    },(error)=>{
        console.log('check user failed in here', error);
    });

    
  }

  initSubscribe(){

 
     // search communitylist by ID
    this.events.dynamicDataRetrieve(environment.api_urls.get_community_residence_by_id).subscribe(data => {
      console.log('NEW COMMUNITY DATA 11&&&&&&&&&&&&&&&:', data);
      let chaindex = environment.api_urls.get_community_residence_by_id;
      let outputdata = this.helper.searchInObjectb(data, chaindex,'key');

      if(outputdata != null && outputdata.length != 0)
      {


        this.filtered_community = outputdata[0]['value']['msg'][0];
        console.log('NEW COMMUNITY DATA &&&&&&&&&&&&&&&:',this.filtered_community);
        this.user_location = this.filtered_community.members[0].residence_name[0].name;
        this.chalocation = this.filtered_community.members[0].location;

        
      }



    },(error)=>{
      console.log('ERRO SUBSCRIBED',error);
    });


  }


  ngOnInit() {
    this.initSubscribe();
    this.getCommunityResidence_by_id();
    
  }

  ngAfterViewInit(){
     

  }


  getCommunityResidence_by_id()
  {

    let data = {
      user_id:this.user_id,
      community_id:this.community_id
    };

    console.log('CHEKC COM DATA:',data);

    this.communityService.getCommunityResidence_by_id(data).then((results)=>{
      this.filtered_community = results;
      console.log('COMCOM',this.filtered_community)
      if(this.filtered_community != null && this.filtered_community.length != 0)
      {
        this.user_location = this.filtered_community[0].members[0].residence_name[0].name;

      }
      


    });

  }


 

  dismiss(transactionData) {
    this.viewCtrl.dismiss(transactionData);
  }


  

  async presentAlert(header,message) {
    const alert = await this.alertController.create({
      header: 'Notice',
      subHeader: header,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }


  register(){
    let data = {
      userid: this.user_id,
      communityid:this.community_id,
      location:this.chalocation

    };

    this.communityService.saveCourier(data).then((results)=>{
      console.log('saved',results);
      if(results != null){
        this.presentAlert('Notice','Courer Agent Successfully Registered');
        this.dismiss('successful');

      }


    })
    console.log('Register',data);


  }

 

}
