import { Component, OnInit } from '@angular/core';
import { NavController, NavParams, ModalController,AlertController } from '@ionic/angular';
import { ChatPage } from '../../chat/chat.page';
import { CallService} from '../../services/call';
import { ContactService} from '../../services/contact';
import { ChatService } from '../../services/chat';
import {HelperService} from '../../services/helper';
import {PushNotificationService} from '../../services/pushnotification';
import { LoginService } from '../../services/login';
import {ChaEventsService} from '../../services/chaevents';
import {User} from '../../models/user';
import { UserService} from '../../services/users';



@Component({
  selector: 'app-user-preview',
  templateUrl: './user-preview.component.html',
  styleUrls: ['./user-preview.component.scss'],
})
export class UserPreviewComponent implements OnInit {
	contact = null;
	user:any = {};
	current_user:any = {};
	type:any='';
	fromid:any;
	userstatus:any;

  constructor(
	private viewCtrl: ModalController,
	private chatService: ChatService, 
	private params: NavParams, 
	private navCtrl: NavController, 
	private callService: CallService, 
	private contactService: ContactService,
	private loginService: LoginService,
	private userService: UserService,
	private helper: HelperService,
	private pushnotification:PushNotificationService,
	public events: ChaEventsService, 
	public alertController: AlertController,

  	) { 

  	this.user = this.params.get('user');
  	this.type = this.params.get('type');
  	this.fromid = this.params.get('fromid');
  	this.checkIfContact();
		console.log('USER:::',this.user);


  	this.events.userLogin().subscribe(data => {
      console.log('see u user:',data);
      this.current_user = data.user;
    });
    this.loginService.checkIfloggedIn().then((user)=>{
      console.log('check user details in here', user);
       this.current_user = user as User;


    },(error)=>{
        console.log('check user failed in here', error);
    });


  }

  checkIfContact(){
  	this.contactService.checkIfContact(this.fromid,this.user._id).then((result)=>{
  		if(result == 'not a contact')
  		{
  			this.userstatus = 'notcontact';

  		}
  		else if(result == 'is a contact' && this.user._id != this.fromid)
  		{
  			this.userstatus = 'iscontact';

  		}

  	});
  	

  }

  chat() {
                // I add chatService and then send chat.id like => pages -> contacts -> contacts.ts
		this.chatService.getChatByContact(this.contact.id).then((chat:any) => {
			this.pushnotification.saveCurrentPage(chat.id,this.loginService.user._id).then((results)=>{
				this.navCtrl.navigateForward(['/chat',chat.id]);
			});

			//this.navCtrl.push(ChatPage, {chatId: chat.id}, {animate: true, direction: 'forward'});
		})
		this.cancel();
	}

	call() {
		this.callService.triggerCall(this.contact.id);
		this.cancel();
	}

	cancel() {
		this.viewCtrl.dismiss();
	}

	ngOnInit() {

	}

	contactsPic(data){
		return this.helper.baseUrl(data) + data;
	}


	addContact(){
		let data = {fromId:this.current_user._id,
								contactId:this.user._id}
		this.userService.addContact(data).then((result)=>{
			console.log('added contact',result)
			let newcontact = {
						id: this.user._id + '',
						name: this.user.name,
						username: this.user.username,
						image: this.user.image,
						online: false
					}
			this.contactService.contacts.push(newcontact)
			this.presentAlert('Status','successfully added contact');
			this.viewCtrl.dismiss('saved');

		});
	}

	async presentAlert(header,message) {
    const alert = await this.alertController.create({
      header: 'Notice',
      subHeader: header,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }


  chatb() {
                // I add chatService and then send chat.id like => pages -> contacts -> contacts.ts
    if(this.user._id == '' || this.user._id == null || this.user._id == undefined)
		{
			
		this.chatService.getChatByContact(this.user._id).then((chat:any) => {
			this.pushnotification.saveCurrentPage(chat.id,this.current_user._id).then((results)=>{
				
				this.navCtrl.navigateForward(['/chat2',chat.id]);
				this.viewCtrl.dismiss('tochat');
			});

			//this.navCtrl.push(ChatPage, {chatId: chat.id}, {animate: true, direction: 'forward'});
		});
		}
		else{
			this.chatService.getChatByContact(this.user._id).then((chat:any) => {
			this.pushnotification.saveCurrentPage(chat.id,this.current_user._id).then((results)=>{
				
				this.navCtrl.navigateForward(['/chat2',chat.id]);
				this.viewCtrl.dismiss('tochat');
			});

			//this.navCtrl.push(ChatPage, {chatId: chat.id}, {animate: true, direction: 'forward'});
		});

		}

		this.cancel();
	}




}
