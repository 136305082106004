// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `agm-map {
  height: 300px !important;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/common/map/map.component.scss"],"names":[],"mappings":"AAKA;EACQ,wBAAA;EACA,WAAA;AAJR","sourcesContent":["app-map{\n\n}\n\n\nagm-map{\n        height: 300px !important;\n        width: 100%;\n    }\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
