import { Component, OnInit } from '@angular/core';
import {ITimer} from "../../models/itimer"

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  NavController,ModalController,NavParams,AlertController } from '@ionic/angular';

import { MoneyService } from '../../services/money';

import { LoginService} from '../../services/login';
import { ChatService} from '../../services/chat';
import { ChaEncryptionService} from '../../services/chaencryption';
import { HelperService} from '../../services/helper';

import {Config} from '../../config';
import {User} from '../../models/user';
import { NgxQrcodeElementTypes } from '@techiediaries/ngx-qrcode';
import { NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';


//import 'babel-polyfill';
//import {brandedQRCode} from 'branded-qr-code';


@Component({
  selector: 'app-recieve-qrcode',
  templateUrl: './recieve-qrcode.component.html',
  styleUrls: ['./recieve-qrcode.component.scss'],
})
export class RecieveQrcodeComponent implements OnInit {

	ErrorMsg:any;
	data:any = {msg:null}
  	public user: User;
  	qrcode:any;
  	//elementType : 'url' | 'canvas' | 'img' = 'url';
    elementType = NgxQrcodeElementTypes.URL;
  	value : any = Config.server;
    errorCorrectionLevel = NgxQrcodeErrorCorrectionLevels.LOW;

  constructor(
  	public navCtrl: NavController, 
	public navParams: NavParams,
	public viewCtrl: ModalController,
	private loginService: LoginService,
  	public alertCtrl: AlertController,
  	public encryptionService: ChaEncryptionService,) {
  		this.user = new User();
    	this.user.image = '';
  	 }

  ngOnInit() {

  	console.log('ionViewDidLoad QRCODE');
    this.user = this.loginService.user as User;
    let data = {text:Config.server + '?userid='+this.user._id + '&method=recieve'};
    this.encryptionService.encryptData(data).then((results)=>{
      this.value = JSON.stringify(results['msg']);
      //console.log('check encrypt', this.value);

    });  
  }


  dismiss() {
	   this.viewCtrl.dismiss();
	}

	home() {
	//this.navCtrl.push('Home');
	}

  baseUrl(data){
    let ext = data.split('.');

    if(ext.length > 1)
    {
      return 'assets/img/avatar/';

    }
    else{
      return Config.server + 'uploads/images/';
    }

}

}
