import { Component, OnInit,  ViewChild, ElementRef  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, Platform, NavParams, LoadingController,NavController,  ActionSheetController,AlertController  } from '@ionic/angular';
import { Dialogs } from '@awesome-cordova-plugins/dialogs/ngx';
import { MoneyService } from '../../services/money';
import { RestProvider } from '../../services/rest/rest';
import {Transaction} from '../../models/transaction';
import { ChatPage } from '../../chat/chat.page';
import {Config} from '../../config';
import { Storage } from '@ionic/storage';
import { ContactService} from '../../services/contact';
import {SparePartsService} from '../../services/spareparts';
import {environment} from '../../../environments/environment';
import { PhotoService } from '../../services/photo.service';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import {SocketService} from '../../services/socket';
import {PaymentMethodsComponent} from '../payment-methods/payment-methods.component';
import {PayComponent} from '../pay/pay.component';
import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';
import {HelperService} from '../../services/helper';
import { 
  IonRadioGroup,
  //IonSlides
   } from '@ionic/angular';
import {Cart} from '../../models/cart';
import { CartModalComponent } from '../cart-modal/cart-modal.component';
import { BehaviorSubject } from 'rxjs';
import { CartService } from '../../services/cart.service';
import { ProductsService } from '../../services/products';

@Component({
  selector: 'app-addcart-modal',
  templateUrl: './addcart-modal.component.html',
  styleUrls: ['./addcart-modal.component.scss'],
})
export class AddCartModalComponent implements OnInit {
  @ViewChild('radioGroup') radioGroup: IonRadioGroup
  @ViewChild('radioGroupSize') radioGroupSize: IonRadioGroup
  @ViewChild('radioGroupColor') radioGroupColor: IonRadioGroup
  //@ViewChild('slider') slider: IonSlides
  @ViewChild('cart', {static: false, read: ElementRef})fab: ElementRef;

  shop:Cart[];
  selectedRadioGroup:any;
  selectedRadioItem:any;
  cha_selectb:any='';
  cha_selectbSize:any='';
  cha_selectbColor:any='';
	userAvatar:any;
  name:any;
  phone:any;
  defaultmethod:any;
  submitForm: FormGroup;
  submitFormShipper:FormGroup;
  submitAttempt: boolean = false;
  chatId:any;
  toId:any;
  fromId:any;
  colleague:any;
  loading:any;
  transactionResults:Transaction;
  transactionApi = 'api/' + Config.transactionApi;
  placeholder:any = './assets/img/sparelogo.webp';
  order_id:any;
  inquiry:any=[];
  order:any=[];
  tracking:any=[];
  slideOpts = {
    initialSlide: 1,
    speed: 400
  };

  isDealer:any=false;
  isShipper:any=false;
  chaphoto:any;
  photo:any;
  user:any;
  slidephoto:any=[];
  rate:any;
  commission:any;
  localCurrency:any= environment.chaCurrency;
  base_url:any=environment.server;
  source:any;
  qty:any=1;
  product:any;
  productsingle:any;
  chasize:any;
  chacolour:any;

//Configuration for each Slider
  slideOptsOne = {
    initialSlide: 0,
    slidesPerView: 1,
    autoplay: true,
    centeredSlides: true,
    grabCursor: true,
    allowSlideNext: true,
    allowSlidePrev: true,
    allowTouchMove: true,
    scrollbar: true

  };
  slideOptsTwo = {
    initialSlide: 0,
    slidesPerView: 2,
    loop: true,
    centeredSlides: true,
    spaceBetween: 25
  };


  sliderTwo:any =
    {
      isBeginningSlide: true,
      isEndSlide: false,
      default_tile: environment.server + 'uploads/images/tile1.png',
      slidesItems: [
        {
          name: 'YD6B211',
          yuan_price: '3000',
          url:environment.server + 'uploads/images/y1.png'
        },
        {
          name: 'YD6B313',
          yuan_price: '3000',
          unit:'sqm',
          url:environment.server + 'uploads/images/y2.png'
        },
        {
          name: 'YD6B215',
          yuan_price: '3000',
          unit:'sqm',
          url:environment.server + 'uploads/images/y3.png'
        },
        {
          name: 'YD6B213',
          yuan_price: '3000',
          unit:'sqm',
          url:environment.server + 'uploads/images/y4.png'
        }
      ]
    };

  tiles:any= this.sliderTwo.slidesItems;
  cart = [];
  productsb = [];
  cartItemCount: BehaviorSubject<number>;
  type:any;
  resultTrue:any=0;
  community_product:any;

  constructor(
  	public platform: Platform,
    public params: NavParams,
    public viewCtrl: ModalController,
    public formBuilder: FormBuilder,
    private sparepartsservice: SparePartsService,
    private money: MoneyService,
    public restProvider: RestProvider,
    public loadingCtrl: LoadingController,
    public storage: Storage,
    private dialogs: Dialogs,
    public contactService: ContactService,
    public photoService: PhotoService,
    private photoViewer: PhotoViewer,
    private socket:SocketService,
    public actionSheetController: ActionSheetController,
    private spinnerDialog: SpinnerDialog,
    private helper: HelperService,
    private alertController: AlertController,
    private cartService: CartService,  
    public navCtrl: NavController,
    public productsService:ProductsService
  	) { 
  	//form validator
  	   
     
	    //get data from chat page
	    this.user = this.params.get('user');
      this.source = this.params.get('source');
      this.productsingle = this.params.get('product');
      this.type = this.params.get('type');
      if(this.type == null || this.type == undefined){
        this.type = '';
      }


      if(this.type =='taobao')
      {
        
      }
      if(this.type == 'community'){
        this.community_product = this.productsingle[0];
        console.log('community DD:',this.community_product);


      }
      console.log('SOURCE::',this.source);
      console.log('product::',this.productsingle);
	    //this.userAvatar = this.colleague.imagePath;
	    //this.name = this.colleague.name;
	    //this.phone = this.phoneTransform(this.colleague.username,['12']);
	    //this.defaultmethod = 'Airtel Money Account Number' + this.colleague.username;
	    //this.toId = this.colleague.id;
	    //this.fromId = user._id;

	    // get chatId
	    //this.chatId = this.params.get('chatId');

	    
  } 

  addToCartb(product) {
    this.animateCSS('tada');
    console.log('Before CART::',product)
    this.cartService.addProductb(product);
    
  }

  getParameters(product){
    console.log('Attributes:',product);
    let color = [];
    this.chasize = this.helper.searchInObjectb(product, 'Size','PropertyName');
    color = this.helper.searchInObjectb(product, 'Colour','PropertyName');
    if(color.length == 0){
      color = this.helper.searchInObjectb(product, 'Color Classification','PropertyName');

    }
    if(color.length == 0){
      color = this.helper.searchInObjectb(product, 'Color','PropertyName');
      
    }

    this.chacolour = this.formatColour(color);
    console.log('chasize:',this.chasize);
    console.log('chacolour',this.chacolour);
  }

  formatColour(data){
    let newdata = [];
    for(let row of data)
    {
      //console.log('TYPE::',typeof Number(row.Vid));

      if(isNaN(row.Vid) == false)
      {

        newdata.push(row);
      }  
    

    }

    return newdata;
    
    
  }

  ngAfterViewInit(){


    if(this.type == 'community'){
      console.log('VALUE+++++++++++++',this.community_product._id);
      this.selectCard(this.community_product._id);
    }

    

  }

  ngOnInit() {
    if(this.type =='taobao')
      {
        this.productsService.taobaoItem({itemid:this.productsingle['Id']}).then((results)=>{
          this.product = results['Result']['Item'];
          this.resultTrue = 1;

          console.log('HERE',this.product);
          this.getParameters(this.product.Attributes);

        });
        
      }
    
    console.log('PRODUCT SINGLE', this.productsingle);
    this.productsb = this.cartService.getProducts();
    this.cart = this.cartService.getCart();
    this.cartItemCount = this.cartService.getCartItemCount();
    if(this.type !='community'){


      this.sparepartsservice.getForex(environment.currency).then((results)=>{
          console.log('VALUES FOREX',results);
          
            this.rate = results['rate'];
            this.commission = results['commission'];

          
        
        
      });
    }
    


    
    

    
  }



  
  
  slideChanged()
  {
     //this.slider.stopAutoplay(); //this code for slide after page change
   }

  getOrder(){
    this.sparepartsservice.getOrderbyId(this.order_id).then((results)=>{
      this.order = results[0]; 
      this.tracking = this.order.tracking;
      this.tracking.sort(this.helper.dynamicSort('-tracking_time'));
      //this.slidephoto = this.inquiry.dealer_partphoto;
      console.log('ORDERS:::',this.tracking);
      

    });
  }



  bullet(index){
    if(this.helper.isOdd(index) == 0){
      return 'bullet pink';
    }
    else{
      return 'bullet green';
    }
  }

  /*addPhotoToGallery() {
      this.photoService.addNewToGalleryB().then((results)=>{
        //console.log('WELL AFTER PHOTO:',results);
        let data ={id:this.inquiry_id,photo:results};
        this.sparepartsservice.updateInquiryDealerInfo(data).then((results)=>{
          console.log('photo saved',results);
        });
      });
    }*/


  validateIfDealer(data){
    let check = false; 
    if(data != null)
    {
      
      for (var i = 0; i < data.length; i++) {
        //console.log("category:::",data[i].category);
        if (data[i].category == environment.sparepart_dealer)
        {
          check = true;
          break;
        }
      }

      
    }
    return check;
  }


  getphoto(photo){

    if(photo != null || photo != '')
    {
      return environment.server + photo;
    }
    else
    {
      return this.placeholder;

    }


  }



async presentLoading() {
  const loading = await this.loadingCtrl.create({
      cssClass: 'cha-loading',
      message: 'Please wait...',
      duration: 2000
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed!');
}



  dismiss(transactionData) {
    this.viewCtrl.dismiss(transactionData);
  }
  records(){
  	console.log('transation history');
  }

  phoneTransform(value: string, args: string[]) : string {
    if(value)
    {
      let limit = args.length > 0 ? parseInt(args[0], 10) : 10;
      let trail = args.length > 1 ? args[1] : '...';


      return value.length > limit ? value.substring(0, limit) + trail : value;
    }
    else{
      return '';
    }
  }

  /*submit(){
    var formdata = this.submitForm.value;
    if(Number(this.user['shipper']) ===1 && this.source == 'spareshipper')
      {

         let data = {
                      id:this.inquiry_id,
                      aircargo:formdata.aircargo,
                      seacargo:formdata.seacargo
                    } 
          this.sparepartsservice.updateInquiryShipperInfo(data).then((results)=>{
              console.log('details updated',results);
              this.socket.emit('shippingstatus', {id:this.inquiry_id,status:1,user:results['fromId']} );
              this.viewCtrl.dismiss();
            });  

      }
      else{

        
        let data = {
                      id:this.inquiry_id,
                      fob:formdata.fob,
                      weight:formdata.weight,
                      volume:formdata.volume,
                      dhl:formdata.dhl
                    } 
        this.sparepartsservice.updateInquiryDealerInfo(data).then((results)=>{
              console.log('details updated',results);

              this.socket.emit('dealerstatus', {id:this.inquiry_id,status:1,user:results['fromId']} );

              this.viewCtrl.dismiss('successful');
            },(error)=>{
               console.log('details updated',error);
            });  
        } 




  }


  shipperSubmit(){
    var formdata = this.submitFormShipper.value;
    

         let data = {
                      id:this.inquiry_id,
                      aircargo:formdata.aircargo,
                      seacargo:formdata.seacargo
                    } 
          this.sparepartsservice.updateInquiryShipperInfo(data).then((results)=>{
              console.log('details updated',results);
              this.socket.emit('shippingstatus', {id:this.inquiry_id,status:1,user:results['fromId']} );
              this.viewCtrl.dismiss('success');
            });  

      




  }*/

  /*
  confirmTransfer(){
  	this.submitAttempt = true;
  	var formdata = this.submitForm.value;
  	let transaction = {amount:formdata.amount,
  						remarks: formdata.remarks,
  						chatId: this.chatId,
  						toId:this.toId,
  						fromId:this.fromId};
  	console.log('transactions::');
  	console.log(transaction);
  	
  	let transactionData = this.money.transferMoney(transaction);
  	this.updateTransactionData(transactionData);
  	//setTimeout(() => {
			//this.dismiss();
		//}, 100);
  	
  	
  	



  	 

  }*/
  /*checkstatus(status,check){
  	console.log(status + ' - ' + check);
  	if(status === check && check === 1)
  	{
  		if(this.loading)
  		{
  			this.loading.dismiss().catch()

  			this.dismiss();
  			this.chat(this.chatId);
  			

  		}
  		return true;
  	}
  	else if (status === check && check === 0)
  	{
  		return true;
  	}
  	else{
  		return false;
  	}

  }*/
  updateTransactionData(transactionData) {
  		

        this.storage.get('token').then((val) => {
          //console.log('hjlll',JSON.stringify(val))

  			this.restProvider.authSendToApi(transactionData,this.transactionApi +'?secret_token='+ val.token).then((result) => {
  			 	//console.log('check if data have been saved');
  			    console.log(result);
  			    this.transactionResults = result['msg'] as Transaction;
  			    
  			    let transId = this.transactionResults._id;

            console.log('check trans', this.transactionResults);
  			    

  			    let transactionData = {
  				url: '',
  				type: 'moneyTransfer',
  				name: this.transactionResults.amount,
  				id: this.transactionResults._id
  				};

          console.log('check trans2', transactionData);


  				///this.loading.dismiss();
  				this.dismiss(transactionData);
  				
  				//console.log(transactionData);
  				/*
  				console.debug('Sending message ', this.data.amount)
  				this.audio.play('message-sent');
  				this.chatService.send(transaction.chatId, this.data.amount, transactionData);

  				this.addMessage({
  					attachment: transactionData,
  					message: this.data.amount,
  					from: this.loginService.user.id
  				});

  				this.chatService.updateLastMessage(transaction.chatId, this.data.amount);
  				this.data.amount = null;
  				this.status = 1;
  				this.loading.dismiss();
  				//this.events.publish('chat-message');
  				//this.navCtrl.pop();
  				//this.nav.push(ChatPage, {chatId: transaction.chatId}, {animate: true, direction: 'forward'});
  				*/
  			  }, (err) => {


            if(this.IsJsonString(err['_body']))
            {
              let message = JSON.parse(err['_body']);

              console.log('err',message.msg);
              //this.loading.dismiss();
              this.dialogs.alert(message.msg)
              .then(() => console.log('Dialog dismissed'))
              .catch(e => console.log('Error displaying dialog', e));
    			    console.log(err);
            }
            else
            {
              console.log('Error', err);
            }
  			  });

      });

		

		
	}

  IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


baseUrl(data){
      if(data){


      let ext = data.split('.');

      if(ext.length > 1)
      {
        return 'assets/img/avatar/';

      }
      else{
        return Config.server + 'uploads/images/';
      }
    }
    

  }

  displayThumbnail(img){
    if(img)
    {
      if(img == environment.default_img)
      {
        return this.placeholder;
      }
      else
      {
        return this.baseUrl(img) + img;
      }
    }
    else
    {
      return this.placeholder;
    }
  }

  getProfilePic(user){
    
    
     let get_contact = this.contactService.get(user);
    // this.pic_check.push(get_contact.image);
           

      //console.log('these users',this.baseUrl(get_contact.image) + get_contact.image);

      if(this.baseUrl(get_contact.image)){

        return this.baseUrl(get_contact.image) + get_contact.image;
      }
      else{
          return this.placeholder;
        


      }



    
    

  }

  viewpic(photo){
    this.photoViewer.show(photo);

  }

  convert(figure){
    let localprice = Number(figure) * Number(this.rate);
    let commission  = localprice * this.commission;

    return  localprice + commission

  }
  mra(fob,shipping)
  {

    return (this.convert(fob) + this.convert(shipping)) * environment.mra


  }
  checkstate(state){
    let chaclass = '';
    //let chaline = '';
    switch (state) {
      case 1:
        // code...
        chaclass = 'active';
        break;
      case 2:
        if(Number(this.inquiry.inquiry_closed) === 1 || Number(this.inquiry.inquiry_closed) === 2 )
        {
          chaclass = 'active';

        }
        // code...
        break;
      case 3:
        if(Number(this.inquiry.inquiry_closed) === 2  )
        {
          chaclass = 'active';

        }

        // code...
        break;
      default:
        // code...
        break;
    }

    return chaclass;

  }

  chaNumber(data){
    return Number(data);
  }

  buy(){
    this.selectDelivery();
  }

  pay(shippingmode)
  {



    if(shippingmode == 'seacargo')
    {
      let amount =this.convert(this.inquiry.fob) + this.convert(this.inquiry.shipping.seacargo) + this.mra(this.inquiry.fob,this.inquiry.shipping.seacargo)
      let newdata = {
       user:this.user.username,
       amount: amount,
       alias:'Sea Cargo Shipping',
       shipping_type:'seacargo',
       type:'sells',
       product_type:'spare',
       inquiry_id:this.inquiry._id,
       mra: this.mra(this.inquiry.fob,this.inquiry.shipping.aircargo)
       }

       this.securityPin(newdata);


    }
    else if(shippingmode == 'aircargo')
    {
        let amount = this.convert(this.inquiry.fob) + this.convert(this.inquiry.shipping.aircargo) + this.mra(this.inquiry.fob,this.inquiry.shipping.aircargo)
        let newdata = {
         user:this.user.username,
         amount: amount,
         alias:'Air Cargo Shipping',
         shipping_type:'aircargo',
         type:'sells',
         product_type:'spare',
         inquiry_id:this.inquiry._id,
         mra: this.mra(this.inquiry.fob,this.inquiry.shipping.aircargo)
         }

         this.securityPin(newdata);


    }

  }

  async securityPin(SentData){
    let modal = await this.viewCtrl.create(
      {
        component:PayComponent,
        componentProps:{data:SentData},
        cssClass: 'my-custom-modal-css-full'
      });
       await modal.present();

    await modal.onDidDismiss().then((rawdata)=>{
      console.log('CHECK Rawdata::',rawdata);
      this.dismiss(rawdata);


      /*let data = rawdata['data'];
      if(data && data.state == 'success')
        { 
          console.log('success',data);
          console.log('Amount::',SentData);
          if(data.type == 'Visa Card')
          {
            let chaData = {
              type:'visacard'




            }
            this.pushPayment(chaData);
          }
          //this.router.navigate(['/dashboard'])
          //this.login();

        }
        else
        {
          console.log('failed',data);
        }*/
        console.log('failed AFTER',rawdata);
    });
        
        
     
      //return await modal.present();
  }

  async pushPayment(chaData){

    let modal = await this.viewCtrl.create(
      {
        component:PayComponent,
        componentProps:{data:chaData},
        cssClass: 'my-custom-modal-css-full'
      });
       await modal.present();

    await modal.onDidDismiss().then((data)=>{

    });


  }
 

  async selectDelivery() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Select Shipping Methods',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Sea Cargo',
        icon: 'boat',
        handler: () => {
          this.pay('seacargo')
        }
      }, {
        text: 'Air Cargo',
        icon: 'airplane',
        handler: () => {
          this.pay('aircargo')
        }
      }, {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  } 

  track(){
    if(this.source == 'sparedealer')
    {
      this.presentAlertRadio();

    }
    else if(this.source == 'spareshipper'){
      this.presentAlertShipperRadio();
    }
    else if(this.source =='spareclearingagent')
    {
      this.presentAlertAgentRadio();
    }
    
  }

  async presentAlertRadio() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'New Tracking Status',
      inputs: [
        {
          name: 'processing',
          type: 'radio',
          label: 'Processing Order',
          value: 'Processing Order',
          handler: () => {
            console.log('Radio 1 selected');
          },
          checked: true
        },
        {
          name: 'packing',
          type: 'radio',
          label: 'Packing Items',
          value: 'Packing Items',
          handler: () => {
            console.log('Radio 3 selected');
          }
        },
        {
          name: 'toshipper',
          type: 'radio',
          label: 'Sent to Shipper',
          value: 'Sent to Shipper',
          handler: () => {
            console.log('Radio 2 selected');
          }
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (data) => {
            console.log('Confirm Ok',data);
            this.saveTracking(data);
          }
        }
      ]
    });

    await alert.present();
  }



async presentAlertShipperRadio() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'New Tracking Status',
      inputs: [
        {
          name: 'localshipping',
          type: 'radio',
          label: 'Goods Recieved From Supplier',
          value: 'Goods Recieved From Supplier',
          handler: () => {
            console.log('Radio 1 selected');
          },
          checked: true
        },
        {
          name: 'Shipped',
          type: 'radio',
          label: 'Items Shipped',
          value: 'Items Shipped',
          handler: () => {
            console.log('Radio 3 selected');
          }
        },
        {
          name: 'arrival',
          type: 'radio',
          label: 'Items Arrived in Country',
          value: 'Items Arrived in Country',
          handler: () => {
            console.log('Radio 3 selected');
          }
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (data) => {
            console.log('Confirm Ok',data);
            this.saveTracking(data);
          }
        }
      ]
    });

    await alert.present();
  }

  async presentAlertAgentRadio() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'New Tracking Status',
      inputs: [
        {
          name: 'clearing',
          type: 'radio',
          label: 'Goods Clearing in Progress',
          value: 'Goods Clearing in Progress',
          handler: () => {
            console.log('Radio 1 selected');
          },
          checked: true
        },
        {
          name: 'localcourier',
          type: 'radio',
          label: 'Sent to Local Courier',
          value: 'Sent to Local Courier',
          handler: () => {
            console.log('Radio 3 selected');
          }
        },
        {
          name: 'recieved',
          type: 'radio',
          label: 'Goods Recieved by the Customer',
          value: 'Goods Recieved by the Customer',
          handler: () => {
            console.log('Radio 3 selected');
          }
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Ok',
          handler: (data) => {
            console.log('Confirm Ok',data);
            this.saveTracking(data);
          }
        }
      ]
    });

    await alert.present();
  }
  saveTracking(data){
    let chadata = {};
    if(this.source == 'sparedealer')
    {
       chadata = {
        orderid:this.order_id,
        status:data,
        name:this.order.dealerId[0].name,
        userimage:this.order.dealerId[0].image,
        role: 'Dealer'

        }

    }
    else if(this.source == 'spareshipper'){
      chadata = {
        orderid:this.order_id,
        status:data,
        name:this.order.shipperId[0].name,
        userimage:this.order.shipperId[0].image,
        role: 'Shipper'

        }

    }
    else if(this.source == 'spareclearingagent'){
      chadata = {
        orderid:this.order_id,
        status:data,
        name:this.order.clearingAgentId[0].name,
        userimage:this.order.clearingAgentId[0].image,
        role: 'Clearing Agent'

        }

    }
    this.sparepartsservice.updateOrderTracking(chadata).then((results)=>{
      console.log('Output:',results);
      this.getOrder();
       this.presentAlert('Tracking Status','Tracking Details Successfully Updated');

    });
  }

  async presentAlert(header,message) {
    const alert = await this.alertController.create({
      header: 'Notice',
      subHeader: header,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }


  selectCard(value){
    if(value != null && value != undefined){
      this.radioGroup.value = value;
      this.cha_selectb = value;

    }
    
  }

  selectCardSize(value){
    this.radioGroupSize.value = value;
    this.cha_selectbSize = value;
  }

  selectCardColor(value){
    this.radioGroupColor.value = value;
    this.cha_selectbColor = value;
  }

  cha_select(value){
    if(value == this.cha_selectb){
      return 'chaselected';
    }
  }
  cha_selectColor(value){
    if(value == this.cha_selectbColor){
      return 'chaselected';
    }
  }


  cha_selectSize(value){
    if(value == this.cha_selectbSize){
      return 'chaselected';
    }
  }

  getPrice(value){
     if(value != null && value != ''){
       //console.log('check tiles 1:',this.tiles);
       //console.log('check tiles 2:',value);
       let data = this.helper.searchInObject(this.productsingle.slides_items,value,'sub_product_price');
       //console.log('check tiles:',data);
      // console.log('check data length:',data.length);
       if(data.length != 0){

         return this.price(data.sub_product_price);

       }
       else
       {
         return 0
       }
       

     }
     else{
       return 0
     }
     

    

  }
  
  incrementQty() {
    this.qty += 1;
  }

  decrementQty() {
    if(this.qty == 0){
      this.qty = 0;
    }
    else{
      this.qty -= 1;

    }

    


  }

  getAttributeById(id,data){
    let attribute = {};
    for(let row of data){
      if(row.Vid == id){
        attribute = row;
        break;
      }
    }

    return attribute;

  }

  addToCart(type){
    console.log('ADD TO CART:',this.cha_selectb);
    console.log('TYPE:',type);

    if(type =='taobao')
    {
      console.log('Color::',this.cha_selectbColor);
      if((this.cha_selectb != '' && this.cha_selectbSize != '' && this.cha_selectbColor != '') 
        || (this.cha_selectb != '' && this.cha_selectbSize != '' && this.cha_selectbColor == '')
        || (this.cha_selectb != '' && this.cha_selectbSize == '' && this.cha_selectbColor != '')
        || (this.cha_selectb != '' && this.cha_selectbSize == '' && this.cha_selectbColor == '') ){
      let data_product =  this.productsingle;
      let data_size =  this.getAttributeById(this.cha_selectbSize,this.chasize);
      let data_color =  this.getAttributeById(this.cha_selectbColor,this.chacolour);

      console.log('product',data_product);
      console.log('size',data_size);
      console.log('color',data_color);

      
      

      
        let product = { 
          id: this.productsingle.Id, 
          name: this.product.OriginalTitle,
          price: this.price(this.product.Price.OriginalPrice),
          amount: this.qty, 
          taobao_img: this.product.MainPictureUrl,
          taobao_url:this.product.TaobaoItemUrl ,
          type:'taobao',
          taobao_size:data_size,
          taobao_color:data_color
        };

          this.addToCartb(product);
          this.presentAlert('Notification','Successfully Added to Cart').then((chaclick)=>{
            this.dismiss('');
          });
          
        }
        else {
          this.presentAlert('Notification','One or More Option is not Selected');
        }
        


    }
    else if(type == 'community'){

      let data =  this.community_product;
      if(data){
        let product = { 
          id: data._id, 
          name: data.product_name,
          subname:data.product_name ,
          subproduct:data,
          unit: 'Items', 
          product_type:data.product_type,
          price: data.product_price, 
          amount: this.qty,
          type:'community',
          communityid: data.community_id[0]._id };

          console.log('data:',product);

        this.addToCartb(product);
      }



    }
    else{

      let data =  this.getProductById(this.cha_selectb);
      if(data){
      let product = { 
        id: data._id, 
        name: this.productsingle.product_name,
        subname: data.sub_product_name,
        subproduct:data,
        unit: data.sub_product_unit, 
        product_type:this.productsingle.product_type,
        price: this.price(data.sub_product_price), 
        amount: this.qty,
        type:'other'  };

        console.log('data:',product);

      this.addToCartb(product);
    }


  }


  }

  getProductById(id){
    console.log('PRODUCT::',this.product);
    if(this.product.length != 0){

      for(let im of this.product.slides_items ){
        if(im._id == id)
        {
          return im;
          break;
        }
      }
    }
    else{
      return [];
    }
  }

  price(figure){
  let localprice = Number(figure) * Number(this.rate);
    let commission  = localprice * this.commission;

    return  localprice + commission

}

getUnit(unit){
  switch (unit)
  {
    case 'sqm':
      return "Square Meters";
      break;
    case 'qty':
      return "Items";
      break;
    default:
      return "Items"

  }
}


 async openCart() {
    this.animateCSS('bounceOutLeft', true);
 
    let modal = await this.viewCtrl.create({
      component: CartModalComponent,
      cssClass: 'cart-modal'
    });
    modal.onDidDismiss().then((data) => {
      this.fab.nativeElement.classList.remove('animated', 'bounceOutLeft')
      this.animateCSS('bounceInLeft');
      console.log('OUTPUT DATA::',data);
      if(data['data'] == 'address')
      {
        this.viewCtrl.dismiss('address');
      }
      else if(data['data'] == 'orders')
      {

          this.viewCtrl.dismiss('orders');
      }


    });
    modal.present();
  }
 
  animateCSS(animationName, keepAnimated = false) {
    const node = this.fab.nativeElement;
    node.classList.add('animated', animationName)
    
    //https://github.com/daneden/animate.css
    function handleAnimationEnd() {
      if (!keepAnimated) {
        node.classList.remove('animated', animationName);
      }
      node.removeEventListener('animationend', handleAnimationEnd)
    }
    node.addEventListener('animationend', handleAnimationEnd)
  }

  imagePath(path){
    console.log('SEE PATH::+++++++',path);
    return path;
  }


}
