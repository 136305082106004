import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, Platform, NavParams, LoadingController,AlertController  } from '@ionic/angular';
import { Dialogs } from '@awesome-cordova-plugins/dialogs/ngx';
import { Dialog } from '@capacitor/dialog';
import { MoneyService } from '../../services/money';
import { LoginService} from '../../services/login';
import { RestProvider } from '../../services/rest/rest';
import {Transaction} from '../../models/transaction';
import { ChatPage } from '../../chat/chat.page';
import {Config} from '../../config';
import { environment } from '../../../environments/environment';
import { Storage } from '@ionic/storage';
import { ContactService} from '../../services/contact';
import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';
import { ChaEncryptionService} from '../../services/chaencryption';

@Component({
  selector: 'app-transfer-modal',
  templateUrl: './transfer-modal.component.html',
  styleUrls: ['./transfer-modal.component.scss'],
})
export class TransferModalComponent implements OnInit {
	userAvatar:any;
  name:any;
  phone:any;
  defaultmethod:any;
  submitForm: FormGroup;
  submitAttempt: boolean = false;
  chatId:any;
  toId:any;
  fromId:any;
  colleague:any;
  loading:any;
  transactionResults:Transaction;
  transactionApi = 'api/' + environment.transactionApi;
  placeholder:any = 'assets/img/avatar/1-81-15.svg';
  ErrorMsg:any;
  data:any = {msg:null};
 
  constructor(
  	public platform: Platform,
    public params: NavParams,
    public viewCtrl: ModalController,
    public formBuilder: FormBuilder,
    private money: MoneyService,
    public restProvider: RestProvider,
    public loadingCtrl: LoadingController,
    public storage: Storage,
    private dialogs: Dialogs,
    public contactService: ContactService,
    private loginService: LoginService,
    public alertController: AlertController,
    public encryptionService: ChaEncryptionService,
    private spinnerDialog: SpinnerDialog

  	) { 
  	//form validator
  	    this.submitForm = formBuilder.group({
        amount: ['', Validators.compose([ Validators.required])],
        remarks: ['', Validators.compose([Validators.maxLength(20), Validators.required])]
        
    	});
    
	    //get data from chat page
	    let user = this.params.get('user');
	    this.colleague = this.params.get('colleague');
	    this.userAvatar = this.colleague.imagePath;
	    this.name = this.colleague.name;
	    this.phone = this.phoneTransform(this.colleague.username,['12']);
	    this.defaultmethod = 'Airtel Money Account Number' + this.colleague.username;
	    this.toId = this.colleague.id;
	    this.fromId = user._id;

	    // get chatId
	    this.chatId = this.params.get('chatId');

      
    
	    
  }

  ngOnInit() {}


async chaAlert(data){
  let showAlert = async () => {
  await Dialog.alert({
    title: 'Information',
    message: data,
  });
};

}
async presentLoading() {
  const loading = await this.loadingCtrl.create({
      cssClass: 'cha-loading',
      message: 'Please wait...',
      duration: 2000
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed!');
}



  dismiss(transactionData) {
    this.viewCtrl.dismiss(transactionData);
  }
  records(){
  	console.log('transation history');
  }

  phoneTransform(value: string, args: string[]) : string {
    if(value)
    {
      let limit = args.length > 0 ? parseInt(args[0], 10) : 10;
      let trail = args.length > 1 ? args[1] : '...';


      return value.length > limit ? value.substring(0, limit) + trail : value;
    }
    else{
      return '';
    }
  }



  confirmTransfer(){
  	this.submitAttempt = true;
  	var formdata = this.submitForm.value;
  	let transaction = {amount:formdata.amount,
  						remarks: formdata.remarks,
              transaction_type:'chatsend',
  						chatId: this.chatId,
  						to:this.toId,
  						fromId:this.fromId};
  	console.log('transactionsYY::');
  	console.log(transaction);
  	this.pin(transaction);
  	//let transactionData = this.money.transferMoney(transaction);
  	//this.updateTransactionData(transactionData);
  	//setTimeout(() => {
			//this.dismiss();
		//}, 100);
  	
  	
  	



  	 

  }
  /*checkstatus(status,check){
  	console.log(status + ' - ' + check);
  	if(status === check && check === 1)
  	{
  		if(this.loading)
  		{
  			this.loading.dismiss().catch()

  			this.dismiss();
  			this.chat(this.chatId);
  			

  		}
  		return true;
  	}
  	else if (status === check && check === 0)
  	{
  		return true;
  	}
  	else{
  		return false;
  	}

  }*/


  async pin(action){
      let prompt = await this.alertController.create({
        header: 'Ufulupay PinCode',
        message: "Enter your Ufulupay secure Pin",
        inputs: [
          {
            name: 'ufulupayPin',
            placeholder: 'Enter Pin Here',
            type: 'password'
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            handler: data => {
              console.log('Cancel clicked');
            }
          },
          { 
            text: 'Submit',
            handler: data => {
              console.log(this.loginService.user.username);
              let encryptedPin = this.encryptionService.encrypt(data.ufulupayPin);
              let sentData = {pin:encryptedPin,username:this.loginService.user.username};
              console.log('verifyPIN::',sentData);
              this.spinnerDialog.show();
              this.loginService.verifyPin(sentData).then((data) => {
                this.data = data;
                console.log(this.data);
                if(this.data.msg == 'Exists')
                { 




                  

                  //this.topupb(action);
                  this.money.Pay(action).then((result)=>{
                    console.log('paymentdone');

                    if(result['msg'] == 'insufficient money')
                    {

                        this.dismiss('insufficient money');

                    }
                    else
                    {

                      this.transactionResults = result['msg'] as Transaction;
              
                        let transId = this.transactionResults._id;

                        console.log('check trans', this.transactionResults);


                        

                        let transactionData = {
                      url: '',
                      type: 'moneyTransfer',
                      name: this.transactionResults.amount,
                      id: this.transactionResults._id
                      };

                      console.log('check trans2', transactionData);


                      ///this.loading.dismiss();
                      this.dismiss(transactionData);

                    }




                    });



                  //this.PaymentIntegration(chadata);
                  //this.dpoToken(chadata,moneyData);
                  /*
                   console.log('it exits'); 
                   this.moneyService.topUpMoney(moneyData,chadata).then((data) =>{
                     console.log('successfully topped up',data);
                       this.dismiss('success');

                   }, (error)=>{
                     this.dismiss('fail');
                   });*/


                }
                else{
                  console.log('ERROR:',data);
                  if(this.data.msg == 'Not Exist')
                  {
                    this.ErrorMsg = 'User Does Not Exist';
                  }
                  else{
                    this.ErrorMsg = this.data.msg;
                  }
                  
                }
              },(error)=>{
                this.ErrorMsg = JSON.stringify(error);

              });

                

              
              console.log(encryptedPin);
            }
          }
        ]
      });
      

      console.log('WE ARE HERE');
      //this.alertCtrl.
      await prompt.present();
    }

  updateTransactionData(transactionData) {
  		

        this.storage.get('token').then((val) => {
          //console.log('hjlll',JSON.stringify(val))

  			this.restProvider.authSendToApi(transactionData,this.transactionApi +'?secret_token='+ val.token).then((result) => {
  			 	//console.log('check if data have been saved');
  			    console.log(result);
  			    this.transactionResults = result['msg'] as Transaction;
  			    
  			    let transId = this.transactionResults._id;

            console.log('check trans', this.transactionResults);
  			    

  			    let transactionData = {
  				url: '',
  				type: 'moneyTransfer',
  				name: this.transactionResults.amount,
  				id: this.transactionResults._id
  				};

          console.log('check trans2', transactionData);


  				///this.loading.dismiss();
  				this.dismiss(transactionData);
  				
  				//console.log(transactionData);
  				/*
  				console.debug('Sending message ', this.data.amount)
  				this.audio.play('message-sent');
  				this.chatService.send(transaction.chatId, this.data.amount, transactionData);

  				this.addMessage({
  					attachment: transactionData,
  					message: this.data.amount,
  					from: this.loginService.user.id
  				});

  				this.chatService.updateLastMessage(transaction.chatId, this.data.amount);
  				this.data.amount = null;
  				this.status = 1;
  				this.loading.dismiss();
  				//this.events.publish('chat-message');
  				//this.navCtrl.pop();
  				//this.nav.push(ChatPage, {chatId: transaction.chatId}, {animate: true, direction: 'forward'});
  				*/
  			  }, (err) => {


            if(this.IsJsonString(err['_body']))
            {
              let message = JSON.parse(err['_body']);

              console.log('err',message.msg);
              //this.loading.dismiss();
              /*this.dialogs.alert(message.msg)

              .then(() => console.log('Dialog dismissed'))
              .catch(e => console.log('Error displaying dialog', e));
    			    console.log(err);*/
              this.chaAlert(message.msg);
            }
            else
            {
              /*this.dialogs.alert(err.msg)
              .then(() => console.log('Dialog dismissed'))
              .catch(e => console.log('Error displaying dialog', e));*/
              this.chaAlert(err.msg);
            }
  			  });

      });

		

		
	}

  IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


baseUrl(data){
      if(data){


      let ext = data.split('.');

      if(ext.length > 1)
      {
        return 'assets/img/avatar/';

      }
      else{
        return Config.server + 'uploads/images/';
      }
    }
    

  }

  getProfilePic(user){
    
    
     let get_contact = this.contactService.get(user);
    // this.pic_check.push(get_contact.image);
           

      //console.log('these users',this.baseUrl(get_contact.image) + get_contact.image);

      if(this.baseUrl(get_contact.image)){

        return this.baseUrl(get_contact.image) + get_contact.image;
      }
      else{
          return this.placeholder;
        


      }



    
    

  }

}
