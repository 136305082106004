// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-contact .contact-card img {
  width: 180px;
  height: 180px;
  border-radius: 100%;
  overflow: hidden;
}
app-contact img {
  width: 20%;
}
app-contact button.action {
  height: 50px;
  width: 50px;
  margin: 10px;
}
app-contact button.close {
  float: right;
  position: absolute;
  margin-top: -20px;
  margin-left: 28vw;
}
app-contact h2 {
  margin-bottom: 30px;
}

.mask1 {
  mask-image: linear-gradient(to top, transparent 25%, black 75%);
}

ion-card-header.ios.hydrated {
  margin-top: -30%;
}`, "",{"version":3,"sources":["webpack://./src/app/common/contact/contact.component.scss"],"names":[],"mappings":"AAEC;EACC,YAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AADF;AAIC;EACC,UAAA;AAFF;AAKC;EACC,YAAA;EACA,WAAA;EACA,YAAA;AAHF;AAMC;EACC,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;AAJF;AAOC;EACC,mBAAA;AALF;;AASA;EAGE,+DAAA;AAPF;;AAUA;EACI,gBAAA;AAPJ","sourcesContent":["app-contact {\n\n\t.contact-card img {\n\t\twidth: 180px;\n\t\theight: 180px;\n\t\tborder-radius: 100%;\n\t\toverflow: hidden;\n\t}\n\n\timg{\n\t\twidth:20%;\n\t}\n\n\tbutton.action {\n\t\theight: 50px;\n\t\twidth: 50px;\n\t\tmargin: 10px;\n\t}\n\n\tbutton.close {\n\t\tfloat: right;\n\t\tposition: absolute;\n\t\tmargin-top: -20px;\n\t\tmargin-left: 28vw;\n\t}\n\n\th2 {\n\t\tmargin-bottom: 30px;\n\t}\n}\n\n.mask1 {\n\t\n  -webkit-mask-image: linear-gradient(to top, transparent 25%, black 75%);\n  mask-image: linear-gradient(to top, transparent 25%, black 75%);\n}\n\nion-card-header.ios.hydrated {\n    margin-top: -30%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
