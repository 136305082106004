import { Component, ViewChild, ElementRef } from '@angular/core';
import { ModalController, Platform, NavParams, LoadingController,  ActionSheetController,AlertController  } from '@ionic/angular';

import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@awesome-cordova-plugins/native-geocoder/ngx';
import {CommunityService} from '../../services/community';
import { LoginService} from '../../services/login';
import {ChaEventsService} from '../../services/chaevents';
import {User} from '../../models/user';
declare var google;


@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent {
  
  @ViewChild('mapcanvas', { static: false }) mapElement: ElementRef;
  @ViewChild('info', { static: false }) info: ElementRef;
  map: any;
  address: string;

  latitude: number;
  longitude: number;
  shapeCoordinates:any = [];
  draggablePolygon:any;
  communityFence:any=[];
  communityName:any;
  residenceName:any;
  current_user:any = {};
  user_location:any

  constructor(
    private geolocation: Geolocation,
    public viewCtrl: ModalController,
    private nativeGeocoder: NativeGeocoder,
    public params: NavParams,
    public alertController: AlertController,
    private communityService:CommunityService,
    private loginService: LoginService,
    public events: ChaEventsService) {

    this.events.userLogin().subscribe(data => {
      console.log('see u user:',data);
      this.current_user = data.user as User;
    });
    this.loginService.checkIfloggedIn().then((user)=>{
      console.log('check user details in here', user);
       this.current_user = user as User;


    },(error)=>{
        console.log('check user failed in here', error);
    });

    this.latitude = this.params.get('latitude');
    this.longitude = this.params.get('longitude');
    this.user_location = this.latitude +','+this.longitude;
    
  }


  ngOnInit() {
    //this.loadMap();

   this.InitMap();
    //this.Getpolygoncoordinates();
  }

  ngAfterViewInit(){
     

  }


  InitMap() {
            let location = new google.maps.LatLng(this.latitude, this.longitude);
            let mapOptions = {
                zoom: 15.5,
                center: location,
                mapTypeId: google.maps.MapTypeId.RoadMap
            };


            let map = new google.maps.Map(document.getElementById('map-canvas'),
                mapOptions);

            let marker = new google.maps.Marker({
                  position: location,
                  map: map,
                  title: 'My residence'
            });



            

            



            let latLng = new google.maps.LatLng(this.latitude, this.longitude);
            let chamapOptions = {
              center: latLng,
              zoom: 15,
              mapTypeId: google.maps.MapTypeId.ROADMAP
            }
            //let mapb = new google.maps.Map(document.getElementById('map-canvas'),
              //  chamapOptions);

        }

 Getpolygoncoordinates(draggablePolygon) {
             console.log('see',draggablePolygon);
             console.log('see2',this.latitude);
             this.draggablePolygon = draggablePolygon;

            var len = this.draggablePolygon.getPath().getLength();
            var strArray = [];
            for (var i = 0; i < len; i++) {
                strArray.push([
                  Number(this.splitLongLat(this.draggablePolygon.getPath().getAt(i).toUrlValue(5),1)),
                  Number(this.splitLongLat(this.draggablePolygon.getPath().getAt(i).toUrlValue(5),0))
                ] );
            }
            this.communityFence = strArray;
            //document.getElementById('info').innerHTML = strArray;
        }

    splitLongLat(data,index){
      let cha_array = data.split(',');
      return cha_array[index]; 
    }

  loadMap() {
    this.geolocation.getCurrentPosition().then((resp) => {

      this.latitude = resp.coords.latitude;
      this.longitude = resp.coords.longitude;
      //this.InitMap();
      //this.Getpolygoncoordinates();

     /* let latLng = new google.maps.LatLng(resp.coords.latitude, resp.coords.longitude);
      let mapOptions = {
        center: latLng,
        zoom: 15,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      }

      this.getAddressFromCoords(resp.coords.latitude, resp.coords.longitude);

      this.map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);

      this.map.addListener('dragend', () => {

        this.latitude = this.map.center.lat();
        this.longitude = this.map.center.lng();

        this.getAddressFromCoords(this.map.center.lat(), this.map.center.lng())
      });*/

    }).catch((error) => {
      console.log('Error getting location', error);
    });
  }

  getAddressFromCoords(lattitude, longitude) {
    console.log("getAddressFromCoords " + lattitude + " " + longitude);
    let options: NativeGeocoderOptions = {
      useLocale: true,
      maxResults: 5
    };

    this.nativeGeocoder.reverseGeocode(lattitude, longitude, options)
      .then((result: NativeGeocoderResult[]) => {
        this.address = "";
        let responseAddress = [];
        for (let [key, value] of Object.entries(result[0])) {
          if (value.length > 0)
            responseAddress.push(value);

        }
        responseAddress.reverse();
        for (let value of responseAddress) {
          this.address += value + ", ";
        }
        this.address = this.address.slice(0, -2);
      })
      .catch((error: any) => {
        this.address = "Address Not Available!";
      });

  }

  dismiss(transactionData) {
    this.viewCtrl.dismiss(transactionData);
  }


  saveCommunity(communityFence){
    if(communityFence.length == 0){
      this.presentAlert('Error','Please set your Community Boundaries on the Map');

    }
    else if(this.communityName ==null || this.communityName ==''){
      this.presentAlert('Error','Please Input Community Name');

    }
    else if(this.residenceName ==null || this.residenceName ==''){
      this.presentAlert('Error','Please Input Your Residence Name');

    }
    else
    {

        console.log('communityFence:',communityFence);
        let data = {
          name: this.communityName,
          userid:this.current_user._id,
          role:'admin',
          location:this.user_location,
          residence_name:this.residenceName,
          community_fence:communityFence
            
        };
        this.communityService.saveCommunity(data).then((results)=>{
          console.log('Saved Community',results);
          this.dismiss('community saved');


        });
    }

  }

  async presentAlert(header,message) {
    const alert = await this.alertController.create({
      header: 'Notice',
      subHeader: header,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  getMyLocation(){
    this.dismiss({lat:this.latitude,lng:this.longitude});

  }

}
