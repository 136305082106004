import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, Platform, NavParams, LoadingController,  ActionSheetController  } from '@ionic/angular';
import { Dialogs } from '@awesome-cordova-plugins/dialogs/ngx';
import { MoneyService } from '../../services/money';
import { RestProvider } from '../../services/rest/rest';
import {Transaction} from '../../models/transaction';
import { ChatPage } from '../../chat/chat.page';
import {Config} from '../../config';
import { Storage } from '@ionic/storage';
import { ContactService} from '../../services/contact';
import {SparePartsService} from '../../services/spareparts';
import {environment} from '../../../environments/environment';
import { PhotoService } from '../../services/photo.service';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import {SocketService} from '../../services/socket';
import {PaymentMethodsComponent} from '../payment-methods/payment-methods.component';
import {PayComponent} from '../pay/pay.component';
import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';
@Component({
  selector: 'app-inquiry-modal',
  templateUrl: './inquiry-modal.component.html',
  styleUrls: ['./inquiry-modal.component.scss'],
})
export class InquiryModalComponent implements OnInit {
	userAvatar:any;
  name:any;
  phone:any;
  defaultmethod:any;
  submitForm: FormGroup;
  submitFormShipper:FormGroup;
  submitAttempt: boolean = false;
  chatId:any;
  toId:any;
  fromId:any;
  colleague:any;
  loading:any;
  transactionResults:Transaction;
  transactionApi = 'api/' + Config.transactionApi;
  placeholder:any = './assets/img/sparelogo.webp';
  inquiry_id:any;
  inquiry:any=[];
  slideOpts = {
    initialSlide: 1,
    speed: 400
  };
  isDealer:any=false;
  isShipper:any=false;
  chaphoto:any;
  photo:any;
  user:any;
  slidephoto:any=[];
  rate:any;
  commission:any;
  localCurrency:any= environment.chaCurrency;
  source:any;

  constructor(
  	public platform: Platform,
    public params: NavParams,
    public viewCtrl: ModalController,
    public formBuilder: FormBuilder,
    private sparepartsservice: SparePartsService,
    private money: MoneyService,
    public restProvider: RestProvider,
    public loadingCtrl: LoadingController,
    public storage: Storage,
    private dialogs: Dialogs,
    public contactService: ContactService,
    public photoService: PhotoService,
    private photoViewer: PhotoViewer,
    private socket:SocketService,
    public actionSheetController: ActionSheetController,
    private spinnerDialog: SpinnerDialog
  	) { 
  	//form validator
  	   
    
	    //get data from chat page
	    this.user = this.params.get('user');
      console.log('USER check:', this.user);
      if(Number(this.user['shipper']) ===1 &&  this.validateIfDealer(this.user['dealer']) == true)
      {
        this.submitFormShipper = formBuilder.group({
            aircargo: ['', Validators.compose([ Validators.required])],
            seacargo: ['', Validators.compose([ Validators.required])]
            
            
          });

        this.submitForm = formBuilder.group({
            fob: ['', Validators.compose([ Validators.required])],
            weight: ['', Validators.compose([Validators.maxLength(20), Validators.required])],
            volume: ['', Validators.compose([Validators.maxLength(20)])],
            dhl: ['', Validators.compose([Validators.maxLength(20)])]

            

          
          });


         
      }
      else  if(Number(this.user['shipper']) ===1 )
      {
        this.submitFormShipper = formBuilder.group({
            aircargo: ['', Validators.compose([ Validators.required])],
            seacargo: ['', Validators.compose([ Validators.required])]
            
            
          });
        

         
      }
      else{

        this.submitForm = formBuilder.group({
            fob: ['', Validators.compose([ Validators.required])],
            weight: ['', Validators.compose([Validators.maxLength(20), Validators.required])],
            volume: ['', Validators.compose([Validators.maxLength(20)])]
            

          
          });

         
      }
      if(this.user != null && this.validateIfDealer(this.user['dealer']) == true)
      {
        this.isDealer = true;
        console.log('Is Dealer', this.isDealer);


      }

      if(this.user != null && Number(this.user.shipper) == 1)
      {
        this.isShipper = true;


      }

	    this.inquiry_id = this.params.get('inquiry_id');
      this.source = this.params.get('source');
      console.log('SOURCE::',this.source);
	    //this.userAvatar = this.colleague.imagePath;
	    //this.name = this.colleague.name;
	    //this.phone = this.phoneTransform(this.colleague.username,['12']);
	    //this.defaultmethod = 'Airtel Money Account Number' + this.colleague.username;
	    //this.toId = this.colleague.id;
	    //this.fromId = user._id;

	    // get chatId
	    //this.chatId = this.params.get('chatId');
	    
  }

  ngOnInit() {
    this.sparepartsservice.getInquiriesbyId(this.inquiry_id).then((results)=>{
      this.inquiry = results[0]; 
      this.slidephoto = this.inquiry.dealer_partphoto;
      console.log('INQUIREY:::',this.inquiry);
      this.sparepartsservice.getForex(environment.currency).then((results)=>{
        console.log('VALUES FOREX',results);
        
          this.rate = results['rate'];
          this.commission = results['commission'];

        
      
      
    });

    });

    
  }

  addPhotoToGallery() {
      this.photoService.addNewToGalleryB().then((results)=>{
        //console.log('WELL AFTER PHOTO:',results);
        let data ={id:this.inquiry_id,photo:results};
        this.sparepartsservice.updateInquiryDealerInfo(data).then((results)=>{
          console.log('photo saved',results);
        });
      });
    }


  validateIfDealer(data){
    let check = false; 
    if(data != null)
    {
      
      for (var i = 0; i < data.length; i++) {
        //console.log("category:::",data[i].category);
        if (data[i].category == environment.sparepart_dealer)
        {
          check = true;
          break;
        }
      }

      
    }
    return check;
  }


  getphoto(photo){

    if(photo != null || photo != '')
    {
      return environment.server + photo;
    }
    else
    {
      return this.placeholder;

    }


  }



async presentLoading() {
  const loading = await this.loadingCtrl.create({
      cssClass: 'cha-loading',
      message: 'Please wait...',
      duration: 2000
    });
    await loading.present();

    const { role, data } = await loading.onDidDismiss();
    console.log('Loading dismissed!');
}



  dismiss(transactionData) {
    this.viewCtrl.dismiss(transactionData);
  }
  records(){
  	console.log('transation history');
  }

  phoneTransform(value: string, args: string[]) : string {
    if(value)
    {
      let limit = args.length > 0 ? parseInt(args[0], 10) : 10;
      let trail = args.length > 1 ? args[1] : '...';


      return value.length > limit ? value.substring(0, limit) + trail : value;
    }
    else{
      return '';
    }
  }

  submit(){
    var formdata = this.submitForm.value;
    if(Number(this.user['shipper']) ===1 && this.source == 'spareshipper')
      {

         let data = {
                      id:this.inquiry_id,
                      aircargo:formdata.aircargo,
                      seacargo:formdata.seacargo
                    } 
          this.sparepartsservice.updateInquiryShipperInfo(data).then((results)=>{
              console.log('details updated',results);
              this.socket.emit('shippingstatus', {id:this.inquiry_id,status:1,user:results['fromId']} );
              this.photoService.photosB = [];
              this.viewCtrl.dismiss();
            });  

      }
      else{

        
        let data = {
                      id:this.inquiry_id,
                      fob:formdata.fob,
                      weight:formdata.weight,
                      volume:formdata.volume,
                      dhl:formdata.dhl
                    } 
        this.sparepartsservice.updateInquiryDealerInfo(data).then((results)=>{
              console.log('details updated',results);

              this.socket.emit('dealerstatus', {id:this.inquiry_id,status:1,user:results['fromId']} );
              this.photoService.photosB = [];
              this.viewCtrl.dismiss('successful');
            },(error)=>{
               console.log('details updated',error);
            });  
        } 




  }


  shipperSubmit(){
    var formdata = this.submitFormShipper.value;
    

         let data = {
                      id:this.inquiry_id,
                      aircargo:formdata.aircargo,
                      seacargo:formdata.seacargo
                    } 
          this.sparepartsservice.updateInquiryShipperInfo(data).then((results)=>{
              console.log('details updated',results);
              this.socket.emit('shippingstatus', {id:this.inquiry_id,status:1,user:results['fromId']} );
              this.viewCtrl.dismiss('success');
            });  

      




  }

  /*
  confirmTransfer(){
  	this.submitAttempt = true;
  	var formdata = this.submitForm.value;
  	let transaction = {amount:formdata.amount,
  						remarks: formdata.remarks,
  						chatId: this.chatId,
  						toId:this.toId,
  						fromId:this.fromId};
  	console.log('transactions::');
  	console.log(transaction);
  	
  	let transactionData = this.money.transferMoney(transaction);
  	this.updateTransactionData(transactionData);
  	//setTimeout(() => {
			//this.dismiss();
		//}, 100);
  	
  	
  	



  	 

  }*/
  /*checkstatus(status,check){
  	console.log(status + ' - ' + check);
  	if(status === check && check === 1)
  	{
  		if(this.loading)
  		{
  			this.loading.dismiss().catch()

  			this.dismiss();
  			this.chat(this.chatId);
  			

  		}
  		return true;
  	}
  	else if (status === check && check === 0)
  	{
  		return true;
  	}
  	else{
  		return false;
  	}

  }*/
  updateTransactionData(transactionData) {
  		

        this.storage.get('token').then((val) => {
          //console.log('hjlll',JSON.stringify(val))

  			this.restProvider.authSendToApi(transactionData,this.transactionApi +'?secret_token='+ val.token).then((result) => {
  			 	//console.log('check if data have been saved');
  			    console.log(result);
  			    this.transactionResults = result['msg'] as Transaction;
  			    
  			    let transId = this.transactionResults._id;

            console.log('check trans', this.transactionResults);
  			    

  			    let transactionData = {
  				url: '',
  				type: 'moneyTransfer',
  				name: this.transactionResults.amount,
  				id: this.transactionResults._id
  				};

          console.log('check trans2', transactionData);


  				///this.loading.dismiss();
  				this.dismiss(transactionData);
  				
  				//console.log(transactionData);
  				/*
  				console.debug('Sending message ', this.data.amount)
  				this.audio.play('message-sent');
  				this.chatService.send(transaction.chatId, this.data.amount, transactionData);

  				this.addMessage({
  					attachment: transactionData,
  					message: this.data.amount,
  					from: this.loginService.user.id
  				});

  				this.chatService.updateLastMessage(transaction.chatId, this.data.amount);
  				this.data.amount = null;
  				this.status = 1;
  				this.loading.dismiss();
  				//this.events.publish('chat-message');
  				//this.navCtrl.pop();
  				//this.nav.push(ChatPage, {chatId: transaction.chatId}, {animate: true, direction: 'forward'});
  				*/
  			  }, (err) => {


            if(this.IsJsonString(err['_body']))
            {
              let message = JSON.parse(err['_body']);

              console.log('err',message.msg);
              //this.loading.dismiss();
              this.dialogs.alert(message.msg)
              .then(() => console.log('Dialog dismissed'))
              .catch(e => console.log('Error displaying dialog', e));
    			    console.log(err);
            }
            else
            {
              console.log('Error', err);
            }
  			  });

      });

		

		
	}

  IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}


baseUrl(data){
      if(data){


      let ext = data.split('.');

      if(ext.length > 1)
      {
        return 'assets/img/avatar/';

      }
      else{
        return Config.server + 'uploads/images/';
      }
    }
    

  }

  getProfilePic(user){
    
    
     let get_contact = this.contactService.get(user);
    // this.pic_check.push(get_contact.image);
           

      //console.log('these users',this.baseUrl(get_contact.image) + get_contact.image);

      if(this.baseUrl(get_contact.image)){

        return this.baseUrl(get_contact.image) + get_contact.image;
      }
      else{
          return this.placeholder;
        


      }



    
    

  }

  viewpic(photo){
    this.photoViewer.show(photo);

  }

  convert(figure){
    let localprice = Number(figure) * Number(this.rate);
    let commission  = localprice * this.commission;

    return  localprice + commission

  }
  mra(fob,shipping)
  {

    return (this.convert(fob) + this.convert(shipping)) * environment.mra


  }
  checkstate(state){
    let chaclass = '';
    //let chaline = '';
    switch (state) {
      case 1:
        // code...
        chaclass = 'active';
        break;
      case 2:
        if(Number(this.inquiry.inquiry_closed) === 1 || Number(this.inquiry.inquiry_closed) === 2 )
        {
          chaclass = 'active';

        }
        // code...
        break;
      case 3:
        if(Number(this.inquiry.inquiry_closed) === 2  )
        {
          chaclass = 'active';

        }

        // code...
        break;
      default:
        // code...
        break;
    }

    return chaclass;

  }

  chaNumber(data){
    return Number(data);
  }

  buy(){

    this.selectDelivery();
  }

  pay(shippingmode)
  {



    if(shippingmode == 'seacargo')
    {
      let amount =this.convert(this.inquiry.fob) + this.convert(this.inquiry.shipping.seacargo) + this.mra(this.inquiry.fob,this.inquiry.shipping.seacargo)
      let newdata = {
       user:this.user.username,
       amount: amount,
       alias:'Sea Cargo Shipping',
       shipping_type:'seacargo',
       type:'sells',
       product_type:'spare',
       inquiry_id:this.inquiry._id,
       mra: this.mra(this.inquiry.fob,this.inquiry.shipping.aircargo)
       }

       this.securityPin(newdata);


    }
    else if(shippingmode == 'aircargo')
    {
        let amount = this.convert(this.inquiry.fob) + this.convert(this.inquiry.shipping.aircargo) + this.mra(this.inquiry.fob,this.inquiry.shipping.aircargo)
        let newdata = {
         user:this.user.username,
         amount: amount,
         alias:'Air Cargo Shipping',
         shipping_type:'aircargo',
         type:'sells',
         product_type:'spare',
         inquiry_id:this.inquiry._id,
         mra: this.mra(this.inquiry.fob,this.inquiry.shipping.aircargo)
         }

         this.securityPin(newdata);


    }

  }

  async securityPin(SentData){

    let modal = await this.viewCtrl.create(
      {
        component:PayComponent,
        componentProps:{data:SentData},
        cssClass: 'my-custom-modal-css-full'
      });
       await modal.present();

    await modal.onDidDismiss().then((rawdata)=>{
      console.log('CHECK Rawdata::',rawdata);
      this.slidephoto = [];
      this.dismiss(rawdata);


      /*let data = rawdata['data'];
      if(data && data.state == 'success')
        { 
          console.log('success',data);
          console.log('Amount::',SentData);
          if(data.type == 'Visa Card')
          {
            let chaData = {
              type:'visacard'




            }
            this.pushPayment(chaData);
          }
          //this.router.navigate(['/dashboard'])
          //this.login();

        }
        else
        {
          console.log('failed',data);
        }*/
        console.log('failed AFTER',rawdata);
    });
        
        
     
      //return await modal.present();
  }

  async pushPayment(chaData){

    let modal = await this.viewCtrl.create(
      {
        component:PayComponent,
        componentProps:{data:chaData},
        cssClass: 'my-custom-modal-css-full'
      });
       await modal.present();

    await modal.onDidDismiss().then((data)=>{

    });


  }
 

  async selectDelivery() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Select Shipping Methods',
      cssClass: 'my-custom-class',
      buttons: [{
        text: 'Sea Cargo',
        icon: 'boat',
        handler: () => {
          this.pay('seacargo')
        }
      }, {
        text: 'Air Cargo',
        icon: 'airplane',
        handler: () => {
          this.pay('aircargo')
        }
      }, {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }]
    });
    await actionSheet.present();

    const { role } = await actionSheet.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  } 

}
