import { Injectable,ViewChild} from '@angular/core';
import { Platform, NavController,LoadingController} from '@ionic/angular';

import { Storage } from '@ionic/storage';
import { Config } from '../config';
import { RestProvider } from './rest/rest';
import {ChaEventsService} from '../services/chaevents';
//import { ChatPage } from '../pages';


@Injectable()
export class VendorService {

	user = null
	playLoginSound = true
	complete = null
	fulldata:any;
	messages = []
	data:any;
	transId:any;
	status = 0;
	loading:any;
	
 

	constructor( 
	 public storage:Storage,
	 public restProvider: RestProvider,
	 private events: ChaEventsService,
	 public loadingCtrl: LoadingController) {
	 	
		
	}
	public registerVendor(data){
		console.log('Vendor register:', data);

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/vendor_manage_register').then((result)=>{
				console.log(result);
				resolve(result);
				},(err) => {
					console.log(err);
				}
			);

		});
	}




	public vendorPoints(data){
		//console.log('Vendor register:', data);

		return new Promise((resolve,reject) => { 
			this.restProvider.authSendToApi(data,'api/loyalty_points').then((result)=>{
				console.log(result);
				resolve(result);
				},(err) => {
					console.log(err);
				}
			);

		});
	}


	
	public getVendor(token,user_id){

		return new Promise((resolve,reject) => {
			

			this.restProvider.authorisedGet(token,'api/vendor/?userid='+user_id).then((result)=>{
					console.log(result);
					resolve(result);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);

			});


		

	}


	public getLoyaltyUsers(token,id,page){

		return new Promise((resolve,reject) => {
			

			this.restProvider.authorisedGet(token,'api/loyalty_users/?id='+id+'&currentpage='+page).then((result)=>{
					console.log(result);
					resolve(result);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);

			});


		

	}


	public getMyVendor(token,userid){

		return new Promise((resolve,reject) => {
			

			this.restProvider.authorisedGet(token,'api/vendor_by_user/?userid='+userid).then((result)=>{
					console.log(result);
					resolve(result);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);

			});


		

	}


	public getAccounts(){

		return new Promise((resolve,reject) => {
			this.storage.get('token').then((token) => {

			this.restProvider.authorisedGet(token.token,'api/money_accounts').then((result)=>{
					console.log(result);
					resolve(result);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);

			});


		});

	}




}
