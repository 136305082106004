import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RestProvider } from './rest/rest';
 
export interface Product {
  id: number;
  name: string;
  price: number;
  amount: number;
}
@Injectable({
  providedIn: 'root'
})
export class CartService {
  data: Product[] = [
    { id: 0, name: 'Pizza Salami', price: 8.99, amount: 0 },
    { id: 1, name: 'Pizza Classic', price: 5.49, amount: 0 },
    { id: 2, name: 'Sliced Bread', price: 4.99, amount: 0 },
    { id: 3, name: 'Salad', price: 6.99, amount: 0 }
  ];
 
  private cart = [];
  private cartItemCount = new BehaviorSubject(0);
 
  constructor(public restProvider: RestProvider,) {}
 
  getProducts() {
    return this.data;
  }
 
  getCart() {
    return this.cart;
  }
 
  getCartItemCount() {
    return this.cartItemCount;
  }
 
  addProduct(product) {
    let added = false;
    let x = 0;
    for (let p of this.cart) {
      if (p.id === product.id) {
        p.amount = product.amount;

        this.cart[x].amount = Number(product.amount) + 1;
        added = true;

        break;
      }
      x = x + 1;
    }
    if (!added) {
      product.amount = product.amount;
      this.cart.push(product);
      this.cartItemCount.next(this.cartItemCount.value + 1);
    }
    
  }

  addProductb(product) {
    let added = false;
    let x = 0;
    for (let p of this.cart) {
      if (p.id === product.id) {
        //p.amount = product.amount;
        
        this.cart[x].amount = Number(product.amount) + p.amount;
        added = true;

        break;
      }
      x = x + 1;
    }
    if (!added) {
      product.amount = product.amount;
      this.cart.push(product);
      this.cartItemCount.next(this.cartItemCount.value + 1);
    }
    
  }
 

  decreaseProduct(product) {
    let x = 0;
    for (let  p of this.cart) {
      if (p.id == product.id) {
        //p.amount = Number(p.amount) - 1;

        if (p.amount == 0) {
          this.cart.splice(x, 1);
        }
        else{

          this.cart[x].amount = Number(product.amount) -1;

        }
        
      }

      x = x + 1;
    }
    //this.cartItemCount.next(this.cartItemCount.value - 1);
  }
 
  removeProduct(product) {
    for (let [index, p] of this.cart) {
      if (p.id === product.id) {
        this.cartItemCount.next(this.cartItemCount.value - 1);
        this.cart.splice(index, 1);
      }
    }
  }


  resetCart(){
    this.cart = [];
    this.cartItemCount.next(0);
  }

  public getOrders(fromId){
    let data ={fromId:fromId}
    return new Promise((resolve,reject) => {
      this.restProvider.authSendToApi(data,'api/productorders').then((result)=>{
        console.log('product OrdersBB',result);
          if(result != null)
          {
            resolve(result['msg']);
          }
          else{
            reject('');
          }
        },(err) => {
          
          reject(err);
          console.log(err);
        }
      );

    });
  }

  public updateOrder(id,status){
    let data ={id:id,status:status};
    console.log('CHECK STATUS STATUS:::::',data);
    return new Promise((resolve,reject) => {
      this.restProvider.authSendToApi(data,'api/product_order_update_status').then((result)=>{
        console.log('Order update from service:::',result);
        resolve(result['msg']);
        },(err) => {
          reject(err);
          console.log(err);
        }
      );

    });
  }

  public updateOrderb(id,status,role){
    let data ={id:id,status:status,role:role};
    console.log('CHECK STATUS STATUS:::::',data);
    return new Promise((resolve,reject) => {
      this.restProvider.authSendToApi(data,'api/product_order_update_status').then((result)=>{
        console.log('Order update from service:::',result);
        resolve(result['msg']);
        },(err) => {
          reject(err);
          console.log(err);
        }
      );

    });
  }

  public getOrderbyId(id){
    let data ={id:id}
    return new Promise((resolve,reject) => {
      this.restProvider.authSendToApi(data,'api/product_order_by_id').then((result)=>{
        //console.log('spareparts',result);
        resolve(result['msg']);
        },(err) => {
          reject(err);
          console.log(err);
        }
      );

    });
  }

  public getUserRoles(fromId,productTypeId){
    let data ={fromId:fromId,product_typeId:productTypeId}
    return new Promise((resolve,reject) => {
      this.restProvider.authSendToApi(data,'api/get_user_roles').then((result)=>{
        //console.log('spareparts',result);
        if(result == null || result == undefined || result == 'No record found')
          {
            resolve([]);
          }
          else
          {
            resolve(result['msg']);
          }
        },(err) => {
          reject(err);
          console.log(err);
        }
      );

    });
  }
  public getUserRolesByUser(fromId){
    let data ={fromId:fromId}
    return new Promise((resolve,reject) => {
      this.restProvider.authSendToApi(data,'api/get_user_roles_by_user').then((result)=>{
        //console.log('spareparts',result);
        if(result == null || result == undefined || result == 'No record found')
          {
            resolve([]);
          }
          else
          {
            resolve(result['msg']);
          }
        },(err) => {
          reject(err);
          console.log(err);
        }
      );

    });
  }

  public productOrdersByDealer(dealerId){
    let data ={dealerId:dealerId}
    return new Promise((resolve,reject) => {
      this.restProvider.authSendToApi(data,'api/productorders_by_dealer').then((result)=>{
        //console.log('spareparts',result);
        if(result == null || result == undefined || result == 'No record found')
          {
            resolve([]);
          }
          else
          {
            resolve(result['msg']);
          }
        },(err) => {
          reject(err);
          console.log(err);
        }
      );

    });
  }

  public productOrdersByShipper(shipperId){
    let data ={shipperId:shipperId}
    return new Promise((resolve,reject) => {
      this.restProvider.authSendToApi(data,'api/productorders_by_shipper').then((result)=>{
        console.log('shipping in',result);
          if(result == null || result == undefined || result == 'No record found')
          {
            resolve([]);
          }
          else
          {
            resolve(result['msg']);
          }
        },(err) => {
          reject(err);
          console.log("cought",err);
        }
      );

    });
  }


  public productOrdersByClearingAgent(clearingAgentId){
    let data ={clearingAgentId:clearingAgentId}
    return new Promise((resolve,reject) => {
      this.restProvider.authSendToApi(data,'api/productorders_by_clearingagent').then((result)=>{
        //console.log('spareparts',result);
        if(result == null || result == undefined || result == 'No record found')
          {
            resolve([]);
          }
          else
          {
            resolve(result['msg']);
          }
        },(err) => {
          reject(err);
          console.log(err);
        }
      );

    });
  }

  public productOrdersByCourier(courierId){
    let data ={courierId:courierId}
    return new Promise((resolve,reject) => {
      this.restProvider.authSendToApi(data,'api/productorders_by_courier').then((result)=>{
        //console.log('spareparts',result);
        if(result == null || result == undefined || result == 'No record found')
          {
            resolve([]);
          }
          else
          {
            resolve(result['msg']);
          }
        },(err) => {
          reject(err);
          console.log(err);
        }
      );

    });
  }


  public updateProductOrderTracking(data){
    
    //console.log('CHECK STATUS STATUS:::::',data);
    return new Promise((resolve,reject) => {
      this.restProvider.authSendToApi(data,'api/update_product_order_tracking').then((result)=>{
        
        resolve(result['msg']);
        },(err) => {
          reject(err);
          console.log(err);
        }
      );

    });
  }

  

}