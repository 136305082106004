import { Injectable,ViewChild} from '@angular/core';
import { Platform, NavController,LoadingController} from '@ionic/angular';
import {AudioService} from './audio';
import {SocketService} from './socket';
import {LoginService} from './login';
import {ChatService} from './chat';
import { Storage } from '@ionic/storage';
import { Config } from '../config';
import {marked} from 'marked';
import { RestProvider } from './rest/rest';
import {Transaction} from '../models/transaction';
import {ChaEventsService} from '../services/chaevents';
import {ChaEncryptionService } from '../services/chaencryption';
import { environment } from '../../environments/environment';
//import { ChatPage } from '../pages';


@Injectable()
export class MoneyService {

	user = null
	playLoginSound = true
	complete = null
	fulldata:any;
	messages = []
	data:any;
	transId:any;
	status = 0;
	loading:any;
	transactionResults:Transaction;


	constructor(
	 public audio:AudioService,
	 public socket:SocketService,  
	 public storage:Storage,
	 private chatService:ChatService,
	 private loginService:LoginService,
	 public restProvider: RestProvider,
	 private events: ChaEventsService,
	 public loadingCtrl: LoadingController,
	 public chaencryptionService:ChaEncryptionService,) {
	 	marked.setOptions({
			sanitize: true,
			gfm: true
		});

		this.init();
		
		
	} 

	public init(){
		let evts = this.events;

		this.socket.on('payment', function(name, message) {
			//console.debug('SOCKET chat-message');
			
			console.log('Payment socket:',message);
			
			evts.chaPaymentPublish(message);
		});
	}


	public topUpMoney(amount,paymentmethod){
		console.log('NEW PAYMENTDATA:',paymentmethod);

		
		let senddata = {code: amount, paymentmethod: paymentmethod}
		return new Promise((resolve,reject) => {
			this.storage.get('token').then((token) => {
				this.restProvider.authSendToApi(senddata,'api/topup?secret_token='+token.token).then((result)=>{
					console.log(result);
					resolve(result);
					},(err) => {
						console.log(err);
					}
				);
			});

		});
	}


	public paymentGatewayToken(paymentdata,userid){
		console.log('NEW PAYMENTDATA:',paymentdata);
	
		return new Promise((resolve,reject) => {
			
				let senddata = {
					amount: paymentdata.amount,
					currency: environment.chaCurrency,
					service_description:"Buying Accessorries",
					



				};

				//this.storage.get('token').then((token) => {
					this.restProvider.authSendToApi(senddata,'api/payment-gateway-token').then((result)=>{
						console.log(result);
						resolve(result);
						},(err) => {
							console.log(err);
							reject(err);
						}
					);
				//});//end Storage

			

		});
	}


	public refundGatewayToken(paymentdata,userid){
		console.log('NEW WITHDRAWDATA:',paymentdata);
	
		return new Promise((resolve,reject) => {
			
				let senddata = {
					amount: paymentdata.amount,
					currency: environment.chaCurrency
					
					



				};

				this.storage.get('token').then((token) => {
					this.restProvider.authSendToApi(senddata,'api/withdraw-gateway-token').then((result)=>{
						console.log(result);
						resolve(result);
						},(err) => {
							console.log(err);
							reject(err);
						}
					);
				});//end Storage

			

		});
	}


	public payUpMoney(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/payup').then((result)=>{
				console.log(result);
				resolve(result);
				},(err) => {
					console.log(err);
				}
			);

		});
	}

	public airtimePay(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/airtimepay').then((result)=>{
				console.log(result);
				resolve(result);
				},(err) => {
					console.log(err);
				}
			);

		});
	}


	public Pay(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/pay').then((result)=>{
				console.log(result);
				resolve(result);
				},(err) => {
					console.log(err);
					reject(err.error);
				}
			);

		});
	}
	
	// send a message
	public transferMoney(transaction) {
		
		this.data = transaction;
		//this.sending = true;
		//this.blurInput(null);
		//set transaction data
		let data = this.getTransactionId(transaction)

		return data;
		
		

		/*setTimeout(() => {
			this.content.scrollToBottom(300);
			this.sending = false;
		}, 100)*/
		//return 'sucessful';
	}
	private addMessage(message) {
		this.messages.push(message);
		if (Config.markdown) {
			message.marked = message.message ? marked.parse(message.message) : null;
		}
		var prev = null;
		for (let message of this.messages) {
			if (message.from != prev) {
				prev = message.from;
				message.first = true;
			}
		}
	}
	private getTransactionId(transaction) {
		let data = {
			toId:transaction.toId,
			fromId:transaction.fromId,
			amount:transaction.amount,
			remarks:transaction.remarks,
			transaction_type:'moneyTransfer'
			};
		return data; 

	}
	public getStatus(){
		return this.status;
	}
	public updateStatus(status){
		this.status =  status;
	}

	public getMoneyAccounts(){

		return new Promise((resolve,reject) => {
			this.storage.get('token').then((token) => {

			this.restProvider.authGetToApi('api/accounts?secret_token='+token.token).then((result)=>{
					console.log(result);
					resolve(result);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);

			});


		});

	}


	public getDefaultMoneyAccount(){

		return new Promise((resolve,reject) => {
			this.storage.get('token').then((token) => {

			this.restProvider.authorisedGet(token.token,'api/default_account').then((result)=>{
					console.log(result);
					resolve(result);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);

			});


		});

	}

	public getAccounts(){

		return new Promise((resolve,reject) => {
			this.storage.get('token').then((token) => {

			this.restProvider.authorisedGet(token.token,'api/money_accounts').then((result)=>{
					console.log(result);
					resolve(result);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);

			});


		});

	}

	public getMoneyService(){
		return new Promise((resolve,reject) => {
			this.storage.get('token').then((token) => {

			this.restProvider.authorisedGet(token.token,'api/service').then((result)=>{
					//console.log(result);
					resolve(result);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);

			});


		});

	}


	public getTransactions(){
		return new Promise((resolve,reject) => {
			this.storage.get('token').then((token) => {

			this.restProvider.authorisedGet(token.token,'api/get_transactions').then((result)=>{
					//console.log(result);
					resolve(result);
					},(err) => {
						//console.log(err);
						reject(err);
					}
				);

			});


		});

	}


	public createPin(data,token){
		console.log('CHECK Pin DATA::',data)
		return new Promise((resolve,reject) => {


			this.restProvider.authSendToApiPin(data,'api/moneyregister',token).then((result)=>{
				console.log(result);
				resolve(result);
				},(err) => {
					console.log(err);
					reject(err);
				}
			);

		});

	}


	public generateVerificationId(data){
		return new Promise((resolve,reject) => {
			//console.log('charlie',JSON.stringify(data));
			this.restProvider.authSendToApi(data,'api/generate_verificaiton').then((result)=>{
				//console.log(result);
				resolve(result);
				},(err) => {
					//console.log(err);
					reject(err);
				}
			);

		});

	}


	public createAccount(data){
		return new Promise((resolve,reject) => {
			//console.log('charlie',JSON.stringify(data));
			this.restProvider.authSendToApi(data,'api/moneyaccount').then((result)=>{
				//console.log(result);
				resolve(result);
				},(err) => {
					//console.log(err);
					reject(err);
				}
			);

		});

	}


	public deleteAccount(data){
		return new Promise((resolve,reject) => {
			//console.log('charlie',JSON.stringify(data));
			this.restProvider.authSendToApi(data,'api/delete_account').then((result)=>{
				//console.log(result);
				resolve(result);
				},(err) => {
					//console.log(err);
					reject(err);
				}
			);

		});

	}

	public updateAccount(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/updatemoneyaccount').then((result)=>{
				resolve(result);
				},(err) => {
					reject(err);
				}
			);

		});

	}

	public setDefaultAccount(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/setdefault_account').then((result)=>{
				resolve(result);
				},(err) => {
					reject(err);
				}
			);

		});

	}



}
