// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
/**
 *Ionic 4 Spotify app starter - Ion Spotify (https://store.enappd.com/product/ionic-4-spotify-app-starter)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
ion-content {
  --background: linear-gradient(to top, #000000, #989aa2);
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

ion-input {
  text-align: center;
  line-height: 46px;
  font-size: 38px;
  color: #f4f5f8;
}

ion-item {
  --background: transparent;
  width: 100%;
  text-align: center;
  --highlight-color-focused: #9E9E9E !important;
  --border-color: #9E9E9E;
}

ion-text {
  font-size: 16px;
  font-weight: bold;
}

.buttons {
  padding-top: 48px;
}`, "",{"version":3,"sources":["webpack://./src/app/microservices/ufulumusic/playlistmodal/playlistmodal.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;;;;;;EAAA;AAQC;EACG,uDAAA;AAEJ;;AACA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,sBAAA;AAEJ;;AACA;EACI,kBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;AAEJ;;AACA;EACI,yBAAA;EACA,WAAA;EACA,kBAAA;EACA,6CAAA;EACA,uBAAA;AAEJ;;AAEA;EACI,eAAA;EACA,iBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ","sourcesContent":["/**\n *Ionic 4 Spotify app starter - Ion Spotify (https://store.enappd.com/product/ionic-4-spotify-app-starter)\n *\n * Copyright © 2019-present Enappd. All rights reserved.\n *\n * This source code is licensed as per the terms found in the\n * LICENSE.md file in the root directory of this source tree.\n */\n ion-content {\n    --background: linear-gradient(to top, #000000, #989aa2);\n}\n\n.flex {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    flex-direction: column;\n}\n\nion-input {\n    text-align: center;\n    line-height: 46px;\n    font-size: 38px;\n    color: #f4f5f8;\n}\n\nion-item {\n    --background: transparent;\n    width: 100%;\n    text-align: center;\n    --highlight-color-focused: #9E9E9E !important;\n    --border-color: #9E9E9E;\n    // --inner-border-width: 0 0 2px 0;\n}\n\nion-text {\n    font-size: 16px;\n    font-weight: bold;\n}\n\n.buttons {\n    padding-top: 48px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
