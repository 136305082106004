import { Component, OnInit,Input } from '@angular/core';
import {HelperService} from '../../services/helper';

import { ModalController, Platform, NavParams, LoadingController,  ActionSheetController,AlertController  } from '@ionic/angular';

@Component({
  selector: 'cha-slider',
  templateUrl: './cha-slider.component.html',
  styleUrls: ['./cha-slider.component.scss'],
})

export class ChaSliderComponent implements OnInit {
  @Input() sliderdata:any[];
	

  constructor(public helper: HelperService) {}

  ngOnInit() {}
  getImage(image_name){

  let image_path = this.helper.getImage(image_name);
  return image_path;

}

}
