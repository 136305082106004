import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

//import { Headers,Http, Response, RequestOptions } from '@angular/http';
//import 'rxjs/add/operator/map';

//import { Config } from '../../config';
import {Transaction} from '../../models/transaction';
import { Observable } from 'rxjs';

//import 'rxjs/add/operator/map';
import { filter } from 'rxjs/operators';
//import 'rxjs/add/observable/from';
import { throwError } from 'rxjs';
//import 'rxjs/add/operator/mergeMap';

import { from } from 'rxjs';
import { of } from 'rxjs';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { Storage } from '@ionic/storage';
import { environment } from '../../../environments/environment';
import {RestProviderLocal} from './rest.local';
import {ChaEventsService} from '../../services/chaevents';
//import { Observable } from 'rxjs/Rx';
//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/operator/catch';
//import 'rxjs/add/observable/throw';



/*
  Generated class for the RestProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class RestProvider {

	apiUrl = environment.server;
	transactionApi = environment.transactionApi;
	apiUrlNode =environment.server;


  constructor(
  	public http: HttpClient, 
  	public storage: Storage,
  	private restProviderLocal:RestProviderLocal,
  	private events: ChaEventsService, ) {
    //console.log('Hello RestProvider Provider');
  }

  addTransaction(data) {
	  return new Promise((resolve, reject) => {
	  	//console.log('url is....' + this.apiUrl + 'api/'+this.transactionApi  +JSON.stringify(data) );
	  	let url = this.apiUrl + 'api/'+this.transactionApi ;
	  	//let headers = new HttpHeaders();
	  	/*
	  	let options: any = {};
		options.headers = new HttpHeaders({ Accept: 'application/json', 
		         'Content-Type': 'application/json;charset=UTF-8',
		         'Access-Control-Allow-Origin': '*',
		         'Access-Control-Allow-Credentials': 'true',
		         'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT, DETELE',
		         'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'

		         

		});
		
		let headers = new Headers();
		headers.append('Content-Type', 'application/json; charset=utf-8');
		headers.append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, PATCH');
		headers.append('Access-Control-Allow-Origin', '*');
		headers.append('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers');
		let optionsb = new RequestOptions({ headers: headers });*/

		
		//headers = headers.set('Content-Type', 'application/json; charset=utf-8');
		//headers = headers.set('Access-Control-Allow-Origin', '*');


		/*let options: any = {};
		options.headers = new HttpHeaders({ Accept: 'application/json', 
		         'Content-Type': 'application/json;charset=UTF-8',
		         'Access-Control-Allow-Origin': '*',
		         'Access-Control-Allow-Credentials': 'true',
		         'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
		         'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers'

		         

		});
		

		var headers = new Headers();
    headers.append('Access-Control-Allow-Origin' , '*');
    headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    headers.append('Accept','application/json');
    headers.append('content-type','application/json');
    // let optionsb = new RequestOptions({ headers:headers});*/

   

	    this.http.post(url, data)
	      .subscribe(res => {
	        resolve(res);
	      }, (err) => {
	      	//console.log('tiyone.........................' + url,JSON.stringify(err));
	        reject(err);
	      });
	  });
	}
 
	getTransaction() {
	  return new Promise(resolve => {
	    this.http.get(this.apiUrl+this.transactionApi).subscribe(data => {
	      resolve(data);
	    }, err => {
	      console.log(err);
	    });
	  });
	}

	sendToApi(data,url){
	  return new Promise((resolve, reject) => {
	  	//console.log('url is....' + this.apiUrlNode+url);
	  	//let headers = new HttpHeaders();
		//headers = headers.set('Content-Type', 'application/json; charset=utf-8');
	    this.http.post(this.apiUrlNode + url, data)
	      .subscribe(res => {
	        resolve(res);
	      }, (err) => {
	        reject(err);
	      });
	  });
	}

	authNetworkPromise(url,token,data,resolve,reject){
		let httpOptions = {
				    	headers: new HttpHeaders({ 'Content-Type': 'application/json',
				    	'Authorization': 'Bearer ' + token.token })
				  	};
				    this.http.post(this.apiUrlNode + url, JSON.stringify(data),httpOptions)
				      .subscribe(res => {
				      	//console.log('Tracking the ERROR:|||||||',res);
				        resolve(res);
				      }, (err) => {
				      	//console.log('error on rest',err);
				        reject(err);
				      });
	}



	async authNetwork(url,token,data,identifier=''){
		let httpOptions = {
				    	headers: new HttpHeaders({ 'Content-Type': 'application/json',
				    	'Authorization': 'Bearer ' + token.token })
				  	};
				    this.http.post(this.apiUrlNode + url, JSON.stringify(data),httpOptions).subscribe(chares => {
				    	//console.log('after offline_url then goes to online',chares);
				    		if(chares != null && chares['msg'] != 'No record found')
				    		{


					      	let datab = [];
					      	let dynamicdata = {
					      		key:url,
					      		value: chares
					      	};
					      	datab.push(dynamicdata)

					      	//console.log('GET DATA TO EVENTSBBB((((((((((((',datab);
					      	this.events.dynamicDataPublish(datab);
					      	let offline_url = url;
			    				if(identifier != ''){
			    					offline_url = url +'_'+identifier;

			    				}
					      	this.restProviderLocal.setToStorage(offline_url,datab);
					      }
					      else{

					      	let newresults = chares;
					      	if(newresults == null)
					      	{
						      	newresults = {msg:[]};
						      }
						      else{

						      	newresults['msg'] = [];
						      	


						      }
						      	let datab = [];
						      	let dynamicdata = {
						      		key:url,
						      		value: newresults
						      	};
						      	//console.log('second part after offline_url then goes to online',dynamicdata);
						      	datab.push(dynamicdata);
						      	this.restProviderLocal.setToStorage(url,datab);
						      	this.events.dynamicDataPublish(datab);
					     	 
					      	//resolve(newresults);

					      }

				        //resolve(res);
				      }, (err) => {
				      	console.log('error on rest',err);
				        //reject(err);
				      });
	}

	async authNetworkb(url,token,data,resolve,reject){
		let httpOptions = {
				    	headers: new HttpHeaders({ 'Content-Type': 'application/json',
				    	'Authorization': 'Bearer ' + token.token })
				  	};
				    this.http.post(this.apiUrlNode + url, JSON.stringify(data),httpOptions).subscribe(res => {
				      	/*let datab = [];
				      	let dynamicdata = {
				      		key:url,
				      		value: res
				      	};
				      	datab.push(dynamicdata)

				      	console.log('GET DATA TO EVENTS((((((((((((',datab);
				      	this.events.dynamicDataPublish(datab);
				      	this.restProviderLocal.setToStorage(url,datab);*/
				      	//console.log('result on save-------------',res);
				      	if(res != null && res['msg'] != 'No record found')
				    		{
				        	resolve(res);
				        }
				        else{
				        	let newresults = res;
					      	newresults['msg'] = [];
					      	let datab = [];
					      	let dynamicdata = {
					      		key:url,
					      		value: newresults
					      	};
					      	datab.push(dynamicdata)
					      	this.restProviderLocal.setToStorage(url,datab);
					      	this.events.dynamicDataPublish(datab);
				        	resolve(newresults);
				        }
				      }, (err) => {
				      	console.log('error on rest',err);
				        reject(err);
				      });
	}
 
	authSendToApi(data,url,identifier=''){
	  return new Promise((resolve, reject) => {

	  	//console.log('url is....' + this.apiUrlNode+url);
	  	this.storage.get('token').then((token)=>{

    		if(token)
    		{


    			if( url.includes('get_')==true)
    			{
    				let offline_url = url;
    				if(identifier != ''){
    					offline_url = url +'_'+identifier;

    				}
	    			this.restProviderLocal.getFromStorage(offline_url).then((results)=>{

	    				//console.log('results+++++++++LOCAL STORAGE________)))))))',results);

	    				this.authNetwork(url,token,data,identifier);
	    				if(results != null && results[0]["value"]["msg"] != 'No record found' ){
	    					resolve(results[0]["value"]);
	    				}
	    				else if(results != null && results[0]["value"]["msg"] == 'No record found'){
	    					let newres = results[0]["value"];
	    					newres['msg'] = [];
	    					this.restProviderLocal.setToStorage(url,newres);
	    					resolve(newres);

	    				}
	    				else
	    				{
	    					resolve([]);
	    				}


	    			},(err)=>{

	    				this.authNetworkPromise(url,token,data,resolve,reject);



	    			});
    			}
    			else
    			{

    				this.authNetworkb(url,token,data,resolve,reject);

    			}

				    

			}
	  		else
	  		{
	  			this.http.post(this.apiUrlNode + url, JSON.stringify(data))
			      .subscribe(res => {
			        resolve(res);
			      }, (err) => {
			        reject(err);
			      });
	  			
	  		}

		});



	  });

	}


	authSendToApiPin(data,url,token){
	  return new Promise((resolve, reject) => {

	  	//console.log('url is....' + this.apiUrlNode+url);
	  	//this.storage.get('token').then((token)=>{

    		if(token)
    		{
			    let httpOptions = {
			    	headers: new HttpHeaders({ 'Content-Type': 'application/json',
			    	'Authorization': 'Bearer ' + token })
			  	};
			    this.http.post(this.apiUrlNode + url, JSON.stringify(data),httpOptions)
			      .subscribe(res => {
			        resolve(res);
			      }, (err) => {
			        reject(err);
			      });
			}
	  		else
	  		{
	  			this.http.post(this.apiUrlNode + url, JSON.stringify(data))
			      .subscribe(res => {
			        resolve(res);
			      }, (err) => {
			        reject(err);
			      });
	  			
	  		}

		//});



	  });

	}


	authSendToApiC(data,url){
	  return new Promise((resolve, reject) => {
	  	//let headers = new Headers();
		//headers.append('Content-Type', 'application/json; charset=utf-8');
		//headers.append('Access-Control-Allow-Origin' , '*');
		/*
		headers.append('Content-Type', 'application/json; charset=utf-8');
		headers.append('Access-Control-Allow-Methods', 'GET, POST, PUT, DELETE, PATCH');
		headers.append('Access-Control-Allow-Origin', '*');
		headers.append('Access-Control-Allow-Headers', 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers');
		let options = new RequestOptions({ headers: headers });
	  	console.log('url is....' + this.apiUrl+url);
	  	console.log('data to be sent is:', JSON.stringify(data));*/

	    this.http.post(this.apiUrl + url, data)
	      .subscribe(res => {
	        resolve(res);
	      }, (err) => {
	        reject(err);
	      });
	  });
	}


	authSendToApiB(data,url){
	  return new Promise((resolve, reject) => {
	  	console.log('url is....' + this.apiUrl+url);
	  	//let headers = new HttpHeaders();
		//headers = headers.set('Content-Type', 'application/json; charset=utf-8');
	    this.http.post(this.apiUrl+url, JSON.stringify(data))
	      .subscribe(res => {
	        resolve(res);
	      }, (err) => {
	        reject(err);
	      });
	  });
	}



	authGetToApi(url){
	  return new Promise((resolve, reject) => {

	  	//console.log('url is....' + this.apiUrlNode+url);
	  	this.storage.get('token').then((token)=>{

    		if(token)
    		{
    			//console.log('SHOW TOKEN SENT:::::::',token);
			    let httpOptions = {
			    	headers: new HttpHeaders({ 'Content-Type': 'application/json',
			    	'Authorization': 'Bearer ' + token.token })
			  	};
			    this.http.get(this.apiUrlNode + url,httpOptions)
			      .subscribe(res => {
			        resolve(res);
			      }, (err) => {
			        reject(err);
			      });
			}
	  		else
	  		{
	  			this.http.get(this.apiUrlNode + url)
			      .subscribe(res => {
			        resolve(res);
			      }, (err) => {
			        reject(err);
			      });
	  			
	  		}

		});



	  });

	}
	authorisedGet(token,url){
		//console.log('JWT ' + token);
		return new Promise((resolve, reject) => {
	  	//console.log('url is....' + this.apiUrlNode+url);
	  	//let headers = new HttpHeaders();
	  	if(url.split('?').length > 1)
	  	{
	  		this.http.get(this.apiUrlNode + url+'&secret_token='+token)
	      .subscribe(res => {
	        resolve(res);
	      }, (err) => {
	        reject(err);
	      });

	  	}
	  	else
	  	{
			//headers = headers.set('Authorization', 'JWT ' + token);
		    this.http.get(this.apiUrlNode + url+'/?secret_token='+token)
		      .subscribe(res => {
		        resolve(res);
		      }, (err) => {
		        reject(err);
		      });
	  }
	  });
	}
	

}
