/**
 * Ionic 4 Spotify app starter - Ion Spotify (https://store.enappd.com/product/ionic-4-spotify-app-starter)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { Component, OnInit, Input } from '@angular/core';
import { SpotifyService } from '../../../services/spotify.service';
import { ActivatedRoute } from '@angular/router';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-playlistmodal',
  templateUrl: './playlistmodal.component.html',
  styleUrls: ['./playlistmodal.component.scss'],
})
export class PlaylistmodalComponent implements OnInit {
  @Input() value: any;
  comingFrom: any;
  inputValue: '';
  modalData: {};
  incomingModalData: any;
  constructor(
    public serviceProvider: SpotifyService,
    public navParams: NavParams
  ) {
    this.incomingModalData = this.navParams.get('value');
    console.log('this.incomingModalData', this.incomingModalData);
  }

  ngOnInit() { }
  closeModal() {
    this.serviceProvider.modalCtrl.dismiss();
  }
  addToPlayList(inputValue) {
    this.modalData = {
      name: inputValue,
      data: [this.incomingModalData]
    };
    this.serviceProvider.arrayData.push(this.modalData);
    this.serviceProvider.modalCtrl.dismiss({
      result: inputValue
    }
    );
  }
}
