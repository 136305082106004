import { Product, CartService } from '../../services/cart.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController,NavController,ActionSheetController , NavParams} from '@ionic/angular';
import {LocationService} from '../../services/location.service';
import {PaymentMethodsComponent} from '../payment-methods/payment-methods.component';
import {PayComponent} from '../pay/pay.component';
import {PaymentPreviewComponent} from '../payment-preview/payment-preview.component';
import {ProductsService} from '../../services/products';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { LoginService} from '../../services/login';
import { environment } from '../../../environments/environment';
import { CommunityService} from '../../services/community';
import { PhotoService } from '../../services/photo.service';
import {ChaEventsService} from '../../services/chaevents';

class Member {
  public userid: [{
    _id:string,
    name:string
  }];
  
}

@Component({
  selector: 'app-community-message-modal',
  templateUrl: './community-message-modal.component.html',
  styleUrls: ['./community-message-modal.component.scss'],
})
export class CommunityMessageModalComponent implements OnInit {

  current_user:any;
  community:any;
  communitymessage:any;
  attachments:any = [];
  posts:any=[];
  chaphoto:any;
  photo:any=[];
  chaloading = 0;
  announcement:any;
  contributions:boolean=true;
  members:Member[];
  paymentuserid:any='';
 
  constructor(
    public params: NavParams,
    private cartService: CartService, 
    private modalCtrl: ModalController, 
    private viewCtrl: ModalController,
    private alertCtrl: AlertController,
    public navCtrl: NavController,
    private locationService:LocationService,
    public actionSheetController: ActionSheetController,
    private productsService:ProductsService,
    private iab: InAppBrowser, 
    private loginService: LoginService,
    private communityService: CommunityService,
    public photoService: PhotoService,
    public event:ChaEventsService,) {

    this.current_user = this.params.get('current_user');
    this.community = this.params.get('community');
    this.members = this.community.members;
    console.log('View members:',this.members);
     }
  
  ngOnInit() {

    this.event.photo().subscribe((photo)=>{
      this.photo.push({data:photo,type:'picture'});

    });
    


  }
  addPhotoToGallery() {
      this.photoService.addNewToGallery();
    }

 contactsPic(data){
    return this.baseUrl(data) + data;
  }

  baseUrl(data){
      if(data){


      let ext = data.split('.');

      if(ext.length > 1)
      {
        return 'assets/img/avatar/';

      }
      else{
        return environment.server + 'uploads/images/';
      }
    }
    

  }


 

  async presentAlert(title,subtitle,message)
  {
    const alert = await this.alertCtrl.create({
        header: title,
        subHeader: subtitle,
        message: message,
        buttons: ['OK']
      });

      await alert.present();
  }

close(){
  this.viewCtrl.dismiss('');

}

post(){

  //this.photo, this.announcement, this.contributions
  // blank message validation
  if((this.communitymessage == null || this.communitymessage == '') &&
    (this.paymentuserid == null || this.paymentuserid == '') && 
    (this.photo.length == 0) &&
    (this.announcement == null || this.announcement == ''))
  {

      this.presentAlert('Information Notification','Validation Error','You can not send a blank message');

  }

  else if((this.communitymessage == null || this.communitymessage == '') &&
    (this.photo.length == 0))
  {

      this.presentAlert('Information Notification','Validation Error','Either Write a Message or with Upload a picture');

  }
  else if(this.announcement == null || this.announcement == '')
  {

      this.presentAlert('Information Notification','Validation Error','Select Announcement Type');

  }
  else if((this.contributions == true) && 
    (this.paymentuserid == null || this.paymentuserid == ''))
  {

      this.presentAlert('Information Notification','Validation Error','Make sure Payment Recipient User is selected');

  }
  else if(this.communitymessage == null || this.communitymessage == '')
  {

      this.presentAlert('Information Notification','Validation Error','Please write Your Message');

  }
  else{
    
    console.log('paymentuserid:',this.paymentuserid);
    if(this.paymentuserid == null || this.paymentuserid == ''){
      this.paymentuserid = this.current_user._id
    }
    
    this.chaloading = 1;
    console.log('PHOTOS::',this.photo);
    this.communityService.saveCommunityPost(this.current_user._id,this.paymentuserid,this.community._id, this.communitymessage, this.photo, this.announcement, this.contributions ).then((results)=>{
      this.chaloading = 0;
      console.log('MSG::',results);
      this.viewCtrl.dismiss({status:'post',data:results});

    });
    
  }




}

onChange(){

  console.log('changed announcement:',this.announcement);
  if(this.announcement == 'maliro' || this.announcement == 'wedding')
  {
    this.contributions  = true;
  }
  else
  {
    this.contributions  = false;
  }


}

}