// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gradient {
  background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
}

ion-title {
  width: -moz-fit-content;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./src/app/common/register-community-courier/register-community-courier.component.scss"],"names":[],"mappings":"AAAA;EACQ,4DAAA;AACR;;AACA;EACI,uBAAA;EAAA,kBAAA;AAEJ","sourcesContent":[".gradient {\n        background: linear-gradient(90deg, #d53369 0%, #daae51 100%);\n      }\nion-title {\n    width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
