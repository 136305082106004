import { Component, OnInit, Input,ViewChild,ElementRef } from '@angular/core';
import { Router, Params, ActivatedRoute,NavigationExtras} from '@angular/router';
import {IonSearchbar,NavController,} from '@ionic/angular';
import {HelperService} from '../../services/helper';
import { environment } from '../../../environments/environment';
import {Item} from '../../models/item'
import * as Pages from '../../pages' ;
import {ProductsService} from '../../services/products';
import {ListenerService} from '../../services/listeners';
 
@Component({
  selector: 'cha-app-list',
  templateUrl: './cha-app-list.component.html',
  styleUrls: ['./cha-app-list.component.scss'],
})
export class ChaAppListComponent {
  
  //@Input() chalimit:any;
  //@Input() locationmenu:any;

  

  constructor(
    public navCtrl: NavController,
    public helper:HelperService,
     ) { 
    console.log('We are here on the Applist');
	    
  }

  lcc(){
    this.navCtrl.navigateForward('/dashboard/apps/cycling');

  }


  community(){
    this.navCtrl.navigateForward('/dashboard/apps/community');

  
 }

 buyFromChina(){
   this.navCtrl.navigateForward('/dashboard/apps/buyinchina');

 }

 ufuluSchools(){
    this.navCtrl.navigateForward('/dashboard/apps/school');

  
 }

  ufuluSchoolsb(){
    let navigationExtras: NavigationExtras = { state: {page:Pages.UfuluSchools(ListenerService,ProductsService)} };
    this.navCtrl.navigateForward('/dashboard/apps/base',navigationExtras);

  
 }

 musicmain(){
    this.navCtrl.navigateRoot('/dashboard/apps/ufulumusic');

  
 }

 music(){
    this.navCtrl.navigateRoot('/dashboard/apps/music');

  
 }

 productManagement()
 {
   this.navCtrl.navigateRoot('/dashboard/apps/productmanagement');
 }

 spareparts(){
    this.navCtrl.navigateForward('/dashboard/apps/spareparts');

   
 }
 sparepartsDealer(){
   this.navCtrl.navigateForward('/dashboard/apps/sparepartsdealer');

 }

 sparepartsShipper(){
   this.navCtrl.navigateForward('/dashboard/apps/sparepartshipper');

 }
 sparepartAgent(){
   this.navCtrl.navigateForward('/dashboard/apps/sparepartagent');

 }


  
   

  

 

}
