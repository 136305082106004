import { Component, OnInit, ApplicationRef } from '@angular/core';
import { NavController,ModalController, NavParams} from '@ionic/angular';
import {Config} from '../../config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { LoginService } from '../../services/login';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss'],

})
export class NetworkComponent implements OnInit {
	status:any = null;
  chaclose:any=0;
  networkType:any;
  connected:any=null;
  warninghide:boolean=false;
  connectedhide:boolean=false;


  constructor(
    private network: Network,
    private appReference: ApplicationRef,
    private loginService: LoginService,
    private alertController: AlertController,
    private iab: InAppBrowser) {
    this.checkForNetwork();
    



   }

  ngOnInit() {



  }

  ngAfterViewInit(){
    if(this.connected == null){
      let chathis = this;
      setTimeout(function() {
                  chathis.warninghide = true;
       }, 100); 
    }
  }
  close(){
    this.chaclose = 1;
  }

checkForNetwork(){
    this.networkType= this.network.type;
    console.log('check for network');
     
    this.network.onDisconnect().subscribe(() => {
      console.log('network disconnected!');

        this.chaclose = 0;
        this.connected = false;
        this.network.type = null;
        this.appReference.tick();

    });

    this.network.onConnect().subscribe(() => {
        console.log('network connected!');
        this.connectedhide = false;
        let chathis = this;
        this.loginService.compareVersions().then((results)=>{
          if(results != true)
          {
            this.chaprompt();
            //browser.close();


          }

        },(error)=>{
          console.log('version Error',error);
        });

              setTimeout(function() {
                  chathis.connectedhide = true;
              }, 300); // <-- time in milliseconds
            //if (this.network.type === 'wifi') {
                this.connected = true;
                this.appReference.tick();
            
    });
}

async chaprompt(){
      let prompt = await this.alertController.create({
        header: 'Ufuluapp Message',
        message: "Please Upgrade your App Now",
        
        buttons: [
          {
            text: 'Cancel',
            handler: data => {
              console.log('Cancel clicked');
            }
          },
          { 
            text: 'Upgrade',
            handler: data => {
              const browser = this.iab.create(environment.upgrade_url);
              
            }
          }
        ]
      });
      

      console.log('WE ARE HERE');
      //this.alertCtrl.
      await prompt.present();
    }
  

}
