import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ActionSheetController, AlertController, ModalController, NavController } from '@ionic/angular';
import { ChaEventsService } from 'src/app/services/chaevents';
import { ChatService } from 'src/app/services/chat';
import { ContactService } from 'src/app/services/contact';
import { HelperService } from 'src/app/services/helper';
import { LoginService } from 'src/app/services/login';
import {User} from '../../models/user';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PushNotificationService } from 'src/app/services/pushnotification';
import { TransactionData } from 'src/app/models/transaction';

@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss'],
})
export class AddGroupComponent  implements OnInit {
  @Input() group: any;
  form: FormGroup;
  isSubmitted = false;
  groupName: string;
  description: string;
  checked = false;
  checkedCheckboxes: any[] = [];
  alertButton = ['Processing'];
 
  filteredContacts: any[] = []; 
  selectedContacts: any[] = [];
  groupname: string;
  current_user:any = {};
  chatId:any;
  groupdescription: string;
  contacts: any[] = [];
  searchQuery: string = '';
  showSelected: boolean = false;

  constructor(
	  private pushnotification:PushNotificationService,
    private fb: FormBuilder,
    public events: ChaEventsService,
    public activatedRoute: ActivatedRoute,
    private chatService: ChatService, 
    public viewCtrl : ModalController,
    private navCtrl: NavController, 
    public  contactService : ContactService,
    private helper : HelperService,
    private alertCtrl : AlertController,
    private loginService : LoginService,
    public actionSheetController: ActionSheetController
  ) { 

    this.events.userLogin().subscribe(data => {
      //console.log('see u user:',data);
      this.current_user = data.user as User;
    });
    this.loginService.checkIfloggedIn().then((user)=>{
      //console.log('check user details in here', user);
       this.current_user = user as User;
    },(error)=>{
        console.log('check user failed in here', error);
    });

	this.chatId = activatedRoute.snapshot.params['chatId'];
  this.contacts = this.contactService.contacts;
  this.filteredContacts = this.contacts;

  }

  ngOnInit() {
    this.form = this.fb.group({
      groupName: ['', Validators.required],
      description: ['']
    });
  }

  ionViewDidEnter(){
  }

  dismiss(transactionData) {
    this.viewCtrl.dismiss();
 }

 contactsPic(data){
  return this.helper.baseUrl(data) + data;
}

getCheckedBoxes(contact) {
 console.log("checked id", contact);
}

filterContacts() {
  this.filteredContacts = this.contacts.filter(contact =>
      contact.name.toLowerCase().includes(this.searchQuery.toLowerCase())
  );
}

contactSelected($event) {
  const id = $event.target.value;
  const name = $event.target.name;
  const isChecked = $event.target.checked;

  let user = {
    id :this.current_user._id,
    name : this.current_user.name,
    select : isChecked
  }
  
  let group1 = {
    id: id,
    name: name,
    select: isChecked 
  };

  if (isChecked) {
    if (!this.selectedContacts.some(contact => contact.id === user.id)) {
      this.selectedContacts.push(user);
    }
    if (!this.selectedContacts.some(contact => contact.id === group1.id)) {
      this.selectedContacts.push(group1);
    }
  } else {
    const index = this.selectedContacts.findIndex(contact => contact.id === id);
    if (index !== -1) {
      this.selectedContacts.splice(index, 1);
    }
  }
  
    const anyChecked = this.selectedContacts.some(contact => contact.select);
    this.showSelected = anyChecked;
}

submitForm() {
  if (this.groupname === '' ) {
    this.presentAlert('Information Notification', 'Validation Error', 'Group Name cannot be empty');
  } else {
    let groupData = {
      creatorid: this.current_user._id,
      check: this.selectedContacts,
      name: this.form.value.groupName,
      desc: this.form.value.description
    };
    // Save the group data
    this.chatService.saveGroup(groupData).then((results) => {
      //console.log('Saved group ::::::::::::::::::', results);
      this.dismiss({message:'group created successfully',group_id:results['_id'],chatid:results['chatid']})
    }).catch((error: any) => {
      console.error('Error saving group', error);
    });

  }
  this.closeActionSheet();
  this.goToChats();
  // this.dismiss(TransactionDatadata);
}
goToChats() {
    // this.chatId = this.chatService.groupListing;
  }


async presentAlert(title,subtitle,message)
  {
    const alert = await this.alertCtrl.create({
        header: title,
        subHeader: subtitle,
        message: message,
        buttons: ['OK']
      });

      await alert.present();
  }

  openActionSheet() {
    document.getElementById('actionSheet').classList.remove('hidden');
  }

  closeActionSheet() {
    document.getElementById('actionSheet').classList.add('hidden');
  }
}
