import { Component, OnInit } from '@angular/core';
import {ITimer} from "../../models/itimer";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavController,NavParams,AlertController,ModalController, LoadingController } from '@ionic/angular';

import { LoginService } from '../../services/login';
import { ChaEncryptionService} from '../../services/chaencryption';
import {  MoneyService } from '../../services/money';
import { HelperService } from '../../services/helper';
import {Config} from '../../../app/config';
import {Payment} from '../../models/payment';
import {VerificationComponent} from '../../verification/verification.component';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.scss'],
})
export class AddServiceComponent implements OnInit {



  submitForm: FormGroup;
	phonenumber:any;
	verificationId:any;
	ErrorMsg:any;
	data:any = {msg:null}
	maxtime: any;
  chaCurrencyb:string;
  paymentMethod:Payment;
   services:any;
   toggle:boolean=false;
   toggle_index:any;
   chaprefix = Config.phoneDefaultPrefix;
    message:any = 'processing...';
    chadata:any;

	

  constructor(public navCtrl: NavController, 
  		public navParams: NavParams,
  		public viewCtrl: ModalController,
  		public formBuilder: FormBuilder,
  		private loginService: LoginService,
      public alertCtrl: AlertController,
      public encryptionService: ChaEncryptionService,
      public moneyService: MoneyService,
      private helperService:HelperService,
      public modalCtrl: ModalController,
       public loadingCtrl: LoadingController,
       public alertController: AlertController,
      ) {
  		//form validator
      this.chaCurrencyb = Config.chaCurrency;
      this.maxtime = 30;
      this.paymentMethod = {method:'Mobile Money',provider:'Airtel Money',account:'0993684680',limit:1000000};

  		//this.phonenumber = this.navParams.get('phoneNumber');
  		//this.verificationId = this.navParams.get('verificationId');


  		this.ErrorMsg = '';
  		//console.log("here is phone: " +  this.phonenumber);
  		//console.log("here is verificatinId: " +  this.verificationId);


  	    this.submitForm = formBuilder.group({
        phone: ['', Validators.compose([Validators.required,Validators.minLength(9)])],
        prefix: [this.chaprefix, Validators.compose([Validators.maxLength(3)])],
        account: ['', Validators.compose([ Validators.required])]
        
      });

  	}

  	ngOnInit() {
  		this.getService();

  	}

 async getMoney(){
  		let data = this.submitForm.value;
  		let moneyData = {code:data.code}
  		
      let prompt =  await this.alertCtrl.create({
        header: 'Ufulupay PinCode',
        message: "Enter your Ufulupay secure Pin",
        inputs: [
          {
            name: 'ufulupayPin',
            placeholder: 'Enter Pin Here',
            type: 'password'
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            handler: data => {
              console.log('Cancel clicked');
            }
          },
          {
            text: 'Submit',
            handler: data => {
              console.log(this.loginService.user.username);
              let encryptedPin = this.encryptionService.encrypt(data.ufulupayPin);
              let sentData = {pin:encryptedPin,username:this.loginService.user.username};
              this.loginService.verifyPin(sentData).then((data) => {
                this.data = data;
                console.log(this.data);
                if(this.data.msg == 'Exists')
                {
                   console.log('it exits');
                   //this.moneyService.topUpMoney(moneyData).then((data) =>{
                     //console.log(data);

                   //});

                }
                else{
                  this.ErrorMsg = data;
                }
              });

                

              
              console.log(encryptedPin);
            }
          }
        ]
      });
      await prompt.present();
  
    }

    resendCode()
    {
    	let data = {verificationId:this.verificationId,username:this.phonenumber}
    	this.loginService.resendCode(data).then(() => {

      		
    	}, data => {
    		console.log(data);
    	});

    	

    }

	
	dismiss() {
	   this.viewCtrl.dismiss();
	}

	home() {
	//this.navCtrl.push('Home');
	}
  

  addService(index){
    this.toggle_index = index;
    this.toggle = !this.toggle;

  }
	

  getService(){
  this.moneyService.getMoneyService().then((result)=>{
    //console.log('ll',result);
      this.services = result['msg'];

  });
}

changeToggle(index){

  //console.log('follow Index:',index);

  if(this.toggle_index == index && this.toggle == true){
    return true;
  }
  else
  {
    return false;
  }

}



async getVerificaiton(accountResult){
	let modal = await this.modalCtrl.create(
    	{ component: VerificationComponent,
    	  componentProps:{phoneNumber: '+'+this.data.account_name,verificationId:accountResult['verificationId']}
    	});
    modal.present();
   	let { data } = await modal.onDidDismiss();

   	console.log('check add service verfied', data);

   	if(data == 'Verified')
        {
          	let updateData = {
	            id: accountResult['id']
	          }
	          console.log('check ID',accountResult);
	          this.updateAccount(updateData);
        }
          

      await modal.present();
}

async loading(data){

    let loading = await this.loadingCtrl.create({
      cssClass: 'my-custom-class',
      message: 'Please wait...'
      
    });
    
    await loading.present().then(()=>{

  this.moneyService.createAccount(data).then((result)=>{
    loading.dismiss();
      let accountResult = result['msg'];
      console.log('account created',result);
      this.getVerificaiton(accountResult);

  },(err)=>{

    console.log('check Error for servic', err);
    let message = err.error;
    console.log('message',message);
    loading.dismiss();
    this.presentAlert('Error Notification',message.msg);
    
    this.submitForm.reset();


  });


  });
}

save(service_id){

	console.log('charlie:', service_id);
  let accountdata = this.submitForm.value;
  let data = {
    userid :this.loginService.user._id,
    service_id: service_id,
    account_alias: accountdata.account,
    account_name: accountdata.prefix + accountdata.phone,

  }

  this.chadata = data;
  this.loading(data);

  
  



}


updateAccount(data){
 this.moneyService.updateAccount(data).then((result)=>{
   this.viewCtrl.dismiss('success');

 },(err)=>{

 	console.log('failed to update', err);

 });

}

matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
      return (group: FormGroup): {[key: string]: any} => {
        let password = group.controls[passwordKey];
        let confirmPassword = group.controls[confirmPasswordKey];

        if (password.value !== confirmPassword.value) {
          return {
            mismatchedPasswords: true
          };
        }
      }
    }


	

async presentAlert(header,message) {
    const alert = await this.alertController.create({
      header: 'Notice',
      subHeader: header,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

}
