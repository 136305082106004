// manages contacts

import { Injectable, ApplicationRef } from '@angular/core';
import { SocketService } from './socket';
//import { Events } from '@ionic/angular';
import {ChaEventsService} from '../services/chaevents';
//import { LoginService} from '../services/login';
import { Storage } from '@ionic/storage';
import { RestProvider } from './rest/rest';
@Injectable()
export class ContactService {
	contacts = []
	public user = null
	chatoken:any;

	// manages contacts online status
	constructor(
		private ref: ApplicationRef, 
		public socket: SocketService, 
		public events: ChaEventsService,
		public storage:Storage,
		public restProvider: RestProvider,
		) {


		this.storageInit();

		

		// triggered after a successfull login
		this.events.userLogin().subscribe(data => {
			
			let self = this;
			this.user = data.user;
			//console.log('CONTACTS USER,',this.user);


				// triggered when a contact comes online
			this.socket.on('online', contact => {
				contact.online = true;
				self.setOnlineUsers([contact],this.user);
			});

			// triggered when a contact goes offline
			this.socket.on('offline', contact => {
				contact.online = false;
				this.setOnlineUsers([contact],this.user);
			});


			// triggered when we request new contacts
			this.socket.on('contacts', contacts => {
				//console.debug('Contacts: ', contacts)
				this.setOnlineUsers(contacts,this.user);
			});

		});
		
		

		// triggeres after a logout event
		// note: this does not break the socket connection
		/*this.events.userLogout().subscribe( () => {
			this.user = null;
		});*/
	}


	async storageInit(){
     await this.storage.create();

     this.storage.get('token').then((val) => {
			//console.log('testing in22');
			//console.log(val)

			if(val != null && val.length != 0)
			{
				this.chatoken = val;
			}
		},(error)=>{
			//console.log('NO token:::::::',error);
		});

  }

	// set online users
	private setOnlineUsers(contacts,user) {
		//console.log('SET ONLINE USERS', this, contacts, user);

		// i think this happens when user is notified that they are online and we havent recieved the login thing yet
		if (!user) {
			//console.error('No user yet for contats');
			return;
		}

		for (var x in contacts) {
			if (contacts[x].id == user.id) {
				continue;
			}
			var add = true;
			for (var xx in this.contacts) {
				if (this.contacts[xx].id == contacts[x].id) {
					// dont overwirte fields with empty data
					for (var xxx in contacts[x]) {
						this.contacts[xx][xxx] = contacts[x][xxx];
					}
					add = false;
					break;
				}
			}
			if (add) {
				this.contacts.push(contacts[x]);
			}
		}
		this.contacts.sort(this.sortContacts);
		//console.debug('Contacts: ', this.contacts)
		this.ref.tick();
	};

	// sort contacts by online and last message
	private sortContacts(a, b) {
		if (!a) {
			return -1;
		}
		if (!b) {
			return 1;
		}
		if (a.online > b.online) {
			return -1;
		}
		if (a.online < b.online) {
			return 1;
		}
		if (new Date(a.lastDate) > new Date(b.lastDate)) {
			return -1;
		}
		if (new Date(a.lastDate) < new Date(b.lastDate)) {
			return 1;
		}
		return 0;
	};

	// get a contact and its details
	public get(id) {
		//console.debug('getting contact on logout', id, this.user)
		if (id == this.getUserId()) {
			return this.user;
		}
		for (let contact of this.contacts) {
			if (contact.id == id) {
				return contact;
			}
		}
		return {
			id: id
		};
	}

	parseJwt (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        };

	public getUserId(){

		if(this.user)
		{
			return this.user._id;
		}
		else
		{
			//console.log('TOKEN',this.chatoken);
			this.user = this.parseJwt(this.chatoken.token);
			return this.user._id;

		}
	}
	public getUser(){

		if(this.user)
		{
			return this.user;
		}
		else
		{
			//console.log('TOKEN',this.chatoken);
			this.user = this.parseJwt(this.chatoken.token);
			return this.user;

		}
	}


	checkIfContact(fromid,contactid){

		let data = {
				fromid:fromid,
				contactid:contactid
				
			}


			return new Promise((resolve,reject) => {
				this.restProvider.authSendToApi(data,'api/check_if_contact').then((result)=>{
					
					resolve(result['msg']);
					},(err) => {
						reject(err);
						console.log(err);
					}
				);

				//resolve('');

			});
			

	}
}