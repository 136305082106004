import {ITimer} from '../models/itimer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {  NavController,AlertController,ModalController,NavParams } from '@ionic/angular';
import { MoneyService } from '../services/money';
import { LoginService} from '../services/login';
import { ChatService} from '../services/chat';
import { ChaEncryptionService} from '../services/chaencryption';
import {Config} from '../../app/config';
import {Payment} from '../models/payment';
//import { ModalController } from '@ionic/angular';
import {PaymentMethodsComponent} from '../common/payment-methods/payment-methods.component';
import {PaymentIntegrationComponent} from '../common/payment-integration/payment-integration.component';
import { Component, ViewChild,OnInit } from '@angular/core';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';

declare var
  cordova:any,
  window:any;

@Component({
  selector: 'app-top-up',
  templateUrl: './top-up.component.html',
  styleUrls: ['./top-up.component.scss'],
})
export class TopUpComponent implements OnInit {
	submitForm: FormGroup;
	phonenumber:any;
	verificationId:any;
	ErrorMsg:any;
	data:any = {msg:null}
	maxtime: any;
  chaCurrencyb:string;
  paymentMethod:Payment;
  backdropDismiss = false;
  showBackdrop = false;
  shouldPropagate = false;
  chatitle:any;
  currencyCode:any ='';



 

 
  constructor(public navCtrl: NavController, 
  		public viewCtrl: ModalController,
  		public formBuilder: FormBuilder,
  		private loginService: LoginService,
      public alertCtrl: AlertController,
      public encryptionService: ChaEncryptionService,
      public moneyService: MoneyService,
      public modalController:ModalController,
      private callNumber: CallNumber,
      public navParams: NavParams,) {
  		//form validator
      this.chaCurrencyb = Config.chaCurrency;
      this.maxtime = 30;
      //this.paymentMethod = {method:'Mobile Money',provider:'Airtel Money',account:'0993684680',limit:1000000};

  		let titledata = this.navParams.get('title');
      console.log('TITLE',titledata)

      if(titledata ==='refund')
      {
        console.log('entered here');
        this.chatitle = 'withdraw';
         console.log('entered here', this.chatitle);
      }
      else
      {
        console.log('out here');

        this.chatitle = titledata;
      }
  		//this.verificationId = this.navParams.get('verificationId');


  		this.ErrorMsg = '';
  		//console.log("here is phone: " +  this.phonenumber);
  		//console.log("here is verificatinId: " +  this.verificationId);


  	    this.submitForm = formBuilder.group({
        code: ['', Validators.compose([Validators.required,Validators.maxLength(7),Validators.pattern('^\\d+$')])]
    	});
  	}

  	 ngOnInit() {}

     onSuccess(result){
  console.log("Success:"+result);
  this.presentAlert('my number',result);
}

onError(result) {
  console.log("Error:"+result);
  this.presentAlert('my number ERR',result);

}

   verifyNumber(){

     let ussdCode = '*500#';
     window.plugins.CallNumber.callNumber(this.onSuccess, this.onError, ussdCode, true);

/*
     this.callNumber.callNumber(ussdCode, true)
    .then(res => {
      console.log('Launched dialer!', res);
      this.presentAlert('my number',res);
    })
    .catch(err => {
      console.log('Error launching dialer', err);

      this.presentAlert('my number ERR',err);
    });*/

     

   }

  getMoney(){
  		let data = this.submitForm.value;
  		let moneyData = {amount:data.code,alias:'Topup'}


  		//this.selectPayment(moneyData);
     // this.verifyNumber();
     this.dismiss(moneyData);
      
  
    }


    getMoneyb(){
      let data = this.submitForm.value;
      let moneyData = {amount:data.code,alias:'Topup'}


      this.selectPayment(moneyData);
      
  
    }

    async selectPayment(moneyData){

      this.backdropDismiss = false;
      this.showBackdrop = true;
      this.shouldPropagate = false;

      const modal = await this.modalController.create(
      {
      	component: PaymentMethodsComponent,
    	  componentProps:{data:moneyData}
      });


      modal.present();
   	let { data } = await modal.onDidDismiss();
   		this.backdropDismiss = false;
        this.showBackdrop = false;
        this.shouldPropagate = false;

	if(data){
          //this.viewCtrl.dismiss('success');
          console.log('check data after dismis', data);
          this.pin(data,moneyData);
          
        }

      
      
      return await modal.present();
    }


    


    async pin(chadata,moneyData){
      let prompt = await this.alertCtrl.create({
        header: 'Ufulupay PinCode',
        message: "Enter your Ufulupay secure Pin",
        inputs: [
          {
            name: 'ufulupayPin',
            placeholder: 'Enter Pin Here',
            type: 'password'
          },
        ],
        buttons: [
          {
            text: 'Cancel',
            handler: data => {
              console.log('Cancel clicked');
            }
          },
          {
            text: 'Submit',
            handler: data => {
              console.log(this.loginService.user.username);
              let encryptedPin = this.encryptionService.encrypt(data.ufulupayPin);
              let sentData = {pin:encryptedPin,username:this.loginService.user.username};
              console.log('verifyPIN::',sentData);
              this.loginService.verifyPin(sentData).then((data) => {
                this.data = data;
                console.log(this.data);
                if(this.data.msg == 'Exists')
                { 



                  //this.PaymentIntegration(chadata);
                  this.dpoToken(chadata,moneyData);
                  /*
                   console.log('it exits'); 
                   this.moneyService.topUpMoney(moneyData,chadata).then((data) =>{
                     console.log('successfully topped up',data);
                     	this.dismiss('success');

                   }, (error)=>{
                     this.dismiss('fail');
                   });*/


                }
                else{
                  console.log('ERROR:',data);
                	if(this.data.msg == 'Not Exist')
                	{
                		this.ErrorMsg = 'User Does Not Exist';
                	}
                	else{
                		this.ErrorMsg = this.data.msg;
                	}
                  
                }
              },(error)=>{
                this.ErrorMsg = error;

              });

                

              
              console.log(encryptedPin);
            }
          }
        ]
      });
      

      console.log('WE ARE HERE');
      //this.alertCtrl.
      await prompt.present();
    }

    dpoToken(userData,moneyData){
      //create a DPO token
      this.moneyService.paymentGatewayToken(moneyData,this.loginService.user._id).then((results)=>{
        console.log('AUTH TOKEN DPO:',results);
        let DPOtoken = results['msg']['data']['API3G']['TransToken'];
        let chaurl = results['msg']['url'];
         this.PaymentIntegration(DPOtoken,chaurl);

      },(error)=>{
        console.log('error dpo',error);
        this.presentAlert('Ufulu payment Service','Sorry Payment Service not Available at the moment, please try again later');
      });


    }

    async PaymentIntegration(tokenData,url){

      this.backdropDismiss = false;
      this.showBackdrop = true;
      this.shouldPropagate = false;

      const modal = await this.modalController.create(
      {
        component: PaymentIntegrationComponent,
        componentProps:{data:tokenData,url:url}
      });


      modal.present();
     let { data } = await modal.onDidDismiss();
       this.backdropDismiss = false;
        this.showBackdrop = false;
        this.shouldPropagate = false;

  if(data){
          //this.viewCtrl.dismiss('success');
          console.log('check data after dismis', data);
          //this.pin(data,moneyData);
          
        }

      
      
      return await modal.present();
    }

    resendCode()
    {
    	let data = {verificationId:this.verificationId,username:this.phonenumber}
    	this.loginService.resendCode(data).then(() => {

      		
    	}, data => {
    		console.log(data);
    	});

    	

    }

	

	dismiss(data) {
	   this.viewCtrl.dismiss(data);
	}

	home() {
	//this.navCtrl.push('Home');
	}

  checkVisible(){
    return this.showBackdrop;
  }


  async presentAlert(headerb,message) {
      const alert = await this.alertCtrl.create({
        header: 'Notice',
        subHeader: headerb,
        message: message,
        buttons: ['OK']
      });

      await alert.present();
    }
	

}
