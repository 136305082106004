import { Injectable,ViewChild} from '@angular/core';
import { Platform, NavController,LoadingController} from '@ionic/angular';
import {AudioService} from './audio';
import {SocketService} from './socket';
import {LoginService} from './login';
import {ChatService} from './chat';
import { Storage } from '@ionic/storage';
import { Config } from '../config';
import {marked} from 'marked';
import { RestProvider } from './rest/rest';
import {Transaction} from '../models/transaction';
import {ChaEventsService} from '../services/chaevents';
//import { ChatPage } from '../pages';


@Injectable()
export class PushNotificationService {

	


	constructor(
	 public audio:AudioService,
	 public socket:SocketService,  
	 public storage:Storage,
	 private chatService:ChatService,
	 private loginService:LoginService,
	 public restProvider: RestProvider,
	 private events: ChaEventsService,
	 public loadingCtrl: LoadingController) {
	 	
		
		
	} 



	public savePushNotificationToken(chadata){
		let data ={tokenData:chadata}
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/pushnotification-token').then((result)=>{
				console.log('pushnotification token',result);
				resolve(result['msg']['Results']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public saveCurrentPage(chatid,userid){
		let data ={chatId:chatid,userId:userid}
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/currentpage').then((result)=>{
				console.log(' currentpage',result);
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

		});
	}

	public exitCurrentPage(chatid,userid){
		let data ={chatId:chatid,userId:userid}
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/exitcurrentpage').then((result)=>{
				console.log(' exitcurrentpage',result);
				resolve(result['msg']);
				},(err) => {
					//reject(err);
					resolve('');
					console.log(err);
				}
			);

		});
	}

	





}
