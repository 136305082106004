/**
 * Ionic 4 Spotify app starter - Ion Spotify (https://store.enappd.com/product/ionic-4-spotify-app-starter)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */
import { Component, OnInit, Input } from '@angular/core';
import { SpotifyService } from '../../../services/spotify.service';
import { SearchartistComponent } from '../searchartist/searchartist.component';
import { Router } from '@angular/router';
import { NavParams } from '@ionic/angular';

@Component({
  selector: 'app-addsongslistmodal',
  templateUrl: './addsongslistmodal.component.html',
  styleUrls: ['./addsongslistmodal.component.scss'],
})
export class AddsongslistmodalComponent implements OnInit {
  @Input() value: any;
  slideOpts = {
    initialSlide: 0,
    speed: 400,
    effect: 'coverflow',
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: 'auto',
    coverflowEffect: {
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    },
  };
  slideSongsList: ({ title: string; songs: { 'title': string; image: string; 'artist': string; }[]; 'description'?: undefined; } | { title: string; songs: { 'title': string; image: string; 'artist': string; }[]; 'description': string; })[];
  comingFrom: string;
  constructor(
    public serviceProvider: SpotifyService,
    public route: Router,
    public navParam: NavParams
  ) {
    this.comingFrom = this.navParam.get('value');
    console.log(this.comingFrom);
    this.slideSongsList = this.serviceProvider.songsList;
  }

  ngOnInit() { }
  async searchInputClicked() {
    const modal = await this.serviceProvider.loadModal(SearchartistComponent);
    modal.present();
    this.serviceProvider.modalCtrl.dismiss();
  }
  closeModal() {
    this.serviceProvider.modalCtrl.dismiss();
    if (this.comingFrom === 'songsPage') {
      this.route.navigate(['tabs/songslist']);
    } else {
      this.route.getCurrentNavigation();

    }
    this.serviceProvider.fromSongs = 'slide_Songs';
  }
  async openModalPlaylist(dataValue: { title: string; artist: string; image: string; }, index: number) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.slideSongsList.length; i++) {
      if (this.slideSongsList[i].songs[index].title === dataValue.title && this.slideSongsList[i].songs[index].artist === dataValue.artist && this.slideSongsList[i].songs[index].image === dataValue.image) {
        this.slideSongsList[i].songs.splice(index, 1);
        const toast = await this.serviceProvider.showToast('Added to your playlist');
        await toast.present();
      }
    }
    console.log(dataValue, index);
  }
}
