import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { ModalController, NavController, NavParams } from '@ionic/angular';
import { PushNotificationService } from 'src/app/services/pushnotification';
import { environment } from 'src/environments/environment';
import {User} from '../../models/user';

@Component({
  selector: 'app-prof-img',
  templateUrl: './prof-img.component.html',
  styleUrls: ['./prof-img.component.scss'],
})
export class ProfImgComponent  implements OnInit {
  placeholder:any = '/assets/img/avatar/1-81-02.svg';
  profImg : any;
  image: any;
  type: any;
  id: any;
  user:User;
  name : any;
  constructor(
	  private pushnotification: PushNotificationService,
    private navCtrl: NavController, 
    private modalController: ModalController,
    public params: NavParams,
    ) {
      this.profImg = this.params.get('image');
      this.type = this.params.get('type');
      this.id = this.params.get('id');
      this.name = this.params.get('user');
    console.log('profile here',this.id,this.name);
    }
  
  ngOnInit() {
  }

  dismissModal() {
    this.modalController.dismiss();
  }
  displayThumbnail() {
    return `data:image/jpeg;base64,${this.profImg}`;
  }


  chat(id){
		this.navCtrl.navigateForward(['/chat',id,'offline']);
    this.dismissModal();
  }

  call(id){

  }
  video(id){

  }
  chatPrev(id){
		// this.navCtrl.navigateForward(['/chat-preview',id,'offline']);
  }

}
