// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* The alert message box */
.chaalert {
  padding: 20px;
  background-color: #f44336; /* Red */
  color: white;
}

.chaalert.success {
  background-color: #04AA6D;
}

.chaalert.warning {
  background-color: #ff9800;
}

/* The close button */
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

/* When moving the mouse over the close button */
.closebtn:hover {
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/app/common/network/network.component.scss"],"names":[],"mappings":"AAAC,0BAAA;AACD;EACE,aAAA;EACA,yBAAA,EAAA,QAAA;EACA,YAAA;AACF;;AAGA;EACI,yBAAA;AAAJ;;AAGA;EACI,yBAAA;AAAJ;;AAGA,qBAAA;AACA;EACE,iBAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AAAF;;AAGA,gDAAA;AACA;EACE,YAAA;AAAF","sourcesContent":[" /* The alert message box */\n.chaalert {\n  padding: 20px;\n  background-color: #f44336; /* Red */\n  color: white;\n  \n}\n\n.chaalert.success {\n    background-color: #04AA6D;\n}\n\n.chaalert.warning {\n    background-color: #ff9800;\n}\n\n/* The close button */\n.closebtn {\n  margin-left: 15px;\n  color: white;\n  font-weight: bold;\n  float: right;\n  font-size: 22px;\n  line-height: 20px;\n  cursor: pointer;\n  transition: 0.3s;\n}\n\n/* When moving the mouse over the close button */\n.closebtn:hover {\n  color: black;\n} "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
