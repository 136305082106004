import { Component, OnInit, Input,NgZone,ViewChild, ElementRef} from '@angular/core';
import { NavController, NavParams, ModalController,AlertController,Platform  } from '@ionic/angular';
import { ChatPage } from '../../chat/chat.page';
import { CallService} from '../../services/call';
import { ContactService} from '../../services/contact';
import { ChatService } from '../../services/chat';
import {HelperService} from '../../services/helper';
import {PushNotificationService} from '../../services/pushnotification';
import { LoginService } from '../../services/login';
import {User} from '../../models/user';
import { PhotoService } from '../../services/photo.service';
import {ChaEventsService} from '../../services/chaevents';
import {ImagePreviewComponent} from '../../common/image-preview/image-preview.component';
import { Capacitor, Plugins } from "@capacitor/core";
import { LocationService } from '../../services/location.service';
const { Geolocation, Toast } = Plugins;

declare var google;


@Component({
  selector: 'app-chamap',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  //@ViewChild(AgmMap) AgmMap: agmMap;
  @ViewChild('mapcanvas', { static: false }) mapElement: ElementRef;
  @ViewChild('info', { static: false }) info: ElementRef;

  @Input() latitude: number;
  @Input() longitude: number;
	
  current_user:any;
  lat: any;
  lng: any;
  watchId: any;
  height = 0;
   map: any;
  address: string;

 
  shapeCoordinates:any = [];
  draggablePolygon:any;
  communityFence:any=[];
  communityName:any;
  residenceName:any;
  user_location:any
  photo:any;
  constructor(
    public ngZone: NgZone, 
    private events:ChaEventsService
    
    ) {
    this.lat = 12.93448;
    this.lng = 77.6192;
    

  }

  
  
	ngOnInit() {

    console.log('finally latitude',this.latitude);
    console.log(' lat',this.lat);
    console.log(' lon',this.lng);

     this.InitMap()
		
	}

  InitMap() {
            let location = new google.maps.LatLng(this.latitude, this.longitude);
            let mapOptions = {
                zoom: 15.5,
                center: location,
                mapTypeId: google.maps.MapTypeId.RoadMap
            };


            let map = new google.maps.Map(document.getElementById('map-canvas'),
                mapOptions);

            let marker = new google.maps.Marker({
                  position: location,
                  map: map,
                  title: 'My residence',
                  draggable:true,
                  animation: google.maps.Animation.DROP,
            });

            let self = this;
            let datab = {lat:marker.getPosition().lat(),lng:marker.getPosition().lng()};
            self.events.coordinatesPublish(datab);

            google.maps.event.addListener(marker, 'dragend', function() 
            {
                //geocodePosition(marker.getPosition());
                console.log('NEW Position|||||||||||||||||||:::::::1',marker.getPosition().lat());
                console.log('NEW Position|||||||||||||||||||:::::::2',marker.getPosition().lng());
                let data = {lat:marker.getPosition().lat(),lng:marker.getPosition().lng()};
                self.events.coordinatesPublish(data);
                //console.log('NEW Position|||||||||||||||||||:::::::',marker.getPosition.lat());

            });

}


 



	

}
