import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'musicmenu',
  templateUrl: './musicmenu.component.html',
  styleUrls: ['./musicmenu.component.scss'],
})
export class MusicMenuComponent {
	

  constructor(public route: Router,) { 


  	
	    
  }

  ngAfterViewChecked() {
    //this.fontFormatting();
  }

  fontFormatting(){
    //console.log('we reached here');
    if(document.querySelector('#my-select').shadowRoot.querySelector('.button-inner') != null)
    {
      document.querySelector('#my-select').shadowRoot.querySelector('.button-inner').setAttribute('style', 'flex-flow: column !important;');
    }
    
    if(document.querySelector('#my-select2').shadowRoot.querySelector('.button-inner') != null)
    {
      document.querySelector('#my-select2').shadowRoot.querySelector('.button-inner').setAttribute('style', 'flex-direction: column !important;');
    }

    if(document.querySelector('#my-select3').shadowRoot.querySelector('.button-inner') != null)
    {
      document.querySelector('#my-select3').shadowRoot.querySelector('.button-inner').setAttribute('style', 'flex-direction: column !important;');
    }

    if(document.querySelector('#my-select4').shadowRoot.querySelector('.button-inner') != null)
    {
      document.querySelector('#my-select4').shadowRoot.querySelector('.button-inner').setAttribute('style', 'flex-direction: column !important;');
    }
  }


  browse(){

    this.route.navigate(['dashboard/apps/ufulumusic']);
    

  }
  search(){
    this.route.navigate(['dashboard/apps/ufulumusic/musictab2']);
    

  }
  library(){
    this.route.navigate(['dashboard/apps/ufulumusic/library']);
    

  }
  premium(){
    this.route.navigate(['dashboard/apps/ufulumusic/premium']);
    

  }

 

}
