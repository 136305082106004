import { NgModule,ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

//import { HttpModule, XHRBackend, RequestOptions, Http } from '@angular/http';
//import {HttpInterceptor} from './services/interceptors/http.interceptor';
//import {  NgMapsCoreModule } from '@ng-maps/core';
//import { AgmCoreModule } from '@agm/core';
//import { AgmCoreModule } from 'itopplus-agm-core';

  
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
//import { Contacts } from '@ionic-native/contacts/ngx';

import { SpinnerDialog } from '@awesome-cordova-plugins/spinner-dialog/ngx';
 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { IonicStorageModule } from '@ionic/storage-angular'; 
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { Toast } from '@awesome-cordova-plugins/toast/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Dialogs } from '@awesome-cordova-plugins/dialogs/ngx';
import { Camera} from '@awesome-cordova-plugins/camera/ngx';
import { ImagePicker } from '@awesome-cordova-plugins/image-picker/ngx';
import { FileTransfer} from '@awesome-cordova-plugins/file-transfer/ngx';
//import { Crop } from '@ionic-native/crop/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { MusicControls } from '@awesome-cordova-plugins/music-controls/ngx';
import { Media, MediaObject } from '@awesome-cordova-plugins/media/ngx';
//import { AutoCompleteModule } from 'ionic4-auto-complete';
import {ChaAppListComponent} from './common/cha-app-list/cha-app-list.component';
import { RestProvider } from './services/rest/rest';
import { RestProviderLocal } from './services/rest/rest.local';
import { AttachmentService} from './services/attachment'
import {AudioService} from './services/audio';
import {CallService} from './services/call';
import {ChatService} from './services/chat';
import {ContactService} from './services/contact';
import {LoginService} from './services/login';
import {SocketService} from './services/socket';
import {VideoService} from './services/video';
import {MoneyService} from './services/money';
import {VendorService} from './services/vendor';
import {AlertService} from './services/alerts';
import {ChaEncryptionService} from './services/chaencryption';
import {HelperService} from './services/helper';
import { UserService} from './services/users';
import {ChaEventsService} from './services/chaevents';
import {ListenerService} from './services/listeners';
import {SparePartsService} from './services/spareparts';
import {SpotifyService} from './services/spotify.service';
import {PushNotificationService} from './services/pushnotification';
import {ProductsService} from './services/products';
import {ChaImageLoaderService} from './services/chaimageloader.service';
import {CommunityService} from './services/community';
import {GeoFenceService} from './services/geofence';
import {SubscriberService} from './services/subscribers';
import {MusicService} from './services/music'




import { FormsModule,ReactiveFormsModule } from '@angular/forms';

import {VerificationComponent} from './verification/verification.component';
import {ResetPasswordComponent} from './common/reset-password/reset-password.component';
import {TimerComponent} from './common/timer/timer.component';
import {RecieveQrcodeComponent} from './common/recieve-qrcode/recieve-qrcode.component';
import {PayComponent} from './common/pay/pay.component';
import {CallComponent} from './common/call/call.component';
import {ServiceComponent} from './common/service/service.component';
import {ContactComponent} from './common/contact/contact.component';
import {TransferModalComponent} from './common/transfer-modal/transfer-modal.component';
import {InquiryModalComponent} from './common/inquiry-modal/inquiry-modal.component';
import {OrderModalComponent} from './common/order-modal/order-modal.component';
import {GeneralOrderModalComponent} from './common/general-order-modal/general-order-modal.component';
import {UserImage} from './common/user-image/user-image.component';
import {NetworkComponent} from './common/network/network.component';
import {MusicMenuComponent} from './common/musicmenu/musicmenu.component';
import {ChaSearchComponent} from './common/cha-search/cha-search.component';
import {ChaSearchLocationComponent} from './common/dynamic-form/layouts/cha-search-location/cha-search-location.component';
import {ChaFormComponent} from './common/dynamic-form/cha-form/cha-form.component';


import {ChatPopOverComponent} from './common/chat-popover/chat-popover.component';
import {PaymentIntegrationComponent} from './common/payment-integration/payment-integration.component';
import {AddCartModalComponent} from './common/addcart-modal/addcart-modal.component';
import {AddressModalComponent} from './common/address-modal/address-modal.component';
import {PaymentPreviewComponent} from './common/payment-preview/payment-preview.component';
import {UserPreviewComponent} from './common/user-preview/user-preview.component';
import {SearchPreviewModalComponent} from './common/searchpreview-modal/searchpreview-modal.component';
import {TaobaoOrderModalComponent} from './common/taobao-order-modal/taobao-order-modal.component';
import { SearchartistComponent } from './microservices/ufulumusic/searchartist/searchartist.component';
import { SongsactionmodalComponent } from './microservices/ufulumusic/songsactionmodal/songsactionmodal.component';
import { ShowcreditsComponent } from './microservices/ufulumusic/showcredits/showcredits.component';
import { PlaylistmodalComponent } from './microservices/ufulumusic/playlistmodal/playlistmodal.component';
import { AddsongsmodalComponent } from './microservices/ufulumusic/addsongsmodal/addsongsmodal.component';
import { AddsongslistmodalComponent } from './microservices/ufulumusic/addsongslistmodal/addsongslistmodal.component';
import {AddCommunityModalComponent} from './common/add-community-modal/add-community-modal.component';
import {CommunityMessageModalComponent} from './common/community-message-modal/community-message-modal.component';
import {CommunityMediaModalComponent} from './common/community-media-modal/community-media-modal.component';
import {CreateSchoolModalComponent} from './common/create-school-modal/create-school-modal.component';

import {CommunityMessageReactionPage} from './common/community-message-reaction/community-message-reaction.page';
import {CommunityMessageReactionModal} from './common/community-message-reaction-modal/community-message-reaction-modal.component';
import {ImageUploaderComponent} from './common/image-uploader/image-uploader.component';
import {ImageUploaderListingComponent} from './common/image-uploader-listing/image-uploader-listing.component';
import {ImagePreviewComponent} from './common/image-preview/image-preview.component';
import {MapComponent} from './common/map/map.component';
import {AddProductModalComponent} from './common/add-product-modal/add-product-modal.component';
import {RegisterCommunityCourierComponent} from './common/register-community-courier/register-community-courier.component';
import {CommunityLocatorComponent} from './common/community-locator/community-locator.component';
import {ChaSliderComponent} from './common/cha-slider/cha-slider.component';
import {JerseyDetailsComponent} from './common/jersey-details/jersey-details.component';
import {ChatPreviewComponent} from './common/chat-preview/chat-preview.component';


import { PhotoPrevComponent } from './common/photo-prev/photo-prev.component';
import { DocsPrevComponent } from './common/docs-prev/docs-prev.component';
import { MediaPreviewComponent } from './common/media-preview/media-preview.component';
import {ChatMessageActionComponent} from './common/chat-message-action/chat-message-action.component';

//import {CommunityMessageDetailsComponent} from './common/community-message-details/community-message-details.component';
//import { IonicSelectableModule } from 'ionic-selectable';
//import { IonicSelectableModule } from '@ionic-selectable/angular';


import {UploadProfilePicComponent} from './upload-profile-pic/upload-profile-pic.component';
import { Storage } from '@ionic/storage';
//import { NgxQRCodeModule } from 'ngx-qrcode3';
//import { MomentModule } from 'angular2-moment';
import { MomentModule } from 'ngx-moment';
import { CommonModule } from '@angular/common';
import {MyCurrencyPipe} from './currency/currency.component';
import {TopUpComponent} from './top-up/top-up.component';
import {PaymentMethodsComponent} from './common/payment-methods/payment-methods.component';
import {AddServiceComponent} from './common/add-service/add-service.component';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import {PinComponent} from './pin/pin.component';
import {CartModalComponent} from './common/cart-modal/cart-modal.component';
import {VendorListComponent} from './vendor-list/vendor-list.component';
import {CompleteTestService} from './services/phonecontacts';
import {CartService} from './services/cart.service';
import {LocationService} from './services/location.service';
import {DataService} from './services/data.service';



//import { JwtModule } from "@auth0/angular-jwt";
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
//import { Storage } from '@ionic/storage';
//import { SocketioModule, SocketIoConfig } from 'ngx-socketio2';
//import { SocketioModule, SocketIoConfig } from 'ngx-socketio2';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { environment } from '../environments/environment';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { PhotoViewer } from '@awesome-cordova-plugins/photo-viewer/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
//import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';


import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';

//import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
//import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
//import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { NativeGeocoder } from '@awesome-cordova-plugins/native-geocoder/ngx';
import { TruncatePipe } from './services/truncate.pipe';
import { AddGroupComponent } from './common/add-group/add-group.component';
import { ProfImgComponent } from './common/prof-img/prof-img.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';

/*const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};*/
//import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';


/*export function httpInterceptorFactory(xhrBackend: XHRBackend, requestOptions: RequestOptions, storage: Storage) {
  return new HttpInterceptor(xhrBackend, requestOptions, storage);
}*/

export function tokenGetter() {
  return localStorage.getItem("token");
}

export function jwtOptionsFactory(storage) {
  return {
    tokenGetter: () => {
      return storage.get('access_token');
    },
    allowedDomains: environment.chaAllowedJWT_Domain,
    disallowedRoutes: environment.chaDisAllowedJWT_Domain,
  }
}

const config: SocketIoConfig = { url: environment.socketbase, options: {transports: ['websocket']} };


//shared module



@NgModule({
  imports: [
    IonicModule.forRoot(),
    //BrowserModule,
    CommonModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    //IonicSelectableModule,
    //PerfectScrollbarModule,
    


  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    UserImage,
    MyCurrencyPipe,
    NetworkComponent,
    MusicMenuComponent,
    ImageUploaderComponent,
    ImageUploaderListingComponent,
    MapComponent,
    ChaSearchComponent,
    ChaSearchLocationComponent,
    ChaFormComponent,
    CommunityLocatorComponent,
    TruncatePipe,
    ChaSliderComponent,
    ChaAppListComponent,
    AddGroupComponent,
    ProfImgComponent,
    
    ChatMessageActionComponent
  ],

  exports: [
    UserImage,
    MyCurrencyPipe,
    NetworkComponent,
    MusicMenuComponent,
    ImageUploaderComponent,
    ImageUploaderListingComponent,
    MapComponent,
    ChaSearchComponent,
    ChaSearchLocationComponent,
    ChaFormComponent,
    CommunityLocatorComponent,
    TruncatePipe,
    ChaSliderComponent,
    ChaAppListComponent,
    AddGroupComponent,
    ProfImgComponent,
    
    ChatMessageActionComponent
  ]

})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [ //services that you want to share across modules
        //SharedService,
        //SharedService2
        //InAppBrowser
        /*{provide: PERFECT_SCROLLBAR_CONFIG,
        useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        }*/
       
      ],
      schemas: [CUSTOM_ELEMENTS_SCHEMA],
    }
  }
}



@NgModule({ declarations: [
        //UserImage,
        AppComponent,
        VerificationComponent,
        TimerComponent,
        RecieveQrcodeComponent,
        PayComponent,
        CallComponent,
        ServiceComponent,
        ContactComponent,
        TransferModalComponent,
        InquiryModalComponent,
        OrderModalComponent,
        GeneralOrderModalComponent,
        UploadProfilePicComponent,
        TopUpComponent,
        PaymentMethodsComponent,
        AddServiceComponent,
        PinComponent,
        VendorListComponent,
        PaymentIntegrationComponent,
        SearchartistComponent,
        SongsactionmodalComponent,
        PlaylistmodalComponent,
        ShowcreditsComponent,
        AddsongsmodalComponent,
        AddsongslistmodalComponent,
        AddCartModalComponent,
        AddressModalComponent,
        CartModalComponent,
        PaymentPreviewComponent,
        ChatPopOverComponent,
        UserPreviewComponent,
        SearchPreviewModalComponent,
        TaobaoOrderModalComponent,
        AddCommunityModalComponent,
        CommunityMessageModalComponent,
        CommunityMediaModalComponent,
        CommunityMessageReactionPage,
        CommunityMessageReactionModal,
        CreateSchoolModalComponent,
        ImagePreviewComponent,
        AddProductModalComponent,
        RegisterCommunityCourierComponent,
        ResetPasswordComponent,
        JerseyDetailsComponent,
        ChatPreviewComponent,
        PhotoPrevComponent,
        DocsPrevComponent,
        MediaPreviewComponent
        //ChaSearchComponent
    ],
    //schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    exports: [
    //UserImage
    ], imports: [BrowserModule,
        FontAwesomeModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        AppRoutingModule,
        //PerfectScrollbarModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [Storage]
            }
        }),
        //HttpModule,
        FormsModule,
        ReactiveFormsModule,
        PdfViewerModule,
        //AutoCompleteModule,
        //NgxQRCodeModule,
        NgxQRCodeModule,
        NgxPermissionsModule,
        SharedModule.forRoot(),
        SocketIoModule.forRoot(config),
        /*SocketioModule.forRoot({
          url: environment.socketbase,
          options: {
           
          }
        }),*/
        /*AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCr__DHauakWcBSsAkIotQOfa3IMLucrmk',
        })*/
        ], 
        providers: [
        StatusBar,
        SplashScreen,
        SpinnerDialog,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        NativeAudio,
        Camera,
        ImagePicker,
        FileTransfer,
        MediaCapture,
        File,
        //Crop,
        //OneSignal,
        AttachmentService,
        LocationService,
        LoginService,
        AudioService,
        SocketService,
        CallService,
        ContactService,
        VideoService,
        ChatService,
        StatusBar,
        SplashScreen,
        MoneyService,
        VendorService,
        AlertService,
        ChaEncryptionService,
        HelperService,
        UserService,
        CompleteTestService,
        ChaEventsService,
        ListenerService,
        SubscriberService,
        NgxPermissionsService,
        SparePartsService,
        SpotifyService,
        PushNotificationService,
        ProductsService,
        CartService,
        ChaImageLoaderService,
        DataService,
        RestProvider,
        RestProviderLocal,
        BarcodeScanner,
        Toast,
        InAppBrowser,
        Dialogs,
        CallNumber,
        //Contacts,
        Diagnostic,
        MusicControls,
        Media,
        PhotoViewer,
        Network,
        Geolocation,
        NativeGeocoder,
        CommunityService,
        GeoFenceService,
        MusicService,
        { provide: ErrorHandler, useClass: ErrorHandler },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {

  constructor(library: FaIconLibrary) { 
    library.addIconPacks(fas, fab, far);
  }

}
