// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#map {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

agm-map {
  height: calc(100vh - 164px);
}

agm-map .gm-svpc {
  display: none;
}

ion-row {
  height: 64px;
  align-items: center;
  background: black;
  color: white;
}
ion-row ion-col {
  text-align: center;
}

#map_canvas {
  width: 90%;
  height: 80%;
  border: 1px solid red;
}

#address {
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
}

#map {
  width: 100%;
  height: 70vh;
}

.map-wrapper {
  position: relative;
}
.map-wrapper #map_center {
  position: absolute;
  z-index: 99;
  height: 40px;
  width: 40px;
  top: 50%;
  left: 50%;
  margin-left: -21px;
  margin-top: -41px;
}`, "",{"version":3,"sources":["webpack://./src/app/common/add-community-modal/add-community-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAGE;EACE,aAAA;AAAJ;;AAGA;EACE,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,YAAA;AAAF;AACE;EACE,kBAAA;AACJ;;AAIA;EACE,UAAA;EACA,WAAA;EACA,qBAAA;AADF;;AAKA;EACE,aAAA;EACA,eAAA;EACA,iBAAA;AAFF;;AAKA;EACE,WAAA;EACA,YAAA;AAFF;;AAKA;EACE,kBAAA;AAFF;AAIE;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,QAAA;EACA,SAAA;EACA,kBAAA;EACA,iBAAA;AAFJ","sourcesContent":["#map {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\nagm-map {\n  height: calc(100vh - 164px);\n}\n\nagm-map {\n  .gm-svpc {\n    display: none;\n  }\n}\nion-row {\n  height: 64px;\n  align-items: center;\n  background: black;\n  color: white;\n  ion-col {\n    text-align: center;\n  }\n}\n\n\n#map_canvas {\n  width: 90%;\n  height: 80%;\n  border: 1px solid red;\n}\n\n\n#address {\n  padding: 10px;\n  font-size: 18px;\n  font-weight: bold;\n}\n\n#map {\n  width: 100%;\n  height: 70vh;\n}\n\n.map-wrapper {\n  position: relative;\n\n  #map_center {\n    position: absolute;\n    z-index: 99;\n    height: 40px;\n    width: 40px;\n    top: 50%;\n    left: 50%;\n    margin-left: -21px;\n    margin-top: -41px;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
