import { Component } from '@angular/core';
import {  NavController, NavParams, ModalController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { UserPreviewComponent } from '../../common/user-preview/user-preview.component';


@Component({
  selector: 'app-community-message-reaction-modal',
  templateUrl: './community-message-reaction-modal.component.html',
  styleUrls: ['./community-message-reaction-modal.component.scss'],
})
export class CommunityMessageReactionModal  {

   likes:any=[];
   type:any;
   user:any;
   total_amount:any;
   currency:any = environment.chaCurrency;
  constructor(private navCtrl: NavController, 
    private navParams: NavParams, 
    private viewCtrl: ModalController,
    public params: NavParams,) {

    this.likes = this.params.get('likes');
    this.type = this.params.get('type');
    this.user = this.params.get('user');
    console.log('HI::',this.likes);

    if(this.type == 'contributions')
    {

      this.total_amount = this.totalAmount(this.likes);

    }


  }

    
  totalAmount(contributions){
    let sum = 0;
    for(let contrib of contributions){
        sum = sum + contrib.amount;


    }

    return sum;


  }
  ionViewDidLoad() {
        console.log('ionViewDidLoad ReactionsPage');
    }

    share(){
        this.viewCtrl.dismiss();
    }


    close(){
      this.viewCtrl.dismiss('');

  }

  contactsPic(data){
    return this.baseUrl(data) + data;
  }

  baseUrl(data){
      if(data){


      let ext = data.split('.');

      if(ext.length > 1)
      {
        return 'assets/img/avatar/';

      }
      else{
        return environment.server + 'uploads/images/';
      }
    }
    

  }


   async details(user) {

     console.log('Check User details react',this.user);
    
      const modal = await this.viewCtrl.create(
      {
        component: UserPreviewComponent,
      componentProps:{user: user, type:'reaction',fromid:this.user._id},
      //cssClass: 'my-custom-modal-css'
      });


      modal.present();
       await modal.onDidDismiss().then((data)=>{
         console.log('It has come back1',data);
         this.viewCtrl.dismiss('');
         if(data['data']=='saved'){

          this.navCtrl.navigateRoot('/dashboard/tab2');

          
        }
        else if(data['data']=='tochat'){

          console.log('It has come back2',data);

          this.viewCtrl.dismiss('');

        }

       });
         
      return await modal.present();
  }
  



}