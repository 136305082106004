import { Component, OnInit, Input} from '@angular/core';
import { NavController, NavParams, ModalController,AlertController } from '@ionic/angular';
import { ChatPage } from '../../chat/chat.page';
import { CallService} from '../../services/call';
import { ContactService} from '../../services/contact';
import { ChatService } from '../../services/chat';
import {HelperService} from '../../services/helper';
import {PushNotificationService} from '../../services/pushnotification';
import { LoginService } from '../../services/login';
import {User} from '../../models/user';
import { PhotoService } from '../../services/photo.service';
import {ChaEventsService} from '../../services/chaevents';
import {ImagePreviewComponent} from '../../common/image-preview/image-preview.component';


@Component({
  selector: 'app-image-uploader-listing',
  templateUrl: './image-uploader-listing.component.html',
  styleUrls: ['./image-uploader-listing.component.scss'],
})
export class ImageUploaderListingComponent implements OnInit {
	@Input() defaultimage: string;
	@Input() defaulttype: string;
  @Input() identifier: string;
  @Input() instructions: string='Click to add Shop Pictures';
	image:any= '';
  images:any='';
  photo:any=[];
  chaloading = 0;
  current_user:any;
  

  constructor(private helpler: HelperService,
  						private loginService:LoginService,
  						public photoService: PhotoService,
  						public event:ChaEventsService,
 							public alertController: AlertController,
 							public modalCtrl: ModalController,

	  						){

    
  	this.event.userLogin().subscribe(data => {
      //console.log('see u user:',data);
      if(data.user != undefined && data.user != '' && data.user !== null)
      {
        this.current_user = data.user as User;
        console.log('user is here:',this.current_user);
        
      }
      else{
        console.log('why is it blank1',data.user);
      }

    });

    //console.log('++++++++++++++++11How to solve this problem +++++++++++++++++++++++++++++++++++++++++++');

    this.loginService.checkIfloggedIn().then((user)=>{
      //console.log('check user details in here!!!!!!!!!!!!!!!!', user);
      if(user != undefined && user != '' && user !== null)
      {
       this.current_user = user as User;
       console.log('user is here1:',this.current_user);

      }
      else{
        console.log('why is it blank',user);
      }

    },(error)=>{
        console.log('check user failed in here', error);
    });




  }

  
	ngOnInit() {

		

this.event.photo().subscribe((photo)=>{
      if(!this.identifier)
      {
        this.photo.push({data:photo,type:'picture'});

      }
      else
      {
        if(this.identifier == photo.identifier){
          this.photo.push({data:photo.data,type:'picture'});

        }
        
      }
      

    });
 
console.log('DEFAULT IMAGE*********************************************************************************',this.defaultimage);

  	if(this.defaultimage != '' && this.defaultimage != null && this.defaultimage != undefined && this.defaultimage.length != undefined){
  		

      if(this.defaulttype == 'picture')
      {
        console.log('CHECK SET OF EDIT PHOTS',this.defaultimage)
        for(let single of this.defaultimage){
          this.photo.push({data:single['data'],type:'picture'});

        }
      }
      else{

        this.images = this.helpler.baseUrl(this.defaultimage) + this.defaultimage;

      } 

  		
  	}
  	else
  	{
  		if(this.defaulttype == 'profile_pic')
  		{
	  		this.loginService.checkIfloggedIn().then((user)=>{
		      //console.log('check user details in here!!!!!!!!!!!!!!!!', user);
		      if(user != undefined && user != '' && user !== null)
		      {
		        let current_user = user as User;
		        this.images =this.helpler.baseUrl(current_user.image) +  current_user.image;
		       

		      }
		      else{
		        console.log('why is it blank',user);
		      }

		    },(error)=>{
		        console.log('check user failed in here', error);
		    });
	  	}
  	}
		
	}
 
	addPhotoToGallery() {

      //console.log('photos here');
      
      if(!this.identifier){
        this.photoService.addNewToGallery().then((results)=>{
          console.log('photo',results);
        });
      }
      else
      {
        this.photoService.addNewToGallery(this.identifier).then((results)=>{
          console.log('photob',results);
        });

      }
      
    }

    async mediaDetails(pic_index,photos){
 
    let modal = await this.modalCtrl.create(
        { component: ImagePreviewComponent,
          componentProps:{pic_index:pic_index,photos:photos, current_user:this.current_user}
        });
      modal.present();
       let { data } = await modal.onDidDismiss();
       console.log('DATA::1',data);
       if(data != '')
          {
   
            this.photo = this.addIdentifier(data);
            console.log('beofre you sen to frontend',this.photo)
            this.event.photoPublish( this.photo);



          }
          else{
            this.event.photoPublish([]);

          }

        return await modal.present();
  }

  addIdentifier(photo){
    let newphoto = []
    for(let pic of photo){
      newphoto.push({data:pic['data'],type:pic['picture'],identifier:this.identifier})

    }
    return newphoto;
  }

  async presentAlert(header,message) {
    const alert = await this.alertController.create({

      header: 'Notice',
      subHeader: header,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

	

}
