import { Injectable } from '@angular/core';
import { Plugins,  Capacitor} from '@capacitor/core';



import { Camera, CameraResultType, CameraPhoto,CameraSource  } from '@capacitor/camera';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

import {Photo} from '../models/photo';
import {ChaEventsService} from '../services/chaevents';
import { RestProvider } from './rest/rest';

//const {  Filesystem, Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class ChaImageLoaderService {
	 
	full:any=[];
  constructor(
  	public events: ChaEventsService,
  	public restProvider: RestProvider,
   ) { }

  public async getImage(imagepath,id) {
  	
  	let thumbnail = {
  		imagesize:'thumbnail',
			imagepath:imagepath,
			id:id
		};
		let medium = {
  		imagesize:'medium',
			imagepath:imagepath,
			id:id
		};
		let chafull = {
  		imagesize:'full',
			imagepath:imagepath,
			id:id
		};

		this.getThumbnail(thumbnail); 
		this.getMedium(medium);
		this.getFull(chafull);

		  

	  

	}


	public async getThumbnail(thumbnail)
	{



  	this.restProvider.authSendToApi(thumbnail,'api/requestImage').then((result)=>{
  		if(this.full != 1){
  			this.events.imagePublish(result['msg']);
  		}

  	});


	}

	public async getMedium(medium)
	{

  	this.restProvider.authSendToApi(medium,'api/requestImage').then((result)=>{
  		if(this.full != 1){
  			this.events.imagePublish(result['msg']);
  		}


  	});

	}

	public async getFull(full)
	{

  	this.restProvider.authSendToApi(full,'api/requestImage').then((result)=>{
  		this.full = 1;
  		this.events.imagePublish(result['msg']);
  	});

	}




	}
