import { Camera, CameraResultType } from '@capacitor/camera';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { FilePicker, PickFilesResult} from '@capawesome/capacitor-file-picker';
import { Chat, SingleChat} from '../../models/chats';
import { ChatService } from 'src/app/services/chat';

@Component({
  selector: 'app-docs-prev',
  templateUrl: './docs-prev.component.html',
  styleUrls: ['./docs-prev.component.scss'],
})
export class DocsPrevComponent  implements OnInit {
  // name = 'Angular ' + VERSION.major;
  //  pdfSource = "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf";

  pdfSource  = "assets/sample.pdf";
  option: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
  videos: boolean = false;
  photos: boolean = false;
  docs: boolean = false;
  photo:any;
  pdf : any;
  doc : any;
  video:any;
  document: any;
  docName : any;
  // pdfSrc: string;
  data = {
    message: null,
    attachment: null,
    type : null,
    time: null
    }
  chat:SingleChat = {
    id: null,
    users: [],
    name: '',
    messages: [],
    // photo:[]
    }
    
  @Input() mediaType: string;
  @Input() userName: string;
  @Input() chatId: string;
  mime: any;

  constructor(
    private chatService: ChatService,
  	public navParams: NavParams,
    public viewCtrl: ModalController
  ) {
    this.document = this.navParams.get('doc');
    this.docName = this.navParams.get('name');
    this.mime = this.navParams.get('mime_type');
    console.log ('the attachmens have',this.mime,this.docName,this.document);
  }

  ngOnInit() {
    this.check_type(this.docName);
    this.updateMediaFlags();
    // if (this.mediaType === 'doc' && this.document.endsWith('.pdf')) {
    //   this.pdfSrc = this.document; // Assign PDF URL to display
    // }
  }

  updateMediaFlags() {
    if (this.mediaType === 'pic') {
      this.photos = true;
      this.videos = false;
      this.docs = false;
    } else if (this.mediaType === 'vid') {
      this.photos = false;
      this.videos = true;
      this.docs = false;
    } else if (this.mediaType === 'doc') {
      this.photos = false;
      this.videos = false;
      this.docs = true;
    }
  }

  
  send() {

    this.data.attachment = this.document;
    this.chatId;

    const docName = this.docName; 
    const extension = this.check_type(docName);

    const timestamp = new Date().toLocaleTimeString([], this.option);
    let data = {
        message: this.data.message,
        attachment: this.data.attachment,
        type: extension,
        time: timestamp
    }
    console.log('extension has-=--=-=-=-',data)
    this.chatService.send(this.chatId, data);
    this.data.attachment = '';

    this.dismiss();
}

check_type(docName) {
    const parts = docName.split('.');
    const extension = parts[parts.length - 1].toLowerCase();
    console.log('extension has',extension)
    return extension;   
}

  dismiss() {
    this.viewCtrl.dismiss();
    this.data.attachment = '';
  }
}