// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timer-button {
  font-size: 5rem !important;
  height: 1em !important;
}

.no-bottom-border.item .item-inner {
  border-bottom: none !important;
}

.hm_sign_resend {
  width: 100%;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/common/add-service/add-service.component.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,sBAAA;AACJ;;AAEE;EACI,8BAAA;AACN;;AAEE;EACE,WAAA;EACA,mBAAA;AACJ","sourcesContent":[".timer-button {\n    font-size: 5rem !important;\n    height: 1em !important;\n  }\n  \n  .no-bottom-border.item .item-inner {\n      border-bottom: none !important;\n  }\n\n  .hm_sign_resend {\n    width: 100%;\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
