import { Component, OnInit } from '@angular/core';
import { ContactService } from 'src/app/services/contact';
import { Config } from '../../../app/config';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-chat-preview',
  templateUrl: './chat-preview.component.html',
  styleUrls: ['./chat-preview.component.scss'],
})
export class ChatPreviewComponent  implements OnInit {
  pic_check:any = [];
  placeholder:any = 'assets/img/avatar/1-81-15.svg';
  chatName: any;
  groupData: any;
  chat : boolean = true;
  group : boolean = false;
  community : boolean = false;
  communityData: any;
  

  constructor(
    public contactService: ContactService,
    public navParams: NavParams,
    public viewCtrl: ModalController
    ) {
      
      this.chatName = this.navParams.get('name');
      this.groupData = this.navParams.get('group');
      this.communityData = this.navParams.get('community')

    console.log('chat in preview for group', this.communityData);
     }

  ngOnInit( 
  ) {
    

    if (this.chatName) {
      this.chat = true;
      this.community = false;
      this.group = false;
    } else if (this.groupData) {
      this.group = true;
      this.chat = false;
      this.community = false;
    } else {
      this.community = true;
      this.group = false;
      this.chat = false;
    }
  
  }

  getProfilePic(user,index){
    let get_contact = this.contactService.get(user);
    this.pic_check.push(get_contact.image);
          
     if(this.baseUrl(get_contact.image)){

       return this.baseUrl(get_contact.image) + get_contact.image;
     }
     else{
       return this.placeholder;
     }
 }
 baseUrl(data){
      if(data){
      let ext = data.split('.');

      if(ext.length > 1)
      {
        return 'assets/img/avatar/';

      }
      else{
        return Config.server + 'uploads/images/';
      }
    }
  }

  dismiss() {
    this.viewCtrl.dismiss();
  }

}
