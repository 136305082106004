// handle login and authenticaion for users

import { Injectable, ViewChild } from '@angular/core';
import { NavController, AlertController, Platform,ModalController } from '@ionic/angular';
import {AudioService} from './audio';
import {SocketService} from './socket';
import {ContactService} from './contact';
import { RestProvider } from './rest/rest';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {ChaEventsService} from '../services/chaevents';
import {HelperService} from '../services/helper'
import {CommunityService} from '../services/community';

//import { LoginService } from '../services/login';

//import { LoginPage } from '../pages';
//import { VerificationComponent } from '../verification/verification.component';

import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';




@Injectable()
export class LoginService {

	user = null
	playLoginSound = true
	complete = null
	userExitResult:any;
	verificationResult:any = {msg:{verificationId:null}};
	charesult:any={msg:null};
	chatoken:any={token:null};

	//private nav:NavController;

	constructor(private contactService: ContactService,
	 public platform: Platform, 
	 public audio: AudioService, 
	 public socket: SocketService, 
	 public events: ChaEventsService, 
	 public alertCtrl: AlertController, 
	 public storage: Storage,
	 public restProvider: RestProvider,
	 public modalCtrl: ModalController,
	 public router:Router,
	 public navCtrl: NavController, 
	 public viewCtrl: ModalController,
	 public formBuilder: FormBuilder,
	 public helper: HelperService,
	 public communityService:CommunityService
	 ) {

		this.complete = this.makeComplete();

		//this.nav = app.getActiveNav();

		// authenticate using jwt once socket is connected
		this.socket.on('connect', socket => {
			this.auth(false);
		});

		// unused
		this.socket.on('disconnect', socket => {
		});

		// triggered when the user logs in

		this.events.userLogout().subscribe(data => {
			if(data)
			{
				this.user = data.user;

			}
			
		});
	}



	public refreshData(){

		return new Promise((resolve,reject)=>{

			
			this.checkIfloggedIn().then((user)=>{
				this.events.userLoginPublish({
					user: user
				});
				this.socket.emit('login', user);
				this.user = user;
				resolve(this.user);

			});
				

		});
			}
	compareVersions(){

		return new Promise((resolve, reject) => {
	    	//console.log('user:',user);
	    	this.restProvider.authorisedGet('','api/getversion').then((result) => {
			 	//console.log('check results for cha');
			    //console.log('Version:',result);
			    let major = result['msg'][0]['major'];
			    let minor = result['msg'][0]['minor'];
			    let patch = result['msg'][0]['patch'];
			    if(environment.version_major == major && environment.version_minor == minor && environment.version_patch == patch)
			    {
			    	resolve(true);
			    }
			    else
			    {
			    	resolve(false);
			    }



			    
			    
			  }, (err) => {
			    //console.log(err);
			    reject(err);
			  })
	    });

	}

	// authenticate a user using jwt
	public auth(force) {
		let complete = token => {
			//console.debug('REAL TOKEN', token);
			if (token) {
				token = token.token;
			}
			//console.debug('token', token);

			if (!this.user) {
				if (!token) {
					this.events.authPublish( false);
					return this.go();
				}
			}

			let done = () => {
				this.socket.removeListener('auth_error', error);
				this.socket.removeListener('login_successful', success);
			};

			let error = message => {
				done();
				this.storage.remove('token');
				this.go();
				//console.log('error auth.');
			};

			let success = (user) => {
				//console.debug('Auth: ', user);
				done();
				if (this.playLoginSound) {
					this.playLoginSound = false;
					this.audio.play('login');
				}
				this.events.userLoginPublish( {
					user: user
				});
			};

			this.socket.emit('auth', token);
			this.socket.on('login_successful', success);
			this.socket.on('auth_error', error);
		};
		this.storage.get('token').then(complete).catch(complete);
	}

	// go to the login page with no transitions
	public go() {
		 this.router.navigate(['/'])
		//this.nav.setRoot(LoginPage, {}, {animate: false})
		//this.nav.push(LoginPage, {}, {animate: false, direction: 'forward'});
	}
	//check if user exists
	public checkIfExist(user)
	{
		//console.log(user);
		// triggered when we request new contacts

	    return new Promise((resolve, reject) => {
	    	//console.log('user:',user);
	    	this.restProvider.sendToApi(user,'api/checkuser').then((result) => {
			 	//console.log('check results for cha');
			    //console.log('Checkuser:',result);
			    this.userExitResult = result;
			    resolve(this.userExitResult);
			    
			    
			  }, (err) => {
			    //console.log(err);
			    reject(err);
			  })
	    });
			

	}
	public signUp(user){
		return new Promise((resolve, reject) => {
	    	this.restProvider.sendToApi(user,'api/signup').then((result) => {
			   // console.log(result);
			    resolve(result);
			  }, (err) => {
			   // console.log(err);
			    reject(err);
			  })
	    });

	}

	public forgotPassword(user){
		return new Promise((resolve, reject) => {
	    	this.restProvider.sendToApi(user,'api/forgotpassword').then((result) => {
			   // console.log(result);
			    resolve(result);
			  }, (err) => {
			   // console.log(err);
			    reject(err);
			  })
	    });

	}

	public verifyCode(data){

		return new Promise((resolve, reject) => {
	    	this.restProvider.sendToApi(data,'api/verifycode').then((result) => {
			   // console.log(result);
			    resolve(result);
			  }, (err) => {
			    //console.log(err);
			    reject(err);
			  })
	    });

	}

	public verifyPin(data){

		return new Promise((resolve, reject) => {
	    	this.restProvider.authSendToApi(data,'api/verifypin').then((result) => {
			    //console.log(result);
			    resolve(result);
			  }, (err) => {
			    //console.log(err);
			    reject(err);
			  })
	    });
		
	}

	public resendCode(data){

		return new Promise((resolve, reject) => {
	    	this.restProvider.sendToApi(data,'api/resendcode').then((result) => {
			   // console.log(result);
			    resolve(result);
			  }, (err) => {
			    //console.log(err);
			    reject(err);
			  })
	    });

	}

	public regenerateCode(data){

		return new Promise((resolve, reject) => {
	    	this.restProvider.sendToApi(data,'api/regeneratecode').then((result) => {
			   // console.log(result);
			    resolve(result);
			  }, (err) => {
			   // console.log(err);
			    reject(err);
			  })
	    });

	}

	parseJwt (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        };

	public authorise(token)
	{
		
		// triggered when we request new contacts
		//console.log('cha token:',  );


	    return new Promise((resolve, reject) => {
	    	if(token == '' && token == null && token == undefined){
	    		resolve('Not Authorised');


	    	}
	    	else
	    	{
		    	this.restProvider.authorisedGet(token,'api/verify').then((result) => {
				 	
				   // console.log('check authorise ::: ',result);
				    this.charesult = result;
				   if(this.charesult.msg['state'] == 'Authorised')
				   {	
				   		//console.log('verified token:',this.charesult.msg['data']);
				   		//this.user = this.parseJwt(token);
				   		this.user = this.charesult.msg['data'];

				   		resolve({state:'Authorised',user:this.user});


				   }
				   else{
				   		resolve('Not Authorised');

				   }
				    
				    
				    
				  }, (err) => {
				    //console.log(err);
				    reject(err);
				  })
		    }

	    });
			

	}

	public checkIfloggedIn(){
		//console.log('testing testing');
	return new Promise((resolve, reject)=>{


		this.storage.get('token').then((val) => {
			//console.log('testing in1');
			//console.log('TOKEN here:',val);

			if(val != null && val.length != 0)
			{
				this.chatoken = val;
				//console.log('chatoken::',this.chatoken);
		    	this.authorise(this.chatoken.token).then((data) => {
		    		//console.log('check cha status logged ', JSON.stringify(data));
			      	if(data == 'Not Authorised' || data == 'Unauthorized'){
			      		//console.log('Not authorised!! in');
			        	this.go();
			        	//return;

			      	}
			      	else{
			        	//console.log('authorised!!CHARLIE', data);
			        	//console.log('data coming user',data['user']);
			        	this.user = data['user'];
			        	resolve(data['user']);
			      	}
			      
			    	},(error) => {
			    		//console.log('see what error it has no backend ', error.message);

			    		if(error.error == 'Not Authorised' || error.error == 'Unauthorized'){
			      		//console.log('Not authorised!!  on error');
			      			this.logout();
			        	//return;

			      		}
			      		else if(this.helper.searchText(error.message,'Http failure') == true)
			      		{
			      			//console.log('this is true to offline mode',this.parseJwt(this.chatoken.token));
			      			//resolve(this.parseJwt(this.chatoken.token));
			      			//resolve('no backend');
			      			this.makeComplete().then((output)=>{
			      				resolve(output);
			      			});



			      		}

			    	});
		    }
		    else
		    {
		    	//console.log('Not authorised!! inner');
		    	this.go();

		    }
		    
			
   
  		},(error)=>{
  			//console.log('testing in');
  			//console.log('Not authorised!! out');
		    	this.go();

  		});

	});


	}


	public userInitialization(user,token){
		return new Promise((resolve, reject) => {
			if (token) {
				this.storage.set('token', {token: token});
				this.storage.set('access_token', token);
				
				//localStorage.setItem('token',token);
			}
					
			this.events.userLoginPublish( {
				user: user
			});

			this.communityService.retrieveAllMyCommunities(user).then((results)=>{
				let communitylist = results;
				let userdata = [];
				let data = {
					key: 'user',
					value: user,
					role: [],
					communities: communitylist
				} 
				userdata.push(data);
				if(communitylist){
					this.socket.channelInitialization(communitylist);
				}
				this.events.dynamicDataPublish(userdata);

				if (this.playLoginSound) {
					this.playLoginSound = false;
					this.audio.play('login');
				}

				resolve(userdata);

				

			},((error)=>{
				reject(error)
			}));
		});

		


	}

	



	// perform a login from the log in page
	public login(user) {
		//console.log('User::',user);
		/*if(this.socket.socket.connected){
			console.log('socket.io is connected.');

		}
		else
		{
			console.log('socket.io NOT is connected.');
		}*/
		
		//this.complete = this.makeComplete();
		return new Promise((resolve, reject) => {
			//console.log('attempt loging',user);
			let done = () => {
				this.socket.removeListener('login_error', cha_error);
				this.socket.removeListener('login_successful', success);
			};

			let cha_error = async (message) => {
				//console.log('ERROR Charlie:',message);
				if(message.message == 'You are already connected.')
				{
					//this.router.navigate(['/dashboard']);
					resolve({message:'already connected',user:message.user});

				}
				else{

					//console.log('ERROR Display',message);

					let alert = await this.alertCtrl.create({
						header: 'Error',
						subHeader: message,
						buttons: ['OK']
					});
					await alert.present();
					done();

				}

				reject('login fail');
			};

			let success = (user, token) => {
				//console.log('SUCCESSS!!!!!: ', user);

				this.userInitialization(user,token).then((results)=>{
					//console.log('userData:',results);
					resolve({token: token,user:user});
					done();
				},(error)=>{
					reject(error);
				})

				
				

				
			};
 
			let verify = async (message) => {

				//let cha_verify = new VerificationComponent(this.navCtrl, this.navParams, this.viewCtrl, this.formBuilder, this)
				let alert = await this.alertCtrl.create({
					header: 'Sorry',
					subHeader: message,
					buttons:[
					        {
					          text: 'Cancel',
					          handler: data => {
					            //console.log('Cancel clicked');
					          }
					        },
					        {
					          text: 'Verify',
					          handler: data => {
					           // console.log('Verify clicked');
					            let verifydata = {username:user.username} 
					            this.regenerateCode(verifydata).then((result) => {
					            	this.verificationResult = result;
					            	//console.log(this.verificationResult);
					            	reject({status:'data',data:{phoneNumber: '+'+user.username,verificationId:this.verificationResult.msg.verificationId,username:user.username}});
							      	/*this.modalCtrl.create({
							      		component:VerificationComponent,
							      		componentProps:{phoneNumber: '+'+user.username,verificationId:this.verificationResult.msg.verificationId}
							      	}).then(modal=>{
							      		modal.onDidDismiss().then((data)=>{
							      			 if(data == 'Verified')
									        {
									        	done();
									          	this.socket.emit('login', user);

									        }

							      		});
							      	});*/


							      	
							      
						    	});

					          }
					        }
					      ]
				});
				alert.present();
				done();

				//reject('login fail');
			}; 

			this.socket.on('login_error', cha_error);
			this.socket.on('login_not_confirmed', verify);
			this.socket.on('login_successful', success);
			//this.socket.emit('login_error', 'testing this');
			this.socket.emit('login', user);
		});
	}

	// log the user out
	public logout() {
		this.playLoginSound = true;
		this.storage.set('token', {token: ''})
		this.storage.remove('token');
		this.storage.remove('user');
		localStorage.removeItem('token');
		this.user = null;

		//community reset
		this.communityService.communitylist = null;
		this.communityService.communitydata = {};
		this.communityService.communityStatus = {};
		this.communityService.latitude = '';
		this.communityService.longitude = '';

		//$rootScope.contacts.length = 0;
		this.contactService.contacts.length = 0;
		this.events.userLogoutPublish(null);
		this.socket.emit('logout', null);
		this.complete = this.makeComplete();
		this.go();
	}

	// a promise that fires once we have logged in
	// used by controllers
	public makeComplete() {
		let self = this;
		return new Promise((resolve, reject) => {
			if (self.user) {
				resolve(self.user);
				//return;
			}
			else{

				this.storage.get('user').then((val) => {
					//console.log('USER NEW++++++++:',val);

					if(val)
					{
						let user = JSON.parse(val)	

						resolve(user[0]['value']);	
					}

				});
			}


			/*var cleanA = self.events.userLogin().subscribe( data => {
				//cleanA(); cleanB();
				resolve(data.user);
			});
			var cleanB = self.events.auth().subscribe( () => {
				//cleanA(); cleanB();
				//reject('auth fail');
			});*/
		});


	}

	public getUserId(){

		if(this.user)
		{
			return this.user._id;
		}
		else
		{
			//console.log('TOKEN',this.chatoken);
			this.user = this.parseJwt(this.chatoken.token);
			return this.user._id;

		}
	}
	public getUser(){

		if(this.user)
		{
			return this.user;
		}
		else
		{
			//console.log('TOKEN',this.chatoken);
			this.user = this.parseJwt(this.chatoken.token);
			return this.user;

		}
	}






}
