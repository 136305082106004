import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Config } from '../config';
import { ModalController,LoadingController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable()
export class HelperService {

	private vendor = new BehaviorSubject({});
  	public vendorData = this.vendor.asObservable();

  	private lastmessage = new BehaviorSubject([]);
  	public lastmessageData = this.lastmessage.asObservable();

	constructor() {

	}

	public  async loading(loadingCtrl,message)
	{

	    let loading = await loadingCtrl.create({
	        spinner: 'hide',
	        content: '<div class="custom-spinner-container"><div class="custom-spinner-box">'+message+'loading...</div></div>'
	    });

	    return await loading;

	}
	public isEmptyObj(value){
  		return (value == null || value.length === 0);
	}



  public filterMembers(data){
    let newdata = [];
    for(let a of data){

      if(a.residence_name != '' && a.residence_name != null && a.residence_name != undefined){
        console.log('check a::::',a.residence_name);
        newdata.push(a)
      }
    }
    return newdata;
  }

	public matchingPasswords(passwordKey: string, confirmPasswordKey: string) {
      return (group: FormGroup): {[key: string]: any} => {
        let password = group.controls[passwordKey];
        let confirmPassword = group.controls[confirmPasswordKey];

        if (password.value !== confirmPassword.value) {
          return {
            mismatchedPasswords: true
          };
        }
      }
    }

    public changeVendor(vendor) {
    this.vendor.next(vendor);
  }

  public changeLastmessage(message) {
    this.lastmessage.next(message);
  }

    
   	public getParam(url) {
		var params = {};
		var parser = document.createElement('a');
		parser.href = url;
		var query = parser.search.substring(1);
		var vars = query.split('&');
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=');
			params[pair[0]] = decodeURIComponent(pair[1]);
		}
		return params;
	}


	public baseUrl(data){
		if(data)
		{
	    	let ext = data.split('.');

	    	if(ext.length > 1 && ext[1] == 'svg')
	    	{
	      	return 'assets/img/avatar/';

	    	}
	    	else{
	      	return environment.server + 'uploads/images/';
	    	}
	    }
	    else{
	    	return ''
	    }



}

public baseUrlb(data){
    if(data)
    {
        let ext = data.split('.');

        if(ext.length > 1 && ext[1] == 'svg')
        {
          return 'assets/img/avatar/';

        }
        else if(data == environment.item_placeholder){
          return 'assets/img/';
        }
        else{
          return environment.server + 'uploads/images/';
        }
      }
      else{
        return ''
      }



}


public uniqueValues(data){
	var uniqueArray = Array.from(new Set(data));
	return uniqueArray;
}

public searchText(str,searchword){
	str = str.toLowerCase();
	searchword = searchword.toLowerCase();
	return str.includes(searchword);
}

public  dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return (a,b) => {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}
public checkIfKeyExists(data,key){
	if(data.hasOwnProperty(key))
	{
		return true;
	}
	else{
		return false;
	}
}

public generateThumbnail(file, boundBox){
  if (!boundBox || boundBox.length != 2){
    throw "You need to give the boundBox"
  }
  var scaleRatio = Math.min(...boundBox) / Math.max(file.width, file.height)
  var reader = new FileReader();
  var canvas = document.createElement("canvas")
  var ctx = canvas.getContext('2d');

  return new Promise((resolve, reject) => {
    reader.onload = function(event){
        var img = new Image();
        img.onload = function(){
            var scaleRatio = Math.min(...boundBox) / Math.max(img.width, img.height)
            let w = img.width*scaleRatio
            let h = img.height*scaleRatio
            canvas.width = w;
            canvas.height = h;
            ctx.drawImage(img, 0, 0, w, h);
            return resolve(canvas.toDataURL(file.type))
        }
        img.src = event.target.result as string;
    }
    reader.readAsDataURL(file);
  })
}

  isOdd(num) { 
    return num % 2;
  }


searchInObject(arr, searchword,key) {
  let obj = arr.find((o, i) => {
    if (o._id === searchword) {
        //arr[i] = { name: 'new string', value: 'this', other: 'that' };
        //console.log('Here',o);
        return  o; // stop searching
    }



});


  return obj;
}


searchInObjectb(arr, searchword,key) {
  let obj = [];
  for (let row of arr){

    if(row[key] == searchword){
      obj.push(row);
    }
  }

  return obj;
}

dynamicSearchInObjectb(arr, searchword,key,keyb='') {
  let obj = [];
  if(searchword == ''){
    obj = arr;

  }
  else{
    for (let row of arr){
       let row_lower = row[key].toLowerCase()
       console.log('check includes:',row_lower.includes(searchword.toLowerCase()));
      if(row_lower.includes(searchword.toLowerCase())){
        obj.push(row);
      }
      else if(keyb!='' &&  row[keyb].toLowerCase().includes(searchword.toLowerCase()) == true){
        console.log('in includes description:',)
        obj.push(row);
      }

    }

  }
  

  return obj;
}


searchCity(arr, searchword) {
  

  for( let row of arr)
  {
    
      if(row._id == searchword)
      {
        return row.cities
      }
    
  }


  return [];
}

chaPrepend(value, array) {
  var newArray = array.slice();
  newArray.unshift(value);
  return newArray;
}

isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

capitalize(s)
{
    return s && s[0].toUpperCase() + s.slice(1);
}

mapAttributes(data, datatype){
  //console.log('HERE IMAGES:',data);
  let newdata = [];
  switch(datatype){
    case "community_products":
      for(let element of data){
         let newelement = {
            image: element.product_image,
            name: element.product_name,
            desc: element.product_desc,
            price: element.product_price,
            shop: element.shop_id,
            _id: element._id
         }
         newdata.push(newelement)

      } 
      break;

  }

  return newdata

}

checkIFBaseUrl(image_name,searchString){
 

  if (image_name.includes(searchString)) {
    return true;
  } else {
      return false;

  }
}

getImage(image_name){
  if(image_name != undefined && image_name != null && image_name != '')
  {
    let searchString = environment.file_upload_path;
    let image_path = '';
    if(this.checkIFBaseUrl(image_name,searchString)){

      image_name = image_name.split(searchString)[1];
      //console.log('+++++++++++++++++image_name:::::',image_name);

    }
    image_path = this.baseUrlb(image_name) + image_name;
    return image_path;
  }
  else{
    return image_name;
  }


}

staticArrayHandler(data, index,type){
  if(data != undefined && data != null && data != ''){
    if(type == 'image'){
      if(typeof data.image != 'undefined')
      {
        return data.image[index].url;

      }
      else
      {
        return environment.item_placeholder
      }
      

    }
    else{

      return data[index];



    }
    

  }
  else{
    if(type == 'image')
    {
      return environment.item_placeholder;
    }
    else{
      return  [];
    }

  }
}



convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});


public formartShop(myshop){
        let shoplist = [];
        for(let shop of myshop){
              shoplist.push({
              name:shop.shop_name,
              shopid:shop._id,
              community_name:shop.community[0].name,
              community_id:shop.community[0]._id,
              shop_pic:shop.shop_pics,
              category_id:shop.category[0]._id
            });

          }
        return shoplist;
    }
reformatPhone(str,prefix="265"){
  console.log('str::',str);
  let state = 0;
  if (str.indexOf("265") === 0) {
    str = str.substring(3); // Truncate the first 3 characters
    //console.log('STR:',str);
  } else if (str.indexOf("0") === 0) {
    str = str.substring(1); // Truncate only the first character (the zero)
  }
  else if (str.indexOf("1") === 0) {
     //console.log('str::2',str);
     state = 1;
    str = str.substring(1); // Truncate the first 1 characters
  }
  else if (str.indexOf("254") === 0) {
     //console.log('str::2',str);
     state = 1;
    str = str.substring(1); // Truncate the first 1 characters
  }
  else if (str.indexOf("27") === 0) {
     //console.log('str::2',str);
     state = 1;
    str = str.substring(1); // Truncate the first 1 characters
  }
  else if (str.indexOf("86") === 0) {
     //console.log('str::2',str);
     state = 1;
    str = str.substring(1); // Truncate the first 1 characters
  }

  if (str.length !== 9 && state !=1 && prefix == "265") {
    return 'Invalid Phone Number'; // or handle it as you prefer
  }
  return str;

}









}