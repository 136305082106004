// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#map {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

agm-map {
  height: calc(100vh - 164px);
}

agm-map .gm-svpc {
  display: none;
}

ion-row {
  height: 64px;
  align-items: center;
  background: black;
  color: white;
}
ion-row ion-col {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/common/address-modal/address-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAEA;EACE,2BAAA;AACF;;AAGE;EACE,aAAA;AAAJ;;AAGA;EACE,YAAA;EACA,mBAAA;EACA,iBAAA;EACA,YAAA;AAAF;AACE;EACE,kBAAA;AACJ","sourcesContent":["#map {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\nagm-map {\n  height: calc(100vh - 164px);\n}\n\nagm-map {\n  .gm-svpc {\n    display: none;\n  }\n}\nion-row {\n  height: 64px;\n  align-items: center;\n  background: black;\n  color: white;\n  ion-col {\n    text-align: center;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
