import { Component, OnInit,ElementRef,Renderer2,ViewChild } from '@angular/core';
import { NavController,ModalController, NavParams } from '@ionic/angular';
import {Config} from '../../config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MoneyService} from '../../services/money';
import { AlertController } from '@ionic/angular';
import { WebrtcService } from '../../providers/webrtc.service';
import { LoginService} from '../../services/login';
import { CallService} from '../../services/call';
import {ChaEventsService} from '../../services/chaevents';
import { environment } from '../../../environments/environment';
//import { NgxPermissionsService } from 'ngx-permissions';
//import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
@Component({
  selector: 'app-call',
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss'],
})  
export class CallComponent implements OnInit {
	data:any;
	pin:any;
  placeholder:any ='Enter Amount';
	submitForm: FormGroup;
  type:any;
  topVideoFrame = 'partner-video';
  userId: string;
  partnerId: string;
  myEl: HTMLMediaElement;
  partnerEl: HTMLMediaElement;
  chacall:any;
  username:any;
  callName:any;
  offer:any;
  all_users:any;
  callToUsername:any;
  connectedUser:any;
  callInitiator:any=true;
  callOngoing:any=false;
  callReceiver:any=false;
  yourConn:any;
  localVideo:HTMLMediaElement;
  remoteVideo:HTMLMediaElement;
  perm = ["ADMIN", "MEDIA", "VIDEO_CAPTURE", "AUDIO_CAPTURE"];
  peerConnectionConfig = {
  'iceServers': [
    {'urls': 'stun:stun.stunprotocol.org:3478'},
    {'urls': 'stun:stun.l.google.com:19302'},
  ]
};
//@ViewChild('localVideo',{static: true} ) localVideob: ElementRef;
//@ViewChild('remoteVideo',{static: true} ) remoteVideob: ElementRef;

  constructor(
  	public navParams: NavParams,
  	public viewCtrl: ModalController,
  	public formBuilder: FormBuilder,
  	private moneyService:MoneyService,
  	public alertController: AlertController,
    public webRTC: WebrtcService,
    public elRef: ElementRef,
    private loginService: LoginService,
    private events: ChaEventsService,
    private call:CallService,
    private renderer: Renderer2)
    //public permissionsService: NgxPermissionsService,
    //public androidPermissions: AndroidPermissions) 
    {
  	this.data = this.navParams.get('data');
    //this.type = this.data.type;
    /*this.events.chaMessage().subscribe((data) => {
      this.chacall = data;
      console.log('CHACALL:::',this.chacall);

    });*/






    

   }



   handleLogin(success,allUsers) { 
    if (success === false) { 
      alert("Ooops...try a different username"); 
    } 
    else { 
      
      let allAvailableUsers = this.all_users;
      console.log('All available users',allAvailableUsers)
      this.localVideo = this.elRef.nativeElement.querySelector('#localVideo');
      this.remoteVideo = this.elRef.nativeElement.querySelector('#remoteVideo');
      //this.localVideo = <HTMLElement>document.getElementById("localVideo");
      //this.remoteVideo = <HTMLElement>document.getElementById("remoteVideo");
      //this.localVideo = document.getElementById('localVideo');
      //this.remoteVideo = document.getElementById('remoteVideo');
      //document.getElementById('myName').hidden = true;
      //document.getElementById('otherElements').hidden = false;

    

    var constraints = {
      video: true,
      audio: true
    };

    /* START:The camera stream acquisition */
    if(navigator.mediaDevices.getUserMedia) {

      navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
        console.log('DO I GOT STREAM.....',stream);
        console.log('DO I GOT localVideob.....',this.localVideo);
        this.getUserMediaSuccess(stream, this.localVideo,this.call);

      }).catch((error) =>{
        this.errorHandler(error);

      });

    } else {
      //alert('Your browser does not support getUserMedia API');
      console.log('Your browser does not support getUserMedia API');
    }
    /* END:The camera stream acquisition */
    }
}

getUserMediaSuccess(stream, localVideob, call) {
  let localStream = stream;
  if (localVideob !== undefined) { 

    console.log('VIEw STREM LOCAL',localVideob );
    console.log('VIEw STREAM..',stream );
    //this.localVideob.nativeElement.stream = 
    localVideob.srcObject = stream;
    this.yourConn = new RTCPeerConnection(this.peerConnectionConfig);

    let connectionState = this.yourConn.connectionState;
    console.log('connection state inside getusermedia',connectionState)

    this.yourConn.onicecandidate = function (event) { 
      console.log('onicecandidate inside getusermedia success', event.candidate)
      if (event.candidate) { 

         call.send({ 
            type: "candidate", 
            candidate: event.candidate, 
            name: this.connectedUser
         }); 
      } 
    }; 
    console.log('Before REMOTE STREAM A',this.remoteVideo);
    let chathis = this;
    this.yourConn.ontrack = function(event) {
      
      console.log('Before REMOTE STREAM event',event);
      console.log('Before REMOTE STREAM B',chathis.remoteVideo);

      chathis.gotRemoteStream(event,chathis)
    };
    this.yourConn.addStream(localStream);
  }
}

gotRemoteStream(event,chathis) {
  console.log('got remote stream',chathis.remoteVideo);
  chathis.remoteVideo.srcObject  = event.streams[0];
}

errorHandler(error) {
  console.log(error);
}

ionViewDidEnter(){
/*
  this.renderer.listen('window', 'click', (e: Event) => {
                if (this.localVideo !== undefined) { 

                    console.log('LOCAL LOCAL',this.localVideo);
                  this.events.allUsers().subscribe((data) => {
                  this.all_users = data.allUsers;
                  console.log('ALL CALL USERS::****************************',this.all_users);
                  this.handleLogin(data.success,data.allUsers); 


                });
                    

                }


                });*/
  


  }

  ngAfterViewInit(){

    

    

  }

  ngOnInit(): void {
    //this.permissionsService.loadPermissions(this.perm);
    /*
    //this.initCall();
    console.log('GET CALL:::',this.chacall)
    if(this.chacall == null)
    {
      this.webRTC.call(this.partnerId);
    }

    this.swapVideo('my-video');*/



      //console.log('LOCAL LOCAL',this.localVideo);
    this.events.allUsers().subscribe((data) => {
    this.all_users = data.allUsers;
    console.log('ALL CALL USERS::****************************',this.all_users);
    this.handleLogin(data.success,data.allUsers); 


  });


    this.events.handleOffer().subscribe((data) => {
      console.log('CHECK OFFER DATA.....',data);
      if(data)
      {
        this.callInitiator = false;
        this.callReceiver = true;
        this.callName = data.name;
        this.offer = data.offer;
      }


    });


    this.events.handleCandidate().subscribe((data) => {
      if(data)
      {  
        this.yourConn.addIceCandidate(new RTCIceCandidate(data.candidate));
      }



    });

    this.events.handleAnswer().subscribe((data) => {
      console.log('CHECK DATA FROM ANSWER',data);
      if(data)
      {  
        this.callOngoing = true; 
        this.yourConn.setRemoteDescription(new RTCSessionDescription(data["answer"])); 

      }



    });


    
                     

  }

  login(){
    if(this.username)
    {
      this.call.callLogin(this.username);
    }

    //this.call.getAllVideoCallUsers();

  }

  callTo(){

    if (this.callToUsername.length > 0) { 
    this.connectedUser = this.callToUsername; 
    console.log('nameToCall',this.connectedUser);
    console.log('create an offer to-',this.connectedUser)

    
    var connectionState2 = this.yourConn.connectionState;
    console.log('connection state before call beginning',connectionState2)
    var signallingState2 = this.yourConn.signalingState;
  //console.log('connection state after',connectionState1)
  console.log('signalling state after',signallingState2)
    this.yourConn.createOffer((offer) => { 
       this.call.send({
          type: "offer", 
          offer: offer,
          name: this.connectedUser 
       }); 
    
       this.yourConn.setLocalDescription(offer); 
    }, (error) => { 
       //alert("Error when creating an offer",error); 
       console.log("Error when creating an offer",error)
    }); 
    //document.getElementById('callOngoing').style.display = 'block';
    //document.getElementById('callInitiator').style.display = 'none';

  } 
  else 
    alert("username can't be blank!")

  }

  answer(){

    this.connectedUser = this.callName;
    console.log('CHECK OFFER ON CLICK',this.offer);

    this.yourConn.setRemoteDescription(new RTCSessionDescription(this.offer)); 
 
    //create an answer to an offer
    let chathis = this; 
    this.yourConn.createAnswer(function (answer) { 
      chathis.yourConn.setLocalDescription(answer); 
   
      chathis.call.send({ 
        type: "answer", 
        answer: answer,
        name: chathis.connectedUser 
      });
   
  }, function (error) { 
     console.log("Error when creating an answer"); 
  });

  this.callReceiver = false;
  this.callOngoing = true; 

  }

  initCall(){
    this.myEl = this.elRef.nativeElement.querySelector('#my-video');
    this.partnerEl = this.elRef.nativeElement.querySelector('#partner-video');
    this.webRTC.init(this.loginService.user.username, this.myEl, this.partnerEl);
    this.partnerId = this.data[0].partnerId;
    console.log('PartID',this.partnerId);
    this.webRTC.call(this.partnerId);
  }

   swapVideo(topVideo: string) {
    this.topVideoFrame = topVideo;
  }

  dismiss(msg){
    this.viewCtrl.dismiss(msg);
  }
  


  async presentAlert(title,subtitle,message) {
    const alert = await this.alertController.create({
      header: title,
      subHeader: subtitle,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }




}
