import { Injectable } from '@angular/core';
import { Plugins,  Capacitor} from '@capacitor/core';



import { Camera, CameraResultType, CameraPhoto,CameraSource  } from '@capacitor/camera';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

import {Photo} from '../models/photo';
import {ChaEventsService} from '../services/chaevents';

//const {  Filesystem, Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class PhotoService {
	 public photos: Photo[] = [];
	  public photosB: Photo[] = [];

  constructor(public events: ChaEventsService, ) { }

  public async addNewToGallery(identifier='') {
  	
 
  	
		  // Take a photo
		  const capturedPhoto = await Camera.getPhoto({
		    resultType: CameraResultType.Base64, 
		    source: CameraSource.Camera, 
		    allowEditing: true,
		    quality: 60 
		    
		  });

		   //const base64Data = await this.readAsBase64(capturedPhoto);
		    const base64Data =capturedPhoto.base64String;
		    //console.log('PHHOTO UPLOADED...',capturedPhoto.)
		    if(identifier != ''){
		    	this.events.photoPublish({data:'data:image/jpeg;base64,'+ base64Data,identifier:identifier});

		    }
		    else
		    {
		    	this.events.photoPublish('data:image/jpeg;base64,'+ base64Data);
		    }
		   
		   //console.log('checkpath:::',capturedPhoto.base64String);
		  this.photos.unshift({
		    filepath: "soon...",
		    webviewPath: 'data:image/jpeg;base64,' + capturedPhoto.base64String
		  });

	  

	}


	public async addNewToGalleryB(identifier='') {
  	

  	
		  // Take a photo
		  const capturedPhoto = await Camera.getPhoto({
		    resultType: CameraResultType.Base64, 
		    source: CameraSource.Camera, 
		    allowEditing: true,
		    quality: 60 
		    
		  });

		   const base64Data = capturedPhoto.base64String;
		   if(identifier != ''){
		    	this.events.photoPublish({data:'data:image/jpeg;base64,'+ base64Data,identifier:identifier});

		    }
		    else
		    {
		    	this.events.photoPublish('data:image/jpeg;base64,'+ base64Data);
		    }

		  this.photosB.unshift({
		    filepath: "soon...",
		    webviewPath:'data:image/jpeg;base64,'+base64Data
		  });
			
	return new Promise((resolve)=>{
		resolve('data:image/jpeg;base64,'+base64Data);

	  });

	}

	public async getPhoto(photo){
		 //let photo = null;
			console.log('inner photo:',photo);

			//let base64Data = await this.readAsBase64(this.photos[0]);
			let base64Data = '';
		return base64Data.toString();	
	}

	private async readAsBase64(cameraPhoto:CameraPhoto) {
	  // Fetch the photo, read as a blob, then convert to base64 format
	  const response = await fetch(cameraPhoto.webPath!);

	  const blob = await response.blob();

	  return await this.convertBlobToBase64(blob) as string;  
	}

	getBase64FromUrl = async (url) => {
	  const data = await fetch(url);
	  const blob = await data.blob();
	  return new Promise((resolve) => {
	    const reader = new FileReader();
	    reader.readAsDataURL(blob); 
	    reader.onloadend = () => {
	      const base64data = reader.result;   
	      resolve(base64data);
	    }
	  });
	}

	convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
	  const reader = new FileReader;
	  reader.onerror = reject;
	  reader.onload = () => {
	      resolve(reader.result);
	  };
	  reader.readAsDataURL(blob);
	});
}
