import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-jersey-details',
  templateUrl: './jersey-details.component.html',
  styleUrls: ['./jersey-details.component.scss'],
})  
export class JerseyDetailsComponent implements OnInit{
  @Input() title: string;
  @Input() order: any;
  @Input() header: any;
  extras:any;

	
  constructor(private modalCtrl: ModalController)
  {

  }
  ngOnInit(){
    console.log('ORDER:',this.order);

    if(this.order.bag_quantity > 0){
      if(this.extras == null)
      {
        this.extras = this.order.bag_quantity + ' Camel Bags';

      }
      else{
        this.extras = this.extras + ', '+ this.order.bag_quantity + ' Camel Bag';
      }
      
    }
    if(this.order.helmet_quantity > 0){
      if(this.extras == null)
      {
        this.extras = this.order.helmet_quantity + ' Helmets';

      }
      else{
        this.extras = this.extras + ', '+ this.order.helmet_quantity + ' Helmet';
      }
      
    }
    if(this.order.coat_quantity > 0){
      if(this.extras == null)
      {
        this.extras = this.order.coat_quantity + ' Rain Coat';

      }
      else{
        this.extras = this.extras + ', '+ this.order.coat_quantity + ' Rain Coat';
      }
      
    }
 

  }

  jersey_size(s){
    let chasize = s;
    switch(s){
      case 'S':
        chasize = 'SMALL';
        break;
      case 'M':
        chasize = 'MEDIUM';
        break;
      case 'L':
        chasize = 'LARGE';
        break;
      case 'XL':
        chasize = 'EXTRA LARGE';
        break;
      case '2XL':
        chasize = '2 EXTRA LARGE';
        break;
      default:
        chasize = s;
        break;


    }

    return chasize;
  }

  pants_size(s){
    let chasize = s;
    switch(s){
      case 'S':
        chasize = 'SMALL';
        break;
      case 'M':
        chasize = 'MEDIUM';
        break;
      case 'L':
        chasize = 'LARGE';
        break;
      case 'XL':
        chasize = 'EXTRA LARGE';
        break;
      case '2XL':
        chasize = '2 EXTRA LARGE';
        break;
      default:
        chasize = s;
        break;


    }

    return chasize;
  }

  sleeves_size(s){
    let chasize = s;
    switch(s){
      case 'SS':
        chasize = 'Short Sleeves';
        break;
      case 'LS':
        chasize = 'Long Sleeves';
        break;
      default:
        chasize = s;
        break;


    }

    return chasize;
  }
  pants_length(s){
    let chasize = s;
    switch(s){
      case 'SP':
        chasize = 'Short Pants';
        break;
      case 'LP':
        chasize = 'Long Pants';
        break;
      default:
        chasize = s;
        break;


    }

    return chasize;
  }


  cancel() {
    this.modalCtrl.dismiss();
  }

  confirm() {
    this.modalCtrl.dismiss();
  }




}
