import { Component } from '@angular/core';
import {  NavController, NavParams, ModalController } from '@ionic/angular';


@Component({
  selector: 'app-community-message-reaction',
  templateUrl: './community-message-reaction.page.html',
  styleUrls: ['./community-message-reaction.page.scss'],
})
export class CommunityMessageReactionPage  {

 
  constructor(private navCtrl: NavController, 
    private navParams: NavParams, 
    private viewCtrl: ModalController) {

  }

    
  
  ionViewDidLoad() {
        console.log('ionViewDidLoad ReactionsPage');
    }

    share(){
        this.viewCtrl.dismiss();
    }
  



}