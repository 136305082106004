import { Camera, CameraResultType } from '@capacitor/camera';
import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { FilePicker, PickFilesResult} from '@capawesome/capacitor-file-picker';
import { Chat, SingleChat} from '../../models/chats';
import { ChatService } from 'src/app/services/chat';
@Component({
  selector: 'app-photo-prev',
  templateUrl: './photo-prev.component.html',
  styleUrls: ['./photo-prev.component.scss'],
})
export class PhotoPrevComponent  implements OnInit {
  option: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
  videos: boolean = false;
  photos:any= [];
  docs: boolean = false;
  photo:any;
  video:any;
  document: any;
  docName : any;
  pdfSrc: string;
  data = {
    caption: null,
    attachment: null,
    time: null
    }
  chat:SingleChat = {
    id: null,
    users: [],
    name: '',
    messages: [],
    // photo:[]
    }
  
  @Input() mediaType: string;
  @Input() userName: string;
  @Input() chatId: string;

  constructor(
    private chatService: ChatService,
  	public navParams: NavParams,
    public viewCtrl: ModalController
  ) {
    this.photos = this.navParams.get('photos');
    console.log('parsed photos from chat am i here',this.photos);
    
  }




  ngOnInit() {
   // this.displayPhotos(this.photos);
  }


  displayPhotos(photos) {
    /*
    const mainPhoto = document.getElementById('mainPhoto') as HTMLImageElement;
    const thumbnailsContainer = document.getElementById('thumbnails');
    thumbnailsContainer.innerHTML = ''; // Clear previous thumbnails

    if (photos.length > 0) {
        mainPhoto.src = photos[0]; // Set the first photo as the main photo
    } else {
        mainPhoto.src = ''; // Clear main photo if no photos
    }

    photos.forEach((photo, index) => {
        const thumbnailContainer = document.createElement('div');
        thumbnailContainer.className = 'thumbnail relative inline-block pl-1';

        const img = document.createElement('img');
        img.src = photo;
        img.alt = 'Thumbnail';
        img.className = 'h-14 w-14 ml-1';

        // Add click event listener to change main photo on click
        img.addEventListener('click', () => {
            mainPhoto.src = photo;
        });

        // const deleteIcon = document.createElement('div');
        const deleteIcon = document.createElement('ion-icon');
        deleteIcon.setAttribute('name', 'trash-outline');

        deleteIcon.className = 'delete-icon absolute -top-2 -right-2 bg-[#b5e3ff] text-red-800 p-1 rounded-full cursor-pointer text-xs';
        deleteIcon.innerHTML = '&times;'; // Unicode for '×'

        // Add click event listener to delete the photo
        deleteIcon.addEventListener('click', (event) => {
          event.stopPropagation(); // Prevent triggering the thumbnail click event
          photos.splice(index, 1); 
          if (photos.length === 0) {
              this.dismiss(); 
          } else {
              this.displayPhotos(photos); 
          }
      });
      

        thumbnailContainer.appendChild(img);
        thumbnailContainer.appendChild(deleteIcon);
        thumbnailsContainer.appendChild(thumbnailContainer);
    }); */
  }
 
  send() {

    if (!Array.isArray(this.photos) || this.photos.length === 0) {
      console.error('No photos to send.');
      return;
    }

    
    const processPhoto = (photo) => {
      const sendPhoto = (base64String) => {
        this.data.attachment = base64String;
        const timestamp = new Date().toLocaleTimeString([], this.option);
        let data = {
          caption: this.data.caption,
          attachment: this.data.attachment,
          type : 'photo',
          time: timestamp
        };
        
        this.chatService.send(this.chatId, data);
        this.data.attachment = '';
        this.dismiss();
      };
  
      if (typeof photo === 'string') {
        sendPhoto(photo);
      } else if (photo instanceof File) {
        const reader = new FileReader();
        reader.onload = () => {
          const result = reader.result as string;
          const base64String = result.split(',')[1];
          sendPhoto(base64String);
        };
        reader.readAsDataURL(photo);
      } else {
        console.error('Unsupported photo type.');
      }
    };
  
    this.photos.forEach(photo => processPhoto(photo));
  }
  
  
  readFileAsBase64 (file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = function(event) {
            const result = event.target.result as string;
            const base64String = result.split(',')[1];
            const fullBase64String = 'data:image/jpeg;base64,' + base64String;
            resolve(fullBase64String);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file.blob);
        });
    }

  pickImages = async () => {
    try {
      const result = await FilePicker.pickImages({
        //multiple: true,
      });

      const base64ImagesArray = [];

      for (const file of result.files) {
        const fullBase64String = await this.readFileAsBase64(file);
        base64ImagesArray.push(fullBase64String);
      }

      this.photos.push(base64ImagesArray);
      console.log('length for photo1:',this.photos.length);
      //this.displayPhotos(photos);
      //this.data.attachment = [];
    } catch (error) {
      console.error("Error picking images: ", error);
    }
  };

  // Function to convert image to base64
  convertToBase64(imageUri) {
      return new Promise((resolve, reject) => {
          const xhr = new XMLHttpRequest();
          xhr.onload = function() {
              const reader = new FileReader();
              reader.onloadend = function() {
                  resolve(reader.result);
              };
              reader.onerror = function(error) {
                  reject(error);
              };
              reader.readAsDataURL(xhr.response);
          };
          xhr.onerror = function(error) {
              reject(error);
          };
          xhr.open('GET', imageUri);
          xhr.responseType = 'blob';
          xhr.send();
      });
  }


  dismiss() {
    this.viewCtrl.dismiss();
    this.data.attachment = '';
  }
 


}
