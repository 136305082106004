import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController,ModalController, NavParams } from '@ionic/angular';


@Component({
  selector: 'chat-popover',
  templateUrl: './chat-popover.component.html',
  styleUrls: ['./chat-popover.component.scss'],
})
export class ChatPopOverComponent {
  @Input() chatId: any;
  @Input() tab: any;
  @Input() messageOptions: any;

  createcommunity:boolean =false;
  creategroup:boolean =false;
  newchat:boolean =false;
  scan:boolean =false;
  newcontact:boolean =false;
  show1: boolean = true;
  show2: boolean = false;
  messageOption : boolean = false;
 
  constructor(
    public popoverController: PopoverController,
    public params : NavParams
    ) { 

    this.chatId = this.params.get('chatId');
    this.messageOptions = this.params.get('messageOptions');
  }

  ngOnInit() {
    this.chatId = this.params.get('chatId');
    if (this.chatId) {
      this.show1=false;
      this.show2 = true; 
    } else if(this.messageOptions) {
      this.messageOption = true; 
    }
    else {
      this.show1 = true; 
    }
  }
  ngAfterViewChecked() {
    //this.fontFormatting();
  }

 dismiss(role){
   console.log('role::',role);
   this.popoverController.dismiss(role);

 }


 

}
