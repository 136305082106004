import { Injectable,ViewChild} from '@angular/core';
import { Platform, NavController,LoadingController} from '@ionic/angular';
import {AudioService} from './audio';
import {SocketService} from './socket';
//import {LoginService} from './login';
//import {ChatService} from './chat';
import { Storage } from '@ionic/storage';
import { Config } from '../config';
import {marked} from 'marked';
import { RestProvider } from './rest/rest';
import {Transaction} from '../models/transaction';
import {ChaEventsService} from '../services/chaevents';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import {GeoFenceService} from '../services/geofence';
import { environment } from '../../environments/environment';

//import { ChatPage } from '../pages';


@Injectable()
export class MusicService {
  	public latitude:any = '';
  	public longitude:any = '';
	


	constructor(
	 public audio:AudioService,
	 public socket:SocketService,  
	 public storage:Storage,
	 //private chatService:ChatService,
	 //private loginService:LoginService,
	 public restProvider: RestProvider,
	 private events: ChaEventsService,
	 public loadingCtrl: LoadingController,
	 private geolocation: Geolocation,
	 private geoFenceService: GeoFenceService,) {
	 	
		
		
	} 


	public downloadMusic(data){
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,environment.api_urls.downloadmusic).then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
				}
			);
		});
	}







}
