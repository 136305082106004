import { Component, OnInit } from '@angular/core';
import { NavController, NavParams, ModalController } from '@ionic/angular';
import { ChatPage } from '../../chat/chat.page';
import { CallService} from '../../services/call';
import { ContactService} from '../../services/contact';
import { ChatService } from '../../services/chat';
import {HelperService} from '../../services/helper';
import {PushNotificationService} from '../../services/pushnotification';
import { LoginService } from '../../services/login';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
	contact = null;

  constructor(
	private viewCtrl: ModalController,
	private chatService: ChatService, 
	private params: NavParams, 
	private navCtrl: NavController, 
	private callService: CallService, 
	private contactService: ContactService,
	private loginService: LoginService,
	private helper: HelperService,
	private pushnotification:PushNotificationService
  	) { }

  chat() {
                // I add chatService and then send chat.id like => pages -> contacts -> contacts.ts
    if(this.contact.id == '' || this.contact.id == null || this.contact.id == undefined)
		{
			
		this.chatService.getChatByContact(this.contact._id).then((chat:any) => {
			this.pushnotification.saveCurrentPage(chat.id,this.loginService.user._id).then((results)=>{
				this.navCtrl.navigateForward(['/chat2',chat.id]);
			});

			//this.navCtrl.push(ChatPage, {chatId: chat.id}, {animate: true, direction: 'forward'});
		});
		}
		else{
			this.chatService.getChatByContact(this.contact.id).then((chat:any) => {
			this.pushnotification.saveCurrentPage(chat.id,this.loginService.user._id).then((results)=>{
				this.navCtrl.navigateForward(['/chat2',chat.id]);
			});

			//this.navCtrl.push(ChatPage, {chatId: chat.id}, {animate: true, direction: 'forward'});
		});

		}

		this.cancel();
	}

	call() {
		this.callService.triggerCall(this.contact.id);
		this.cancel();
	}

	cancel() {
		this.viewCtrl.dismiss();
	}

	ngOnInit() {
		this.contact = this.contactService.get(this.params.get('contact').id);
		console.log('member member1',this.contact);
		if(this.contact.id == '' || this.contact.id == null || this.contact.id == undefined)
		{
			this.contact = this.contactService.get(this.params.get('contact')._id);
			console.log('member member',this.contact);

		}
	}

	contactsPic(data){
		//console.log('here u go:',this.helper.baseUrl(data) + data);
		return this.helper.baseUrl(data) + data;
	}

}
