// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-video {
  position: absolute;
  top: 100px;
  right: 100px;
  max-width: 100px;
  z-index: 2;
}

.main-video {
  width: 100%;
  max-height: 70%;
}`, "",{"version":3,"sources":["webpack://./src/app/common/call/call.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;EACA,YAAA;EACA,gBAAA;EACA,UAAA;AACJ;;AAEA;EACI,WAAA;EACA,eAAA;AACJ","sourcesContent":[".top-video{\n    position: absolute;\n    top: 100px;\n    right: 100px;\n    max-width: 100px;\n    z-index: 2;\n}\n\n.main-video{\n    width: 100%;\n    max-height: 70%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
