import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {HelperService} from '../services/helper';
import { ChaEventsService } from '../services/chaevents';
import {User} from '../models/user';
import {Item} from '../models/item';

@Injectable({
    providedIn: 'root'
})
export class SubscriberService {

    public products:Item[] = [];
    public current_user:any = {};

    constructor(
        public events: ChaEventsService, 
        ){
            this.initSubscribers();
            


     }

     initSubscribers(){
        //get community products
        this.events.productList().subscribe(data => {

            this.products = data;



        });

        // get current user
        this.events.userLogin().subscribe(data => {
          if(data.user != undefined && data.user != '' && data.user !== null)
          {
            this.current_user = data.user as User;
          }
          else{
            console.log('current user is blank',data.user);
          }

        });

        





         



     }
   

}