import { Injectable,ViewChild} from '@angular/core';
import { Platform, NavController,LoadingController} from '@ionic/angular';
import {AudioService} from './audio';
import {SocketService} from './socket';
//import {LoginService} from './login';
//import {ChatService} from './chat';
import { Storage } from '@ionic/storage';
import { Config } from '../config';
import {marked} from 'marked';
import { RestProvider } from './rest/rest';
import {Transaction} from '../models/transaction';
import {ChaEventsService} from '../services/chaevents';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import {GeoFenceService} from '../services/geofence';
import { environment } from '../../environments/environment';
//import { ChatPage } from '../pages';


@Injectable()
export class CommunityService {
	public communitylist:any = [];
  	public communitydata:any = {};
  	public communityStatus:any = {};
  	public latitude:any = '';
  	public longitude:any = '';
	


	constructor(
	 public audio:AudioService,
	 public socket:SocketService,  
	 public storage:Storage,
	 //private chatService:ChatService,
	 //private loginService:LoginService,
	 public restProvider: RestProvider,
	 private events: ChaEventsService,
	 public loadingCtrl: LoadingController,
	 private geolocation: Geolocation,
	 private geoFenceService: GeoFenceService,) {
	 	
		
		
	} 



	public setSessionStorage(key,value){
		switch(key) {
		  case 'communityStatus':
		  this.communityStatus = value;
		  this.storage.set("communityStatus", value);
		    
		    break;
		 
		  default:
		    // code block
		} 
		
	}

	public getSessionStorage(key){
		return new Promise((resolve,reject)=>{


		
			switch(key) {
			  case 'communityStatus':
			  
			  this.storage.get('communityStatus').then((results)=>{
			  	this.communityStatus = results;
			  	resolve(this.communityStatus);
			  });
			    
			    break;
			 
			  default:
			    // code block
			} 
		});

	}


	public checkIfBelongCommunity(data){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/get_community_by_point').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					//console.log(err);
				}
			);

			resolve('');

		});
	}


	public saveCommunity(data){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,environment.api_urls.savecommunity).then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					//console.log(err);
				}
			);

			//resolve('');

		});
	}

	public saveLocation(data){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,environment.api_urls.savelocation).then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					//console.log(err);
				}
			);

			//resolve('');

		});
	}

	public checkCommunityByCoordinates(data){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/get_community_by_point').then((result)=>{
				//console.log('get_community_by_point:',result);
				resolve(result['msg']);
				},(err) => {
					reject(err);
					//console.log(err);
				}
			);

			//resolve('');

		});
	}

	public checkNearByLocation(data){
		return new Promise((resolve,reject) => {
			
			this.restProvider.authSendToApi(data,environment.api_urls.get_near_residence).then((result)=>{
				if(result['msg'] == 'Please provide longitude and lat')
				{

					reject(result['msg']);

				}
				resolve(result['msg']);
				},(err) => {
					reject(err);
					//console.log(err);
				}
			);

			//resolve('');

		});
	}

	public getCommunityList(data,userid=''){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		//console.log('userid::',userid);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,environment.api_urls.get_communitylist,userid).then((result)=>{
				//console.log('-------------------------At this stage of community list CHARLIE3', result);

					if(result != null && result['msg'] != 'No record found' )
					{
						//console.log('-------------------------At this stage of community list 5', result['msg']);
						resolve(result['msg']);
					}
					else
					{
						resolve([]);

					}

				},(err) => {
					//console.log('ERROR for community List:',err);
					reject(err);
					
				}
			);

			//resolve('');

		});
	}


	public checkIfMemberExist(members,user){
		let outcome = false;
		for(let member of members){
			//console.log('MEMBER:',member);
			if(member.userid[0]._id == user._id){
				outcome = true;
				break

			}
		}

		return outcome;

	}

	joinMember(community_id,userid,latlng,residence_name,chatid){

		let data = {
			community_id:community_id,
			userid:userid,
			latlng:latlng,
			residence_name:residence_name,
			chatid:chatid
		}

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/join_community').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					//console.log(err);
				}
			);

			//resolve('');

		});

	}


	geoLocate(current_user){
		
	   	return new Promise((resolve,reject) => {
		    this.geolocation.getCurrentPosition().then((resp) => {

		      let latitude = resp.coords.latitude;
		      let longitude = resp.coords.longitude;
		      let data = {lat:latitude,lng:longitude};
		      this.storage.set('currentposition_'+current_user._id,JSON.stringify(data));
		      //console.log('CHA CHA CHA COORDINATES_::::',data);

		      this.checkCommunityByCoordinates(data).then((results)=>{
		        if(results =='No record found'){
		          //this.spinnerDialog.hide();
		            //this.chaloading = 0;
		            //this.check = true; // no community available
		            resolve({geo:data,community:'no community available'});
		        }
		        else{
		          let community = results[0];
		          let communityname = community.name;
		          let community_data = community;
		          
		          this.storage.set('communitydata_'+current_user._id,JSON.stringify(community_data));

		          //comminity available (true am a member and false not a member)
		          let chajoin = this.checkIfMemberExist(community.members,current_user);
		          if(chajoin == true){
		            //this.spinnerDialog.hide();
		            //this.chaloading = 0;
		            //console.log('redirect to Community Detailed page');
		            //this.community_auth = true;
		            resolve({geo:data,community:'community available',member:true});
		          }
		          else
		          {
		            //this.spinnerDialog.hide();
		            //this.chaloading = 0;
		            resolve({geo:data,community:'community available',member:false});
		          }
		      }
		        //this.check = true;
		      });

		    });
		});
  
	}

	retrieveAllMyCommunities(current_user, state=0){
		return new Promise((resolve,reject) => {

			let inputdata = {userid:current_user._id}

			this.getCommunityList(inputdata,current_user._id).then((data)=>{
				//console.log('COmmunity list results::::$$$:',data);
				this.communitylist = data;
				resolve(data);



			},(err)=>{
				//console.log('Problem in getting list:',err);
				this.communitylist = [];
				resolve([]);
			});

			/*

			console.log('------');
			if(state == 0)
			{
				/
				this.storage.get('communitylist_'+current_user._id).then((communitydata) => {
					if(communitydata == null || communitydata == '' || communitydata == undefined)
					{

						let inputdata = {userid:current_user._id}

						console.log('-------------------------At this stage of community list 0');
						this.getCommunityList(inputdata).then((data)=>{

							console.log('-------------------------At this stage of community list 1');


							this.storage.set('communitylist_'+current_user._id,JSON.stringify(data));
							this.events.communityListPublish(data);
							this.communitylist = data;
							resolve(data);



						},(err)=>{
							console.log('Problem in getting list:',err);
							this.communitylist = [];
							resolve([]);
						});


					}
					else
					{

						this.events.communityListPublish(communitydata);
						let inputdata = {userid:current_user._id}

						console.log('-------------------------At this stage of community list 2');
						this.getCommunityList(inputdata).then((data)=>{
							console.log('-------------------------At this stage of community list 3', data);

							this.storage.set('communitylist_'+current_user._id,JSON.stringify(data));
							this.events.communityListPublish(data);
							this.communitylist = data;
							resolve(data);




						},(err)=>{
							console.log('community list error', err);
							this.communitylist = [];
							resolve([]);

						});


					}


				});
			}
			else{

				let inputdata = {userid:current_user._id};
				this.getCommunityList(inputdata).then((data)=>{

					console.log('-------------------------At this stage of community list 101');


					this.storage.set('communitylist_'+current_user._id,JSON.stringify(data));
					this.events.communityListPublish(data);
					this.communitylist = data;
					resolve(data);



				},(err)=>{
					console.log('Problem in getting list:',err);
					this.communitylist = [];
					resolve([]);
				});



			}

			*/
		});





	}

	retrieveMycommunity(data, current_user){
		//console.log('entered');
		return new Promise((resolve,reject) => {

			this.checkCommunityByCoordinates(data).then((results)=>{
				let newresults = results as any[];
		        if(results =='No record found' || results == null || newresults.length == 0 ){
		          //this.spinnerDialog.hide();
		          //console.log('No record found');
		          this.retrieveAllMyCommunities(current_user);
		          resolve({state:'norecordfound',community:null});
		        }
		        else
		        {

		        	let community = results[0];
		          	

		          	//comminity available (true am a member and false not a member)
		          	let chajoin = this.checkIfMemberExist(community.members,current_user);
		          	if(chajoin == true)
		          	{
		          		this.storage.set('communitydata_'+current_user._id,JSON.stringify(community));
		            	resolve({state:'autologin',community:community});
		          	}
		          	else
		          	{
		          		this.retrieveAllMyCommunities(current_user);
		            	resolve({state:'promptjoin',community:community});
		          	}
		      	}
	 		 },(error)=>{
	 		 	//console.log('error check community by coordinates:',error);
	 		 	reject(error);
	 		 });

        //this.check = true;
      });
	}

	checkIfGeoFenceChanged(coordinates,current_user){
		return new Promise((resolve,reject) => {
			this.storage.get('communitydata_'+current_user._id).then((communitydata) => {
				if(communitydata == null || communitydata == '' || communitydata == undefined)
				{

					//console.log('=>=>=>no communitydata1');

					this.retrieveMycommunity(coordinates, current_user).then((data)=>{


						//console.log('my community::',data);


						resolve(data);

					},(err)=>{

						reject(err);

					});



				}
				else
				{
					//console.log('=>=>=> there is communitydata',communitydata);
					let community =  JSON.parse(communitydata);
					let community_fence = communitydata.community_fence;
					let fencestatus = this.geoFenceService.checkIfWithinGeoFence(coordinates,community.community_fence);
					if(fencestatus == 'currentcommunity')
					{

						let chajoin = this.checkIfMemberExist(community.members,current_user);
			          	if(chajoin == true)
			          	{
			          		
			            	resolve({state:'autologin',community:community,coordinates:coordinates});

			          	}
			          	else
			          	{
			            	resolve({state:'promptjoin',community:community,coordinates:coordinates});
			          	}

					}
					else if (fencestatus == 'differentcommunity')
					{
						//console.log('different');
						resolve({state:'differentcommunity',community:null,coordinates:coordinates});

					}

					
					

				}

				
			

			},(error)=>{
				//console.log('=>=>=>no communitydata error',);
			});	

		});

	}

	retrieveCommunityList(current_user){
		return new Promise((resolve,reject) => {

			let inputdata = {userid:current_user._id}

			this.getCommunityList(inputdata,current_user._id).then((data)=>{

			//console.log('-------------------------At this stage of community list 1');


			//this.storage.set('communitylist_'+current_user._id,JSON.stringify(data));
			//this.events.communityListPublish(data);
			resolve({state:'offline',communitylist:data});




		});

			/*
			this.storage.get('communitylist_'+current_user._id).then((communitylist) => {

				//console.log('check offline1',communitylist);
				if(communitylist != null)
				{

					resolve({state:'offline',communitylist:JSON.parse(communitylist)});
				}
				{

					let inputdata = {userid:current_user._id}

						this.getCommunityList(inputdata,current_user._id).then((data)=>{

						console.log('-------------------------At this stage of community list 1');


						//this.storage.set('communitylist_'+current_user._id,JSON.stringify(data));
						//this.events.communityListPublish(data);
						resolve({state:'offline',communitylist:data});




					});


				}

			});*/
		});



	}

updateJerseyOrder(pics,orderid){
	let data = {
			orderid:orderid,
			data:pics

		}

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/update_jersey_order').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					//console.log(err);
				}
			);

			//resolve('');

		});

}

updateJerseyOrderAdd(data){

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/update_jersey_order_add').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					//console.log(err);
				}
			);

			//resolve('');

		});

}
saveCommunityPost(userid,paymentuserid,community_id,message, attachments,announcement, contributions){

	let data = {
			userid:userid,
			community_id:community_id,
			message:message,
			attachment:attachments,
			comments:[],
			announcement_type:announcement,
			activate_contributions:contributions,
			contributions:[],
			paymentuserid:paymentuserid

		}

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/savecommunity_message').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					//console.log(err);
				}
			);

			//resolve('');

		});

}

public getCommunityMessages(data){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,environment.api_urls.get_community_message,data.community_id).then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					//console.log(err);
				}
			);

			//resolve('');

		});
	}

getCommunitySingleMessage(data){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/get_single_community_message').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					//console.log(err);
				}
			);

			//resolve('');

		});
	}


getCommunityById(community_id){
		
		//console.log('CHECK STATUS STATUS:::::',data);
		let data = {community_id:community_id};

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,environment.api_urls.get_communitylist_by_id).then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

			//resolve('');

		});
	}

saveComment(userid,community_message_id,message){

	let data = {
			userid:userid,
			community_message_id:community_message_id,
			comment:message,
			

		}


		//console.log('COmment to Save:',data);

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/savecommunity_comment').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

			//resolve('');

		});

}


saveLike(userid,community_message_id,reaction_type){

	let data = {
			userid:userid,
			community_message_id:community_message_id,
			reaction_type:reaction_type,
			

		}


		//console.log('COmment to Save:',data);

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/savecommunity_reactions').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

			//resolve('');

		});

}


saveContributions(userid,community_message_id,amount){

	let data = {
			userid:userid,
			community_message_id:community_message_id,
			amount:amount,
			

		}


		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/savecommunity_contributions').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

			//resolve('');

		});

}

getCommunityMembers(data){

/*
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/get_community_members').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

			//resolve('');

		});*/

}

JerseyOrders(data){

	return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/jersey_orders_filter').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

			//resolve('');

		});

}

getJersetOrders(){

		let data = {};
		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/get_jersey_orders').then((result)=>{
				console.log('GET ORDERS receipt:',result);
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log('receipt of get orders ERROR:',err);
				}
			);

			//resolve('');

		});

}

getCommunityShopCategory(data){


		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/get_shopcategory').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

			//resolve('');

		});

}

saveShop(data){

	

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/savecommunity_shop').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

			//resolve('');

		});

}

getCommunityShopProductCategory(data){
	return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,environment.api_urls.get_community_shop_product_category).then((result)=>{
				console.log('am i here getCommunityShopProductCategory:**&&**(())&&',result);
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

			//resolve('');

		});

}
getCommunityShop_by_id(data){

	

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/get_community_shop_by_userid').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

			//resolve('');

		});

}

getCommunityCourier_by_id(data){

	

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/get_community_courier_by_userid').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

			//resolve('');

		});

}

getCommunityResidence_by_id(data){

	

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,environment.api_urls.get_community_residence_by_id).then((result)=>{
				//console.log('am i here:**&&**(())&&',result);
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

			//resolve('');

		});

}


saveCourier(data){

	

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/savecourier').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

			//resolve('');

		});

}

getCommunityCourier(data){

	

		return new Promise((resolve,reject) => {
			this.restProvider.authSendToApi(data,'api/get_nearest_available_courier').then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);

			//resolve('');

		});

}



//dynamic pages endpoints

getDropDown(url){
	return new Promise((resolve,reject) => {
			let data = [];
			/*
			this.restProvider.authSendToApi(data,'api/'+url).then((result)=>{
				
				resolve(result['msg']);
				},(err) => {
					reject(err);
					console.log(err);
				}
			);*/

			resolve([
				{name:'list1',title:'List 1'},
				{name:'list2',title:'List 2'},
				{name:'list3',title:'List 3'},
				{name:'list4',title:'List 4'}])

		});

}






}
