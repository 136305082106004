
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, AlertController,NavController,ActionSheetController , NavParams} from '@ionic/angular';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { environment } from '../../../../environments/environment';
import {ListenerService} from '../../../services/listeners';

import {ChaEventsService} from '../../../services/chaevents';
import { SocketService } from '../../../services/socket';
import {HelperService} from '../../../services/helper';
import {CommunityService} from '../../../services/community';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { Storage } from '@ionic/storage';
import { LoginService} from '../../../services/login';
@Component({
  selector: 'cha-form',
  templateUrl: './cha-form.component.html',
  styleUrls: ['./cha-form.component.scss'],
})
export class ChaFormComponent extends ListenerService implements OnInit  {

  

  step1Form: FormGroup;
 
  current_user:any;
  community:any;
  page:any;
  form_components:any=[];
  chaloading:any=1;
  title:any = '';
  description:any = '';
  main_button:any = null;
  form_buttons:any = [];
  constructor(
    public params: NavParams,
    
    private modalCtrl: ModalController, 
    private viewCtrl: ModalController,
    private alertCtrl: AlertController,
    public navCtrl: NavController,
    public actionSheetController: ActionSheetController,
    private iab: InAppBrowser, 
    public formBuilder: FormBuilder,

    public events: ChaEventsService,
    public socket: SocketService, 
    public helper: HelperService,
    public communityService:CommunityService,
    public geolocation: Geolocation,
    public storage: Storage,
    public loginService: LoginService,


    ) {

    super(events,socket,helper,communityService,geolocation,storage,loginService);


    this.current_user = this.params.get('current_user');
    this.community = this.params.get('community');
    this.page = this.params.get('page');

    console.log('Pages1++:',this.page);
    this.initial();



    /*this.step1Form = formBuilder.group({
          product_type: ['', Validators.compose([ Validators.required])],
          school_name: ['', Validators.compose([ Validators.required])],
          product_desc: ['', Validators.compose([ Validators.required])],
          product_price: ['', Validators.compose([ Validators.required])],
          product_unit: ['', Validators.compose([ Validators.required])],
          available_quantity: ['', Validators.compose([ Validators.required])]
         
          
        });*/

    
    }

    ngOnInit(){

    }

    async initial(){
      this.step1Form = this.chaFormBuilder(this.page);
      this.form_components = await this.formComponents(this.page);
      this.form_buttons = this.formButtons(this.page);
      console.log('this.form_components1++:',this.form_components);
    }


    chaFormBuilder(page): FormGroup{
      let config: any = [];
      if(page.layout.parameters.sections.form_builder){
        this.title = page.layout.parameters.sections.page_info.title;
        this.description = page.layout.parameters.sections.page_info.description;

        config = page.layout.parameters.sections.form_builder;
      }
      const group: any = {};

      config.forEach(field => {
        const validators = field.validators.map((validator: string) => {
          switch (validator) {
            case 'required':
              return Validators.required;
            case 'email':
              return Validators.email;
            case 'minLength':
              return Validators.minLength(5); // Adjust as needed
            case 'maxLength':
              return Validators.maxLength(20); // Adjust as needed
            // Add more cases as needed
            default:
              return null;
          }
        }).filter((v: any) => v !== null);

        group[field.name] = ['', Validators.compose(validators)];
        
      });

      return this.formBuilder.group(group);
    }

    async formComponents(page){
      let fields:any = [];
      let components = [];
      
      if(page.layout.parameters.sections.form_builder){

        fields = page.layout.parameters.sections.form_builder;
        
      }

      for(let field of fields){

        if(field.type == 'dropdown'){
          let dropdown_data = await this.getDropDown(field.action[0].backend_url,this.communityService);
          
          components.push({
            name:field.name,
            title:field.title,
            type:field.type,
            dropdown_data:dropdown_data
            })


        }
        else{
          components.push({
            name:field.name,
            title:field.title,
            type:field.type
            })
        }
      }
      this.chaloading = 0;

      return components;

    }

    formButtons(page){
      let buttons:any = [];
      

      if(page.layout.parameters.sections.form_buttons){

        buttons = page.layout.parameters.sections.form_buttons;
        console.log('form_buttons::',buttons);
        
      }
      for(let button of buttons){
        if(button.button_type == 'save_button'){
          this.main_button = button;
        }

      }

      return buttons;

    }

    action(chabutton){
      if(chabutton.button_type == 'save_button'){
        console.log('save the data');
      }
    }


    close(){
      this.viewCtrl.dismiss('');
    }
  
  
 

}