import { Component, OnInit } from '@angular/core';
import { NavController,ModalController, NavParams, LoadingController } from '@ionic/angular';
import {Config} from '../../config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MoneyService} from '../../services/money';
import { AlertController } from '@ionic/angular';
import {DomSanitizer} from '@angular/platform-browser';
import {ChaEventsService} from '../../services/chaevents';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-payment-integration',
  templateUrl: './payment-integration.component.html',
  styleUrls: ['./payment-integration.component.scss'],
})
export class PaymentIntegrationComponent implements OnInit {
	data:any;
  chaurl:any;
	pin:any;
  placeholder:any ='Enter Amount';
	submitForm: FormGroup;
  type:any;
  safeUrl:any;
  safeUrlb:any;
  loading:any=null;
   chaloading:any=1;
   browser:any;


  constructor(
  	public navParams: NavParams,
  	public viewCtrl: ModalController,
  	public formBuilder: FormBuilder,
  	private moneyService:MoneyService,
  	public alertController: AlertController,
    private domSanit: DomSanitizer,
    private events: ChaEventsService,
    public loadingController:LoadingController,
    private iab: InAppBrowser
     ) {
  	this.data = this.navParams.get('data');
    this.chaurl = this.navParams.get('url');
    this.safeUrlb = this.chaurl + this.data;

    console.log('do we have good URL:',this.safeUrlb);
 
    

   }

  ngOnInit() {
    this.safeUrl = this.getSafeUrl() ;
     let self = this;
     
     /*this.browser = this.iab.create(this.safeUrlb);
    //this.events.subscribe('chat-message', this.messageHandleWrap);
    this.browser.addEventListener('loadstop', function(e) {
        self.chaloading = 0;
        let loc = e.url;
        //when url is changed check if the url contains your specific callbackURL
      if (loc.search(environment.server) >= 0) {
        //at this point close your inapp browser
        //you will land on the index page within your application.
        self.browser.close();
        //your code after successful authentication
        }
    });*/

    this.events.chaPayment().subscribe((data) => {
           console.log('Data received HERE', data);
           //self.browser.hide();
           //self.browser.close();
           //self.dismiss({paymentdata:data,paymenttoken:this.data,paymenturl:this.chaurl});

           
    });
  }
  ionViewWillEnter(){
    //this.safeUrl = this.getSafeUrl() ;
  }

  getSafeUrl(){
    return this.domSanit.bypassSecurityTrustResourceUrl(this.safeUrlb) ;

  }

  

  dismiss(msg){
  	this.viewCtrl.dismiss(msg);
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
    spinner: 'bubbles',
    message:'Ufulu Micro Service Loading ...'
    //duration: 2000
    });
return await this.loading.present();
}


loadingDismiss(){
  //this.chaloading = 0;
  setTimeout(() => this.chaloading = 0, 3000);

  

  
}


  

}
