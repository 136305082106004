import { Product, CartService } from '../../services/cart.service';
import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController,NavController,ActionSheetController , NavParams} from '@ionic/angular';
import {LocationService} from '../../services/location.service';
import {PaymentMethodsComponent} from '../payment-methods/payment-methods.component';
import {PayComponent} from '../pay/pay.component';
import {PaymentPreviewComponent} from '../payment-preview/payment-preview.component';
import {ProductsService} from '../../services/products';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { LoginService} from '../../services/login';


@Component({
  selector: 'app-taobao-order-modal',
  templateUrl: './taobao-order-modal.component.html',
  styleUrls: ['./taobao-order-modal.component.scss'],
})
export class TaobaoOrderModalComponent implements OnInit {

  order:any;
 
  constructor(
    public params: NavParams,
    private cartService: CartService, 
    private modalCtrl: ModalController, 
    private viewCtrl: ModalController,
    private alertCtrl: AlertController,
    public navCtrl: NavController,
    private locationService:LocationService,
    public actionSheetController: ActionSheetController,
    private productsService:ProductsService,
    private iab: InAppBrowser, 
    private loginService: LoginService,) {

    this.order = this.params.get('order');

     }
  
  ngOnInit() {
    


  }

 taobao(url){
   this.iab.create(url);
 }


 checkIfSupplier(){
   if(this.loginService.user._id == this.order.dealerId)
   {
     return true;
   }
   else
   {
     return false;
   }
 }


  async presentAlert(title,subtitle,message)
  {
    const alert = await this.alertCtrl.create({
        header: title,
        subHeader: subtitle,
        message: message,
        buttons: ['OK']
      });

      await alert.present();
  }

close(){
  this.viewCtrl.dismiss('');

}

}